import React, { useEffect } from "react";
import classes from "../../styles/asset.module.css";
import { Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getProject, getProjectById } from "../../redux/features/project/projectSclice";
import { getAllUsers } from "../../redux/features/user/userSlice";
import { createTask, getTaskById, resetTask } from "../../redux/features/task/taskSclice";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {Dropdown} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { SelectAssignedTo } from "../selectPersons/SelectPersons";
export default function AddtaskModal({
  openModalCreate,
  closeModalCreate,
  formDataFromLocation,
}) {
  const dispatch = useDispatch();

  const dummyOptions1 = [
    { id: 1, label: "TODO",name:"To Do" , background : "#0155a3" },
    { id: 2, label: "In Progress", name:"In Progress", background : "#db9004" },
    { id: 3, label: "Completed", name:"Completed" , background : "#00872a" },

  ];
  const initialDataState = {
    id: null,
    name: "",
    status: "",
    assigned_to: [],
    description: "",
    start_date: "",
    finish_date: "",
    project: formDataFromLocation?.id,
  };
  const [data, setData] = React.useState(initialDataState);
  //fetching project
  React.useEffect(() => {
    dispatch(getProject());
  }, [dispatch]);
  
  useEffect(() => {
    if (openModalCreate) {
      setData(initialDataState)
    }
  }, [openModalCreate])
  
  
  // console.log("formDataFromLocation",formDataFromLocation)

  //fetching uers for assign to
  const getUserProfileData = async ()=> {
    await dispatch(getAllUsers());
  }
  React.useEffect(() => {
    getUserProfileData()
  }, []);
  const { allUsers } = useSelector((state) => state.user);
  // const userOptions = allUsers.map((user) => ({
  //   id: user.id,
  //   label: `${user.first_name} ${user.last_name}`, 
  // }));
  const handleFormSubmit = async(e)=>{
    e.preventDefault();
    if (data.name && data.status && data.start_date && data.finish_date) {
    await dispatch(createTask({...data , assigned_to : data.assigned_to.map(e => e.value)}))
    await dispatch(getProjectById(formDataFromLocation.id))
    closeModalCreate()
    }else{
      toast.warning('Please fill required fields then try again', {
        toastId: 'createatask',
    });
    }
   
  }
  
  const handleChange = (event) => {
    setData({
      ...data,
      assigned_to: event,
    });
  };

  const handleFinishDateChange = (e) => {
    const start = new Date(data.start_date).getTime();
    const end = new Date(e.target.value).getTime();
    if (start <= end) {
      setData({ ...data, finish_date: e.target.value });
    }else{
      toast.error("Please select a valid start date and end date.")
    } 
    
  };
  
  return (
    <>
      <div className={classes.assets}>
        <Drawer
          anchor="right"
          open={openModalCreate}
          onClose={closeModalCreate}
          PaperProps={{
            style: {
              width: "100%",
              maxWidth: "600px",
              height: "100%",
            },
          }}
        >
          <form
            action="{{action}}"
            className="h-100"
            method="POST"
            onSubmit={handleFormSubmit}
          >
            <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
              <div className="col-12 row px-2">
                <div
                  className="my-auto"
                  style={{ fontWeight: "bold", padding: "9px" }}
                >
                  Add Task
                </div>
              </div>
            </div>

            <div className="col-12 pb-1">
              <div className="card-body row">
                <div
                  className="my-auto"
                  style={{ fontWeight: "bold", padding: "9px" }}
                >
                  <h3> Project Name : {formDataFromLocation?.name}</h3>
                </div>
                <div className="col-lg-12 col-sm-12">
                  <span className="font-weight-normal row col-12">
                    Task
                    <div className="font-danger">*</div>
                  </span>
                  <input
                    type="text"
                    name="name"
                    className="form-control mb-2"
                    value={data.name}
                    onChange={(e) => setData({...data,name:e.target.value})}
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                <span className="font-weight-normal row col-12">
                  Description:
                </span>
                <textarea
                  type="text"
                  name="description"
                  className="form-control mb-2"
                  value={data.description}
                  onChange={(e) => setData({...data,description:e.target.value})}
                />
              </div>
            <div className="col-lg-12 col-sm-12">
              <label className="font-weight-normal">
                Assigned To
              </label>
              {/* <div className="col-12 p-0">
                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">User</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={data.assigned_to}
                      label="User"
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {userOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              </div> */}
              <SelectAssignedTo teams= {allUsers} handelChange={handleChange} assignedTo={data.assigned_to}/>
            </div>
              <div className="col-lg-12 col-sm-12 pt-3">
                <label className="font-weight-normal">
                  Select Status <span className="font-danger">*</span>
                </label>
                <select className="form-control select-dash custom-select2 mb-2" 
                name="status" id="status"
                onChange={(e) => setData({...data,status:e.target.value})}
                value={data.status}
                >
                <option value="" disabled selected>--SELECT--</option>
                {dummyOptions1.map(option =>(

                        <option style={{background: option.background , color: "white"}} key={option.id} value={option.label}>{option.name}</option>
                    ))}
                </select>
                {/* //todo modify design to implement a chip like approach */}
        {/* <Dropdown onChange={(e) => setData({...data, status:e.target.value})} className="mb-2">
        <Dropdown.Toggle variant="light" id="dropdown-custom-components">
          {data.status ? data.status : '--SELECT--'}
        </Dropdown.Toggle>
        <Dropdown.Menu className="super-colors">
          {dummyOptions1.map((option) => (
            <Dropdown.Item key={option.id} eventKey={option.label}>
              <div
                style={{
                  backgroundColor: option.background,
                  color: 'white',
                  padding: '5px 10px',
                  borderRadius: '20px',
                  margin: '2px',
                }}
              >
                {option.name}
              </div>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown> */}
                </div>
            <div className=" col-lg-6 col-md-6 col-sm-12">
              <label className="font-weight-normal">
                Start Date <label className="font-danger m-0">*</label>
              </label>
              <input
                type="datetime-local"
                className="form-control mb-2"
                name="start_date"
                id="start_date"
                placeholder="Start Date"
              
                value={data.start_date}
                onChange={(e) => setData({...data,start_date:e.target.value,actual_start_date:e.target.value})}
              />
            </div>
          <div className=" col-lg-6 col-md-6 col-sm-12">
              <label className="font-weight-normal">
                Finish Date <label className="font-danger m-0">*</label>
              </label>
              <input
                type="datetime-local"
                className="form-control mb-2"
                name="finish_date"
                id="finish_date"
                placeholder="Start Date"
              
                value={data.finish_date}
                // onChange={(e) =>setData({...data,finish_date:e.target.value})}
                onChange={handleFinishDateChange}
              />
            </div>
            {/* <div className=" col-lg-6 col-md-6 col-sm-12">
              <label className="font-weight-normal">
                Actual Start Date{" "}
                <label className="font-danger m-0">*</label>
              </label>
              <input
                type="datetime-local"
                className="form-control mb-2"
                name="actual_start_date"
                id="actual_start_date"
                placeholder="Start Date"
              
                value={data.actual_start_date}
                onChange={(e) =>setData({...data,actual_start_date:e.target.value})}
              />
            </div> */}
            {/* <div className=" col-lg-6 col-md-6 col-sm-12">
              <label className="font-weight-normal">
                Actual Finish Date{" "}
                <label className="font-danger m-0">*</label>
              </label>
              <input
                type="datetime-local"
                className="form-control mb-2"
                name="actual_finish_date"
                id="actual_finish_date"
                placeholder="Start Date"
              
                value={data.actual_finish_date}
                onChange={(e) => setData({...data,actual_finish_date:e.target.value})}
              />
            </div> */}
              </div>
            </div>

            <div className="row  m-0 p-2 page-profile-footer">
              <button
                className="btn button-btn pmd-btn-fab primary-btn"
                type="submit"
                onClick={handleFormSubmit}
              >
                <span className="button__text">Save</span>
              </button>
            </div>
          </form>
        </Drawer>
      </div>
    </>
  );
}
