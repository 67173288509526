import React, { useEffect, useState } from 'react'
import classes from "../../styles/asset.module.css"

import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import { Drawer } from "@mui/material";
import PopUpDeleteModal from '../popUpModals/popUpDeleteModal'
import Loader from '../loader/loader';

function SafetyInductionPeriodSetting() {
  const dispatch = useDispatch();
  const [data, setData] = useState({ first_name: "",last_name:"", email: "", phone_number: "", company_name: "" })

  useEffect(() => {

  }, [])

  useEffect(() => {
    // setData({
    //   first_name: data.first_name,
    //   last_name: data.last_name,
    //   email: data.email,
    //   phone_number: data.phone_number,
    //   company_name: data.company_name,
    // })
  }, [])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // Getting value from redux
  const {
    contractorModalUpdate,
    contractorModalDelete,
    isCreateContractorLoading,
    isUpdateContractorLoading,
    isDeleteContractorLoading,
    isCreateContractorSuccess,
    isUpdateContractorSuccess,
    isDeleteContractorSuccess,
    isCreateContractorError,
    isUpdateContractorError,
    isDeleteContractorError,
  } = useSelector((state) => state.project);



  const openDrawer = () => {
    // dispatch(openContractorModalCreate());
    setIsDrawerOpen(true); // Open the drawer
  };

  const closeDrawer = () => {
    // dispatch(closeContractorModalCreate());
    setIsDrawerOpen(false); // Close the drawer
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // await dispatch(createContractor(data))
    setData({ first_name: "",last_name:"", email: "", phone_number: "", company_name: "" });
    closeDrawer(); // Close the drawer after submission
  };

  const handleContractorEditclick = (contractor) => {
    // dispatch(openContractorModalUpdate())
    // dispatch(setContractor(contractor))
  }
  const [getcontactorID, setContractorId] = useState(null);
  const handleContractorDeleteClick = (contractor) => {
    // dispatch(openContractorModalDelete())
    setContractorId(contractor.id)
  }

  const handleDeleteContractor = async () => {
    // await dispatch(deleteContractor(getcontactorID))
    // dispatch(closeContractorModalDelete())
  }



  function displayToast() {
    if (isCreateContractorError) {
      toast.error('Something went wrong with creating the Contractor', {
        toastId: 'contructorCreateError',
      });
    } else if (isUpdateContractorError) {
      toast.error('Something went wrong at updating Contractor', {
        toastId: 'updatingContractorError'
      })
    } else if (isDeleteContractorError) {
      toast.error('Something went wrong at deleting Contractor', {
        toastId: 'deletingContractorError'
      })
    } else if (isCreateContractorSuccess) {
      toast.success('Contractor Created Successfully', {
        toastId: 'createContractorSuccess'
      })
    } else if (isUpdateContractorSuccess) {
      toast.success('Contractor Updated Successfully', {
        toastId: 'updateContractorSuccess'
      })
    } else if (isDeleteContractorSuccess) {
      toast.success('Contractor Deleted Successfully', {
        toastId: 'deleteContractorSuccess'
      })
    }
  }
  const isLoading =
    isCreateContractorLoading ||
    isUpdateContractorLoading ||
    isDeleteContractorLoading;

  return isLoading ? (
    <Loader />
  ) : (
    <div>
      {displayToast()}
      <h3 className="card-title py-3 px-3 col-lg-12"></h3>
      <div className="card-body p-0 m-0">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>Safety Induction Validity Period </b>
                  </span>
                </span>
      <div className='col-12 p-0'>


              </div>
                {/* <div className="card-tools">
                  <button
                    type="button"
                    className="mr-2 cursor_pointer"
                    title="Add Department"
                    onClick={openDrawer}
                  >
                    Add Contractor
                  </button>
                </div> */}
              </div>
              <div className={classes.assetActions}>
            <input
              style={{ backgroundColor: 'white', margin:10 }}
              type='number'
              placeholder='Set expiry interval'
              step={1}
              min={1}
              defaultValue={1}
            //   onChange={e =>
            //     dispatch(setInductionSearchText(e.target.value.toLowerCase()))
            //   }
            /> 
          
            <span>    Month(s)</span>
          </div>
            <div style={{margin:4}}>

            <button
              title='Save'
              //   onClick={() => handleShow()}
              type='button'
              className={'btn button-btn pmd-btn-fab primary-btn'}
              >
                Save
            </button>
              </div>

            </div>
          </div>

        </div>
      </div>
      <br />
      <br />
      <br />


    </div>
  )
}

export default SafetyInductionPeriodSetting