import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classess from "../../../styles/asset.module.css";
import QRCode from 'qrcode';
import moment from 'moment';
import {
    setTitle,
    setDescription,
    setSite,
    setWorkPlace,
    setWorkType,
    setAttributes,
    setContractor,
    setStartDate,
    setEndDate,
    setExpiryDate,
    setDocumentNumber,
    setPermitNumber,
} from '../../../redux/features/eptw/eptwSlice';
import { useDispatch, useSelector } from 'react-redux';
import { jsPDF } from 'jspdf';

async function generateQRCode(data) {
    try {
        const qrDataUrl = await QRCode.toDataURL(data);
        return qrDataUrl;
    } catch (error) {
        console.error('Error generating QR code:', error);
        return null;
    }
}


const printEptw = async (ePtwData) => {
    const sections = Object.keys(ePtwData.sections);
    const doc = new jsPDF();

    const addCheckbox = (x, y) => {
        doc.setDrawColor(0); // Set border color to black
        doc.setFillColor(0); // Set fill color to black
        doc.rect(x, y, 5, 5, 'FD'); //
    };
    //checkbox dimension
    const checkboxSize = 8; // Size of the checkbox
    const checkboxX = 15;   // X-coordinate
    let checkboxY = 195;   // Y-coordinate

    //pdf title name
    doc.setFontSize(26);
    doc.text('PERMIT TO WORK', 115, 20,);

    // Add company logo
    // const img = new Image();
    // img.src = '/logo192.png';
    // doc.addImage(img, 'png', 5, 5, 50, 20);

    //add QR
    const qrCodeDataUrl = await generateQRCode(JSON.stringify(ePtwData));

    if (qrCodeDataUrl) {
        // Add the QR code to the PDF
        doc.addImage(qrCodeDataUrl, 'PNG', 130, 30, 50, 50); // Adjust position and size as needed
    }

    //fill with gray color
    doc.setFillColor(200, 200, 200);

    //rectangle around document
    doc.setLineWidth(0.2);
    doc.rect(5, 25, 200, 270);

    //document number and expiry date
    doc.setFontSize(14);
    doc.text("Document Number : " + ePtwData.document_number, 5, 10);
    doc.text("Permit Expiry Date: " + new Date(ePtwData.end_date).toLocaleDateString(), 5, 20);

    // Set font size and add company information
    doc.setFontSize(12);
    doc.text("Company Name", 10, 35);
    doc.text("Address Here", 10, 45);
    doc.text("Email: " + 'foxproject@foxmy.io', 10, 55);
    doc.text("Tel: " + '+60111111111', 10, 65);


    //contractor info
    doc.text("Contractor Name: " + ePtwData.contractor.name, 10, 98);
    doc.text("Email : " + ePtwData.contractor.email, 10, 108);
    doc.text("Company: " + ePtwData.contractor.company_name, 10, 118);

    doc.setFontSize(11);
    doc.setFont('loto');
    doc.setTextColor(0);


    const sectionTitles = sections

    const sectionInfo = sectionTitles.map((title, index) => ({
        y: index * 50 + 20, title
    }));

    // static sections
    const staticSectionInfo = [
        { y: 80, title: 'Section I - REQUESTOR INFORMATION' },
        { y: 120, title: 'Section II - WORK DETAILS' },
        { y: 240, title: 'Section III - FACILITY MANAGER PERMIT APPROVAL' },
    ];

    for (const staticSection of staticSectionInfo) {
        doc.setFillColor(200, 200, 200); // Set section background color
        doc.rect(5, staticSection.y, 200, 10, 'F'); // Section background
        doc.setTextColor(0);
        doc.setFontSize(12);
        doc.text(staticSection.title, 80, staticSection.y + 6); // Section title
        doc.setTextColor(400);
        doc.setFillColor(255, 255, 255); // Reset fill color

        // Rectangle under each section
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.rect(5, staticSection.y, 200, 10);

        // Add content to sections
        doc.setFontSize(10);
        doc.setTextColor(0);


        doc.setFillColor(0, 0, 0); // Set section background color

        doc.setFont("Arial");
    }
    doc.setFontSize(13); // Set font size for the attribute name
    doc.text('Site :' + ePtwData.site, 12, 140);
    doc.text('Work place :' + ePtwData.work_place, 12, 150);
    doc.text('Work description :' + ePtwData.work_description, 12, 160);
    doc.addPage()
    //rectangle around document
    doc.setLineWidth(0.2);
    doc.rect(5, 25, 200, 270);
    // Draw sections, lines, and text
    for (const section of sectionInfo) {
        doc.setFillColor(200, 200, 200); // Set section background color
        doc.rect(5, section.y, 200, 10, 'F'); // Section background
        doc.setTextColor(0);
        doc.setFontSize(12);
        doc.text(section.title, 70, section.y + 6); // Section title
        doc.setTextColor(400);
        doc.setFillColor(255, 255, 255); // Reset fill color

        // Rectangle under each section
        doc.setDrawColor(0, 0, 0);
        doc.setLineWidth(0.2);
        doc.rect(5, section.y, 200, 10);

        // Add content to sections
        doc.setFontSize(10);
        doc.setTextColor(0);


        doc.setFillColor(0, 0, 0); // Set section background color

        doc.setFont("Arial");
    }


    doc.setFontSize(13); // Set font size for the attribute name
    // doc.text('The project will involve the following :', 12, 190);
    // //todo uncomment this to create the checkboxes 
    // ePtwData.attributes.forEach((attribute) => {
    //     // Draw the checkbox outline (a small rectangle)
    //     doc.setLineWidth(0.5); // Set the line width
    //     doc.rect(checkboxX, checkboxY, checkboxSize, checkboxSize); // Draw the checkbox

    //     // Add the attribute name next to the checkbox
    //     doc.setFontSize(12); // Set font size for the attribute name
    //     doc.text(attribute.name, checkboxX + checkboxSize + 5, checkboxY + checkboxSize / 2 + 3);

    //     // Increase the Y-coordinate for the next checkbox
    //     checkboxY += checkboxSize + 5; // Adjust spacing as needed
    // });
    // Additional notes
    doc.setFontSize(10);
    doc.text("Note: ", 10, 280);


    doc.text("Printed at: " + new Intl.DateTimeFormat('en-MY', { dateStyle: 'full', timeStyle: 'long' }).format(new Date()), 60, 288);
    // Generate a Blob from the PDF data
    const pdfBlob = doc.output('blob');

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl);

}
function DetailsModal({ open, onClose, rowData }) {
    const dispatch = useDispatch();
    const {
        title,
        description,
        site,
        workPlace,
        documentNumber,
        permitNumber,
        workType,
        attributes,
        contractor,
        startDate,
        endDate,
        expiryDate,
    } = useSelector(state => state.eptw)
    useEffect(() => {
        dispatch(setTitle(rowData?.ePTWName || rowData?.title))
        dispatch(setDescription(rowData?.EptwDescription || rowData?.work_description))
        dispatch(setSite(rowData?.site))
        dispatch(setWorkPlace(rowData?.work_place))
        dispatch(setDocumentNumber(rowData?.document_number))
        dispatch(setPermitNumber(rowData?.permit_number))
        dispatch(setWorkType(rowData?.eptWType || rowData?.work_type))
        dispatch(setContractor(rowData.contractor?.first_name))
        dispatch(setStartDate(rowData?.start_date))
        dispatch(setEndDate(rowData?.end_date))
        dispatch(setExpiryDate(rowData?.expiry_date))
    }, [])

    const handelTitle = (e) => {
        dispatch(setTitle(e.target.value))
    }

    const handelDescription = (e) => {
        dispatch(setDescription(e.target.value))
    }
    const handelSite = (e) => {
        dispatch(setSite(e.target.value))
    }
    const handelWorkPlace = (e) => {
        dispatch(setWorkPlace(e.target.value))
    }
    const handelDocumentNumber = (e) => {
        dispatch(setDocumentNumber(e.target.value))
    }
    const handelPermitNumber = (e) => {
        dispatch(setPermitNumber(e.target.value))
    }
    const handelWorkType = (e) => {
        dispatch(setWorkType(e.target.value))
    }
    const handelAttributes = (e, id) => {
        const arrOfAttributes = []
        attributes.forEach(item => {
            if (item.id === id) {
                arrOfAttributes.push({ ...item, value: !item.value })
            } else {
                arrOfAttributes.push(item)
            }
        })
        dispatch(setAttributes(arrOfAttributes))
    }
    const handelContractor = (e) => {
        dispatch(setContractor(e.target.value))
    }
    const handleStartDate = (e) => {
        dispatch(setStartDate(e.target.value))
    }
    const handleEndDate = (e) => {
        dispatch(setEndDate(e.target.value))
    }
    const handleExpiryDate = (e) => {
        dispatch(setExpiryDate(e.target.value))
    }
    return (
        <Modal show={open} onHide={onClose} animation={true}>
            <Modal.Header>
                <Modal.Title>Permit Details</Modal.Title>
                <button
                    className='btn secondary-btn'
                    onClick={onClose} type='button'>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="pt-2">
                        <label className="font-weight-normal">Work / Project Title<label className="font-danger m-0">*</label></label>
                        <input required name='meter_name' type="text" className="form-control mb-2"
                            placeholder="Work/Project Title"
                            value={title} onChange={handelTitle}
                            disabled
                        />
                    </div>
                    <div className="pt-2">
                        <label className="font-weight-normal">Work Description<label className="font-danger m-0">*</label></label>
                        <textarea required name='meter_name' type="text" className="form-control mb-2"
                            placeholder="Work Description"
                            onChange={handelDescription}
                            value={description}
                            disabled
                        />
                    </div>
                    {/* <div className="pt-2">
                        <label className="font-weight-normal">Template<label className="font-danger m-0">*</label></label>
                        <input required name='template_name' type="text" className="form-control mb-2"
                            placeholder="Site"
                            value={site} onChange={handelSite}
                            disabled
                        />
                    </div> */}
                     {/* <div className="pt-2">
                        <label className="font-weight-normal">Work Place<label className="font-danger m-0">*</label></label>
                        <input required name='meter_name' type="text" className="form-control mb-2"
                            placeholder="Work Place"
                            value={workPlace} onChange={handelWorkPlace}
                            disabled
                        />
                    </div> */}
                    <div className="pt-2">
                        <label className="font-weight-normal">Document Number<label className="font-danger m-0">*</label></label>
                        <input required name='meter_name' type="text" className="form-control mb-2"
                            placeholder="Document Number"
                            value={documentNumber} onChange={handelDocumentNumber}
                            disabled
                        />
                    </div>
                    <div className="pt-2">
                        <label className="font-weight-normal">Permit Number<label className="font-danger m-0">*</label></label>
                        <input required name='meter_name' type="text" className="form-control mb-2"
                            placeholder="Permit Number"
                            value={permitNumber} onChange={handelPermitNumber}
                            disabled
                        />
                    </div>
                    <div className="pt-2">
                        <label className="font-weight-normal ">Work Type</label><label className="font-danger m-0">*</label>
                        <select className="form-control select-dash custom-select2 mb-2"
                            name="priority2" id="priority2"
                            required
                            disabled
                            onChange={handelWorkType}
                            value={workType}                >
                            <option value="" disabled selected>--SELECT--</option>
                            {["Hot Work", "Cold Work", "Confined Space", "Work at Height", "Excavation", "General"].map(val => (
                                <option key={val} value={val}>{val}</option>
                            ))}
                        </select>
                    </div> 
                    {/* <div className="pt-3">
                        <label className="font-weight-normal">The Task will involve the following:</label>
                        <div>
                            {rowData?.attributes && rowData?.attributes?.length > 0
                                && rowData?.attributes?.map(att => (
                                    <div className="col col-lg-12 col-sm-12 px-0" id="checklist_item">
                                        <div className="row col-sm-12 col-md-12 col-lg-12 col-xs-12 mb-3 px-0 mx-0">
                                            <div className="col col-2 col-md-2 col-lg-2 custom_Checkbox">
                                                <input
                                                    type="checkbox"
                                                    checked={att.value}
                                                    className=" mb-2" placeholder="Result"
                                                    onChange={(e) => handelAttributes(e, att.id)}
                                                    disabled
                                                />
                                            </div>
                                            <div style={{ marginTop: "6.5px" }}>
                                                {att.name}
                                            </div>
                                        </div>
                                    </div>
                                ))}

                        </div>
                    </div> */}
                    <div className="pt-2">
                        <label className="font-weight-normal">Contractor<label className="font-danger m-0">*</label></label>

                        <select className="form-control select-dash custom-select2 mb-2"
                            name="category" id="category2"
                            // onChange={handelContractor}
                            disabled
                            onChange={handelContractor}
                            value={contractor}
                            required
                        >
                            {/* <option value="" disabled selected>--SELECT--</option> */}

                            {/* {contractors && contractors.length > 0 
                    && contractors.map(cont =>(
                        <option key={cont.id} value={cont.id}>{cont.name}</option>
                    ))} */}
                            <option value="">{contractor}</option>


                        </select>
                    </div>

                    <div className="pt-2">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <label className="font-weight-normal">Start Date </label>
                                <input
                                    type="date"
                                    className="form-control mb-2"
                                    name="service_date"
                                    id="meter_service_date"
                                    placeholder="Start Date"
                                    disabled
                                    value={startDate}
                                    onChange={handleStartDate}
                                />
                            </div>
                            <div className=" col-lg-6 col-md-6 col-sm-12">
                                <label className="font-weight-normal">End Date  </label>
                                <input
                                    type="date"
                                    className="form-control mb-2"
                                    name="service_date"
                                    id="meter_service_date"
                                    placeholder="End Date"
                                    disabled
                                    value={endDate}
                                    onChange={handleEndDate}
                                />
                            </div>
                            <div className=" col-lg-12 col-md-12 col-sm-12">
                                <label className="font-weight-normal">Expiry Date  </label>
                                <input
                                    type="date"
                                    className="form-control mb-2"
                                    name="service_date"
                                    id="meter_service_date"
                                    placeholder="Expiry Date"
                                    disabled
                                    value={expiryDate}
                                    onChange={handleExpiryDate}
                                />
                            </div>
                        </div>
                        <div className="pt-2">
                            <label className="font-weight-normal">Status</label>

                            <select className="form-control select-dash custom-select2 mb-2"
                                name="category" id="category2"
                                // onChange={handelContractor}
                                disabled
                                value={rowData?.status}
                                required
                            >
                                {/* <option value="" disabled selected>--SELECT--</option> */}

                                {/* {contractors && contractors.length > 0 
                    && contractors.map(cont =>(
                        <option key={cont.id} value={cont.id}>{cont.name}</option>
                    ))} */}
                                <option value="">{rowData?.status}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={classess.eptwButtons}>
                    <Button variant="contained" size="small" onClick={() => printEptw(rowData)}>
                        Print ePTW PDF
                    </Button>
                </div>
                <Button variant="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DetailsModal
