import React, { useEffect, useMemo, useState } from 'react'
import classes from '../../styles/asset.module.css'
import { DataGrid } from '@mui/x-data-grid';
import Addteams from '../../components/teams/addteams';
import { useDispatch, useSelector } from 'react-redux';
import { activateTeam, deactivateTeam, deleteTeam, getTeamsByAuthentication, reset, setTeam } from '../../redux/features/teams/teamSlice';
import { Drawer } from "@mui/material";
import Loader from '../../components/loader/loader';
import { getAllUsers } from '../../redux/features/user/userSlice';
import profilePicture from '../../assets/img/profile.png';
import PopUpActivateModel from '../../components/teams/popUpModals/popupActivateModal';
import PopUpDeleteModel from '../../components/teams/popUpModals/popUpDeleteModal';
import ManegerPopUp from '../../components/teams/managerEditform/managerPopup';
import MemberPopUp from '../../components/teams/memberEditform/memberPopup';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';
import ProjectContractor from '../../components/createContractor/projectContractor';
import TeamsList from './teamsList';
import { Tab, Tabs } from 'react-bootstrap';
import ContractorList from './contractorList';
export default function Teams() {

  const [key, setKey] = useState(JSON.parse(localStorage.getItem('activeTab')) || 'teams-tab');
  const { user, isLoading, isError, isSuccess } = useSelector(state => state.user);
  useEffect(() => {
    localStorage.setItem('activeTab', JSON.stringify(key));
  }, [key]);

  return (
    <div className=' ml-2'>
      <div className='float-left'>
        <h3 className='px-2'>
          <b>Team Management</b>
        </h3>
      </div>
      <Tabs
        defaultActiveKey={key}
        id='uncontrolled-tab-example'
        className='nav nav-tabs custom_tabs'
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey='teams-tab' title='Teams'>
        <TeamsList/>
         {/* <TeamsTab/> */}
        </Tab>
        {/* {!isLoading && user?.role!=="Manager"?
        <Tab eventKey='approver-tab' title='Approvers'>
        </Tab>
        :null} */}
        <Tab eventKey='contractor' title='Requester'>
         {/* <ProjectContractor/> */}
         <ContractorList/>
        </Tab>
      </Tabs>
    </div>
  );
}



