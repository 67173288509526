import React, { useEffect, useState } from 'react'
import AWS from 'aws-sdk';
import Card from '@mui/material/Card'
import { Button, Modal } from 'react-bootstrap/';
import moment from 'moment';
import { toast } from "react-toastify"
import { useLocation } from 'react-router-dom';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ImageIcon from '@mui/icons-material/Image';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import DrawIcon from '@mui/icons-material/Draw';
import { useDispatch, useSelector } from 'react-redux';
import SuccessPage from '../../components/Success';
import SignatureCanvas from 'react-signature-canvas'
import {
    setWorkType,
    setAttributes,
    setStartDate,
    setEndDate,
    createEptw,
    setIdType,
    setSubcontractorName,
    setsubcontractorRepresentative,
    setContractorName,
    setContractorLastName,
    setContractorEmail,
    setContractorPhoneNumber,
    setCompanyContactNumber,
    setPassportNumber,
    setLicenseNumber,
    setGreenCardNumber,
    setMykadNumber,
    setCompanyName,
    getFillEptw,
    setDescription,
    createFillEptw,
    setSections,
    setForms
} from '../../redux/features/eptw/eptwSlice';
import logo from "../../assets/img/FOX-Logo3.png"
import Loader from '../../components/loader/loader';
// import TemplateReview from '../../components/Steps/templateReview';
import classes from "../../styles/asset.module.css";
import Signature from './Signature';


AWS.config.update({
    region: 'ap-southeast-1',
    accessKeyId: 'AKIA3HYBXAPB3HVXNG6F',
    secretAccessKey: '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',
});

const s3 = new AWS.S3();

function uploadImageToS3(file) {
  const params = {
    Bucket: 'foxproject',
    Key: Date.now().toString(), // Change the Key as needed
    Body: file,
    ContentType: file?.type
  };

  return new Promise((resolve, reject) => {
    s3.upload(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}

function ContractorView() {
    const [showSubContractor, setShowSubContractor] = React.useState(false)
    const [isShowSignModal, setIsShowSignModal] = React.useState(false);
    const [sign, setSign] = React.useState(null);
    const [signFile, setSignFile] = React.useState(null);
    const [signFilePhoto, setSignFilePhoto] = React.useState(null);
    const [signUrlImg, setSignUrlImg] = React.useState(null);
    const [signError, setSignError] = React.useState(false);
    const [theSignatureURL, setTheSignatureURL] = React.useState(null);
    const {
        contractorFirstName,
        contractorLastName,
        subcontractorName,
        subcontractorRepresentative,
        contractorEmail,
        contractorPhoneNumber,
        companyName,
        companyContactNumber,
        passportNumber,
        workType,
        attributes,
        licenseNumber,
        startDate,
        endDate,
        greenCardNumber,
        idType,
        mykadNumber,
        description,
        eptw,
        isEptwsGetLoading,
        sections,
        revisionNumber,
        revisionDate,
        forms
    } = useSelector(state => state.eptw)
    const dispatch = useDispatch();
    const location = useLocation().pathname.split("/")
    const ptwId = location[2]
    const contractorId = location[3]
    const route = `${ptwId}/${contractorId}`
    useEffect(() => {
        dispatch(getFillEptw(route))
    }, [route])


    const convertSectionsToArray = (sections) => {
        const newSections = sections?.map(item =>{
            if (item.type === "Checkbox" && item.value === null) {
                return {...item , value : false}
            }
            return item
        })
        return newSections || []
    }

    useEffect(() => {
        dispatch(setContractorName(eptw?.contractor?.first_name || ""))
        dispatch(setContractorLastName(eptw?.contractor?.last_name || ""))
        dispatch(setSubcontractorName(eptw?.sub_contractor || ""))
        dispatch(setSubcontractorName(eptw?.sub_contractor_representative || ""))
        dispatch(setContractorEmail(eptw?.contractor?.email || ""))
        dispatch(setContractorPhoneNumber(eptw?.contractor?.phone_number || ""))
        dispatch(setWorkType(eptw?.work_type || ""))
        dispatch(setCompanyName(eptw?.contractor?.company_name || ""))
        // dispatch(setAttributes(eptw?.attributes || []))
        // dispatch(setStartDate(eptw?.start_date || ""))
        // dispatch(setEndDate(eptw?.end_date || ""))
        dispatch(setDescription(eptw?.work_description || ""))
        dispatch(setSections(convertSectionsToArray(eptw?.sections && eptw?.sections)))
    }, [eptw])


    const handelContractorName = (e) => {
        dispatch(setContractorName(e.target.value))
    }
    const handelSubcontractorName = (e) => {
        setShowSubContractor(true)
        dispatch(setSubcontractorName(e.target.value))
    }
    const handelSubcontractorRepresentative = (e) => {
        dispatch(setsubcontractorRepresentative(e.target.value))
    }
    const handelContractorEmail = (e) => {
        dispatch(setContractorEmail(e.target.value))
    }
    const handelContractorPhoneNumber = (e) => {
        dispatch(setContractorPhoneNumber(e.target.value))
    }
    const handelCompanyName = (e) => {
        dispatch(setCompanyName(e.target.value))
    }
    const handelCompanyContactNumber = (e) => {
        dispatch(setCompanyContactNumber(e.target.value))
    }
    const handelPassportNumber = (e) => {
        dispatch(setPassportNumber(e.target.value))
    }
    const handelWorkType = (e) => {
        dispatch(setWorkType(e.target.value))
    }   

    const handelSections = async (e, ele, index, idx) => {
                const items = await Promise.all(sections?.map(async (item, i) => {
                    if (i === index) {
                        const f = await Promise.all(item.fields.map(async (l, j) => {
                            if (idx === j) {
                                if (l?.type !== "Checkbox" && l?.type !== "Attachment") {
                                    return { ...l, value: e.target.value };
                                } else if (l?.type === "Attachment") {
                                    const file = e.target.files[0];
                                    const handleFileChange = async () => {
                                        try {
                                            const result = await uploadImageToS3(file);
                                            return result.Location;
                                        } catch (error) {
                                            console.error('Error uploading image:', error);
                                            throw error; // Rethrow the error if needed
                                        }
                                    };
    
                                    try {
                                        const response = await handleFileChange();
                                        return { ...l, value: response };
                                    } catch (error) {
                                        // Handle the error if needed
                                        console.error('Error updating value:', error);
                                        throw error; // Rethrow the error if needed
                                    }
                                } else {
                                    return { ...l, value: !l?.value };
                                }
                            } else return l;
                        }));
                        return { ...item, fields: f };
                    } else return item;
                }));
        dispatch(setSections((items)))
    };
    
    const handelLicenseNumber = (e) => {
        dispatch(setLicenseNumber(e.target.value))
    }
    const handleStartDate = (e) => {
        dispatch(setStartDate(e.target.value))
    }
    const handleEndDate = (e) => {
        dispatch(setEndDate(e.target.value))
    }
    const handleGreenCardNumber = (e) => {
        dispatch(setGreenCardNumber(e.target.value))
    }
    const handleMykadNumber = (e) => {
        dispatch(setMykadNumber(e.target.value))
    }
    const handelIdType = (e) => {
        dispatch(setIdType(e.target.value))
    }
    const handelDescription = (e) => {
        dispatch(setDescription(e.target.value))
    }
    const data = {
        sub_contractor: subcontractorName,
        sub_contractor_representative: subcontractorRepresentative,
        work_description: description,
        // start_date: startDate,
        // end_date: endDate,
        contractor: {
            first_name: contractorFirstName,
            last_name: contractorLastName || "last name",
            email: contractorEmail,
            phone_number: contractorPhoneNumber,
            company_name: companyName,
            company_contact_number: companyContactNumber,
            id_type: idType,
            passport: idType === "Passport" ? {
                passport_number: passportNumber,
                license_number: licenseNumber,
                saftey_green_card_number: greenCardNumber
            } : null,
            mykad: idType === "MyKad" ? {
                mykad_number: mykadNumber,
                license_number: licenseNumber,
                saftey_green_card_number: greenCardNumber
            } : null,
            work_type: workType
        },
    }

    const date = eptw?.revision_date?.split('T')[0];
    const handelSubmit = async (e) => {
        e.preventDefault()
        let isValidSubmission=true
        for (const singleObj of sections) {
            for (const element of singleObj.fields) {
                if (element.isRequired === true && element.value === null) {
                    toast.error(`Field ${element.name} is required`, {
                        toastId: 'permitFillError',
                      });
                    isValidSubmission = false;
                    break; // Break out of the inner loop
                }
            }
        
            if (!isValidSubmission) {
                break; // Break out of the outer loop if an invalid submission is encountered
            }
        }
        
        if (isValidSubmission) {
            dispatch(createFillEptw({ route, data: { ...data, sections } }))
        }
        
    }
    if (isEptwsGetLoading) {
        return <Loader />
    }
    if (eptw?.status === 'Submitted' || eptw?.status === 'Approved' || eptw?.status === 'Resubmitted') {
        return (
            <SuccessPage title=
                {eptw?.status === 'Submitted' ? 'Submitted'
                    : eptw?.status === 'Approved' ? 'Approved' : 'Resubmitted'} />
        )
    }
    else return (
        <>
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-0">
                <div className="col col-lg-3 col-md-4 col-sm-4 col-xs-12  mb-2 logo float-left"
                    style={{ marginTop: "-35px" }}
                >
                    {/* the logo returned in local doesnt work */}
                    <img src={eptw?.company_image||logo} alt='logo' height="110" width="200" />
                </div>
                <div className="col-lg-9 col-sm-12 col-md-8 mt-5 col-xs-12">
                    <h1 style={{ fontWeight: "400", marginLeft: "16px", textAlign: "center" }}>FOX Project Management</h1>
                </div>
            </div>
            <div className="mt-5">
                <center>
                    <div className="card col-lg-7 col-sm-12 col-xs-12 col-md-12 text-left"
                        style={{ display: "block" }}
                    >
                        <Card sx={{ width: "100%" }}>
                            <form action="action" method='POST'
                                onSubmit={handelSubmit}
                            >
                                <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
                                    <div id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                                        <div className="col-lg-12 col-sm-12 pt-3">
                                            <div className="row">
                                                <div className=" col-lg-6 col-md-6 col-sm-12">
                                                    <label className="font-weight-normal">Document Number</label>
                                                    <input disabled required name='meter_name' readOnly value={eptw?.document_number} type="text" className="form-control mb-2"
                                                        placeholder="Document Number"
                                                    />
                                                </div>
                                                <div className=" col-lg-6 col-md-6 col-sm-12">
                                                    <label className="font-weight-normal">Permit Number</label>
                                                    <input disabled required name='meter_name' readOnly value={eptw?.id} type="text" className="form-control mb-2"
                                                        placeholder="Permit Number"
                                                    />
                                                </div>
                                                <div className=" col-lg-6 col-md-6 col-sm-12">
                                                    <label className="font-weight-normal">Revision Number</label>
                                                    <input disabled required name='meter_name' readOnly value={eptw?.revision_number} type="text" className="form-control mb-2"
                                                        placeholder="Revision Number"
                                                    />
                                                </div>
                                                <div className=" col-lg-6 col-md-6 col-sm-12">
                                                    <label className="font-weight-normal">Revision Date</label>
                                                    <input disabled required name='date' readOnly value={date || ""} type="date" className="form-control mb-2"
                                                        placeholder="Revision Date"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pb-3">

                                            {sections?.length > 0 && <div className="col-lg-12 col-sm-12 pt-3">
                                                <div>
                                                    {sections.map((item, index) => (
                                                        <div className={classes.templateSection} key={index}>
                                                            <h3 className={classes.sectionTitle}>{item.title}</h3>
                                                            <ul className={classes.sectionFeilds}>
                                                                {item?.fields?.map((ele, idx) => {
                                                                    const type = ele?.type
                                                                    return type === "String" ||  type === "Date"  ? <div className="col-lg-12 col-sm-12">
                                                                        <label className="font-weight-normal">{ele.name}</label>{ele?.isRequired===true &&<h6 style={{ marginTop: '30px', textAlign: 'center'}} className="font-danger fs-6 m-0">*Required</h6>}
                                                                        <input
                                                                            type={type === "String" ? "text" :  "date" }
                                                                            accept=".pdf, image/*"
                                                                            className="form-control mb-2"
                                                                            // placeholder={ele.type}
                                                                            value={ele.value ? ele.value : ""}
                                                                            onChange={(e) => handelSections(e, ele, index, idx)}
                                                                        />
                                                                    </div> : type === "Checkbox" ? <div className="col-lg-12 col-sm-12">
                                                                        <div className={classes.templateCheckbox} style={{mr:0}}>
                                                                            <input type="Checkbox"
                                                                                checked={ele.value}
                                                                                onChange={(e) => handelSections(e, ele, index, idx)} />
                                                                            <label className="font-weight-normal">{ele.name}</label>
                                                                        </div> 
                                                                        </div> : type == 'Description' ? <div className="col-lg-12 col-sm-12"><h6>{ele.name}</h6></div> :
                                                                            type === "Attachment" ? <div className="col-lg-12 col-sm-12">
                                                                            <label className="font-weight-normal">{ele.name}</label>{ele?.isRequired===true &&<h6 style={{ marginTop: '30px', textAlign: 'center'}} className="font-danger fs-6 m-0">*Required</h6>}
                                                                            <input
                                                                                type="file"
                                                                                accept=".pdf, image/*"
                                                                                className="form-control mb-2"
                                                                                // placeholder={ele.type}
                                                                                onChange={(e) => handelSections(e, ele, index, idx)}
                                                                            />
                                                                            {/* {ele.value && <div style={{width: "80% ", margin: "auto"}}> {ele.typeOfFile.includes("image") ? <a href={ele.value} target="_blank"><img className='sign-image' src={ele.value} alt={ele.name} /> </a> : <a href={ele.value} target="_blank">Link of Attachment</a>} </div>} */}
                                                                            {ele.value && <div style={{width: "80% ", margin: "auto"}}> <a href={ele.value} target="_blank">Link of Attachment</a> </div>}
                                                                        </div> :
                                                                        // <Signature index= {index} idx= {idx} sections={sections} forms={forms} formNumber={formNumber} value={ele.value}/>
                                                                        // <div style={{ cursor: 'pointer' }} onClick={() => setIsShowSignModal(!isShowSignModal)} className='sign-image'>
                                                                        // {theSignatureURL === null ? <input
                                                                        //     value={""}
                                                                        //     name="crop"
                                                                        //     id="crop_image"
                                                                        //     // onChange={cropImage}
                                                                        //     type="file"
                                                                        //     style={{ display: 'none' }}
                                                                        //     accept="image/*"
                                                                        // /> :
                                                                        // <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', padding: '10px' }}><img src={theSignatureURL} /></div>}
                                                                        // {/* setIsShowSignModal(!isShowSignModal) */}
                                                                        // <div className="mx-3" />
                                                                        // <label style={{ marginTop: '30px', textAlign: 'center', color: '#858585', cursor: 'pointer' }}  >
                                                                        //     Add/Edit Signature
                                                                        // </label></div>
                                                                        <div className="col-lg-12 col-sm-12 pt-3">
                                                                        <label className="font-weight-normal">{ele.name}{ele.isRequired===true &&<h6 className="font-danger fs-6 m-0">*</h6>}</label>
                                                                        <Signature required={ele} index= {index} idx= {idx} sections={sections} value={ele.value} />
                                                                        </div>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="col-lg-12 col-sm-12 pt-3">
                                                    <div className="row">
                                                        <div className=" col-lg-6 col-md-6 col-sm-12">
                                                            <label hidden className="font-weight-normal">Start Date  </label>
                                                            <input
                                                                hidden
                                                                onChange={(e) => handleStartDate(e)}
                                                                type="date"
                                                                className="form-control mb-2"
                                                                name="service_date"
                                                                id="meter_service_date"
                                                                placeholder="Start Date"
                                                                value={startDate}
                                                            />
                                                        </div>
                                                        <div className=" col-lg-6 col-md-6 col-sm-12">
                                                            <label hidden className="font-weight-normal">End Date  </label>
                                                            <input
                                                                hidden
                                                                onChange={(e) => handleEndDate(e)}
                                                                type="date"
                                                                className="form-control mb-2"
                                                                name="service_date"
                                                                id="meter_service_date"
                                                                placeholder="End Date"
                                                                value={endDate}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        <center>
                                            <button type="submit" id='srvc-btn' className="btn primary-btn mb-5" > Submit </button>
                                        </center>
                                    </div>
                                </div>

                            </form>
                        </Card>
                    </div>
                </center>
            </div>
        </>
    )
}
function MyVerticallyCenteredModal({
    showModal,
    handleClose,
    primaryButtonText,
    handleCreateSave,
    children,
    handleCreateTrim,
    clearButtonText,
    setTab,
    tab,
    setSignFile,
    setSignFilePhoto,
    setSign,
    setSignUrlImg,
}) {
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal} onHide={handleClose}
        >
            <Modal.Header style={{ minWidth: "100%" }} closeButton>
                <Modal.Title style={{ width: "100%" }} id="contained-modal-title-vcenter">
                    {/* {modalTitle} */}
                    <div className='signature-header'>
                        <div onClick={() => {
                            setTab("sign")
                            setSignFile(null)
                            setSignFilePhoto(null)
                        }}>
                            <DrawIcon />
                            <p>Draw</p>
                        </div>
                    </div>
                </Modal.Title>
                <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={handleClose}
                >
                    <span aria-hidden='true'>&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer style={{ minWidth: "100%", justifyContent: "space-between" }} >
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <div>
                    {tab === "sign" && <Button variant='primary' onClick={handleCreateTrim}>
                        {clearButtonText}
                    </Button>}
                    <span className='mr-2' />
                    <Button variant='primary' onClick={handleCreateSave}>
                        {primaryButtonText}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}
export default ContractorView
