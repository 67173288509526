import React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/fontawesome-free-solid'
import { faFileCircleCheck, faUsersLine } from '@fortawesome/free-solid-svg-icons'
import foxSafety from '../../../assets/img/safety.jpg'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import './SafetyHome.css'
import { useDispatch, useSelector } from 'react-redux'
import { reset } from '../../../redux/features/safety/safetySlice'

const SafetyHome = () => {
  const backButtonStyle = {
    backgroundColor: '#f63854',
    color: 'white',
    '&:hover': {
      backgroundColor: '#f63854',
    },
  };
  const dispatch = useDispatch()
  const { isPostSafetyVideoSuccess, isPostSafetyVideoError } = useSelector(
    state => state.safety
  )

  function displayToast() {
    if (isPostSafetyVideoSuccess) {
      toast.success('Safety Video Successfully posted', {
        toastId: 'Safety Video Successfully posted'
      })
    } else if (isPostSafetyVideoError) {
      toast.error('Something went wrong', {
        toastId: 'Something went wrong'
      })
    }
    dispatch(reset())
  }

  const history = useHistory()
  return (
    <Box>
      {displayToast()}
      <Box
        sx={{
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center'
        }}
      >
        <section className='safety-home-container'>
          <Box sx={{ bgcolor: 'white', borderRadius: '20px', flexGrow: 1, padding: 3 }}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={4}>
                <div>
                  <img src={foxSafety} alt='' />
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div>
                  <h2>Help keep your workplace safe</h2>
                  <p>Use FOX safety feature to ensure workers watch and understand the safety requirements.</p>
                  <Button
                    sx={{ mt: 4 }}
                    style={backButtonStyle}
                    onClick={e => history.push('/safetyDashBoard')}
                  >
                    Safety Dashboard
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          marginTop: '20px'
        }}
      >
        <section
          style={{ cursor: 'pointer' }}
          onClick={e => history.push('/safety-induction')}
          className='safety-home-container upload-video'
        >
          <Box sx={{ bgcolor: 'white', borderRadius: '20px', flexGrow: 1, padding: 1 }}>
            <Grid container spacing={2}>
              <Grid sx={{ marginLeft: '30px' }} item xs={12} md={4}>
                <div style={{ marginTop: '10px' }}>
                  <FontAwesomeIcon
                    icon={faVideo}
                    size='2xl'
                    style={{ color: '#f63854', fontSize: '50px' }}
                  />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <h5>Create New Induction Session</h5>
                  <p>
                    This allows you to create a new induction session which can be shared with contractors.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: '20px'
      }}>
        <section style={{ cursor: 'pointer' }} onClick={e => history.push('/videos')} className='safety-home-container upload-video'>
          <Box sx={{ bgcolor: 'white', borderRadius: '20px', flexGrow: 1, padding: 1 }}>
            <Grid container spacing={2}>
              <Grid sx={{ marginLeft: '30px' }} item xs={12} md={4}>
                <div style={{ marginTop: '10px' }}>
                  <FontAwesomeIcon icon={faUsersLine} size="2xl" style={{ color: "#f63854", fontSize: '50px', }} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <h5>Manage Induction Video</h5>
                  <p>This section allows you to manage your inductions</p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Box>
      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        marginTop: '20px'
      }}>
        <section style={{ cursor: 'pointer' }} onClick={e => history.push('/templates-quiz')} className='safety-home-container upload-video'>
          <Box sx={{ bgcolor: 'white', borderRadius: '20px', flexGrow: 1, padding: 1 }}>
            <Grid container spacing={2}>
              <Grid sx={{ marginLeft: '30px' }} item xs={12} md={4}>
                <div style={{ marginTop: '10px' }}>
                  <FontAwesomeIcon icon={faFileCircleCheck} size="2xl" style={{ color: "#f63854", fontSize: '50px', }} />
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <h5>Manage Induction Quiz</h5>
                  <p>This section allows you to manage your inductions</p>
                </div>
              </Grid>
            </Grid>
          </Box>
        </section>
      </Box>
    </Box>
  )
}

export default SafetyHome
