/* eslint-disable no-unused-vars */
import { assert, http } from '../../../utils';

import { BASE_URL } from '../../../utils/axios';

const getAnalyticsForDashboard = async filterData => {
  const queryParamsString = Object.entries(filterData)
    .filter(([key, value]) => value !== null) // Filter out empty values
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const res = await http.post(`${BASE_URL}/analytics/analytics/?${queryParamsString}`, { "duration": "year" });
  return assert(res, res.data, 'Retrieval of analytics failed', res);
};

const AnalyticsService = {
  getAnalyticsForDashboard,
};
export default AnalyticsService;
