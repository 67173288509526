import React, { useEffect, useState } from 'react'
import classes from '../../../styles/asset.module.css'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { CardContent, TextField } from '@mui/material'
import { setSessiontitle } from '../../../redux/features/session/sessionSlice'

const InductionManagementReview = ({ customSection }) => {
  const { quizName, video_details, session } = useSelector(
    state => state.session
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setSessiontitle(session?.title))
  }, [])
  return (
    <div>
      <Box>
        <Typography alignContent={'center'} variant='h5' component='div'>
          Session Name
        </Typography>
        <TextField
          required
          fullWidth
          defaultValue={session?.title}
          onChange={e => dispatch(setSessiontitle(e.target.value))}
          sx={{ mt: 1 }}
          id='session-name'
          label='Session Name'
          type='text'
        />
      </Box>
      <div style={{ height: '400px', overflowY: 'auto', marginTop: '20px' }}>
        <h3 className={classes.templateName}>Quiz Name : {quizName}</h3>
        {customSection.map((item, index) => (
          <div className={classes.templateSection} key={index}>
            <h3 className={classes.sectionTitle}>
              Question {index + 1} : {item.title}
            </h3>
            <p>List of answer</p>
            <ul className={classes.sectionFeilds}>
              {item.feilds.map((ele, idx) => {
                const type = ele.type
                return type !== 'Checkbox' ? (
                  <div className='col-lg-12 col-sm-12'>
                    <label className='font-weight-normal'>
                      {idx + 1} ) {ele.name}
                    </label>
                    <input
                      type={type === 'Right' ? 'Right Answer' : 'Wrong Answer'}
                      className='form-control mb-2'
                      disabled
                      placeholder={ele.type}
                    />
                  </div>
                ) : (
                  <div className='col-lg-12 col-sm-12'>
                    <div className={classes.templateCheckbox}>
                      <input type='checkbox' disabled />
                      <label className='font-weight-normal'>{ele.name}</label>
                    </div>{' '}
                  </div>
                )
              })}
            </ul>
          </div>
        ))}
      </div>
      <CardContent>
        {/* <Typography variant="body1" gutterBottom>
              Submitted Video Link
            </Typography> */}
        <h4 style={{ textAlign: 'center' }}>{video_details?.title}</h4>
        <div style={{ position: 'relative', paddingTop: '56.25%' }}>
          <iframe
            title='Embedded YouTube Video'
            src={video_details?.link}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}
            frameBorder='0'
          ></iframe>
        </div>
      </CardContent>
    </div>
  )
}

export default InductionManagementReview
