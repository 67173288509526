import * as React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Steps from "../../components/Steps";
import Card from "@mui/material/Card";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import { Box, IconButton, TextField } from "@mui/material";
import classes from "../../styles/asset.module.css";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteTemplate from './templateDeleteModal'
import { useDispatch, useSelector } from "react-redux";
import profile from "../../assets/img/profile.png"
import {
  getTemplates,
  reset as resetTemplateStatus,
  resetTemplate,
  setIsErr,
  setTemplate,
  setTemplateName,
  setDocumentNumber,
  setRevisionNumber,
  setRevisionDate,
  setTemplateNameError,
  templateDelete,
  opentemplateDetailModal,
  opentemplateDeleteModal,
  closetemplateDeleteModal
} from "../../redux/features/template/templateSclice";
import AddEptwModal from "../eptw/componantes/AddEptwModal";
import {
  closeEptwModalCreate,
  getEptwsByAuthentication,
  openEptwModalCreate,
  resetEptw,
  reset as resetEptwStatus,
} from "../../redux/features/eptw/eptwSlice";
import { getContractor } from "../../redux/features/project/projectSclice";
import AddTemplate from "../../components/Steps/addTemplate";
import { Typography } from "@mui/material";
export default function CustomizedSteppers() {
  const [isTemplateModalOpen, setTemplateModal] = useState(false);
  const [inputList, setInputList] = React.useState([{ name: "" }]);
  const [saftyList, setSaftyList] = React.useState([{ name: "" }]);
  const [customFeild, setCustomFeild] = React.useState([]);
  const [customSection, setCustomSection] = React.useState([
    { title: "", fields: [] },
  ]);
  const [rows, setRows] = React.useState([]);
  const [templateToDeleteId, settemplateToDeleteId] = React.useState();
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getTemplates());
    dispatch(getContractor());
    dispatch(getEptwsByAuthentication());
  }, []);

  const {
    templates,
    templateName,
    documentNumber,
    revisionNumber,
    revisionDate,
    isTemplateCreateSuccess,
    isTemplateDeleteSuccess,
    isTemplateUpdateSuccess,
    isTemplateCreateError,
    isTemplateDeleteError,
    isTemplateUpdateError,
    templateDetailModal,
    templateSearchTerm,
    tepmlateModalDelete,
    isErr
  } = useSelector((state) => state.template);
  const { isEptwCreateSuccess, isEptwCreateError } = useSelector(
    (state) => state.eptw
  );

  const closeModal = () => {
    setTemplateModal(false);
    dispatch(resetTemplate());
    setInputList([{ name: "" }]);
    setCustomSection([{ title: "", fields: [] }]);
  };

  React.useEffect(() => {
    setRows(
      templates
        ?.filter((singleTemplate) =>
          singleTemplate.name.toLowerCase().startsWith(templateSearchTerm)
        )
        .map((oneTemplate) => oneTemplate)
    );
  }, [templates, templateSearchTerm]);

  const handleEditClick = (id) => {
    const template = templates.find((item) => item?.id === id);
    setInputList(template?.attributes || []);
    const sections = template?.sections;
    // const newArray = [];
    // for (const sectionName in sections) {
    //   if (sections.hasOwnProperty(sectionName)) {
    //     const section = {
    //       title: sectionName,
    //       feilds: [],
    //     };
    //     const sectionFields = sections[sectionName];
    //     for (const fieldName in sectionFields) {
    //       if (sectionFields.hasOwnProperty(fieldName)) {
    //         section.feilds.push({
    //           name: fieldName,
    //           type: sectionFields[fieldName],
    //         });
    //       }
    //     }
    //     newArray.push(section);
    //   }
    // }
    setCustomSection(sections);
    dispatch(setTemplateName(template?.name));
    dispatch(setDocumentNumber(template?.document_number));
    dispatch(setRevisionNumber(template?.revision_number));
    dispatch(setRevisionDate(template?.revision_date));
    dispatch(setTemplate(template));
    setTemplateModal(true);
  };

  const handleDeleteClick = (id) => {
    //dispatch(templateDelete(id));
    settemplateToDeleteId(id)
    dispatch(opentemplateDeleteModal());
  };

  const openTemplateHandler = (type, id) => {
    // const template = templates.find(item => item?.id === id)
    if (type === "openCard") {
      dispatch(opentemplateDetailModal());
      // dispatch(setTemplate(template))
      handleEditClick(id);
    } else if (type === "editCard") {
      handleEditClick(id);
    } else if (type === "deleteCard") {
      handleDeleteClick(id);
    }
  };

  function displayToast() {
    if (isTemplateCreateError) {
      toast.error("Something went wrong Creating Template", {
        toastId: "CreatedTemplateError",
      });
      dispatch(resetTemplateStatus());
    } else if (isTemplateUpdateError) {
      toast.error(" Something went wrong Updating Template", {
        toastId: "UpdatingTemplateError",
      });
      dispatch(resetTemplateStatus());
    } else if (isTemplateDeleteError) {
      toast.error(" Something went wrong Deleting Template", {
        toastId: "DeletingTemplateError",
      });
      dispatch(resetTemplateStatus());
    } else if (isTemplateCreateSuccess) {
      toast.success(" Template Created successfully", {
        toastId: "Template Created Success",
      });
      closeModal();
      dispatch(resetTemplate());
      dispatch(resetTemplateStatus());
    } else if (isTemplateDeleteSuccess) {
      toast.success(" Template Deleted successfully", {
        toastId: "DeletedTemplateError",
      });
      dispatch(resetTemplateStatus());
      dispatch(resetTemplate());
      // dispatch(closeTemplateModalUpdate())
    } else if (isTemplateUpdateSuccess) {
      toast.success(" Template Updated successfully", {
        toastId: "UpdatedTemplateError",
      });
      closeModal();
      dispatch(resetTemplateStatus());
    } else if (isEptwCreateSuccess) {
      toast.success("Template Created successfully", {
        toastId: "CreatedEptwsuccess",
      });
      dispatch(resetEptw());
      dispatch(resetEptwStatus());
      dispatch(closeEptwModalCreate());
    } else if (isEptwCreateError) {
      toast.error("Something went wrong creating EPTW Created", {
        toastId: "CreatedEptwfaild",
      });
      dispatch(resetEptwStatus());
    } else if (isErr) {
      toast.error("Please Fill Details Inputs", {
        toastId: "DeletingTemplateError",
      });
      dispatch(setIsErr(false));
      dispatch(resetTemplateStatus());
    }
  }

  return (
    <>
      <AddTemplate />
      <Card sx={{ m: 2, p: 5 }}>
        {templateDetailModal && (
          <>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <TextField
                value={templateName}
                onChange={(event) => {
                  dispatch(setTemplateName(event.target.value));
                  dispatch(setTemplateNameError(false));
                }}
                placeholder="Template Name"
                InputProps={{
                  style: { fontWeight: "bold", textAlign: "center" },
                }}
                style={{ color: "#63666A" }}
              />
            </Box>
            <Button
              type="button"
              onClick={closeModal}
              sx={{
                color: "#FFEBEE",
                paddingTop: "10px",
                paddingLeft: "10px",
              }}
            >
              <CloseSharpIcon
                style={{
                  fontSize: "2rem",
                  color: "white",
                  backgroundColor: "#D9314A",
                }}
              />
            </Button>
          </>
        )}
        {templateDetailModal === true ? (
          <Steps
            inputList={inputList}
            setInputList={setInputList}
            saftyList={saftyList}
            setSaftyList={setSaftyList}
            customFeild={customFeild}
            setCustomFeild={setCustomFeild}
            customSection={customSection}
            setCustomSection={setCustomSection}
            documentNumber={documentNumber}
            revisionNumber={revisionNumber}
            revisionDate={revisionDate}
          />
        ) : templates?.length === 0 ? (
          <>
            <div className={classes.centeredContent}>
              <div className={classes.placeholder}>
                <Typography style={{ color: "gray" }} variant="h4">
                  No Template Found
                </Typography>
              </div>
            </div>
          </>
        ) : (
          <>
            <h5>Create a template or select from the following:</h5>
            <div className={classes.templates}>
              {rows?.map((template, index) => (
                <div
                  id={index}
                  className={classes.ptwcards}
                  onClick={() => openTemplateHandler("openCard", template?.id)}
                  title={template?.name}
                >
                  <h5
                    id={index}
                    onClick={() =>
                      openTemplateHandler("openCard", template?.id)
                    }
                    title={template?.name}
                  >
                    {template?.name.length > 13
                      ? `${template?.name.slice(0, 13)}...`
                      : template?.name}
                  </h5>
                  <h7>
                    Document Number:{" "}
                    {template?.document_number.length > 13
                      ? `${template?.document_number.slice(0, 13)}...`
                      : template?.document_number}
                  </h7>
                  <h7>
                    Revision Number:{" "}
                    {template?.document_number.length > 13
                      ? `${template?.document_number.slice(0, 13)}...`
                      : template?.revision_number}
                  </h7>
                  <div></div>
                  <div className={classes.cardActions}>
                    <div style={{ padding: "8px" }}>
                      <img
                        key={index}
                        className={classes.assignedImage}
                        src={template?.created_by?.image || profile}
                        alt="logo"
                        title={template?.created_by?.first_name + " " + template?.created_by?.last_name || "logo"}
                      />
                    </div>
                    <IconButton
                      title="Delete"
                      onClick={(e) => {
                        e.stopPropagation()
                        openTemplateHandler("deleteCard", template?.id)
                      }
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              ))}

            </div>
          </>
        )}
        {displayToast()}
        {
          tepmlateModalDelete && (
            <DeleteTemplate open={opentemplateDeleteModal} id={templateToDeleteId} />)
        }
      </Card>
    </>
  );
}
