import React, { useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import AddtaskModal from '../../components/Task/addtaskModal'
import classes from '../../styles/asset.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import CustomColumnModal from '../../components/project/projectModal/customCulomnModal';
import classe from '../../styles/assetTable.module.css';
import { getProject } from '../../redux/features/project/projectSclice';
import { useDispatch } from 'react-redux';

export default function Task() {
   // States for managing column
   const [openDialog, setOpenDialog] = useState(false);
   const [selectedIcon, setSelectedIcon] = useState(faPlus);
   const [modalTitle, setModalTitle] = useState('Choose a Title');
   const handleOpenDialog = () => {
     setOpenDialog(true);
   };
 
   const handleCloseDialog = () => {
     setOpenDialog(false);
   };
   const handleIconSelection = (icon) => {
     setSelectedIcon(icon);
     setModalTitle('Choose a Title');
   };
   const handleDeleteRow = (idToDelete) => {
     const updatedRows = rows.filter((row) => row.id !== idToDelete);
     setRows(updatedRows);
   };
  const initialColumns = [
    {field:'project', headerName:'Project', width:150},
    { field: 'task', headerName: 'Task', width: 150 },
    { field: 'owner', headerName: 'Owner', width: 150 },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      editable: true,
      renderCell: (params) => {
        let statusClass = classe.complete;
        if (params.value === 'In Progress') {
          statusClass = classe.hPriority;
        } else if (params.value === 'Pending') {
          statusClass = classe.lPriority;
        }else if (params.value === 'Complete'){
          statusClass = classe.completed
        }

        return (
          <span className={statusClass}>
            {params.value || '—'}
          </span>
        );
      }
    },
    { field: 'timeline', headerName: 'Timeline', width: 150 },
    { field: 'duration', headerName: 'Duration', width: 150 },
    { field: 'dependent', headerName: 'Dependent', width: 150 },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <div>
           <button
          type="button"
          data-toggle="modal"
          data-target="#modal-delete-part"
          title="Delete Spare part"
          className="font-danger"
          onClick={() => handleDeleteRow(params.row.id)}
        >
          Delete
        </button>
        </div>
      ),
    },
    {
      field: 'customColumn',
      headerName: (
        <div onClick={handleOpenDialog} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={selectedIcon} style={{ marginRight: '5px' }} /> <span>{modalTitle}</span>
        </div>
      ),
      customColumnName: 'Custom Input', 
      width: 200,
      renderCell: (params) => (
        <FontAwesomeIcon icon="fa-solid fa-plus" />
      ),
    },
  ];
  const initialRows = [
    {id:1, proejct:"",task: 'Addgg', owner: 'GG', status: 'Jon', timeline: '12 jun 2023' , duration:'1 week',dependent:'task1' },
    {id:2,  proejct:"", task: 'ogJH', owner: 'GG3', status: 'JonPur', timeline: '13 jun 2023' , duration:'1 week',dependent:'task1' },
  ];
 // States for managing component
 const [rows, setRows] = useState(initialRows);
 const [openModalCreate, setOpenModalCreate] = useState(false);

const openModal = () => {
  setOpenModalCreate(true);
};



const closeModalCreate = () => {
  setOpenModalCreate(false);
};

 const dispatch = useDispatch()
React.useEffect(() => {
  dispatch(getProject());
}, [dispatch]);
  return (
    <div className={classes.assets}>
      <AddtaskModal 
       openModal={openModal}
       openModalCreate={openModalCreate} 
       closeModalCreate={closeModalCreate} 
      />
       <div style={{ height: '70vh', width: '100%', }}>
        <DataGrid rows={rows} 
        columns={initialColumns} 
        pageSize={5} 
        pagination 
        checkboxSelection 
        style={{ background: 'white' }}
        />
      </div>
      <CustomColumnModal
      openDialog={openDialog} 
      handleCloseDialog={handleCloseDialog} 
      handleIconSelection={handleIconSelection}
      modalTitle={modalTitle}
      setModalTitle={setModalTitle}
      />
      
    </div>
  )
}
