import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cropper from 'react-cropper';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import { toast } from 'react-toastify';
import {
  reset,
  getUserProfile,
  updateUserProfileImg,
  updateUserProfile,
} from '../../redux/features/user/userSlice';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ImageIcon from '@mui/icons-material/Image';
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import DrawIcon from '@mui/icons-material/Draw';
import { Button, Form, Modal } from 'react-bootstrap/';
import SignatureCanvas from 'react-signature-canvas'
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';
import profilePicture from '../../assets/img/profile.png';
import './profile.scss';
// import { getManagerPositionByAuthentication } from '../../redux/features/managerPosition/managerPositionSlice';
// import { getTechnicianPositionsByAuthentication } from '../../redux/features/technicianPosition/technicianPositionSlice';

export default function Profile() {
  const history = useHistory();
  const { departments } = useSelector(state => state.department);
  // const { managers } = useSelector(state => state.managerPosition);
  // eslint-disable-next-line no-unused-vars
  // const { technicians } = useSelector(state => state.technicianPosition);
  const dispatch = useDispatch();
  const { user, isLoading, isError, isSuccess, isUserProfileUpdateSuccess, isGetUserProfileSuccess, isUserProfileUpdateError } = useSelector(state => state.user);
  const [image, setImage] = useState('');
  const [imageUpload, setImageUpload] = useState('');

  const [cropData, setCropData] = useState('#');

  const [crop, setCrop] = useState(false);
  const cropperRef = useRef(null);
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    role: '',
    signature: '',
    signature_type: '',
  });
  // const [firstName, setFirstName] = useState(profile.first_name);
  // const [lastname, setLastName] = useState(profile.last_name);
  // const [phone, setPhone] = useState(profile.phone_number);
  const [job, setJob] = useState(null);
  const [dep, setDep] = useState(null);
  const [lowInv, setLowInv] = useState(null);
  const [posIndex, setPosIndex] = useState();
  const [tab, setTab] = useState("sign");
  const [sign, setSign] = useState(null);
  const [signFile, setSignFile] = useState(null);
  const [signFilePhoto, setSignFilePhoto] = useState(null);
  const [signUrlImg, setSignUrlImg] = useState(null);
  const [isShowSignModal, setIsShowSignModal] = useState(false);
  const [signError, setSignError] = useState(false);

  useEffect(() => {
    if (profile?.signature_type === "uploadedFile") {
      setTab("image")
    } else {
      setTab("sign")
    }
  }, [profile?.signature_type])


  useEffect(() => {
    dispatch(reset());
  }, []);

  const handleCloseSignModal = () => {
    setIsShowSignModal(false)
  }
  const handleSave = () => {
    if (sign) {
      const signImg = sign.getTrimmedCanvas().toDataURL("image/png")

      const handleSaveFile = (base64String) => {
        // Extract the file format from the base64 string (e.g., 'image/png')
        const format = base64String.split(';')[0].split('/')[1];

        // Remove the data:image/<format>;base64, prefix to get just the base64 data
        const data = atob(base64String.split(',')[1]);

        // Create a Uint8Array to hold the binary data
        const array = new Uint8Array(data.length);

        // Fill the Uint8Array with the binary data
        for (let i = 0; i < data.length; i++) {
          array[i] = data.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([array], { type: `image/${format}` });

        // Create a File object with the desired properties
        const fileName = 'image (3).png'; // Replace with your desired filename
        const lastModified = Date.now(); // Replace with the last modified timestamp if needed
        const file = new File([blob], fileName, { type: blob.type, lastModified });
        return file
      };


      if (!sign?.isEmpty()) {
        setSignUrlImg(handleSaveFile(signImg))

        const formatData = new FormData
        if (profile.first_name) {
          formatData.append("first_name", profile.first_name)
        }
        if (profile.last_name) {
          formatData.append("last_name", profile.last_name)
        }
        if (profile.phone_number) {
          formatData.append("phone_number", profile.phone_number)
        }
        if (sign) {
          formatData.append("signature", handleSaveFile(signImg))
        }
        formatData.append("signature_type", "drawing")
        dispatch(updateUserProfile(formatData));
        setSignFile(null)
        setSignFilePhoto(null)
        // setIsShowSignModal(false)
      } else {
        setSignUrlImg(null)
        setSignError(true)
        setTimeout(() => {
          setSignError(false)
        }, 3000);
      }
    } if (signFile) {
      const formatData = new FormData
      if (profile.first_name) {
        formatData.append("first_name", profile.first_name)
      }
      if (profile.last_name) {
        formatData.append("last_name", profile.last_name)
      }
      if (profile.phone_number) {
        formatData.append("phone_number", profile.phone_number)
      }
      if (signFile) {
        formatData.append("signature", signFile)
      }
      formatData.append("signature_type", "uploadedFile")
      dispatch(updateUserProfile(formatData));
      setIsShowSignModal(false)
    }
  }
  const handleCreateTrim = () => {
    sign.clear()
  }

  // eslint-disable-next-line no-unused-vars
  const [pos, setPos] = useState(0);

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    setProfile({
      first_name: user?.first_name,
      last_name: user?.last_name,
      email: user?.email,
      role: user?.role,
      image: user?.image,
      phone_number: user?.phone_number,
      department: user?.department,
      signature: user?.signature,
      signature_type: user?.signature_type,
    });
    // setFirstName(profile.first_name);
    // setLastName(profile.last_name);
    // setPhone(profile.phone_number);
    setJob(profile.job_title);
    // setDep(profile.department);
    // setLowInv(user??.inv_low_email);

    // set the position index of the manager and technician

    if (profile?.role === 'Manager') {
      setPosIndex(user?.manager?.position);
    } else if (profile?.role === 'Technical Team') {
      setPosIndex(user?.technicalteam?.position);
    }
  }, [user]);

  const cropImage = e => {
    setCrop(true);
    const imgurl = URL.createObjectURL(e.target.files[0]);
    setImage(imgurl);
    setImageUpload(e.target.files[0])
    e.preventDefault();
  };

  const getCropData = e => {
    e.preventDefault();
    if (cropperRef.current?.cropper !== 'undefined') {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      cropperRef.current?.cropper.getCroppedCanvas().toBlob(blob => {
        const formData = new FormData();

        // Pass the image file name as the third parameter if necessary.
        formData.append('image', blob, 'example.png');

        // Use `jQuery.ajax` method for example
        dispatch(updateUserProfileImg(formData));
      }, 'image/png');
      setCrop(false);
    }
  };
  useEffect(() => {
    setProfile({ ...profile, image });
  }, [cropData]);



  const handleUpdateProfile = e => {
    e.preventDefault();
    const userinfo = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      phone_number: profile.phone_number,
      // job_title:job
    };
    if (profile.role === 'Manager') {
      userinfo.manager = {
        position: parseInt(pos, 10),
        // hourly_rate: user.manager.hourly_rate,
        manager_admin: {}
      };
    } else if (profile.role === 'Technical Team') {
      userinfo.technicalteam = {
        position: parseInt(pos, 10),
        hourly_rate: user.technicalteam.hourly_rate || '0.00',
        tech_manager: user?.technicalteam?.tech_manager,
      };
    }
    dispatch(updateUserProfile(userinfo));
  };

  // useEffect(() => {
  //   dispatch(reset());
  // }, [user, isError, isSuccess, isLoading, dispatch, history]);

  const { showSpinner } = useApiCallStatusNotificationHandler({
    isSuccess,
    isLoading,
    isError,
    successCallBack: () =>
      setTimeout(() => {
        history.push('/profile/');
      }, 3500),
  });

  // const handlePosition = e => {
  //   setPos(e.target.value);
  //   if (profile.role === 'Manager') {
  //     managers.forEach(manager => {
  //       if (parseInt(e.target.value, 10) === manager.id) {
  //         setJob(manager.name);
  //       }
  //     });
  //   } else {
  //     technicians.forEach(technician => {
  //       if (parseInt(e.target.value, 10) === technician.id) {
  //         setJob(technician.name);
  //       }
  //     });
  //   }
  // };

  // console.log("sign" , signFile );
  const handelUploadSignImage = (e) => {
    setSignFile(e.target.files[0])
    setSignFilePhoto(URL.createObjectURL(e.target.files[0]))
    setSignUrlImg(null)
  }
  function displayToast() {
    if (isUserProfileUpdateSuccess) {
      toast.success('Successfully updated user profile', {
        toastId: 'user profile update success',
      });
    } else if (isUserProfileUpdateError) {
      toast.error('Error updating user profile', {
        toastId: 'user profile update error',
      });
    }
  }
  return (
    <>
      {!crop && (
        <form
          onSubmit={handleUpdateProfile}
        >
          {showSpinner ? <div id="loader" /> : null}
          {displayToast()}
          {/* {console.log("user profile",isUserProfileUpdateSuccess)} */}
          <div className=" h-20 row justify-content-around col-lg-12">
            <div className="row justify-content-start my-auto col-lg-9 col-md-8 col-sm-12">
              <div className="icon img-hover ml-4 my-auto ">
                <div className="icon img-select ">
                  <input
                    type="file"
                    id="profile_image_user"
                    name="profile_image"
                    accept="image/*"
                    className="none"
                  />
                </div>
                <label htmlFor="crop_image" style={{ cursor: 'pointer' }} className="image-label">
                  {profile?.image ? (
                    <img
                      className="prof_img img-circle user_profile "
                      height="120px"
                      width="120px"
                      alt="profile"
                      src={profile?.image}
                      id="result_profile"
                      title="Change profile picture"
                    />
                  ) : (
                    <img
                      className="prof_img img-circle user_profile "
                      height="120"
                      width="120"
                      alt="profile"
                      src={profilePicture}
                      id="result_profile"
                      title="Change profile picture"
                    />
                  )}
                  <div className="edit-icon">
                    <div style={{ fontSize: '18px' }} className="fas fa-pen"> </div>
                  </div>
                </label>

                <br />
                <span id="image_name" className="small-font gcolor" />
              </div>
              <div className="row pl-4 col-8 my-auto">
                <div className="row col-lg-12 ml-1">
                  {' '}
                  <h4>{`${profile?.first_name} ${profile?.last_name}`}</h4>{' '}
                </div>
                <div className="row col-lg-12">
                  <a className=" ml-2" href="/change-password">
                    Change Password
                  </a>
                  <div className="mx-3" />
                  {/* <label htmlFor="crop_image" style={{ cursor: 'pointer' }}>
                    Change Profile Photo
                  </label> */}
                  <input
                    value=""
                    name="crop"
                    id="crop_image"
                    onChange={cropImage}
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                  />
                  <div className="mx-3" />
                  <label onClick={() => setIsShowSignModal(!isShowSignModal)} style={{ cursor: 'pointer' }}>
                    Add Signature
                  </label>
                </div>
              </div>
            </div>
            <div className="float-right my-auto col-lg-3 col-md-4 col-sm-12 d-flex justify-content-end">
              <button
                className="btn primary-btn"
                type="submit"
                id="profile_update"
                onClick={() => {
                  if (this instanceof Element) {
                    // eslint-disable-next-line react/no-this-in-sfc
                    this.classList.toggle('button--loading');
                  }
                }}
              >
                <span className="button__text">Update</span>
              </button>
              <button
                className="btn secondary-btn ml-2"
                type="button"
                onClick={() => history.push('/')}
              >
                Back
              </button>
            </div>
          </div>
          {/* page alignment */}
          <div className="card-body mx-auto row justify-content-center col-lg-12">
            <div className="row col-lg-8 col-sm-12">
              <div className="card col-lg-12 col-sm-12 custom_profile_card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <label htmlFor="firstName" className="font-weight-normal">
                        First Name
                      </label>
                      <input
                        name="firstName"
                        type="text"
                        className="form-control mb-2"
                        // placeholder="First Name"
                        id="firstName"
                        placeholder={profile?.first_name}
                        value={profile?.first_name}
                        onChange={e => setProfile({ ...profile, first_name: e.target.value })}
                      />
                      &nbsp;
                    </div>

                    <div className="col-lg-6  col-md-6 col-sm-12">
                      <label htmlFor="lastName" className="font-weight-normal">
                        Last Name
                      </label>
                      <input
                        name="lastName"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Last Name"
                        id="lastName"
                        value={profile.last_name}
                        onChange={e => setProfile({ ...profile, last_name: e.target.value })}
                      />
                      &nbsp;
                    </div>

                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <label htmlFor="email" className="font-weight-normal ">
                        Email
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-control mb-2"
                        placeholder="Email"
                        id="email"
                        onChange="remove_error_msg('email')"
                        value={profile.email}
                        readOnly
                        disabled
                      />
                      &nbsp;
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="email_error" />
                      </div>
                    </div>
                    {profile.role === 'Manager' ||
                      profile.role === 'Technical Team' ||
                      profile.role === 'Requestor' ? (
                      <div className="col-lg-6 col-md-4 col-sm-12">
                        <label htmlFor="phoneNo" className="font-weight-normal">
                          Phone Number
                        </label>
                        <input
                          name="phoneNo"
                          type="text"
                          className="form-control mb-2"
                          placeholder="e.g. +601199119911"
                          id="phoneNo"
                          value={profile.phone_number}
                          onChange={e => setProfile({ ...profile, phone_number: e.target.value })}
                        />
                      </div>
                    ) : (
                      <div className="col-lg-6  col-md-4 col-sm-12">
                        <label htmlFor="phoneNo" className="font-weight-normal">
                          Phone Number
                        </label>
                        <input
                          name="phoneNo"
                          type="text"
                          className="form-control mb-2"
                          placeholder="e.g. +601199119911"
                          id="phoneNo"
                          value={profile.phone_number}
                          onChange={e => setProfile({ ...profile, phone_number: e.target.value })}
                        />
                      </div>
                    )}

                    <div className="col-lg-6 col-sm-12">
                      <label htmlFor="role" className="font-weight-normal">
                        Account Type
                      </label>
                      {profile?.role === 'CliAdm' ? (
                        <input
                          name="role"
                          type="text"
                          className="form-control mb-2"
                          value="Administrator"
                          readOnly
                          disabled
                        />
                      ) : (
                        <input
                          name="role"
                          type="text"
                          className="form-control mb-2"
                          value={profile?.role}
                          readOnly
                          disabled
                        />
                      )}
                    </div>
                    {profile.role === 'Manager' || profile.role === 'Member' ? (
                      <div className="col-lg-6 col-sm-12">
                        <label htmlFor="role" className="font-weight-normal">
                          Department
                        </label>
                        <input
                          name="role"
                          type="text"
                          className="form-control mb-2"
                          value={profile?.department?.name || ""}
                          readOnly
                          disabled
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {crop ? (
        <div className="col croper">
          <Cropper
            style={{ height: '100%', width: '100%' }}
            initialAspectRatio={1}
            className="row align-items-center my-auto col-lg-12 col-md-12 col-sm-12 croper"
            preview=".img-preview"
            src={image}
            ref={cropperRef}
            viewMode={1}
            guides
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background
            responsive
            checkOrientation={false}
          />
          <div
            className="box"
          // style={{ width: "50%", float: "right", height: "300px" }}
          >
            <div className="buttons_crop">
              <button onClick={() => setCrop(false)} type="button" className="btn btn-danger">
                Cancel
              </button>

              <button onClick={getCropData} type="button" className="btn btn-success">
                Crop Image
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {isShowSignModal && (
        <MyVerticallyCenteredModal
          showModal={isShowSignModal}
          handleClose={handleCloseSignModal}
          modalTitle="Signature"
          primaryButtonText="Save"
          handleCreateSave={handleSave}
          handleCreateTrim={handleCreateTrim}
          clearButtonText="Clear"
          setTab={setTab}
          tab={tab}
          setSignFile={setSignFile}
          setSignFilePhoto={setSignFilePhoto}
          setSign={setSign}
          setSignUrlImg={setSignUrlImg}
        >
          {tab === "sign" ? <>
            {signError && <h5 className='red pb-2'>Please add your Signature</h5>}
            <div className='sigContainer' style={{ border: "1px solid" }}>
              <SignatureCanvas penColor='black'
                canvasProps={{ className: 'sigCanvas' }}
                ref={data => setSign(data)} />
            </div>
            {profile?.signature && profile?.signature_type === "drawing" ? <img className='sign-image' src={profile?.signature} alt="" />
              : <h5 className='nosign-image'>No Signature Yet, Add Yours⬆️</h5>}
          </> :
            <div className='wrap-sign-image'>
              <label className="custom-file-upload">
                <input className='upload-sign-image' onChange={handelUploadSignImage} type="file" />
                Select Image
              </label>
              {(profile?.signature && profile?.signature_type === "uploadedFile") || signFilePhoto ? <img className='sign-photo' src={signFilePhoto || profile?.signature} alt="" />
                : <h5 className='nosign-image'>No Signature Yet, Add Yours⬆️</h5>}
            </div>
          }
        </MyVerticallyCenteredModal>
      )}
    </>
  );
}

function MyVerticallyCenteredModal({
  showModal,
  handleClose,
  primaryButtonText,
  handleCreateSave,
  children,
  handleCreateTrim,
  clearButtonText,
  setTab,
  tab,
  setSignFile,
  setSignFilePhoto,
  setSign,
  setSignUrlImg,
}) {
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal} onHide={handleClose}
    >
      <Modal.Header style={{ minWidth: "100%" }} closeButton>
        <Modal.Title style={{ width: "100%" }} id="contained-modal-title-vcenter">
          {/* {modalTitle} */}
          <div className='signature-header'>
            <div onClick={() => {
              setTab("sign")
              setSignFile(null)
              setSignFilePhoto(null)
            }}>
              {tab === "sign" ? <DrawIcon /> : <DrawOutlinedIcon />}
              <p>Draw</p>
            </div>
            <div onClick={() => {
              setTab("image")
              setSign(null)
              setSignUrlImg(null)
            }}>
              {tab === "image" ? <ImageIcon /> : <ImageOutlinedIcon />}
              <p>Image</p>
            </div>
          </div>
        </Modal.Title>
        <button
          type='button'
          className='close'
          data-dismiss='modal'
          aria-label='Close'
          onClick={handleClose}
        >
          <span aria-hidden='true'>&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer style={{ minWidth: "100%", justifyContent: "space-between" }} >
        <Button variant="secondary" onClick={handleClose}>Close</Button>
        <div>
          {tab === "sign" && <Button variant='primary' onClick={handleCreateTrim}>
            {clearButtonText}
          </Button>}
          <span className='mr-2' />
          <Button variant='primary' onClick={handleCreateSave}>
            {primaryButtonText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}




