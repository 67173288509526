import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import foxLogo from '../../assets/img/FOX_Logo2.png.webp';
import { resetConfirmPassByEmail,resetAuth } from '../../redux/features/auth/authSilice';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './style.scss'

export default function ResetConfirmPasswordByEmail() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [newPassword, setnewPassword] = useState('');
    const [confirmPass, setConfirmPassword] = useState('');
    const [otp, setotp] = useState('');
    const {  uid, isLoading, isError, isSuccess } = useSelector((state) => state.auth);
    const submitConfirmPass = e => {
      e.preventDefault();
      if (newPassword !== confirmPass) {
        toast.error("New password and confirm password do not match");
        return; // Exit the function early
      }
      const userinfo = { uid: uid.uid, new_password: confirmPass, otp: parseInt(otp, 10) };
      dispatch(resetConfirmPassByEmail(userinfo));
    };
    
    const { showSpinner } = useApiCallStatusNotificationHandler({
      isSuccess,
      isLoading,
      isError,
      successCallBack: () =>
        setTimeout(() => {
          history.push('/login');
        }, 3500),
    });
    
    console.log( "uid",uid);
    useEffect(() => {
        dispatch(resetAuth());
      }, [isError, isSuccess, dispatch, history]);

  return (
    <div>
      {showSpinner ? <div id="loader" /> : null}

      <div className="row d-flex justify-content-center mt-4">
        <div className="col-lg-7 col-sm-12 col-md-10 ">
          <div className="login-logo-check-email">
            <img src={foxLogo} alt="fox logo" height="130" width="250" />
          </div>

        
          <p className="text-center mb-2 text-success">Please Check your email for OTP</p>

          <form action="{{action}}" method="POST" onSubmit={submitConfirmPass}>
            <div className="card col-lg-12 col-sm-12 col-xs-12 col-md-12 reset-pass-card">
              <div className="card-header medium-title" style={{ borderBottom: 'none' }}>
                <b>Create new password</b>
              </div>
              <div className="card-body">
                <div className="row">
                  <span> </span>
                  <label className="font-weight-normal" htmlFor="newpassword">
                    New Password{' '}
                    <label className="font-danger m-0" htmlFor="newpassword">
                      *
                    </label>
                  </label>
                  <input
                    name="newpassword"
                    type="password"
                    className="form-control mb-2"
                    required
                    placeholder="New password"
                    id="newpassword"
                    onChange={e => setnewPassword(e.target.value)}
                  />
                  <span className="font-danger col-lg-12 col-sm-12 ml-0" id="newpassword_error">
                    {' '}
                  </span>

                  <label className="font-weight-normal" htmlFor="confirm_password">
                    Confirm Password{' '}
                    <label className="font-danger m-0" htmlFor="confirm_password">
                      *
                    </label>
                  </label>
                  <input
                    name="confirm_password"
                    type="password"
                    className="form-control mb-2"
                    required
                    placeholder="Confirm password"
                    id="confirm_password"
                    onChange={e => setConfirmPassword(e.target.value)}
                  />
                  <span
                    className="font-danger col-lg-12 col-sm-12 ml-0"
                    id="confirm_password_error"
                  >
                    {' '}
                  </span>

                  <label className="font-weight-normal" htmlFor="otp">
                    OTP{' '}
                    <label className="font-danger m-0" htmlFor="otp">
                      *
                    </label>
                  </label>
                  <input
                    name="otp"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Enter OTP"
                    id="otp"
                    onChange={e => setotp(e.target.value)}
                  />
                  <span className="font-danger col-lg-12 col-sm-12 ml-0" id="otp_error">
                    {' '}
                  </span>
                </div>
              </div>
            </div>

            <div className="float-right">
              <div className="float-right">
                <button className="btn primary-btn" type="submit">
                  Save
                </button>
                <button
                  onClick={() => history.push('/forgot-password')}
                  className="btn secondary-btn ml-2"
                  type="button"
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="text-center my-3" style={{ color: '#9B9B9B !important' }}>
        {' '}
        © 2023 Infinity Wave Sdn. Bhd.
      </div>
      
    </div>
  )
}
