import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import classes from '../../../styles/asset.module.css'
import Button from '@mui/material/Button'
import { Drawer } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faPlus } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { postVideoConsent } from '../../../redux/features/safety/safetySlice'
import { getInductionSession } from '../../../redux/features/session/sessionSlice'
import { getProject, setOpenSafetyPopup } from '../../../redux/features/project/projectSclice'
import { SelectProject } from '../../../components/selectPersons/SelectPersons'
const AddSafetyToContractor = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { contractors  , openSafetyPopup , project , objectData} = useSelector(state => state.project)
  const { sessions } = useSelector(state => state.session)
  const [data, setData] = useState({
    contractor: '',
    video_induction_id: '',
    expiry_date: '',
    project: null
  })
  useEffect(() => {
    dispatch(getInductionSession())
    dispatch(getProject())
  }, [])
  console.log("objectData" , objectData);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const openDrawer = () => {
    //   dispatch(openTeamModalCreate());
    setIsDrawerOpen(true) // Open the drawer
  }

  useEffect(() => {
    if (openSafetyPopup) {
      setIsDrawerOpen(true)
      setData({...data , project: {label :objectData?.name , value: objectData?.id}})
    }
  }, [openSafetyPopup])
  
console.log(data);
  const closeDrawer = () => {
    //   dispatch(closeTeamModalCreate());
    setIsDrawerOpen(false) // Close the drawer
    setData({ contractor: '', video_induction_id: '', expiry_date: '' ,  project: null})
    dispatch(setOpenSafetyPopup(false))
  }
  const handelChange = (e)=>{
    setData({...data , project: e})
  }
  const handleSubmit = async e => {
    e.preventDefault()
    await dispatch(postVideoConsent({...data , project: data.project.value}))
    setData({ contractor: '', video_induction_id: '', expiry_date: '' ,  project: null})
    closeDrawer() // Close the drawer after submission
  }
  const backButtonStyle = {
    backgroundColor: '#f24660',
    color: 'white',
    '&:hover': {
      backgroundColor: '#f24660'
    }
  }
  return (
    <div>
      <div className={classes.assets}>
        <div style={{
          display:'flex',
          justifyContent:'flex-end'
        }}>
          {/* <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ marginRight: '20px' }} className={classes.assetsTitle}>
              Safety Induction Management
            </h4>
            <Button
              style={backButtonStyle}
              onClick={e => history.push('/safety-induction')}
              variant='contained'
            >
              Create Session
            </Button>
          </div> */}
          <div className={classes.assetActions}>
            <input type='text' placeholder='Search' />
            <button
              type='button'
              className={classes.assetfaPlus}
              onClick={openDrawer}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </button>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <Drawer
        anchor='right'
        open={isDrawerOpen}
        onClose={closeDrawer}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '600px',
            height: '100%'
          }
        }}
      >
        <form
          action='{{action}}'
          className='h-100'
          method='POST'
          onSubmit={handleSubmit}
        >
          <div className='navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0'>
            <div className='col-12 row px-2'>
              <div
                className='my-auto'
                style={{ fontWeight: 'bold', padding: '9px' }}
              >
                Add Induction to Requester 
              </div>
            </div>
          </div>
          <div className='col-12 pb-1'>
            <div className='card-body row'>
              <div className='col-12 p-0'>
                <label className='font-weight-normal'>
                Requester name <span className='font-danger'>*</span>
                </label>
                <select
                  className='form-control select-meter custom-select2 mb-2'
                  name='role'
                  id='role'
                  style={{ width: '100%' }}
                  required
                  value={data.role}
                  onChange={e =>
                    setData({ ...data, contractor: +e.target.value })
                  }
                >
                  <option selected value='' disabled>
                    --SELECT--
                  </option>
                  {contractors.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.first_name+" "+option.last_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-12 p-0'>
                <label className='font-weight-normal'>
                  Select Induction <span className='font-danger'>*</span>
                </label>
                <select
                  className='form-control select-meter custom-select2 mb-2'
                  name='role'
                  id='role'
                  style={{ width: '100%' }}
                  required
                  value={data.role}
                  onChange={e =>
                    setData({ ...data, video_induction_id: +e.target.value })
                  }
                >
                  <option selected value='' disabled>
                    --SELECT--
                  </option>
                  {sessions.map(option => (
                    <option key={option.id} value={option.id}>
                      {option?.title || option.quiz?.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-12 p-0'>
                <label className='font-weight-normal'>Expiry Date </label>
                <input
                  type='date'
                  className='form-control mb-2'
                  name='service_date'
                  id='meter_service_date'
                  placeholder='Date'
                  onChange={e =>
                    setData({ ...data, expiry_date: e.target.value })
                  }
                />
              </div>
              <div className='col-12 p-0'>
                <label className='font-weight-normal'>Project </label>
                <SelectProject projects={project} handelChange={handelChange} projectInfo={data.project} />
              </div>
            </div>
          </div>
          <div className='row  m-0 p-2 page-profile-footer'>
            <button
              className='btn button-btn pmd-btn-fab primary-btn'
              type='submit'
            >
              <span className='button__text'>Save</span>
            </button>
          </div>
        </form>
      </Drawer>
    </div>
  )
}

export default AddSafetyToContractor
