import React, { useEffect, useMemo, useState } from 'react'
import { Box} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import MenuItem from '@mui/material/MenuItem';
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { getTaskByAuthentication, openTaskDetailModal, setTask, taskDelete, updateTask } from '../../redux/features/task/taskSclice';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import TaskDeatilsPopupModal from '../project/projectTaskTable/taskDeatilsPopupModal';
import CustomButton from '../Task/customButton';
import { getAllUsers } from '../../redux/features/user/userSlice';
export default function TaskNevigationTable() {
    const dispatch = useDispatch()
    const { ID } = useParams();
    const parsedID = parseInt(ID, 10);

    const [rows,setRows] = React.useState([]);
    const [rowModesModel, setRowModesModel] = React.useState({});
    const [selectedUser, setSelectedUser] = useState('');
    const { allUsers } = useSelector((state) => state.user);
    const userOptions = allUsers.map((user) => ({
      id: user.id,
      label: `${user.first_name} ${user.last_name}`,
    }));
    const { project } = useSelector((state) => state.project)
    useEffect(()=>{
        dispatch(getTaskByAuthentication())
        dispatch(getAllUsers())
      },[dispatch])
      const { task } = useSelector((state) => state.task)
      React.useEffect(() => {
        if (parsedID && task.length > 0) {
          const selectedTask = task.find((taskItem) => taskItem.id === parsedID);
          if (selectedTask) {
            setRows([
              {
                id: selectedTask.id,
                name: selectedTask.name,
                status: selectedTask.status,
                description: selectedTask.description,
                start_date: selectedTask.start_date,
                finish_date: selectedTask.finish_date,
                assigned_to: selectedTask.assigned_to.map((user) => user.name),
                assigned_to_Id: selectedTask.assigned_to.map((user) => user.id),
                project: selectedTask.name,
                projectId: selectedTask.id,
                actual_start_date: selectedTask.actual_start_date,
                actual_finish_date: selectedTask.actual_finish_date,
              },
            ]);
          }
        }
      }, [parsedID, task]);
      
      const columns = useMemo(
        () => [
          {
            field: 'detailView',
            headerName: '...',
            width: 90,
            renderCell: (params) => (
              <FontAwesomeIcon 
              icon={faUpRightFromSquare} 
              onClick={()=> dispatch(openTaskDetailModal())}
              style={{ cursor: 'pointer' }}
              />
            ),
            
          },
          { field: "name", headerName: "Task Name", width:150,editable: true },
          {
            field: 'status',
            headerName: 'Status',
            width: 150,
            type: 'singleSelect',
            valueOptions: ['TODO', 'In Progress', 'admin'],
            editable: true,
          },
          {
            field: 'assigned_to',
            headerName: 'Assign To',
            width: 150,
            // type: 'singleSelect',
            renderEditCell: (params) => (
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id={`select-label-${params.id}`}>User</InputLabel>
                <Select
                  labelId={`select-label-${params.id}`}
                  id={`select-${params.id}`}
                  value={selectedUser}
                  label="User"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {userOptions.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ),
            editable: true,
          },
      
          
          {
            field: 'project',
            headerName: 'Project',
            width: 100,
            type: 'singleSelect',
            valueOptions: project.map(option => option.name),
          },
          {
            field: 'start_date',
            headerName: 'Start Date',
            width: 100,
            editable: true,
          },
          {
            field: 'finish_date',
            headerName: 'Finish Date',
            width: 100,
            editable: true,
          },
          {
            field: 'description',
            headerName: 'Description',
            width: 100,
            editable: true,
          },

          {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
              const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
      
              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    sx={{
                      color: 'primary.main',
                    }}
                    onClick={handleSaveClick(id)}
                  />,
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />,
                ];
              }
      
              return [
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  className="textPrimary"
                  onClick={handleEditClick(id)}
                  color="inherit"
                />,
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                  color="inherit"
                />,
              ];
            },
          },
          
              
          
        ],
        
      );


      const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
          event.defaultMuiPrevented = true;
        }
      };
    
      const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
      };
    
      const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
      };
    
      const handleDeleteClick = (id) => async () => {
        try {
          await dispatch(taskDelete(id)); // Wait for the taskDelete action to complete
          setRows(rows.filter((row) => row.id !== id));
          toast.success('Task deleted successfully'); // Show success toast
        } catch (error) {
          toast.error('Failed to delete task'); // Show error toast
        }
      };
      
    
      const handleCancelClick = (id) => () => {
        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    
        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
          setRows(rows.filter((row) => row.id !== id));
        }
      };
      const handleChange = (event) => {
        setSelectedUser(event.target.value);
      };

      const processRowUpdate = async (newRow) => {
        try {
          const updatedRow = { ...newRow, isNew: false, assigned_to: [selectedUser] };
          await dispatch(updateTask(updatedRow));
      
          // Find the label (user name) for the selected user's ID
          const assignedToLabel = userOptions.find(option => option.id === selectedUser)?.label || '';
      
          // Update the assigned_to field with the user's name for display
          updatedRow.assigned_to = [assignedToLabel]; // Assign as an array for consistency
      
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
          toast.success('Task updated successfully'); // Show success toast
          return updatedRow;
        } catch (error) {
          toast.error('Failed to update task'); // Show error toast
          return newRow; // Return the original row if there's an error
        }
      };
      
      
      
      const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
      };
  return (

    <Box
      sx={{
        height: 400,
        width: '80%',
        margin: '100px'
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowClick={(params) => {
          dispatch(setTask(params.row)) 
        }}
        style={{ background: 'white' }}
      />
      <TaskDeatilsPopupModal userOptions={userOptions}/>
    </Box>
  )
}
