import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import classes from '../../styles/asset.module.css'
import SettingsIcon from '@mui/icons-material/Settings'
import VideoLabelIcon from '@mui/icons-material/VideoLabel'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useHistory } from 'react-router-dom'
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'
import TextField from '@mui/material/TextField'
import {
  createQuizTemplate,
  createTemplate,
  setIsErr,
  setStep,
  setTemplateName,
  setTemplateNameError,
  updateTemplate
} from '../../redux/features/quiz/quizSlice'
import TemplateReview from './TemplateReview'
import QuizTemplateReview from './QuizTemplateReview'
import { Button } from '@mui/material'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  })
}))

function ColorlibStepIcon(props) {
  const { active, completed, className } = props

  const icons = {
    1: <SettingsIcon />,
    2: <VideoLabelIcon />,
    3: <VisibilityIcon />
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
}
//todo we need to add a section to review the complete template in a clean manner.
const steps = ['Select a name for template', 'Enter details', 'Review Template']

export default function Steps({
  inputList,
  setInputList,
  customFeild,
  setCustomFeild,
  saftyList,
  setSaftyList,
  quizVideo,
  customSection,
  setCustomSection
}) {
  const history = useHistory()
  const [addCustomInputs, setAddCustomInputs] = React.useState(false)
  const [disabledFeild, setDisabledFeild] = React.useState(false)
  const [disabledSection, setDisabledSection] = React.useState(false)
  const [quizTemplate, setQuizTemplate] = React.useState(false)
  const {
    isTemplateCreateSuccess,
    video_id,
    step,
    templateName,
    templateNameError,
    template
  } = useSelector(state => state.quiz)

  const dispatch = useDispatch()
  const backButtonStyle = {
    backgroundColor: '#f63854',
    color: 'white',
    '&:hover': {
      backgroundColor: '#f63854'
    }
  }
  const handleNextStep = () => {
    // if (templateName) {
    if (step < steps.length - 1) {
      dispatch(setStep(step + 1))
    }
    // } else {
    //   dispatch(setTemplateNameError(true))
    // }
  }
  const handlePrevStep = () => {
    if (step < steps.length + 1) {
      dispatch(setStep(step - 1))
    }
  }

  const addCustomSectionHandler = type => {
    if (type === 'section') {
      if (customSection.length > 0) {
        if (customSection.some(obj => obj.title === '')) {
          return
        }
        if (
          customSection[customSection.length - 1].feilds.length === 0 ||
          customSection.some(obj => obj.feilds.some(item => item.name === ''))
        ) {
          return
        }
        setCustomSection([...customSection, { title: '', feilds: [] }])
      }
      setCustomSection([...customSection, { title: '', feilds: [] }])
    }

    if (type === 'feild') {
      if (customSection.some(obj => obj.title === '')) {
        return
      }
      if (
        customSection.some(obj => obj.feilds.some(item => item.name === ''))
      ) {
        return
      }
      const idx = customSection?.length - 1
      const updatedItems = customSection.map((item, index) => {
        if (index === idx) {
          return {
            ...item,
            feilds: [...item.feilds, { name: '', type: 'Wrong' }] // Update the title within the 'section' object
          }
        }
        return item
      })
      setCustomSection(updatedItems)
    }
  }
  const addnewFeildHandler = (i, type, idx) => {
    const arr = [...customSection]
    if (type === 'feild') {
      arr[i].feilds.splice(idx + 1, 0, { name: '', type: 'Wrong' })
    }
    // else if (type === "section") {
    //   arr.splice(i + 1 , 0 , { title: "", feilds: [] })
    // }
    setCustomSection(arr)
  }

  const removeCustomSectionHandler = (i, type, idx) => {
    const list = [...customSection]
    if (type === 'section') {
      list.splice(i, 1)
    }
    if (type === 'feild') {
      list[i].feilds.splice(idx, 1)
    }
    setCustomSection(list)
  }

  const handelCutomSectionChange = (e, i, id, type, idx) => {
    const { name, value } = e.target
    let updatedItems
    if (type === 'title') {
      updatedItems = customSection.map((item, index) => {
        if (index === i) {
          return { ...item, title: value } // Update the title within the 'section' object
        }
        return item
      })
    } else if (type === 'feild') {
      updatedItems = [...customSection]
      if (name === 'name') {
        updatedItems[i].feilds[idx].name = value
      }
      if (name === 'type') {
        updatedItems[i].feilds[idx].type = value
      }
    }
    setCustomSection(updatedItems)
  }

  const conditionMet = inputList?.some(obj => obj.name === '')

  const handelSaveTemplate = () => {
    const questions = []
    customSection?.forEach((ques, index) => {
      const questionList = {
        question: '',
        answer1: '',
        answer2: '',
        answer3: '',
        correct_answer: 0
      }
      let corrected_answer = 0
      ques.feilds.forEach((ans, i) => {
        if (ans.type === 'Right') corrected_answer = i + 1
      })
      questionList.question = customSection[index].title
      questionList.answer1 = customSection[index].feilds[0].name
      questionList.answer2 = customSection[index].feilds[1].name
      questionList.answer3 = customSection[index].feilds[2].name
      questionList.correct_answer = corrected_answer
      questions.push(questionList)
    })

    const quizData = {
      video_id: video_id,
      title: templateName,
      questions: questions
    }

    if (templateName) {
      if (template?.id) {
        dispatch(updateTemplate({ id: template?.id, data: quizData }))
      } else {
        dispatch(createQuizTemplate(quizData))
      }
    } else {
      dispatch(setIsErr(true))
    }
    if (isTemplateCreateSuccess) {
      history.push('/templates-quiz')
    }
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {/* Render different content for each step */}
      {step === 0 && (
        <div>
          <TextField
            error={templateNameError}
            helperText={templateNameError && 'Incorrect entry.'}
            label='Quiz Name'
            value={templateName}
            variant='outlined'
            fullWidth
            margin='normal'
            onChange={event => {
              dispatch(setTemplateName(event.target.value))
              dispatch(setTemplateNameError(false))
            }}
          />
          <Button
            style={templateName !== "" ? backButtonStyle : null}
            onClick={handleNextStep}
            disabled={templateName === ""}
          >
            Next
          </Button>
        </div>
      )}
      {step === 1 && (
        <div>
          <QuizTemplateReview
            addCustomSectionHandler={addCustomSectionHandler}
            removeCustomSectionHandler={removeCustomSectionHandler}
            handelCutomSectionChange={handelCutomSectionChange}
            addCustomInputs={addCustomInputs}
            customSection={customSection}
            quizVideo={quizVideo}
            setCustomSection={setCustomSection}
            setAddCustomInputs={setAddCustomInputs}
            addnewFeildHandler={addnewFeildHandler}
          />
          <Button
            // className={`${classes.threeDButton} mr-2`}
            style={backButtonStyle}
            onClick={handlePrevStep}
            sx={{ mr: 2 }}
          >
            Back
          </Button>
          {/* {console.log("the section", customSection[0], "color condition check: ",customSection[0].title.length,customSection[0].title.length < 5 ,customSection[0].feilds.length!=0 , customSection[0].feilds[0]?.name === "")} */}
          <Button
            style={customSection[0].title.length > 5 && customSection[0].feilds.length > 0 && customSection[0].feilds[0]?.name !== "" ? backButtonStyle : { color: 'gray' }}
            // className={`${classes.threeDButton}`}
            onClick={handleNextStep}
            disabled={customSection[0].title.length < 5 || customSection[0].feilds.length <= 0 || customSection[0].feilds[0]?.name === ""}
          >
            Next
          </Button>
        </div>
      )}
      {step === 2 && (
        <div>
          <TemplateReview
            quizVideo={quizVideo}
            templateName={templateName}
            customSection={customSection}
          />

          <Button
          sx={{mr:2}}
            style={backButtonStyle}
            onClick={handlePrevStep}
          >
            Back
          </Button>
          <Button
            style={video_id !== 0 ? backButtonStyle : { color: 'gray' }}
            disabled={video_id === 0}
            onClick={handelSaveTemplate}
          >
            Save
          </Button>
        </div>
      )}
    </Stack>
  )
}
