/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import React from 'react';
import Chart from 'react-apexcharts';

const PieChart = (props) => {
  const series = props.categories;
  const options = {
    labels: ['Approved e-PTW', 'rejected e-PTW', 'Pending e-PTW', 'submitted', 'resubmitted e-PTW'],
    colors: ["#F97FAB", "#f6367b", "#36dbf6", "#55FFFF", "#333333"],

    chart: {
      width: 100,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'status',
              fontSize: '15px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              color: '#373d3f',
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      type: 'solid',
    },
    legend: {
      position: 'top',
      formatter: (val, opts) => {
        const customLabels = ['Approved', 'Rejected', 'Pending', 'Submitted', 'Resubmitted'];

        return customLabels[opts.seriesIndex];
      },
    },
    responsive: [
      {
        breakpoint: 300,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'top',
            display: 'block',
          },
          donut: {
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: 'All ePTW\'s',
                fontSize: '10px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b;
                  }, 0);
                },
              },
            },
          },
        },
      },
    ],
  };
  return (
    <div>
      <Chart options={options} series={series} type="donut" height={"317px"} width={"100%"} />
    </div>
  );
};

export default PieChart;
