import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddProject from "../../components/project/projectDrawer/addproject";
import classes from "../../styles/asset.module.css";
import ProjectCard from "../../components/project/card/card";
import {
  getProject, openProjectModalCreate, resetProjectActions,
} from "../../redux/features/project/projectSclice";
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from "../../components/loader/loader";
import { Box, Card, Grid, Typography } from "@mui/material";
import { getAnalyticsForDashboard } from '../../redux/features/analytics/analyticsSlice';

import Paper from '@mui/material/Paper';
import StatCard from "../../components/statCard/statCard";
export default function Project() {

  const { analytics, filter, staff } = useSelector(state => state.analytics);
  const [duration, setDuration] = useState('year');
  const [ptwCategory, setEptwCategory] = useState([0, 0, 0, 0, 0]);
  const [pendingptwCount, SetPendingPetCount] = useState(0);
  const [taskData, setTaskData] = useState([0, 0, 0, 0]);
  const [projectTotalAndActive, setProjectTotalAndActive] = useState([0, 0]);
  const [rows, setRows] = React.useState([]);

  useEffect(() => {
    dispatch(getAnalyticsForDashboard({ filter, staff }));
  }, [filter, staff]);

  useEffect(() => {
    setProjectTotalAndActive([
      parseInt(analytics?.projects_analytic?.total_projects, 10),
      parseInt(analytics?.projects_analytic?.active_projects, 10),]
    );
  }, [analytics]);
  // States for managing component
  const dispatch = useDispatch();
  const { project,
    isGetProjectLoading,
    isCreateprojectError,
    isUpdateprojectError,
    isDeleteprojectError,
    isCreateprojectSuccess,
    isUpdateprojectSuccess,
    isDeleteprojectSuccess ,
    isCreateprojectLoading,
    isUpdateprojectLoading,
    isDeleteprojectLoading,
    projectModalCreate,
    projectSearchTerm
  } = useSelector((state) => state.project);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const { ID } = useParams();

  const openModal = () => {
    setOpenModalCreate(true);
  };

  const closeModalCreate = () => {
    setOpenModalCreate(false);
  };
  
  React.useEffect(() => {
    dispatch(getProject());
  }, [dispatch]);

  React.useEffect(() => {
    setRows(project?.filter(singleProject=>singleProject.name.toLowerCase().startsWith(projectSearchTerm)).map(oneProject => (
        // {
        //     ...oneProject,
        //     id: oneProject?.id,
        //     name: oneProject?.name,
        //     assigned_to: oneProject?.assigned_to,
        //     location: oneProject?.location,
        //     status: oneProject?.status,
        // }
        oneProject
    )))
}, [project,projectSearchTerm])


  function displayToast() {
     if (isCreateprojectError) {
        toast.error('Something went wrong Creating Project', {
        toastId: 'CreatingProjectError',
      });
     }else if(isCreateprojectSuccess){
      toast.success('Project added successfully', {
        toastId: 'Project added Success',
      });
     }else if(isDeleteprojectError){
      toast.error('Something Went Wrong Deleting Project', {
        toastId: 'Projectnot added',
      });
     }else if(isUpdateprojectError){
      toast.error('Something Went Wrong Updating Project', {
        toastId: 'Projectnot updated',
      });
     }else if(isDeleteprojectSuccess){
      toast.success('Project Deleted successfully', {
        toastId: 'Project deleted Success',
      });
     }else if(isUpdateprojectSuccess){
      toast.success('Project Updated successfully', {
        toastId: 'Project Updated Success',
      });
     }
  }
  useEffect(()=>{
    dispatch(resetProjectActions())
  },[isCreateprojectError,isCreateprojectSuccess,isDeleteprojectError
  ,isDeleteprojectSuccess,isUpdateprojectSuccess,isUpdateprojectError
  ])
 
         
  return isCreateprojectLoading|| isUpdateprojectLoading||isDeleteprojectLoading || isGetProjectLoading?
  (
   <Loader/>
  ):( <div className={classes.assets}>
    {displayToast()}
    <AddProject
      openModalCreate={projectModalCreate}
      // closeModalCreate={closeProjectModalCreate}
    />
          <Box ml={5}>
        <Grid  container spacing={2} >
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={projectTotalAndActive[0]||0} type={'Total projects'} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={projectTotalAndActive[1]||0} type={'Active Projects'} sx={{ ml: 2 }} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={0} type={'Approved Permit'} sx={{ ml: 2 }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={0} type={'Rejected Permit'} sx={{ bgcolor: '#red' }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatCard count={0} type={'Completed Permit'} />
          </Grid> */}
        </Grid>
      </Box>
<Card sx={{p:4}}>
{/* {rows.length === 0 ? (
    // Display a placeholder image or message when project length is 0
        <div className={classes.centeredContent}>
    <div className={classes.placeholder}>
      <Typography style={{ color: 'gray' }} variant="h4">No Projects Found</Typography>
    </div>

      </div>
  ) */}
{rows.length === 0 ? (
    // Display a placeholder image or message when project length is 0
        <div className={classes.centeredContent}>
    <div className={classes.placeholder}>
      <Typography style={{ color: 'gray' }} variant="h4">No Projects Found</Typography>
    </div>

      </div>
  )
  
  
  : (
    <div className={classes.card_container}>
      {rows.map((Data) => {
        // Check if ID exists and matches Data.id
        if (ID && Data.id === parseInt(ID)) {
          return (
            <ProjectCard
              key={Data.id}
              formData={Data}
              openModal={openModal}
              openModalCreate={openModalCreate}
              closeModalCreate={closeModalCreate}
            />
          );
        } else if (!ID) {
          // If ID doesn't exist, render all cards
          return (
            <ProjectCard
              key={Data.id}
              formData={Data}
              openModal={openModal}
              openModalCreate={openModalCreate}
              closeModalCreate={closeModalCreate}
            />
          );
        }
        // Skip rendering if ID exists and doesn't match Data.id
        return null;
      })}
    </div>)}
</Card>
  </div>);
}
