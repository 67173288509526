import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classess from "../../styles/asset.module.css"

import { useDispatch, useSelector } from 'react-redux';
import {
    reset,
    templateDelete,
    closetemplateDeleteModal
  } from "../../redux/features/template/templateSclice";
  function DeleteTemplate({ open, id }) {
    const {
      tepmlateModalDelete,
    } = useSelector((state) => state.template);
    const dispatch = useDispatch();
    const confirmDeletion = async (e) => {
        dispatch(templateDelete(id))
        dispatch((closetemplateDeleteModal()))
    }

    return (
        <Modal show={open} onHide={()=> dispatch(closetemplateDeleteModal())} animation={true}>
            <Modal.Header>
                <Modal.Title>Delete Template</Modal.Title>
                <button
                    className='btn secondary-btn'
                    onClick={()=>dispatch(closetemplateDeleteModal())} type='button'>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className={classess.eptwButtons}>
                        <h5>Are you sure you want to delete this template?</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={classess.eptwButtons}>
                    <Button  variant="contained" size="small" onClick={confirmDeletion}>
                        Delete
                    </Button>
                </div>
                <Button variant="primary"
                    onClick={()=>dispatch(closetemplateDeleteModal())}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteTemplate
