import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getProject = async () => {
    const res = await http.get(`${BASE_URL}/project/`)
    return assert(
        res,
        res.data,
        "Retrival of project by Project is failed"
    )
}
const getProjectById = async (projectID) => {
    const res = await http.get(`${BASE_URL}/project/${projectID}`)
    return assert(
        res,
        res.data,
        "Retrival of project by Project is failed"
    )
}

const projectDelete = async (projectID) => {
    const res = await http.delete(
        `${BASE_URL}/project/${projectID}/`
    )
    return assert(res, projectID, "Deletion of sub location failed", res);
}

const updateProject = async (data) => {
    const res = await http.put(`${BASE_URL}/project/${data.id}/`, data);
    return assert(res, res.data, 'Update of the Project failed', res);
};



const addProject = async (project) => {
    const res = await http.post(`${BASE_URL}/project/`, project)
    return assert(res, res.data, 'Create Project is failed', res);
}
const getContractor = async () => {
    const res = await http.get(`${BASE_URL}/contractor/`)
    return assert(
        res,
        res.data,
        "Retrival of contractor by Project is failed"
    )
}

const createContractor = async (data) => {
    const res = await http.post(`${BASE_URL}/contractor/`, data)
    return assert(res, res.data, 'Create Contractor is failed', res);
}

const updateContractor = async (data) => {
    const res = await http.put(`${BASE_URL}/contractor/${data.id}/`, data);
    return assert(res, res.data, 'Update of the Contractor failed', res);
};

const deleteContractor = async (contractorID) => {
    const res = await http.delete(
        `${BASE_URL}/contractor/${contractorID}/`
    )
    return assert(res, contractorID, "Deletion of contractor failed", res);
}




const projectService = { getProject, getProjectById, projectDelete, addProject, updateProject, getContractor, createContractor,
updateContractor,deleteContractor
}

export default projectService