import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login, resetAuth} from '../../redux/features/auth/authSilice';
import loginLogo from '../../assets/img/maintenance.png';
import foxLogo from '../../assets/img/FOX-Logo3.png';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import './login.css'

function Login() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const {user, isLoading, isError, isSuccess } = useSelector((state) => state.auth);
    const [isShow, setIsShow] = useState(false);
    const handleSubmit = (e) => {
      const userInfo = { username, password };
      dispatch(login(userInfo));
      e.preventDefault();
      };
    const { showSpinner } = useApiCallStatusNotificationHandler({
      isSuccess,
      isLoading,
      isError,
      successCallBack: () =>
        setTimeout(() => {
          history.push('/');
        }, 3500),
    });
    
    useEffect(() => {
      dispatch(resetAuth());
    }, [user, isError, isSuccess, isLoading, dispatch, history]);
    return (
      
      <div className='login-container'>
        {showSpinner ? <div id='loader' /> : null}
        <div className='row d-flex m-2 mt-5 justify-content-center '>
          <div
            id='carouselExampleIndicators'
            className='carousel slide  my-auto col-lg-4 col-sm-12'
            data-ride='carousel'
          >
            <ol className='carousel-indicators'>
              <li
                data-target='#carouselExampleIndicators'
                data-slide-to='0'
                className='active inline-main'
              />
            </ol>
            <div className='carousel-inner '>
              <div className='carousel-item active d-flex justify-content-center'>
                <div className='col-lg-12 col-md-6 col-sm-6 inline-carousel-item'>
                  <div className='text-center inline-maintain-logo'>
                    <img alt='maintain logo' src={loginLogo} />
                  </div>
                </div>
              </div>
            </div>
            <button type='button' className='carousel-control-prev' href='#'>
              <span className='carousel-control-prev-icon' aria-hidden='true' />
              <span className='sr-only'>Previous</span>
            </button>
            <button className='carousel-control-next' href='#' type='button' data-slide='next'>
              <span className='carousel-control-next-icon' aria-hidden='true' />
              <span className='sr-only'>Next</span>
            </button>
          </div>
          <div className='col-lg-1 col-sm-12' />
          <div className='login-box justify-content-center my-auto inline-login-box'>
            <div className='mb-5 row justify-content-center'>
              <img alt='logo' src={foxLogo} height='100' />
            </div>

            <div className=''>
              <form name='loginForm' onSubmit={handleSubmit}>
                <div className='mb-4'>
                  <input
                    name='username'
                    type='text'
                    className='form-control inline-username'
                    placeholder='Username'
                    id='username'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <div className='col-lg-12 col-sm-12 m-0'>
                    <span className='font-danger' id='username_error' />
                  </div>
                </div>
                <div className='input-group mb-2'>
                  <input
                    name='password'
                    value={password}
                    type={!isShow ? 'password' : 'text'}
                    className='form-control inline-password'
                    placeholder='Password'
                    id='password'
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className='input-group-append'>
                    <div className='input-group-text inline-show-password pointer'>
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => setIsShow(!isShow)}
                        title={!isShow ? 'Show password' : 'Hide password'}
                        id="eye"
                      >
                        {isShow ? (
                          <FontAwesomeIcon icon={faEye} />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} />
                        )}{' '}
                      </span>
                    </div>
                  </div>
                  <div className='col-lg-12 col-sm-12 m-0'>
                    <span className='font-danger' id='password_error' />
                  </div>
                </div>
                <div className='mb-5 col-lg-12 col-sm-12 m-0 inline-forgot-password'>
                  Forgot your password?{' '}
                  <a className='  ' href='/forgot-password'>
                    Reset password
                  </a>
                </div>
                <div className='row'>
                  <div className='col-lg-12 col-sm-12 m-0'>
                    <button type='submit' className='btn btn-block primary-btn inline-login-btn'>
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='footer text-center col-lg-12 my-3 inline-footer'>
          {' '}
          © 2023 Infinity Wave Sdn. Bhd.
        </div>
      </div>
    

  )
}

export default Login