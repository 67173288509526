import { assert, http } from '../../../utils'
import { BASE_URL } from '../../../utils/axios'

const getQuizOfVideoByID = async video_pk => {
  const res = await http.post(`${BASE_URL}/quiz/video/${video_pk}/`)
  return assert(res, res.data, 'Get quiz of video by if failed', res)
}

const createQuizTemplate = async template => {
  const res = await http.post(`${BASE_URL}/quiz/`, template)
  return assert(res, res.data, 'Create template is failed', res)
}
const getTemplates = async () => {
  const res = await http.get(`${BASE_URL}/quiz/`)
  return assert(res, res.data, 'Create template is failed', res)
}

const getTemplateById = async templateID => {
  const res = await http.get(`${BASE_URL}/quiz/${templateID}`)
  return assert(res, res.data, 'Retrival of project by Project is failed')
}

const templateDelete = async templateID => {
  const res = await http.delete(`${BASE_URL}/quiz/${templateID}/`)
  return assert(res, templateID, 'Deletion of sub location failed', res)
}

const updateTemplate = async payload => {
  const res = await http.patch(`${BASE_URL}/quiz/${payload.id}/`, payload.data)
  return assert(res, res.data, 'Update of the Project failed', res)
}
const quizService = {
  getQuizOfVideoByID,
  getTemplates,
  createQuizTemplate,
  getTemplateById,
  templateDelete,
  updateTemplate
}

export default quizService
