import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { useDispatch } from 'react-redux';
import { setVideoDetails } from '../../../redux/features/session/sessionSlice';
import './InductionManagementForm.css'
import InductionModalBody from '../InductionModalBody/InductionModalBody';

const InductionManagementForm = ({ show, handleClose, handleShow }) => {
    const dispatch = useDispatch()
    return (
        <Modal show={show} onHide={()=>{
            dispatch(setVideoDetails(null))
            handleClose()
          }} size="lg" aria-labelledby="example-modal-sizes-title-lg"
          centered>
            <Modal.Header>
              <Modal.Title id="example-modal-sizes-title-lg">Create Induction session</Modal.Title>
              <div>
                <Button variant="primary" onClick={()=>{
                  dispatch(setVideoDetails(null))
                  handleClose()
                }}>
                X
              </Button>
              </div>
            </Modal.Header>
            <Modal.Body><InductionModalBody/></Modal.Body>
          </Modal>
    );
};

export default InductionManagementForm;