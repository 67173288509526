/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */

import React, { useEffect } from 'react'
import { Drawer} from "@mui/material";
import moment from 'moment';
import {toast} from "react-toastify"
import { useDispatch, useSelector } from 'react-redux';
import {
    setTitle,
    setDescription,
    setSite,
    setWorkPlace,
    setWorkType,
    setAttributes,
    setContractor,
    setStartDate,
    setEndDate,
    setExpiryDate,
    createEptw,
    setDocumentNumber,
    setPermitNumber,
    setTemplateId,
} from '../../../redux/features/eptw/eptwSlice';
import { SelectContractors, SelectPersons, SelectTemplates } from '../../../components/selectPersons/SelectPersons';
import { setAssignedTo } from '../../../redux/features/approver/approverSlice';
import { getAllUsers } from '../../../redux/features/user/userSlice';

function AddEptwModal({openModalCreate, closeModalCreate }) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getAllUsers());
      }, [])

    const {
       contractors
      } = useSelector(state => state.project)
      const {
        templates,
        template,
      } = useSelector(state => state.template)
    const {
        title,
        description,
        documentNumber,
        permitNumber,
        templateId,
        attributes,
        contractor,
        // startDate,
        // endDate,
        expiryDate,
        eptwModalCreate
    } =useSelector(state => state.eptw)

    const {
        assigned_to,
      } = useSelector(state => state.approver);
      const {allUsers} = useSelector(state => state.user)

    useEffect(() => {
     dispatch(setAttributes(template && template?.attributes?.map(att =>(
        {...att , value: false}
     ))))
    }, [])
    
    // const handelTitle = (e)=>{
    //     dispatch(setTitle(e.target.value))
    // }
  
    // const handelDescription = (e)=>{
    //   dispatch(setDescription(e.target.value))
    // }
    const handelSite = (e)=>{
      dispatch(setSite(e.target.value))
    }
    const handelWorkPlace = (e)=>{
      dispatch(setWorkPlace(e.target.value))
    }
    const handelDocumentNumber = (e)=>{
      dispatch(setDocumentNumber(e.target.value))
    }
    const handelPermitNumber = (e)=>{
      dispatch(setPermitNumber(e.target.value))
    }
    const handelWorkType = (e)=>{
      dispatch(setWorkType(e.target.value))
    }
    const handelTemplate = (e)=>{
      dispatch(setTemplateId(e))
    }
    console.log("templateId" , templateId);
    const handelAttributes = (e , id)=>{
    const arrOfAttributes = []
    attributes.forEach(item =>{
        if (item.id === id) {
            arrOfAttributes.push({...item , value: !item.value})
        }else{
            arrOfAttributes.push(item)
        }
    })
      dispatch(setAttributes(arrOfAttributes))
    }
    const handelContractor = (e)=>{
      dispatch(setContractor(e.value))
    }
    const handleStartDate = (e)=>{
    dispatch(setStartDate(e.target.value))
    }
    const handleEndDate = (e)=>{
    dispatch(setEndDate(e.target.value))
    }
    const handleExpiryDate = (e)=>{
    dispatch(setExpiryDate(e.target.value))
    }
  const getId = (data)=>{
    return data.map(item => item?.value)
  }
const approvers = getId(assigned_to)
const templateIds = getId(templateId)
const data = {
    // title,
    // work_description: description,
    contractor: contractor ? contractor : null,
    template_id: templateIds,
    approvers
}

const handelSave = async (e)=>{
    e.preventDefault()
    if (templateId) {
        await dispatch(createEptw(data))
    }else{
        toast.warning('Please Fill The Required Fields', {
            toastId: 'AddPtwWarning',
        });
    }
}

useEffect(() => {
    dispatch(setAssignedTo([]))
}, [eptwModalCreate])


return (
<Drawer 
anchor="right"
      open={openModalCreate}
      onClose={closeModalCreate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
>
  <form 
  onSubmit={handelSave}
  className="h-100 w-100" >
    <div className="h-100">
        <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
        
            <div className="col-12 mb-2">
                <div className="row px-2">
                    <div className="my-auto">
                            <b>
                            Add Permit
                            </b>
                    </div>
                    <button onClick={closeModalCreate} type='button'  className="btn secondary-btn ml-auto" data-dismiss="modal" >
                        <div className="fas fa-times"></div>
                        </button>
                </div>
            </div>
        </div>
        <div className="" style= {{minHeight: "78.5%"}}>
                <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
                    <div  id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                        <div className="card-body">
                            <div className="row pb-5">
        
                {/* <div className="col-lg-12 col-sm-12 pt-3">
                    <label className="font-weight-normal">Work/Project Title<label className="font-danger m-0">*</label></label>
                    <input  required name='meter_name' type="text" className="form-control mb-2"
                            placeholder="Work/Project Title" 
                        value={title} onChange={handelTitle} 
                        />
                    <div className="col-lg-12 col-sm-12 m-0"><span className="font-danger" id="meter_error"></span></div>
                </div>

                <div className="col-lg-12 col-sm-12 pt-3">
                    <label className="font-weight-normal">Work Description</label>
                    <textarea name='description' className="form-control mb-2"
                    placeholder="Work Description" id="description" 
                    onChange={handelDescription}
                    value={description}
                    ></textarea>
                
                </div> */}


                <div className="col-lg-12 col-sm-12 pt-3">
                <label className="font-weight-normal ">Permit</label><label className="font-danger m-0">*</label>
                {/* <select className="form-control select-dash custom-select2 mb-2" 
                name="priority2" id="priority2"
                required
                onChange={handelTemplate}
                value={templateId}
                >
                    <option value="" disabled selected>--SELECT--</option>
                    {templates?.map(template =>(
                        <option  key={template.id} value={template.id}>{template?.name}</option>
                    ))}
                </select> */}
                <SelectTemplates templates= {templates} handelChange= {handelTemplate}   />
                </div>
                <div className="col-lg-12 col-sm-12 pt-3">                        
                <label className="font-weight-normal">Requester</label>

                {/* <select className="form-control select-dash custom-select2 mb-2" 
                name="category" id="category2"
                onChange={handelContractor}
                value={contractor}
                >
                    <option value="" disabled selected>--SELECT--</option>
                    {contractors && contractors.length > 0 
                    && contractors.map(cont =>(
                        <option key={cont.id} value={cont.id}>{cont.name}</option>
                    ))}
                </select> */}
                <SelectContractors contractors= {contractors} handelChange= {handelContractor} contractor={contractor} />
                </div>
                <div className="col-lg-12 col-sm-12 pt-3">                        
                <label className="font-weight-normal">Approvers</label>
                <SelectPersons teams={allUsers} assignedTo={assigned_to} setAssignedTo={setAssignedTo} />
                </div>
                {/* the following is the start and end dates for permit, commented as per requested by mr zul */}
                {/* <div className="col-lg-12 col-sm-12 pt-3">  
                <div className="row">
                <div className=" col-lg-6 col-md-6 col-sm-12">
                    <label className="font-weight-normal">Start Date  </label>
                    <input
                            type="date"
                            className="form-control mb-2"
                            name="service_date"
                            id="meter_service_date"
                            placeholder="Date"
                            
                            value={startDate}
                            onChange={handleStartDate}
                            />
                </div>
                <div className=" col-lg-6 col-md-6 col-sm-12">
                    <label className="font-weight-normal">End Date  </label>
                    <input
                            type="date"
                            className="form-control mb-2"
                            name="service_date"
                            id="meter_service_date"
                            placeholder="Date"
                            
                            value={endDate}
                            onChange={handleEndDate}
                            />
                </div>
                </div>        
                </div> */}
              
               
                        
                           
                            </div>
                            <p className="font-danger float-center" id="code_error"></p>
                        </div>
                    </div>           
        </div>
        </div>
        <div className=" m-0 p-2 page-profile-footer" >
            <button
                className="btn primary-btn"
                type="submit"

            >
                Save
            </button>
        </div>
    </div>
 </form>
 </Drawer>
  
  )
}

export default AddEptwModal


