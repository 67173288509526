import React from 'react';

import Container from '@mui/material/Container';

import Paper from '@mui/material/Paper';

import Typography from '@mui/material/Typography';

import Button from '@mui/material/Button';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const containerStyle = {

    display: 'flex',

    alignItems: 'center',

    justifyContent: 'center',

    minHeight: '100vh',

};

const paperStyle = {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',

};



const iconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f63854',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    marginBottom: '16px',
};



const iconStyle = {
    fontSize: '48px',
    color: 'white',
};



const messageStyle = {
    textAlign: 'center',
    marginBottom: '16px',
};



const backButtonStyle = {
    backgroundColor: '#f63854',
    color: 'white',
    '&:hover': {
        backgroundColor: '#f63854',
    },
};



const SuccessInduction = ({ title }) => {

    return (

        <Container style={containerStyle}>

            <Paper style={paperStyle} elevation={3}>

                <div style={iconContainerStyle}>

                    <CheckCircleIcon style={iconStyle} />

                </div>

                <Typography variant="h5" style={messageStyle}>

                    Success! You have submitted your Safety induction
                </Typography>

                <Button

                    variant="contained"

                    style={backButtonStyle}

                    onClick={() => {

                        window.location.href = "https://google.com";
                    }}

                >

                    Okay
                </Button>

            </Paper>

        </Container>

    );

};



export default SuccessInduction;