import React, { useEffect, useRef, useState } from 'react'
import AWS from 'aws-sdk';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { jsPDF } from 'jspdf';
// import { useReactToPrint } from 'react-to-print';
import { useReactToPrint } from 'react-to-print';
import QRCode from 'qrcode';
import Modal from 'react-bootstrap/Modal';
import {toast} from "react-toastify"
import {
    setWorkType,
    setAttributes,
    setStartDate,
    setEndDate,
    setSubcontractorName,
    setContractorName,
    setContractorEmail,
    setContractorPhoneNumber,
    setDescription,
    setEptw,
    setIdType,
    setMykadNumber,
    setGreenCardNumber,
    setLicenseNumber,
    setPassportNumber,
    setCompanyContactNumber,
    setSections,
    setDocumentNumber as eptwNum,
    setsubcontractorRepresentative,
    setForms,
    updateFillEptw,
    reset,
} from '../../redux/features/eptw/eptwSlice';
import logo from "../../assets/img/FOX-Logo3.png"
import PrintPdf from './printPdf';
import classes from "../../styles/asset.module.css";
import { getUserProfile } from '../../redux/features/user/userSlice';
import { getCompanyProfile } from '../../redux/features/company/companySlice';
import { setDocumentNumber, setRevisionNumber, setRevisionDate, } from '../../redux/features/template/templateSclice';
import Signature from '../contractorView/Signature';

AWS.config.update({
    region: 'ap-southeast-1',
    accessKeyId: 'AKIA3HYBXAPB2BK6VSEW',
    secretAccessKey: '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',
});

const s3 = new AWS.S3();

    function uploadImageToS3(file) {
    const params = {
        Bucket: 'foxproject',
        Key: Date.now().toString(), // Change the Key as needed
        Body: file,
        ContentType: file?.type
    };

    return new Promise((resolve, reject) => {
        s3.upload(params, (err, data) => {
        if (err) {
            reject(err);
        } else {
            resolve(data);
        }
        });
    });
    }

function FoxUserView({ open, onClose, onOpen, rowData }) {

    useEffect(() => {
        dispatch(getCompanyProfile())
    }, []);

    const { company } = useSelector((state) => state.company);
    const modalBodyStyle = {
        width: '90%',
        margin: '0 auto',
    };
    const [isSnackbarOpen, setSnackbarOpen] = React.useState(false);


    const handleCopyClick = async () => {
        // Logic to copy text to clipboard goes here
        await navigator.clipboard.writeText(rowData?.link);
        setSnackbarOpen(true);
    };
    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };
    const {
        eptw,
        sections,
        forms,
        isEptwFillSuccess,
        isEptwFillError
    } = useSelector(state => state.eptw)
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setEptw(rowData))
    }, [rowData])
    const getUserProfileData = async () => {
        await dispatch(getUserProfile());
    }
    useEffect(() => {
        getUserProfileData()
    }, []);
    const convertSectionsToArray = (sections) => {
        const newSections = sections?.map(item => {
            if (item.type === "Checkbox" && item.value === null) {
                return { ...item, value: false  }
            }
            return item
        })
        return newSections || []
    }
    useEffect(() => {
        dispatch(setDocumentNumber(eptw?.docuement_number))
        dispatch(setRevisionDate(eptw?.revision_date))
        dispatch(setRevisionNumber(eptw?.revision_number))
        dispatch(setContractorName(eptw?.contractor?.name || ""))
        dispatch(setSubcontractorName(eptw?.sub_contractor || ""))
        dispatch(setsubcontractorRepresentative(eptw?.sub_contractor_representative || ""))
        dispatch(setContractorEmail(eptw?.contractor?.email || ""))
        dispatch(setContractorPhoneNumber(eptw?.contractor?.phone_number || ""))
        dispatch(setWorkType(eptw?.work_type || ""))
        dispatch(setAttributes(eptw?.attributes || []))
        dispatch(setStartDate(eptw?.start_date || ""))
        dispatch(setEndDate(eptw?.end_date || ""))
        dispatch(setDescription(eptw?.work_description || ""))
        dispatch(setCompanyContactNumber(eptw?.contractor?.company_contact_number))
        if (eptw?.contractor?.id_type === "Passport") {
            dispatch(setPassportNumber(eptw?.contractor?.passport?.passport_number))
            dispatch(setLicenseNumber(eptw?.contractor?.passport?.license_number))
            dispatch(setGreenCardNumber(eptw?.contractor?.passport?.saftey_green_card_number))
        } else {
            dispatch(setMykadNumber(eptw?.contractor?.mykad?.mykad_number))
            dispatch(setLicenseNumber(eptw?.contractor?.mykad?.license_number))
            dispatch(setGreenCardNumber(eptw?.contractor?.mykad?.saftey_green_card_number))
        }

        dispatch(setIdType(eptw?.contractor?.id_type))
        dispatch(setSections(convertSectionsToArray(eptw?.sections && eptw?.sections)))
    }, [eptw])

    
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if (isEptwFillSuccess) {
            toast.success('Submitted successfully', {
                toastId: 'SubmittedeSuccess',
            });
            dispatch(reset())
        }
        if (isEptwFillError) {
            toast.error('Something went wrong', {
                toastId: 'SubmittedeError',
            });
            dispatch(reset())
        }
    }, [isEptwFillSuccess , isEptwFillError])
    
    const handelSections = async (e, ele, index, idx) => {
                const items = await Promise.all(sections?.map(async (item, i) => {
                    if (i === index) {
                        const f = await Promise.all(item.fields.map(async (l, j) => {
                            if (idx === j) {
                                if (l?.type !== "Checkbox" && l?.type !== "Attachment") {
                                    return { ...l, value: e.target.value };
                                } else if (l?.type === "Attachment") {
                                    const file = e.target.files[0];
                                    const handleFileChange = async () => {
                                        try {
                                            const result = await uploadImageToS3(file);
                                            return result.Location;
                                        } catch (error) {
                                            console.error('Error uploading image:', error);
                                            throw error; // Rethrow the error if needed
                                        }
                                    };
    
                                    try {
                                        const response = await handleFileChange();
                                        return { ...l, value: response  };
                                    } catch (error) {
                                        // Handle the error if needed
                                        console.error('Error updating value:', error);
                                        throw error; // Rethrow the error if needed
                                    }
                                } else {
                                    return { ...l, value: !l?.value };
                                }
                            } else return l;
                        }));
                        return { ...item, fields: f };
                    } else return item;
                }));    
            dispatch(setSections((items)))
    };
    const date = rowData?.revision_date?.split('T')[0];
    console.log("sections" , sections);
    const { user } = useSelector((state) => state.user);

    const handelSubmit = async (e) => {
        e.preventDefault()
        await dispatch(updateFillEptw({...eptw , sections}))
    }
    // const handelSubmit = async (e) => {
    //     e.preventDefault()
    //     let isValidSubmission=true
    //     for (const singleObj of sections) {
    //         for (const element of singleObj.fields) {
    //             if (element.isRequired === true && element.value === null) {
    //                 toast.error(`Field ${element.name} is required`, {
    //                     toastId: 'permitFillError',
    //                   });
    //                 isValidSubmission = false;
    //                 break; // Break out of the inner loop
    //             }
    //         }
        
    //         if (!isValidSubmission) {
    //             break; // Break out of the outer loop if an invalid submission is encountered
    //         }
    //     }
        
    //     if (isValidSubmission) {
    //     await dispatch(updateFillEptw({...eptw , forms}))
    // }
    // }

    return (
        <Modal show={open} onHide={onClose} animation={true} dialogClassName="modal-lg">
            <Modal.Header style={{ minWidth: "100%" }}>
                <Modal.Title> <img src={company.logo || logo} alt='logo' height="110" width="110" /></Modal.Title>
                <button
                    className='btn secondary-btn'
                    onClick={onClose} type='button'>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </Modal.Header>
            <Modal.Body >
                <h1 style={{ fontWeight: "400", marginLeft: "16px", textAlign: "center" }}>FOX Project Management</h1>
                <form
                >
                    <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
                        <div id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                            <div className="col-lg-12 col-sm-12 pt-3">
                                <div className="row">
                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Document Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                            placeholder="Document Number"
                                            value={rowData?.document_number || ""}
                                        />
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Permit Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                            placeholder="Document Number"
                                            value={rowData?.id || ""}
                                        />
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Revision Number<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                            placeholder="Revision Number"
                                            value={rowData?.revision_number || ""}
                                        />
                                    </div>
                                    <div className=" col-lg-6 col-md-6 col-sm-12">
                                        <label className="font-weight-normal">Revision Date<label className="font-danger m-0">*</label></label>
                                        <input disabled required name='date' type="date" className="form-control mb-2"
                                            placeholder="Revision Date"
                                            value={date || "N/A"}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 col-sm-12 pt-3">
                                <div className="row">

                                </div>
                            </div>
                                                {sections?.length > 0 && <div className="col-lg-12 col-sm-12 pt-3">
                                                <div>
                                                    {sections.map((item, index) => (
                                                        <div className={classes.templateSection} key={index}>
                                                            <h3 className={classes.sectionTitle}>{item.title}</h3>
                                                            <ul className={classes.sectionFeilds}>
                                                                {item?.fields?.map((ele, idx) => {
                                                                    const type = ele?.type
                                                                    console.log(ele);
                                                                    return type === "String" || type === "Date"  ? <div className="col-lg-12 col-sm-12">
                                                                        <label className="font-weight-normal">{ele.name}</label>{ele?.isRequired===true &&<h6 style={{ marginTop: '30px', textAlign: 'center'}} className="font-danger fs-6 m-0">*Required</h6>}
                                                                        <input
                                                                            type={type === "String" ? "text" :  "date" }
                                                                            className="form-control mb-2"
                                                                            accept=".pdf, image/*"
                                                                            value={ele.value}
                                                                            onChange={(e) => handelSections(e, ele, index, idx)}
                                                                        />
                                                                    </div> : type === "Checkbox" ? <div className="col-lg-12 col-sm-12">
                                                                        <div className={classes.templateCheckbox} style={{mr:0}}>
                                                                            <input type="Checkbox"
                                                                                checked={ele.value}
                                                                                onChange={(e) => handelSections(e, ele, index, idx)} />
                                                                            <label className="font-weight-normal">{ele.name}</label>
                                                                        </div> 
                                                                        </div> : type == 'Description' ? <div className="col-lg-12 col-sm-12"><h6>{ele.name}</h6></div> : 
                                                                        type === "Attachment" ? <div className="col-lg-12 col-sm-12">
                                                                        <label className="font-weight-normal">{ele.name}</label>{ele?.isRequired===true &&<h6 style={{ marginTop: '30px', textAlign: 'center'}} className="font-danger fs-6 m-0">*Required</h6>}
                                                                        <input
                                                                            type="file"
                                                                            accept=".pdf, image/*"
                                                                            className="form-control mb-2"
                                                                            onChange={(e) => handelSections(e, ele, index, idx)}
                                                                        />
                                                                        {ele.value && <div style={{width: "80% ", margin: "auto"}}> <a href={ele.value} target="_blank">Link of Attachment</a> </div>}
                                                                        </div> :
                                                                        <div className="col-lg-12 col-sm-12 pt-3">
                                                                        <label className="font-weight-normal">{ele.name}{ele.isRequired===true &&<h6 className="font-danger fs-6 m-0">*</h6>}</label>
                                                                        <Signature required={ele} index= {index} idx= {idx} sections={sections} value={ele.value} />
                                                                        </div>
                                                                })}
                                                            </ul>
                                                        </div>
                                                    ))}
                                                </div>
                                                </div>
                                                }
                        </div>
                    </div>
                </form>
                <div style={{ paddingBottom: '1.25rem', display: 'none' }}>
                    <div className="card-body" ref={componentRef}>
                        <PrintPdf sections={sections} rowData={rowData} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ minWidth: "100%", justifyContent: "space-between" }}>
                <div>
                    <Snackbar
                        open={isSnackbarOpen}
                        autoHideDuration={3000} // Adjust the duration as needed
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert severity="error" style={{
                            color: "#f63854",
                            // color: 'white', // Text color
                        }} onClose={handleCloseSnackbar}>
                            Text copied to clipboard!
                        </Alert>
                    </Snackbar>
                    <button id='srvc-btn' type='button' className="btn primary-btn mr-2" onClick={handlePrint}> Print PDF </button>
                    <button id='srvc-btn' type='button' className="btn primary-btn" onClick={handleCopyClick}> Copy Link </button>
                </div>
                <div>
                <button className="btn secondary-btn mr-2"
                    onClick={onClose}
                >
                    Cancel
                </button>
                <button className="btn primary-btn mr-2"
                    onClick={handelSubmit}
                    disabled={(user?.role !== "Administrator") && (user?.role !== "Manager")}
                >
                    Submit
                </button>

                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default FoxUserView