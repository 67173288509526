import React from 'react'
import { useSelector } from 'react-redux';
import classes from "../../styles/asset.module.css";
import { Card } from "@mui/material"
import logo from "../../assets/img/FOX-Logo3.png"
function TemplateReview({ templateName, customSection, revisionNumber, revisionDate, documentNumber }) {
    const date = revisionDate?.split('T')[0];
    const { company } = useSelector((state) => state.company);
    return (
        // <div className={classes.templateCard}>
        <div  className={classes.templateCard}>
            {/* <h3 className={classes.templateName}>{templateName}</h3>  */}
            {/* <div style={{position : "absolute" , right :"0"}}>HAAAAAAAAAAAAAAA</div> */}
            <div className="col col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-0">
                <div className="col col-lg-3 col-md-4 col-sm-4 col-xs-12  mb-2 logo float-left"
                    style={{ marginTop: "-35px" }}
                >
                    <img src={company?.logo ? company?.logo : logo} alt='logo' style={{width : "100px", height: "100px" , objectFit: "contain"}} />
                </div>
                <div className="col-lg-9 col-sm-12 col-md-8 mt-5 col-xs-12">
                    <h1 style={{ fontWeight: "400", marginLeft: "16px", textAlign: "center" }}>{templateName}</h1>
                </div>
            </div>
            <div className="mt-5">
                <center>
                    <div className="card col-lg-9 col-sm-12 col-xs-12 col-md-12 text-left"
                        style={{ display: "block" }}
                    >
                        {/* <Card sx={{ width: "100%" }}> */}
                        <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
                            <div id="maintenance-tab" role="tabpanel" aria-labelledby="custom-tabs-two-maintenance-tab">
                                <div className="col-lg-12 col-sm-12 pt-3">
                                    <div className="row">
                                        <div className=" col-lg-6 col-md-6 col-sm-12">
                                            <label className="font-weight-normal">Document Number<label className="font-danger m-0">*</label></label>
                                            <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                                placeholder="Document Number"
                                                value={documentNumber || ""}
                                            />
                                        </div>
                                        <div className=" col-lg-6 col-md-6 col-sm-12">
                                            <label className="font-weight-normal">Permit Number<label className="font-danger m-0">*</label></label>
                                            <input disabled required name='text' type="text" className="form-control mb-2"
                                                placeholder="Permit Number"
                                                // value={revisionNumber || ""}
                                                value={'Auto Generated Running Number'}
                                            />
                                        </div>
                                        <div className=" col-lg-6 col-md-6 col-sm-12">
                                            <label className="font-weight-normal">Revision Number<label className="font-danger m-0">*</label></label>
                                            <input disabled required name='meter_name' type="text" className="form-control mb-2"
                                                placeholder="Revision Number"
                                                value={revisionNumber || ""}
                                            />
                                        </div>
                                        <div className=" col-lg-6 col-md-6 col-sm-12">
                                            <label className="font-weight-normal">Revision Date<label className="font-danger m-0">*</label></label>
                                            <input disabled required name='date' type="date" className="form-control mb-2"
                                                placeholder="Revision Date"
                                                value={revisionDate || ""}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pb-3">

                                    {customSection.map((item, index) => (
                                        <div className={classes.templateSection} key={index}>
                                            {console.log("the itemss",item)}
                                            <h3 className={classes.sectionTitle}>{item.title}</h3>
                                            <ul className={classes.sectionfields}>
                                                {item?.fields.map((ele, idx) => {
                                                    console.log("an element",ele)
                                                    const type = ele.type
                                                    return (type === "String" || type === 'Date' || type === 'Attachment') ? <div className="col-lg-12 col-sm-12">
                                                        <label className="font-weight-normal">{ele.name}{ele.isRequired===true &&<label className="font-danger m-0">*</label>}</label>
                                                        <input
                                                            type={type === "String" ? "Text" : type === "Number" ? "number" : type === "Date" ? "date" : "file"}
                                                            className="form-control mb-2"
                                                            disabled
                                                            // placeholder={ele.type}
                                                            placeholder={null}
                                                        />
                                                    </div> : type == 'Checkbox' ? <div className="col-lg-12 col-sm-12">
                                                        <div className={classes.templateCheckbox}>
                                                            <input type="Checkbox" disabled />
                                                            <label className="font-weight-normal">{ele.name}</label>
                                                        </div> </div> :
                                                        type == 'Description' ? <div className="col-lg-12 col-sm-12"><h6>{ele.name}</h6></div>
                                                        : <div>
                                                            <label className="font-weight-normal">{ele.name}{ele.isRequired===true &&<label className="font-danger m-0">*</label>}</label>
                                                            <div className="col-lg-12 col-sm-12"><div className='sign-image'><h3 style={{ marginTop:'30px', textAlign: 'center', color: '#858585' }}>{ele.name }{ele.isRequired===true &&<label className="font-danger m-0">*</label>} [Signature field]</h3></div></div>
                                                        </div>
                                                        // <div className="col-lg-12 col-sm-12"><div className='sign-image'>
                                                        //     <h3 style={{ marginTop:'30px', textAlign: 'center', color: '#858585' }}>{ele.name } [Signature field]</h3>
                                                        //     </div></div>
                                                        //     
                                                        // </div> </div> : <div className="col-lg-12 col-sm-12"><div className='sign-image'><h3 style={{ marginTop:'30px', textAlign: 'center', color: '#858585' }}>{ele.name }{ele.isRequired===true &&<label className="font-danger m-0">*</label>} [Signature field]</h3></div></div>
                                                })}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {/* </Card > */}
                    </div>
                </center >
            </div>
        </div>
    )
}

export default TemplateReview