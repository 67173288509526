import React, { useEffect, useMemo, useState } from 'react'
import { Avatar, Box, Chip } from '@mui/material';
import classes from "../../../styles/asset.module.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import MenuItem from '@mui/material/MenuItem';
import profile from "../../../assets/img/profile.png"
import {toast} from "react-toastify"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { addPtwTask, getTaskByAuthentication, openTaskDetailModal, resetTaskActions, setTask, taskDelete, updateTask } from '../../../redux/features/task/taskSclice';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../Task/customButton';
import TaskDeatilsPopupModal from './taskDeatilsPopupModal';
import moment from 'moment';
import Loader from '../../loader/loader';
import PopUpAddModal from '../../popUpModals/popUpAddModal';
import { getEptwsByAuthentication } from '../../../redux/features/eptw/eptwSlice';
import DetailsModal from '../../eptw/ePtwModal';
import PopUpDeleteModal from '../../popUpModals/popUpDeleteModal';
import { SelectEptws } from '../../selectPersons/SelectPersons';
import { setProjectTasks } from '../../../redux/features/project/projectSclice';
// const initialElements = [];
export default function TaskDataTable({ projectById, userOptions }) {

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [selectedUser, setSelectedUser] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPtw, setSelectedPtw] = useState("");
  const [taskId, setTaskId] = useState(null);
  const [isOpenPtwModal, setIsOpenPtwModal] = useState(false);
  const [selectedPtwAtthached, setSelectedPtwAtthached] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);

  // const [elements, setElements] = useState(initialElements);
  // const [newElement, setNewElement] = useState('');
  const dispatch = useDispatch()
  const { project } = useSelector((state) => state.project)
  const {eptws} = useSelector(state => state.eptw)

  const handelSelectedPtw = (e) => {
   setSelectedPtw(e.value)
  };
  const handleCreateSave = () => {
    const data = {taskId , selectedPtw}
    if (selectedPtw) {
      dispatch(addPtwTask(data))
    }else{
      toast.warning("Please Select A e-PTW" , {
        toastId: 'please Select A e-PTW',
      })
    }
  };

  const {
    isCreateTaskSuccess,
    isUpdateTaskSuccess,
    isDeleteTasksSuccess,
    isCreateTaskLoading,
    isUpdateTaskLoading,
    isDeleteTaskLoading,
    isCreateTaskError,
    isUpdateTaskError,
    isDeleteTaskError,
    searchValue,
    isPtwAtachSuccess,
    isPtwAtachError,
    updatedTask
  } = useSelector((state) => state.task)

  useEffect(() => {
    if (isPtwAtachSuccess) {
      dispatch(setProjectTasks(projectById?.tasks?.map(item =>{
        if (item.id === updatedTask.id) {
          return updatedTask
        }else{
          return item
        }
      })))
      setIsOpen(false)
      toast.success("A e-PTW Attached Successfully" , {
        toastId: 'please Select A e-PTW',
      })
    }else if (isPtwAtachError){
      toast.error("Something went wrong at Attached e-PTW" , {
        toastId: 'please Select A e-PTW',
      })
    }
    dispatch(resetTaskActions())
  }, [isPtwAtachSuccess , isPtwAtachError, updatedTask])
  



    
  // useEffect(() => {
  //   const tasks =  [...projectById?.tasks]
  //   if (searchValue) {
  //     dispatch(setProjectTasks(tasks?.filter(item => item?.name?.toLowerCase()?.includes(searchValue.toLowerCase()))))
  //   }else{
  //     dispatch(setProjectTasks(projectById?.tasks))
  //   }
   
  // }, [searchValue])

  React.useEffect(() => {
    if (projectById?.tasks) {
      setRows(projectById.tasks?.filter(task => task.name.toLowerCase().startsWith(searchValue)).map((task) => ({
        id: task.id,
        name: task.name,
        status: task.status==="TODO"?"To Do":task.status,
        description: task.description,
        start_date: task.start_date ,
        finish_date:task.finish_date ,
        assigned_to: task.assigned_to,
        // assigned_to_Id: task.assigned_to.map(user => user.id),
        project: projectById.name,
        projectId: projectById.id,
        actual_start_date: projectById.actual_start_date,
        actual_finish_date: projectById.actual_finish_date,
        ptws: task.ptws
      })));
    }
  }, [projectById?.tasks , searchValue]);
  // const handleRowEditStop = (params, event) => {
  //   if (params.reason === GridRowEditStopReasons.rowFocusOut) {
  //     event.defaultMuiPrevented = true;
  //   }
  // };

  // const handleEditClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  // };

  // const handleSaveClick = (id) => () => {
  //   setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  //   console.log("Clcick");
  // };


  const handelDelete = async ()=>{
    await dispatch(taskDelete(taskIdToDelete));
    setRows(rows.filter((row) => row.id !== taskIdToDelete));
    setDeleteConfirm(false)
  }  
  
  const handleDeleteClick = (id) => async () => {
    setDeleteConfirm(true)
    setTaskIdToDelete(id)
  };



  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };
  const handleChange = (event) => {
    setSelectedUser(event.target.value);
  };

  // const processRowUpdate = async (newRow) => {
  //   try {
  //     const updatedRow = { ...newRow, isNew: false, assigned_to: [selectedUser] };
  //     await dispatch(updateTask(updatedRow));

  //     // Find the label (user name) for the selected user's ID
  //     const assignedToLabel = userOptions.find(option => option.id === selectedUser)?.label || '';

  //     // Update the assigned_to field with the user's name for display
  //     updatedRow.assigned_to = [assignedToLabel]; // Assign as an array for consistency

  //     setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
  //     console.log("rows", rows);
  //     // Show success toast
  //     return updatedRow;
  //   } catch (error) {
  //     return newRow; // Return the original row if there's an error
  //   }
  // };



  // const handleRowModesModelChange = (newRowModesModel) => {
  //   setRowModesModel(newRowModesModel);
  // };
  function stringAvatar(name1,name2, image) {
    if (image && !image.includes('no_profile')) {
      return {
        src: image
      };
    } else {
  //     const initials = name.trim().split(/\s+/)
  //       .map(part => part.charAt(0).toUpperCase())
  //       .join('');
  // console.log("here",name1,name2)
const initials = name1.charAt(0).toUpperCase()+name2.charAt(0).toUpperCase()||"--"
  return {
        children: initials
      };
    }
  }
  const columns = useMemo(
    () => [
      {
        field: 'Detail view',
        headerName: '',
        width: 70,
        sortable: false, // Disable sorting for this column
        disableColumnMenu: true,
        renderCell: (params) => (
          <FontAwesomeIcon
            icon={faUpRightFromSquare}
            onClick={() => dispatch(openTaskDetailModal())}
            style={{ cursor: 'pointer' }}
          />
        ),
      },
      { field: "name", headerName: "Task Name", width: 150 },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        // type: 'singleSelect',
        // valueOptions: ['TODO', 'In Progress', 'admin'],
        // editable: true,
        renderCell: (params) => (
          <>
              {params.row?.status?<Chip label={params.row.status} color={params.row?.status==='To Do'?"primary":params.row?.status==='In Progress'?"warning":"success"} />:  <Chip label="No status" color="primary" />}
          </> 
        )
        
      },
      {
        field: 'assigned_to',
        headerName: 'Assigned To',
        width: 150,
        // type: 'singleSelect',
        // renderEditCell: (params) => (
        //   <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        //     <InputLabel id={`select-label-${params.id}`}>User</InputLabel>
        //     <Select
        //       labelId={`select-label-${params.id}`}
        //       id={`select-${params.id}`}
        //       value={selectedUser}
        //       label="User"
        //       onChange={handleChange}
        //     >
        //       <MenuItem value="">
        //         <em>None</em>
        //       </MenuItem>
        //       {userOptions.map((option) => (
        //         <MenuItem key={option.id} value={option.id}>
        //           {option.label}
        //         </MenuItem>
        //       ))}
        //     </Select>
        //   </FormControl>
        // ),
        // editable: true,
        renderCell: (params) => (
          <>
              {/* {params.row?.approvals?.filter(item => item.approver.id === user?.id)?.length !== 0 &&
                  params.row?.status !== 'Rejected' && params.row?.status !== 'Closed' && params.row?.status !== 'Approved'
                  && params.row?.approvals?.length < 3 && <button className='mr-1'>
                      <GridActionsCellItem
                          icon={<AddCircleIcon style={{ color: '#f63854' }} />}
                          label="add"
                          onClick={() => handleOpenDialog(params.row.id)}
                          color="inherit"
                      />
                  </button>} */}
              {params.row?.assigned_to?.map((item, index) => (
                  // <img
                  //     key={index}
                  //     className={classes.assignedImage}
                  //     src={item?.image}
                  //     alt={item?.first_name}
                  //     title={`${item?.first_name}(${item?.role})`}
                  // />
                  <Avatar key={index} {...stringAvatar(item?.first_name||"",item?.last_name||"",item?.image)} title={item?.first_name||"Assigned To Person"} alt={item?.first_name+" "+item?.last_name||"Assigned To Person"} />

              ))}
          </> 
        )
      },


      // {
      //   field: 'project',
      //   headerName: 'Project',
      //   width: 150,
      //   type: 'singleSelect',
      //   valueOptions: project.map(option => option.name),
      // },
      {
        field: 'start_date',
        headerName: 'Start Date',
        width: 170,
        // editable: true,
        renderCell: (params) => (
          <p>{moment(params.row?.start_date).format('DD MMM dddd YYYY')}</p>
        ),
      },
      {
        field: 'finish_date',
        headerName: 'Finish Date',
        width: 170,
        // editable: true,
        renderCell: (params) => (
          <p>{moment(params.row?.finish_date).format('DD MMM dddd YYYY')}</p>
        ),
      },
      // {
      //   field: 'description',
      //   headerName: 'Description',
      //   width: 150,
      //   editable: true,
      // },

      // {
      //   field: 'ePTW',
      //   type: 'actions',
      //   headerName: 'ePTW',
      //   width: 150,
      //   editable: false,
      //   getActions: ({ id }) => {
      //     const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

      //     if (isInEditMode) {
      //       return [
      //         <GridActionsCellItem
      //           icon={<AddCircleIcon />}
      //           label="add"
      //           className="textPrimary"
      //           onClick={()=> handleOpenDialog(id)}
      //           color="inherit"
      //         />,
      //       ];
      //     }

      //     return [
      //       <GridActionsCellItem
      //         icon={<AddCircleIcon />}
      //         label="add"
      //         onClick={()=>handleOpenDialog(id)}
      //         color="inherit"
      //       />,
      //     ];
      //   },
      // },

      {
        field: 'ePTWs',
        headerName: 'Permits',
        width:300,
        renderCell: (params) => (
          <>
          <button className='mr-1'>
          <GridActionsCellItem
                icon={<AddCircleIcon />}
                label="add"
                className="textPrimary"
                onClick={()=> handleOpenDialog(params.row.id)}
                color="inherit"
              />
            </button>
            {params.row.ptws?.slice(0,3)?.map(item => (
              <div className='mr-1'>
                    <Button title={item?.id?.toString()} variant="contained" size="small" 
                    onClick={(e) => {
                      setSelectedPtwAtthached(item)
                      setIsOpenPtwModal(true)
                    }}
                    >
                      {`${item?.title?.length > 10 ? item?.title?.slice(0,10)+`...` :  item?.id}`}
                    </Button>
                </div>))}
                </>
        ),
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        // const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        // if (isInEditMode) {
        //   return [
        //     <GridActionsCellItem
        //       icon={<SaveIcon />}
        //       label="Save"
        //       sx={{
        //         color: 'primary.main',
        //       }}
        //       onClick={handleSaveClick(id)}
        //     />,
        //     <GridActionsCellItem
        //       icon={<CancelIcon />}
        //       label="Cancel"
        //       className="textPrimary"
        //       onClick={handleCancelClick(id)}
        //       color="inherit"
        //     />,
        //   ];
        // }

        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    ],
  );
  const handleOpenDialog = (id) => {
    setIsOpen(true);
    setTaskId(id)
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
    setTaskId(null)
  };
  const handleClosePtwModal = () => {
    setSelectedPtwAtthached(null)
    setIsOpenPtwModal(false)
  };


  useEffect(() => {
    dispatch(getTaskByAuthentication())
    dispatch(getEptwsByAuthentication())
  }, [dispatch])
  useEffect(() => {
    dispatch(resetTaskActions())
  }, [isCreateTaskError,
    isCreateTaskSuccess,
    isUpdateTaskError,
    isUpdateTaskSuccess,
    isDeleteTaskError,
    isDeleteTasksSuccess])

  return isCreateTaskLoading || isDeleteTaskLoading || isUpdateTaskLoading ? (
    <Loader />
  ) : (
    <Box
      sx={{
        height: 400,
        width: '90%',
      }}
    >
      <div style={{ height: 400, width: '100%', marginLeft: "2rem" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          // editMode="row"
          // rowModesModel={rowModesModel}
          // onRowModesModelChange={handleRowModesModelChange}
          // onRowEditStop={handleRowEditStop}
          // processRowUpdate={processRowUpdate}
          onRowClick={(params) => {
            dispatch(setTask(params.row))
          }}
          style={{ background: 'white' }}
        />
      </div>
      {isOpen && (
        <PopUpAddModal
          showModal={isOpen}
          handleClose={handleCloseDialog}
          modalTitle="Add Approved Permit"
          primaryButtonText="Add"

          handleCreateSave={handleCreateSave}
        >
            <div className="col-lg-12 col-sm-12 pt-3">
            <label className="font-weight-normal ">Select Permit</label><label className="font-danger m-0">*</label>
            <SelectEptws eptws={eptws.filter(item => item?.status === "Approved" || item?.status === "Extended" )} handelChange={handelSelectedPtw}/>
          </div>
        </PopUpAddModal>
      )}   
      <TaskDeatilsPopupModal userOptions={userOptions} />
      {isOpenPtwModal && <DetailsModal open={isOpenPtwModal} onClose={handleClosePtwModal} rowData={selectedPtwAtthached} />}
      <PopUpDeleteModal
          showModal={deleteConfirm}
          handleClose={()=> (setDeleteConfirm(false))}
          handleConfirm={handelDelete}
          modalTitle="Delete Task"
          modalBodyText="Are you sure you want to delete this Task?"
        />
    </Box>

  )
}

