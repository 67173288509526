import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Button, Modal } from 'react-bootstrap/';
import DrawIcon from '@mui/icons-material/Draw';
import { useDispatch } from 'react-redux';
import { setForms, setSections } from '../../redux/features/eptw/eptwSlice';


function Signature({index , idx , sections , value , required } ) {
    const [isShowSignModal, setIsShowSignModal] = React.useState(false);
    const [sign, setSign] = React.useState(null);
    const [signFile, setSignFile] = React.useState(null);
    const [signFilePhoto, setSignFilePhoto] = React.useState(null);
    const [signError, setSignError] = React.useState(false);
    const [theSignatureURL, setTheSignatureURL] = React.useState(value);
    const dispatch = useDispatch();


    const handleCloseSignModal = () => {
        setIsShowSignModal(false)
    }
    const handelSections = (sig) => {
                const items = sections?.map((item, i) => {
            if (i === index) {
                const f = item.fields.map((l, j) => {
                    if (idx === j) {
                        if (l?.type === "Signature") {
                            return { ...l, value: sig }
                        }
                    } else return l
                })
                return { ...item, fields: f }
            } else return item
        })
        dispatch(setSections((items)))
    }

    const handleCreateTrim = () => {
        sign.clear()
        handelSections(null)
        setTheSignatureURL(null)
    }



        const handleSave = () => {
        if (sign) {
            const signImg = sign.getTrimmedCanvas().toDataURL("image/png")
            if (!sign?.isEmpty()) {
                setTheSignatureURL(signImg)
                handelSections(signImg)
            } else {
                setTheSignatureURL(null)
                setSignError(true)
                setTimeout(() => {
                    setSignError(false)
                }, 3000);
            }
        }
    }

    console.log(sections);
  return (
    <>
        <label style={{ marginTop: '10px', textAlign: 'center', color: '#858585', cursor: 'pointer' }}  >
        Add/Edit Signature
        </label>
     <div style={{ cursor: 'pointer' }} onClick={() => setIsShowSignModal(!isShowSignModal)} className='sign-image'>
     {required?.isRequired===true &&<h6 style={{ marginTop: '10px', textAlign: 'center'}} className="font-danger fs-6 m-0">*Required</h6>}
        {theSignatureURL === null ? <input
        value={""}
        name="crop"
        id="crop_image"
        // onChange={cropImage}
        type="file"
        style={{ display: 'none' }}
        accept="image/*"
        /> :
        <div style={{ display: 'flex', alignItems: 'center',justifyContent:'center', alignContent: 'center', padding: '10px' }}><img src={theSignatureURL} /></div>}
        {/* setIsShowSignModal(!isShowSignModal) */}
        <div className="mx-3" />
    </div>
    {isShowSignModal && (
        <MyVerticallyCenteredModal
            showModal={isShowSignModal}
            handleClose={handleCloseSignModal}
            modalTitle="Signature"
            primaryButtonText="Save"
            handleCreateSave={handleSave}
            handleCreateTrim={handleCreateTrim}
            clearButtonText="Clear"
            setSignFile={setSignFile}
            setSignFilePhoto={setSignFilePhoto}
        >
            <>
                {signError && <h5 className='red pb-2'>Add your Signature</h5>}
                <div className='sigContainer' style={{ border: "1px solid" }}>
                    <SignatureCanvas penColor='black'
                        canvasProps={{ className: 'sigCanvas' }}
                        ref={data => setSign(data)}  />
                </div>
                {theSignatureURL && theSignatureURL !== null ? <img className='sign-image' src={theSignatureURL} alt="signature" />
                    : <h5 className='nosign-image'>Sign⬆️</h5>}
        </>
        </MyVerticallyCenteredModal>
    )}
    </>
  )
}
function MyVerticallyCenteredModal({
    showModal,
    handleClose,
    primaryButtonText,
    handleCreateSave,
    children,
    handleCreateTrim,
    clearButtonText,
    setTab,
    tab,
    setSignFile,
    setSignFilePhoto,
}) {
    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal} onHide={handleClose}
        >
            <Modal.Header style={{ minWidth: "100%" }} closeButton>
                <Modal.Title style={{ width: "100%" }} id="contained-modal-title-vcenter">
                    {/* {modalTitle} */}
                    <div className='signature-header'>
                        <div onClick={() => {
                            setTab("sign")
                            setSignFile(null)
                            setSignFilePhoto(null)
                        }}>
                            <DrawIcon />
                            <p>Draw</p>
                        </div>
                    </div>
                </Modal.Title>
                <button
                    type='button'
                    className='close'
                    data-dismiss='modal'
                    aria-label='Close'
                    onClick={handleClose}
                >
                    <span aria-hidden='true'>&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer style={{ minWidth: "100%", justifyContent: "space-between" }} >
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <div>
                    {<Button variant='primary' onClick={handleCreateTrim}>
                        {clearButtonText}
                    </Button>}
                    <span className='mr-2' />
                    <Button variant='primary' onClick={handleCreateSave}>
                        {primaryButtonText}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default Signature


