import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { smartTryCatch } from '../../../utils';
import TaskService from './taskService';

const initState = {
    task: [],
    singleTask:{},

    //modals
    taskDetailModal:false,
    searchValue : "",
    id: null,
    name: "",
    status: "",
    assigned_to: [],
    description: "",
    start_date: "",
    finish_date: "",
    actual_start_date: "",
    actual_finish_date: "",
    updatedTask: null,
    isgetTaskSuccess: false,
    isCreateTaskSuccess: false,
    isUpdateTaskSuccess: false,
    isDeleteTasksSuccess: false,

    isgetTaskLoading: false,
    isCreateTaskLoading: false,
    isUpdateTaskLoading: false,
    isDeleteTaskLoading: false,

    isgetTaskError: false,
    isCreateTaskError: false,
    isUpdateTaskError: false,
    isDeleteTaskError: false,

    isError: false,
    isSuccess: false,
    isLoading: false,
    isPtwAtachError: false,
    isPtwAtachSuccess: false,
}


export const getTaskById = createAsyncThunk('getTaskById', async (data, thunkAPI) => {
    const get = await smartTryCatch(TaskService.getTaskById, data, thunkAPI);
    return get;
});
export const getTaskByAuthentication = createAsyncThunk('getTaskByAuthentication', async (data, thunkAPI) => {
    const get = await smartTryCatch(TaskService.getTaskByAuthentication, data, thunkAPI);
    return get;
});
export const taskDelete = createAsyncThunk('taskDelete', async (data, thunkAPI) => {
    const response = await smartTryCatch(TaskService.taskDelete, data, thunkAPI);
    return response;
});
export const createTask = createAsyncThunk('createTask', async (data, thunkAPI) => {
    const response = await smartTryCatch(TaskService.createTask, data, thunkAPI);
    return response;
});
export const updateTask = createAsyncThunk('updateTask', async (data, thunkAPI) => {
    const response = await smartTryCatch(TaskService.updateTask, data, thunkAPI);
    return response;
});
export const addPtwTask = createAsyncThunk('addPtwTask', async (data, thunkAPI) => {
    const response = await smartTryCatch(TaskService.addPtwTask, data, thunkAPI);
    return response;
});

export const taskSlice = createSlice({
    name: 'task',
    initialState: initState,
    reducers: {


        resetTask: (state) => {
            state.id = null
            state.name = ""
            state.status = ""
            state.assigned_to = []
            state.description = ""
            state.start_date = ""
            state.finish_date = ""
            state.actual_start_date = ""
            state.actual_finish_date = ""
        },
        openTaskDetailModal:state =>{
            state.taskDetailModal=true
        },
        closeTaskDetailModal:state =>{
            state.taskDetailModal=false
        },
        setTask:(state,action)=>{
         state.singleTask = action.payload
        },
        setSearchValue:(state,action)=>{
         state.searchValue = action.payload
        },
        resetTaskActions: state => {
            state.isCreateTaskError = false;
            state.isUpdateTaskError = false;
            state.isDeleteTaskError = false;
            state.isCreateTaskSuccess = false;
            state.isUpdateTaskSuccess = false;
            state.isDeleteTasksSuccess = false;
            state.isGetTaskLoading = false;
            state.isCreateTaskLoading = false;
            state.isUpdateTaskLoading = false;
            state.isDeleteTaskLoading = false;
            state.isError = false;
            state.isLoading = false;
            state.isSuccess = false;
            state.isgetTaskSuccess= false;
            state.isgetTaskLoading= false;
            state.isgetTaskError= false;
            state.isPtwAtachError= false;
            state.isPtwAtachSuccess= false;
          },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getTaskById.pending, (state) => {
                state.isgetTaskSuccess= false;
                state.isgetTaskLoading= true;
                state.isgetTaskError= false;
            })
            .addCase(getTaskById.fulfilled, (state, action) => {
                state.isgetTaskSuccess= true;
                state.isgetTaskLoading= false;
                state.isgetTaskError= false;
                state.singleTask = action.payload
            })
            .addCase(getTaskById.rejected, (state) => {
                state.isgetTaskSuccess= false;
                state.isgetTaskLoading= false;
                state.isgetTaskError= true;
            })
            .addCase(taskDelete.pending, (state) => {
                state.isDeleteTaskError= false;
                state.isDeleteTaskLoading = true;
                state.isDeleteTasksSuccess = false;
            })
            .addCase(taskDelete.fulfilled, (state, action) => {
                state.isDeleteTaskError = false;
                state.isDeleteTaskLoading = false;
                state.isDeleteTasksSuccess = true;
                state.task = state.task.filter((i) => i.id !== action.payload)
            })
            .addCase(taskDelete.rejected, (state) => {
                state.isDeleteTaskError = true;
                state.isDeleteTaskLoading = false;
                state.isDeleteTasksSuccess = false;
            })
            .addCase(createTask.pending, (state) => {
                state.isCreateTaskError = false;
                state.isCreateTaskLoading = true;
                state.isCreateTaskSuccess = false;
            })
            .addCase(createTask.fulfilled, (state, action) => {
                state.isCreateTaskError = false;
                state.isCreateTaskLoading = false;
                state.isCreateTaskSuccess = true;
                state.task.push(action.payload);
            })
            .addCase(createTask.rejected, (state) => {
                state.isCreateTaskError = true;
                state.isCreateTaskLoading = false;
                state.isCreateTaskSuccess = false;
            })
            .addCase(updateTask.pending, (state) => {
                state.isUpdateTaskError = false;
                state.isUpdateTaskLoading = true;
                state.isUpdateTaskSuccess = false;
            })
            .addCase(updateTask.fulfilled, (state, action) => {
                state.isUpdateTaskError = false;
                state.isUpdateTaskLoading = false;
                state.isUpdateTaskSuccess = true;
                const taskIndex = state.task.findIndex(t => t.id === action.payload.id);
                if (taskIndex !== -1) {
                    state.task[taskIndex] = { ...state.task[taskIndex], ...action.payload };
                }

            })
            .addCase(updateTask.rejected, (state) => {
                state.isUpdateTaskError = true;
                state.isUpdateTaskLoading = false;
                state.isUpdateTaskSuccess = false;
            })
            .addCase(getTaskByAuthentication.pending, (state) => {
                state.isError = false;
                state.isLoading = true;
                state.isSuccess = false;
            })
            .addCase(getTaskByAuthentication.fulfilled, (state, action) => {
                state.isError = false;
                state.isLoading = false;
                state.isSuccess = true;
                state.task = action.payload
            })
            .addCase(getTaskByAuthentication.rejected, (state) => {
                state.isError = true;
                state.isLoading = false;
                state.isSuccess = false;
            })
            .addCase(addPtwTask.pending, (state) => {
                state.isPtwAtachError = false;
                state.isLoading = true;
                state.isPtwAtachSuccess = false;
            })
            .addCase(addPtwTask.fulfilled, (state, action) => {
                state.isPtwAtachError = false;
                state.isLoading = false;
                state.isPtwAtachSuccess = true;
                state.updatedTask = action.payload
            })
            .addCase(addPtwTask.rejected, (state) => {
                state.isPtwAtachError = true;
                state.isLoading = false;
                state.isPtwAtachSuccess = false;
            })


    }
});

export const {
openTaskDetailModal,
closeTaskDetailModal,
setTask,
resetTask,
resetTaskActions,
setSearchValue
}=taskSlice.actions;

export default taskSlice.reducer;