import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import './editCategory.scss';
import TeamsTab from '../../components/teamsTab/teamsTab';
import ProjectContractor from '../../components/createContractor/projectContractor';
import ApproverTab from './Approvers/Approvers';
import { getUserProfile } from '../../redux/features/user/userSlice';
import { useSelector } from 'react-redux';
import SafetyInductionPeriodSetting from '../../components/SafetyInductionPeriodSetting';
export default function EditCategory() {
  const [key, setKey] = useState(JSON.parse(localStorage.getItem('activeTab')) || 'teams-tab');
  const { user, isLoading, isError, isSuccess } = useSelector(state => state.user);
  useEffect(() => {
    localStorage.setItem('activeTab', JSON.stringify(key));
  }, [key]);

  return (
    <div className=' ml-2'>
      <div className='float-left'>
        <h3 className='px-2'>
          <b>Settings</b>
        </h3>
      </div>
      <Tabs
        // defaultActiveKey={key}
        id='uncontrolled-tab-example'
        className='nav nav-tabs custom_tabs'
        // onSelect={(k) => setKey(k)}
      >
        <Tab eventKey='teams-tab' title='Teams'>
         <TeamsTab/>
        </Tab>
        {/* {user?.role !== "Manager" &&
        <Tab eventKey='approver-tab' title='Approvers'>
         <ApproverTab />
        </Tab>
        } */}
        <Tab eventKey='contractor' title='Safety Induction'>
         <SafetyInductionPeriodSetting/>
        </Tab>

      </Tabs>
    </div>
 
  );
}
