import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import approveRejectService from "./approveRejectPtwService";
import { smartTryCatch } from '../../../utils';

const initState = {
    // approver
    approvesStatus: null,
    status: "",
    comment: "",
    errorMessage: "",

    // modal
    aproveRejectModalCreate: false,
    aproveRejectModalDelete: false,
    aproveRejectModalUpdate: false,

    // success
    isAproveRejectGetSuccess: false,
    isAproveRejectCreateSuccess: false,
    isAproveRejectDeleteSuccess: false,
    isAproveRejectUpdateSuccess: false,

    // loading
    isAproveRejectGetLoading: false,
    isAproveRejectDeleteLoading: false,
    isAproveRejectUpdateLoading: false,
    isAproveRejectCreateLoading: false,

    // error
    isAproveRejectGetError: false,
    isAproveRejectCreateError: false,
    isAproveRejectDeleteError: false,
    isAproveRejectUpdateError: false,
};



export const updateStatus = createAsyncThunk(
    'updateStatus',
    async (data, thunkAPI) => {
        const res = await smartTryCatch(approveRejectService.updateStatus, data, thunkAPI);
        return res;
    },
);

export const approveRejectSlice = createSlice({
    name: 'approveReject',
    initialState: initState,
    reducers: {
        changeStatus: (state, action) => {
            state.comment =  action.payload.status === 'Rejected' ? action.payload.comment : ''
            state.status = action.payload;
        },
        openApproverModalUpdate: state => {
            state.approverModalUpdate = true;
        },
        closeApproverModalUpdate: state => {
            state.approverModalUpdate = false;
        },

        reset: state => {
            state.isAproveRejectUpdateSuccess = false;
            state.isAproveRejectUpdateError = false;
        },
        resetApproveReject: state => {
            state.status = "Submitted"
            state.comment = ""
        }
    },
    extraReducers: builder => {
        builder
            //network related
            .addCase(updateStatus.pending, state => {
                state.isAproveRejectUpdateLoading = true;
                state.isAproveRejectUpdateSuccess = false;
                state.isAproveRejectUpdateError = false;
            })
            .addCase(updateStatus.fulfilled, (state, action) => {
                state.isAproveRejectUpdateLoading = false;
                state.isAproveRejectUpdateSuccess = true;
                state.isAproveRejectUpdateError = false;
                state.approvesStatus = action.payload;
            })
            .addCase(updateStatus.rejected, state => {
                state.isAproveRejectUpdateLoading = false;
                state.isAproveRejectUpdateSuccess = false;
                state.isAproveRejectUpdateError = true;
            });
    },
});

export const {
    setApproval,
    openApproverModalDelete,
    closeApproverModalDelete,
    openApproverModalUpdate,
    closeApproverModalUpdate,
    openApproverModalCreate,
    closeApproverModalCreate,
    reset,
    resetApproveReject,

} = approveRejectSlice.actions;

export default approveRejectSlice.reducer;
