import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { DataGrid } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import {
  getInductionSession,
  getInductionSessionByID,
  reset,
  setSessionId,
  setSessionModal,
  setSessionViewOnlyModal
} from '../../../redux/features/session/sessionSlice'
import classess from '../../../styles/asset.module.css'
import InductionDeleteModal from '../InductionModal/InductionDeleteModal'
import InductionModalBodyViewOnly from '../InductionModalBody/inductionModalViewOnlyBody'

const InductionManagementTable = () => {
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState()
  const [rows, setRows] = useState([])
  const [open, setOpen] = useState(false)
  const { user } = useSelector(state => state.user)
  const {
    sessions,
    sessionViewOnlyModal,
    inductionSearchText,
    isSessionDeleteSuccess,
    isSessionDeleteError,
    isSessionUpdateSuccess,
    isSessionUpdateError
  } = useSelector(state => state.session)
  const handleCopyClick = async params => {
    // Logic to copy text to clipboard goes here
    await navigator.clipboard.writeText(params.row.link)
    setSnackbarOpen(true)
  }
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getInductionSession())
  }, [])


  function displayToast () {
    // if (isAproveRejectUpdateSuccess) {
    //     toast.success('Status Updated successfully', {
    //         toastId: 'AproveRejectUpdateSuccess',
    //     });
    //     dispatch(reset());
    //     dispatch(updateEptws(approvesStatus));
    //     setIsRejectModalOpen(false);
    //     setIsStatusModalOpen(false);
    // }
    if (isSessionDeleteSuccess) {
      toast.success('Induction deleted successfully', {
        toastId: 'Successfully deleted'
      })
    }
    if (isSessionDeleteError) {
      toast.error('Induction delete failed', {
        toastId: 'Fail Delete'
      })
    }
    if (isSessionUpdateError) {
      toast.error('Update induction failed', {
        toastId: 'failed update'
      })
    }
    if (isSessionUpdateSuccess) {
      toast.success('Induction updated successfully', {
        toastId: 'update success'
      })
    }
    dispatch(reset())
  }

  useEffect(() => {
    setRows(
      sessions
        ?.filter(ses => ses?.title?.toLowerCase().includes(inductionSearchText))
        .map(session => ({
          ...session,
          id: session?.id,
          SessionName: session?.title,
          videoLink: session?.video?.link,
          createdAt: session?.created_at?.split('T')[0],
          quizName: session?.video?.quizes[0]?.title
        }))
    )
  }, [sessions, inductionSearchText])
  const columns = React.useMemo(
    () => [
      { field: 'id', headerName: 'ID', width: 130 },
      {
        field: 'SessionName',
        headerName: 'Session Name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        width: 200
      },

      // {
      //     field: 'videoLink', headerName: 'Video Link', width: 200,
      //     renderCell: params => (
      //         <a href={params?.row?.videoLink} target='_blank' rel='noopener noreferrer'>
      //             <Button variant='outlined' color='primary'>
      //                 Video LINK
      //             </Button>
      //         </a>
      //     )
      // },

      {
        field: 'quizName',
        headerName: 'Quiz name',
        width: 450
      },
      {
        field: 'createdAt',
        headerName: 'Created at',
        width: 350
      },
      {
        field: 'Actions',
        headerName: 'Actions',
        sortable: false,
        width: 400,
        renderCell: params => {
          return (
            <div className={classess.eptwButtons}>
              <Button
                variant='container'
                size='small'
                onClick={() => {
                  dispatch(getInductionSessionByID(params?.row?.id))
                  dispatch(setSessionId(params?.row?.id))
                  dispatch(setSessionViewOnlyModal(true))
                  // dispatch(setSessionModal(true))
                }}
              >
                View
              </Button>
              <Button
                variant='container'
                size='small'
                onClick={() => {
                  dispatch(getInductionSessionByID(params?.row?.id))
                  dispatch(setSessionId(params?.row?.id))
                  dispatch(setSessionModal(true))
                }}
              >
                Edit
              </Button>
              <Button
                variant='container'
                size='small'
                onClick={() => {
                  setOpen(true)
                  setSelectedRow(params?.row?.id)
                }}
              >
                Delete
              </Button>
            </div>
          )
        }
      }
    ],
    [user]
  )
  return (
    <>
      {displayToast()}
      <InductionDeleteModal
        open={open}
        onClose={() => setOpen(false)}
        rowData={selectedRow}
      />
      {/* <InductionModalBodyViewOnly open={sessionViewOnlyModal} /> */}
      <div style={{ height: 400, width: '100%' }}>
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={3000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'bottom' }}
        >
          <Alert
            severity='error'
            style={{
              color: '#f63854'
              // color: 'white', // Text color
            }}
            onClose={handleCloseSnackbar}
          >
            Text copied to clipboard!
          </Alert>
        </Snackbar>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 }
            }
          }}
          pageSizeOptions={[5, 10, 20]}
        />
      </div>
    </>
  )
}

export default InductionManagementTable
