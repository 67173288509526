import {assert , http}from  '../../../utils'
import { BASE_URL } from '../../../utils/axios'


const getComapnyProfile =async()=>{
    const res = await http.get(`${BASE_URL}/company/`)
return assert (res, res.data  , "Retrieval of Company  Profile failed" , res)
}

const updateCompanyProfile = async (companyProfileData)=>{
    const res = await http.put(`${BASE_URL}/company/`,companyProfileData)
    return assert (res, res.data  , "Retrieval of Company  Profile failed" , res)
}

const companyService= {
    getComapnyProfile,
    updateCompanyProfile,
}


export default companyService