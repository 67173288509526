import React, { useEffect, useMemo, useState } from 'react'
import classes from '../../styles/asset.module.css'
import { DataGrid } from '@mui/x-data-grid';
import Addteams from '../../components/teams/addteams';
import { useDispatch, useSelector } from 'react-redux';
import { activateTeam, deactivateTeam, deleteTeam, getTeamsByAuthentication, reset, setTeam } from '../../redux/features/teams/teamSlice';
import { Drawer } from "@mui/material";
import Loader from '../../components/loader/loader';
import { getAllUsers } from '../../redux/features/user/userSlice';
import profilePicture from '../../assets/img/profile.png';
import PopUpActivateModel from '../../components/teams/popUpModals/popupActivateModal';
import PopUpDeleteModel from '../../components/teams/popUpModals/popUpDeleteModal';
import ManegerPopUp from '../../components/teams/managerEditform/managerPopup';
import MemberPopUp from '../../components/teams/memberEditform/memberPopup';
import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';
export default function TeamsList() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTeamsByAuthentication());
    async function fetchDepartmentPositions() {
      await dispatch(getDepartmentsByAuthentication());
    }
    fetchDepartmentPositions();
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAllUsers())
  }, [dispatch])

  const {teamSearchTerm, teams, teamModalCreate,
    isTeamCreateSuccess,
    isTeamGetSuccess,
    isTeamDeleteSuccess,
    isTeamActivateSuccess,
    isTeamDeactivateSuccess,
    isTeamUpdateSuccess,
    // loading
    isTeamCreateLoading,
    isTeamGetLoading,
    isTeamDeleteLoading,
    isTeamUpdateLoading,
    isTeamActivateLoading,
    isTeamDeactivateLoading,

    // error
    isTeamGetError,
    isTeamCreateError,
    isTeamDeleteError,
    isTeamUpdateError,
    isTeamActivateError,
    isTeamDeactivateError,
    team,
    

  } = useSelector((state) => state.team)
  const { departments } = useSelector(state => state.department);
  const { user } = useSelector((state) => state.user);
  //todo the following function is to be used if the user has only initials and no image
  function stringAvatar(name1,name2, image) {
    if (image && !image.includes('no_profile')) {
      return {
        src: image
      };
    } else {
  //     const initials = name.trim().split(/\s+/)
  //       .map(part => part.charAt(0).toUpperCase())
  //       .join('');
const initials = name1.charAt(0).toUpperCase()+name2.charAt(0).toUpperCase()||"--"
  return {
        children: initials
      };
    }
  }
  const columns = useMemo(() => [
    {
      field: 'avatar', headerName: 'Picture', width: 150, renderCell: (params) => {
        return <Avatar {...stringAvatar(params.row.first_name,params.row.last_name||"",params.row.avatar)} alt="Avatar" />;
      }
    },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 150 },
    { field: 'position', headerName: 'Position', width: 150 },
    { field: 'department', headerName: 'Department', width: 150 },
    { field: 'role', headerName: 'Account Type', width: 200 },
    {
      field: 'is_active',
      headerName: 'Active',
      width: 200,
      renderCell: (params) => (
        <span
          className={`px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm ${params.value === 'True' ? 'bg-green-100 text-green-800' : ''} ${params.value === 'False' ? 'bg-red-100 text-red-800' : ''}`}
        >
          {params.value === 'True' ? 'Active' : 'Inactive'}
        </span>
      ),
    },

  ])

  const rows = teams.filter(team=>team.first_name.toLowerCase().startsWith(teamSearchTerm)).map(item => ({
    avatar: item.image,
    id: item.id,
    email: item.email,
    first_name: item.first_name,
    last_name: item.last_name,
    is_active: item.is_active,
    role: item.role,
    position: item?.manager?.position?.name,
    positionId: item?.manager?.position?.id,
    department: item?.department?.name,
    departmentId: item?.department?.id,
    hourly_rate: item?.manager?.hourly_rate ? item?.manager?.hourly_rate : null
  }));
  const [showPopupActivate, setShowPopupActivate] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [showPopupEdit, setShowPopupEdit] = useState(false); // Controls modal visibility
  const [selectedTeam, setSelectedTeam] = useState(null); // Holds the data of the selected team
  useEffect(() => {
    dispatch(reset());
  }, [
    // success
    isTeamCreateSuccess,

    isTeamDeleteSuccess,
    isTeamActivateSuccess,
    isTeamDeactivateSuccess,
    isTeamUpdateSuccess,

    // loading
    isTeamCreateLoading,
    isTeamGetLoading,
    isTeamDeleteLoading,
    isTeamUpdateLoading,
    isTeamActivateLoading,
    isTeamDeactivateLoading,

    // error
    isTeamGetError,
    isTeamCreateError,
    isTeamDeleteError,
    isTeamUpdateError,
    isTeamActivateError,
    isTeamDeactivateError,
  ]);
  function displayToast() {
    if (isTeamGetError) {
      toast.error('Something went wrong Getting teams info', {
        toastId: 'FetchingteamError',
      });
    } else if (isTeamCreateError) {
      toast.error('Something went wrong Creating team', {
        toastId: 'TeamCreateError',
      });
    } else if (isTeamDeleteError) {
      toast.error('Something went wrong with deleting the team', {
        toastId: 'teamDeleteError',
      });
    } else if (isTeamUpdateError) {
      toast.error('Something went wrong with updating the team', {
        toastId: 'teamupdateError',
      });
    } else if (isTeamActivateError) {
      toast.error('Something went wrong with activate the team', {
        toastId: 'teamactivateError',
      });
    } else if (isTeamDeactivateError) {
      toast.error('Something went wrong with deactivate the team', {
        toastId: 'teamdeactivateError',
      });
    } else if (isTeamCreateSuccess) {
      toast.success('team created successfully', {
        toastId: 'team Created Success',
      });
    } else if (isTeamDeleteSuccess) {
      toast.success('team deleted successfully', {
        toastId: 'team Deleted Success',
      });
    } else if (isTeamActivateSuccess) {
      toast.success('team activated successfully', {
        toastId: 'team Activated Success',
      });
    } else if (isTeamDeactivateSuccess) {
      toast.success('team deactivated successfully', {
        toastId: 'team Deactivated Success',
      });
    } else if (isTeamUpdateSuccess) {
      toast.success('team updated successfully', {
        toastId: 'team Updated Success',
      });
    }
  }

  const handleCloseEdit = () => {
    setShowPopupEdit(false);
    setSelectedTeam(null);
  };
  const handleCloseActivate = () => {
    setShowPopupActivate(false);
  };
  const handleCloseDelte = () => {
    setShowPopupDelete(false);
  };
  const handleDeleteTeam = () => {
    dispatch(deleteTeam(selectedTeam?.id));
    setShowPopupDelete(false);
  };

  const handleAcetivation = () => {
    if (selectedTeam.is_active === 'True') {
      dispatch(deactivateTeam(selectedTeam.id));
    } else {
      dispatch(activateTeam(selectedTeam.id));
    }
    setShowPopupActivate(false);
  };


  return isTeamCreateLoading || isTeamDeleteLoading ||
    isTeamUpdateLoading || isTeamActivateLoading ||
    isTeamDeactivateLoading ? (
    <Loader />
  ) : (

    <>
      {displayToast()}
      <div className={classes.assets}>
        {/* adding teams  */}
        <Addteams />
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            onRowClick={(params) => {
              const selectedRowData = params.row;
              setSelectedTeam(selectedRowData);
              setShowPopupEdit(true); // Open the modal
              dispatch(setTeam(selectedRowData))
            }}
            style={{ background: 'white' }}
          />

        </div>
        <div>
          <PopUpActivateModel
            showModal={showPopupActivate}
            handleClose={handleCloseActivate}
            data={selectedTeam}
            handleSave={handleAcetivation}
            modelType={selectedTeam?.is_active}
          />
        </div>
        <div>
          <PopUpDeleteModel
            showModal={showPopupDelete}
            handleClose={handleCloseDelte}
            data={team}
            handleSave={handleDeleteTeam}
          />
        </div>
        <div>
          <Drawer anchor="right" open={showPopupEdit} onClose={handleCloseEdit}>
            {selectedTeam && (
              <>
                {/* Render content using selectedTeam data */}
                <div className="modal-dialog modal-lg" id="modal-dialog">
                  <div className="modal-content" id="modal-content">
                    <div className="modal-body p-0" id="modal-body">
                      <form
                        action="{{action}}"
                        className="h-1000"
                        style={{ width: '600px' }}
                        method="POST"
                        onSubmit="return check_validation()"
                      >
                        <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header">
                          <div className="col-12 row px-2">
                            <div className="row ml-auto">
                              {selectedTeam?.role !== 'Administrator' && (
                                <>
                                  {selectedTeam?.is_active === 'False' ? (
                                    <button
                                      onClick={() => {
                                        setShowPopupActivate(true);
                                        setShowPopupEdit(false);
                                      }}
                                      className="btn activate-btn"
                                      data-toggle="modal"
                                      data-target="#modal-status"
                                      title="Activate"
                                      type="button"
                                    >
                                      {/*  */}               Activate
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => {
                                        setShowPopupActivate(true);
                                        setShowPopupEdit(false);
                                      }}
                                      className="btn btn deactivate-btn"
                                      data-toggle="modal"
                                      data-target="#modal-status"
                                      title="Activate"
                                      type="button"
                                    >
                                      Deactivate
                                    </button>
                                  )}
                                  <button
                                    // href="#"
                                    type="button"
                                    onClick={() => {
                                      setShowPopupDelete(true);
                                      setShowPopupEdit(false);
                                    }}
                                    title="Delete Team Member"
                                    className="btn secondary-btn ml-2"
                                    data-toggle="modal"
                                    data-target="#modal-delete"
                                  >
                                    <i className="fa fa-trash" aria-hidden="true">
                                      {' '}
                                    </i>
                                  </button>
                                </>
                              )}
                              <button
                                type="button"
                                className="btn tertiary-btn ml-2"
                                data-dismiss="modal"
                                onClick={handleCloseEdit}
                              >
                                <div className="fas fa-times" />
                              </button>
                            </div>
                          </div>
                          <div className="col-lg-12 col-sm-12 row"> </div>

                          <div className="col-lg-12 col-sm-12 row">

                            <div className="col-12 text-center flex justify-content-center">
                              {/* {user?.image ? (
                                          <img
                                            className="prof_img img-circle"
                                            height="100"
                                            alt="profile"
                                            width="100"
                                            src={user?.image}
                                          />
                                        ) : ( */}
                              <img
                                className="prof_img img-circle"
                                height="100"
                                width="100"
                                alt="profile"
                                src={profilePicture}
                              />
                              {/* )} */}
                            </div>
                            <div className="col-12 text-center">
                              <div className="large">
                                {selectedTeam?.first_name || selectedTeam?.last_name ? (
                                  <span>
                                    {selectedTeam?.first_name} {selectedTeam?.last_name}
                                  </span>
                                ) : (selectedTeam?.role === 'Administrator' && selectedTeam?.first_name) ||
                                  selectedTeam?.last_name ? (
                                  <span>
                                    {selectedTeam?.first_name} {selectedTeam?.last_name}
                                  </span>
                                ) : null}
                              </div>
                              <div className="col-12 text-center">
                                <div className="medium-title">
                                  <span>{selectedTeam?.role === 'Administrator' ? 'Admin' : selectedTeam?.role}</span>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-start mt-2 col-lg-12 col-sm-12 ">
                              <ul
                                className="nav nav-pills justify-content-center"
                                id="custom-tabs-two-tab"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    id="custom-tabs-two-user-tab"
                                    data-toggle="tab"
                                    href="#user-tab"
                                    role="tab"
                                    aria-controls="custom-tabs-two-user"
                                    aria-selected="false"
                                  >
                                    User Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {selectedTeam?.role === 'Administrator' ? (
                          <>
                            <div
                              className="card-body mx-auto row col-lg-12 pb-0"
                              style={{ paddingBottom: '0rem !important' }}
                            >
                              <div className="col col-lg-12 col-sm-12 mr-2">
                                <div className="row mb-0">
                                  <div className=" col-lg-12 col-sm-12">
                                    <div className="row mb-0">
                                      <div className="row mb-0">
                                        <div className=" col-lg-6 col-sm-12">
                                          {' '}
                                          <span className="font-weight-normal">
                                            Email <span className="font-danger m-0">*</span>
                                          </span>
                                          <input
                                            name="email"
                                            type="email"
                                            className="form-control mb-2"
                                            required
                                            placeholder="Email"
                                            id="username"
                                            onChange={e => setSelectedTeam({ ...selectedTeam, email: e.target.value })}

                                            value={selectedTeam?.email}
                                            disabled
                                          />
                                        </div>
                                        <div className=" col-lg-6 col-sm-12">
                                          <span className="font-weight-normal">
                                            Account Type <span className="font-danger m-0">*</span>
                                          </span>

                                          <select
                                            required
                                            className="mb-2"
                                            name="role"
                                            id="role"
                                            value={selectedTeam?.role}

                                          >
                                            <option selected>{selectedTeam?.role}</option>
                                          </select>

                                          <div className="col-lg-12 col-sm-12 m-0">
                                            <span className="font-danger" id="role_error" />
                                          </div>
                                        </div>
                                        <div className=" col-lg-6 col-sm-12">
                                          {' '}
                                          <span className="font-weight-normal">
                                            First Name <span className="font-danger m-0">*</span>
                                          </span>
                                          <input
                                            name="firstname"
                                            type="text"
                                            className="form-control mb-2"
                                            required
                                            placeholder="firstname"
                                            id="username"
                                            onChange={e => setSelectedTeam({ ...selectedTeam, first_name: e.target.value })
                                            }
                                            value={selectedTeam?.first_name}
                                            disabled
                                          />
                                        </div>
                                        <div className=" col-lg-6 col-sm-12">
                                          {' '}
                                          <span className="font-weight-normal">
                                            Last Name <span className="font-danger m-0">*</span>
                                          </span>
                                          <input
                                            name="lastname"
                                            type="text"
                                            className="form-control mb-2"
                                            required
                                            placeholder="last Name"
                                            id="username"
                                            onChange={e => setSelectedTeam({ ...selectedTeam, last_name_name: e.target.value })
                                            }
                                            value={selectedTeam?.last_name}
                                            disabled
                                          />
                                        </div>
                                        <div className="col-lg-6 col-sm-12">
                                          <span className="font-weight-normal">
                                            Department <span className="font-danger m-0">*</span>
                                          </span>
                                          <select
                                            required
                                            className="mb-2"
                                            name="department"
                                            id="department"
                                            defaultValue={selectedTeam.department || ""}
                                            onChange={e => setSelectedTeam({ ...selectedTeam, department: e.target.value })}
                                            disabled
                                          >

                                            <option selected>{selectedTeam?.department}</option>
                                            {departments?.map(t =>
                                              team?.departmentId === t.id ? (
                                                <option value={t.id} selected>
                                                  {t.name}
                                                </option>
                                              ) : (
                                                <option value={t.id}>{t?.name}</option>
                                              ),
                                            )}
                                          </select>
                                        </div>
                                        {/* <div className=" col-lg-6 col-sm-12">
                                                <span className="font-weight-normal">
                                                  Position <span className="font-danger m-0">*</span>
                                                </span>

                                                <select
                                                  required
                                                  className="mb-2"
                                                  name="role"
                                                  id="role"
                                                  onChange="remove_error_msg('role'); show_line_manager(this)"
                                                  disabled
                                                >
                                                  <option selected>{selectedTeam?.position}</option>
                                                </select>

                                                <div className="col-lg-12 col-sm-12 m-0">
                                                  <span className="font-danger" id="role_error" />
                                                </div>
                                              </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className=" row  m-0 p-2 page-profile-footer">
                              <button className="btn primary-btn" type="submit" onClick="" disabled>
                                Save
                              </button>
                            </div>
                          </>
                        ) : selectedTeam?.role === 'Manager' ? (
                          <ManegerPopUp handleClosePopup={() => setShowPopupEdit(false)} />
                        ) : selectedTeam?.role === 'Member' ? (
                          <MemberPopUp selectedTeam={selectedTeam} handleClosePopup={() => setShowPopupEdit(false)} />
                        ) : null}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Drawer>

        </div>
      </div>
    </>
  )
}



