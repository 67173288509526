import React from 'react'
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card'

const StatCard = (props) => {

    return (

        <Card sx={{ maxWidth: 345,mb:5 }}>

            <CardActionArea>

                {/* <CardMedia

                    component="img"

                    height="50"

                    image="/approvedEPTW.jpg"

                    alt="place holder image"

                /> */}

                <CardContent>

                    <Typography gutterBottom variant="h5" component="div">

                        {props.count}

                    </Typography>

                    <Typography variant="body2" color="text.secondary">

                        {props.type}

                    </Typography>

                </CardContent>

            </CardActionArea>

        </Card>

    )

}

 

export default StatCard