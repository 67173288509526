import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Button } from 'react-bootstrap';
import { faSmile,faSadTear,faGrinBeamSweat } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default function CustomColumnModal({openDialog,handleCloseDialog,handleIconSelection,modalTitle,setModalTitle}) {
  const [titleInput, setTitleInput] = useState(''); // Add this state for the title input

  const handleTitleChange = (event) => {
    setTitleInput(event.target.value);
  };

  const handleTitleConfirm = () => {
    if (titleInput.trim() !== '') {
      setModalTitle(titleInput);
      setTitleInput('');
    }
  };
  return (
    <div>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{modalTitle}</DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'space-around',cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faSmile} onClick={() => handleIconSelection(faSmile)} />
            <FontAwesomeIcon icon={faSadTear} onClick={() => handleIconSelection(faSadTear)} />
            <FontAwesomeIcon icon={faGrinBeamSweat} onClick={() => handleIconSelection(faGrinBeamSweat)} />
          </div>
          <br />
          <br />
          <div>
            <input
              type="text"
              value={titleInput}
              onChange={handleTitleChange}
              placeholder="Enter custom title"
            />
            <Button onClick={handleTitleConfirm} style={{marginLeft:'5px'}}>Confirm Title</Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
