import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getEptwsByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/ptw/`);

  return assert(res, res.data, 'Failed to get ePTW list', res);
};


const getEptwById = async (EptwId) => {
  const res = await http.get(`${BASE_URL}/ptw/${EptwId}/`);

  return assert(res, res.data, 'Failed to get ePTW by ID', res);
};

const createEptw = async (EptwData) => {
  const res = await http.post(`${BASE_URL}/ptw/`, EptwData);

  return assert(res, res.data, 'Creation of Eptw failed', res);
};

const deleteEptw = async (EptwId) => {
  const res = await http.delete(`${BASE_URL}/ptw/${EptwId}/`);
  return assert(res, EptwId, 'Deletion of Eptw failed', res);
};

const updateEptw = async (data) => {
  const res = await http.put(
    `${BASE_URL}/ptw/${data.id}/`,
    data.formData,
  );
  return assert(res, res.data, 'Update of eptw failed', res);
};
const assignApprover = async (data) => {
  const res = await http.post(
    `${BASE_URL}/ptw/${data.pTWId}/assign_approver/`,
    data.formdata,
  );
  return assert(res, res.data, 'Update of eptw failed', res);
};
const unassignedApprover = async (id) => {
  const res = await http.get(
    `${BASE_URL}/ptw/${id}/unassigned_approvers/`,
  );
  return assert(res, res.data, 'Update of eptw failed', res);
};
const getFillEptw = async (route) => {
  const res = await http.get(
    `${BASE_URL}/ptw/fill/${route}/`
  );
  return assert(res, res.data, 'Update of Eptw failed', res);
};

const createFillEptw = async (payload) => {
  // console.log("issue",EptwData)
  const res = await http.patch(`${BASE_URL}/ptw/fill/${payload.route}/`
  , payload.data
  );

  return assert(res, res.data, 'Creation of Eptw failed', res);
};
const updateFillEptw = async (payload) => {
  // console.log("issue",EptwData)
  const res = await http.patch(`${BASE_URL}/ptw/${payload.id}/internal_user_fill_ptw/`
  , payload
  );

  return assert(res, res.data, 'Creation of Eptw failed', res);
};



const eptwService = {
  getEptwsByAuthentication,
  getEptwById,
  createEptw,
  deleteEptw,
  updateEptw,
  getFillEptw,
  createFillEptw,
  updateFillEptw,
  assignApprover,
  unassignedApprover
};

export default eptwService;
