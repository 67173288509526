/* eslint-disable no-shadow */
/* eslint-disable react/jsx-curly-brace-presence */
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  getDepartmentsByAuthentication,
  reset as resetDepartment,
  deleteDepartment,
  updateDepartment,
  openDepartmentModalDelete,
  closeDepartmentModalDelete,
  openDepartmentModalUpdate,
  closeDepartmentModalUpdate,
  closeDepartmentModalCreate,
  openDepartmentModalCreate,
  setDepartment,
  createDepartment,
} from '../../redux/features/department/departmentSlice';
import PopUpDeleteModal from '../popUpModals/popUpDeleteModal';
import PopUpEditModal from '../popUpModals/popUpEditModal';
import PopUpAddModal from '../popUpModals/popUpAddModal';
import {
  getManagerPositionByAuthentication,
  openManagerPositionModalCreate,
  createManagerPosition,
  closeManagerPositionModalCreate,
  closeManagerPositionModalDelete,
  closeManagerPositionModalUpdate,
  openManagerPositionModalDelete,
  deleteManagerPosition,
  openManagerPositionModalUpdate,
  setManager,
  updateManagerPosition,
  reset as resetManager,
} from '../../redux/features/managerPosition/managerPositionSlice';
import {
  getmemberPositionsByAuthentication,
  openmemberPositionModalCreate,
  creatememberPosition,
  closememberPositionModalCreate,
  closememberPositionModalDelete,
  closememberPositionModalUpdate,
  openmemberPositionModalDelete,
  deletememberPosition,
  openmemberPositionModalUpdate,
  setmember,
  updatememberPosition,
  reset as resetmember,
} from '../../redux/features/memberPosition/memberPositionSlice';
import { setTeamType } from '../../redux/features/editCategory/editCategorySlice';
import Loader from '../loader/loader';

export default function TeamsTab () {
  const {
    departments,
    departmentModalDelete,
    departmentModalUpdate,
    departmentModalCreate,
    department,
    isDepartmentCreateError,
    isDepartmentCreateLoading,
    isDepartmentCreateSuccess,
    isDepartmentDeleteError,
    isDepartmentDeleteLoading,
    isDepartmentDeleteSuccess,
    isDepartmentUpdateError,
    isDepartmentUpdateLoading,
    isDepartmentUpdateSuccess,
  } = useSelector(state => state.department);
  const {
    members,
    memberPositionModalDelete,
    memberPositionModalUpdate,
    memberPositionModalCreate,
    member,
    ismemberCreateLoading,
    ismemberCreateError,
    ismemberCreateSuccess,
    ismemberDeleteLoading,
    ismemberDeleteError,
    ismemberDeleteSuccess,
    ismemberUpdateLoading,
    ismemberUpdateError,
    ismemberUpdateSuccess,
  } = useSelector(state => state.memberPosition);
  const {
    managers,
    managerPositionModalDelete,
    managerPositionModalUpdate,
    managerPositionModalCreate,
    manager,
    isManagerCreateLoading,
    isManagerCreateError,
    isManagerCreateSuccess,
    isManagerDeleteLoading,
    isManagerDeleteError,
    isManagerDeleteSuccess,
    isManagerUpdateLoading,
    isManagerUpdateError,
    isManagerUpdateSuccess,
  } = useSelector(state => state.managerPosition);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetmember());
  }, [
    ismemberCreateLoading,
    ismemberCreateError,
    ismemberCreateSuccess,
    ismemberDeleteLoading,
    ismemberDeleteError,
    ismemberDeleteSuccess,
    ismemberUpdateLoading,
    ismemberUpdateError,
    ismemberUpdateSuccess,
    // dispatch
  ]);
  useEffect(() => {
    dispatch(resetDepartment());
  }, [
    isDepartmentCreateError,
    isDepartmentCreateLoading,
    isDepartmentCreateSuccess,
    isDepartmentDeleteError,
    isDepartmentDeleteLoading,
    isDepartmentDeleteSuccess,
    isDepartmentUpdateError,
    isDepartmentUpdateLoading,
    isDepartmentUpdateSuccess,
  ]);
  useEffect(() => {
    dispatch(resetManager());
  }, [
    isManagerCreateLoading,
    isManagerCreateError,
    isManagerCreateSuccess,
    isManagerDeleteLoading,
    isManagerDeleteError,
    isManagerDeleteSuccess,
    isManagerUpdateLoading,
    isManagerUpdateError,
    isManagerUpdateSuccess,
  ]);
  const { teamType } = useSelector(state => state.editCategory);
  const [departmentData, setDepartmentData] = useState(null);
  const [memberData, setmemberData] = useState(null);
  const [managerData, setManagerData] = useState(null);
  const handleConfirm = (id, type) => {
    if (type === 'department') {
      dispatch(openDepartmentModalDelete());
      dispatch(setDepartment(id));
    }
    if (type === 'member') {
      dispatch(openmemberPositionModalDelete());
      dispatch(setmember(id));
    } else if (type === 'manager') {
      dispatch(openManagerPositionModalDelete());
      dispatch(setManager(id));
    }
  };
  const handleDelete = () => {
    if (teamType === 'department') {
      dispatch(deleteDepartment(department));
      dispatch(closeDepartmentModalDelete());
    } else if (teamType === 'member') {
      dispatch(deletememberPosition(member));
      dispatch(closememberPositionModalDelete());
    } else if (teamType === 'manager') {
      dispatch(deleteManagerPosition(manager));
      dispatch(closeManagerPositionModalDelete());
    }
  };
  const handleEdit = (data, type) => {
    if (type === 'department') {
      dispatch(openDepartmentModalUpdate());
      dispatch(setDepartment(data.id));
      setDepartmentData(data);
    }
    if (type === 'member') {
      dispatch(openmemberPositionModalUpdate());
      dispatch(setmember(data.id));
      setmemberData(data);
    }
    if (type === 'manager') {
      dispatch(openManagerPositionModalUpdate());
      dispatch(setManager(data.id));
      setManagerData(data);
    }
  };

  const handleEditSave = () => {
    if (teamType === 'department') {
      dispatch(updateDepartment({ id: department, name: departmentData.name }));
      dispatch(closeDepartmentModalUpdate());
    }
    if (teamType === 'member') {
      dispatch(
        updatememberPosition({
          id: member,
          name: memberData.name,
        }),
      );
      dispatch(closememberPositionModalUpdate());
    }
    if (teamType === 'manager') {
      dispatch(updateManagerPosition({ id: manager, name: managerData.name }));
      dispatch(closeManagerPositionModalUpdate());
    }
  };

  const handleCreate = type => {
    if (type === 'department') {
      dispatch(openDepartmentModalCreate());
    } else if (type === 'member') {
      dispatch(openmemberPositionModalCreate());
    } else if (type === 'manager') {
      dispatch(openManagerPositionModalCreate());
    }
  };

  const handleCreateSave = () => {
    if (teamType === 'department') {
      dispatch(createDepartment({ name: departmentData.name }));
      dispatch(closeDepartmentModalCreate());
      dispatch(resetDepartment());
    } else if (teamType === 'member') {
      dispatch(creatememberPosition({ name: memberData.name }));
      dispatch(closememberPositionModalCreate());
      dispatch(resetmember());
    } else if (teamType === 'manager') {
      dispatch(createManagerPosition({ name: managerData.name }));
      dispatch(closeManagerPositionModalCreate());
      dispatch(resetManager());
    }
  };

  useEffect(() => {
    dispatch(resetDepartment());
    dispatch(resetmember());
    dispatch(resetManager());
    dispatch(getDepartmentsByAuthentication());
    dispatch(getmemberPositionsByAuthentication());
    dispatch(getManagerPositionByAuthentication());
  }, [dispatch]);

  function displayToast () {
    if (isDepartmentCreateError) {
      toast.error('Something went wrong with creating the department', {
        toastId: 'departmentCreateError',
      });
    } else if (isDepartmentDeleteError) {
      toast.error('Something went wrong with deleting the department', {
        toastId: 'departmentDeleteError',
      });
    } else if (isDepartmentUpdateError) {
      toast.error('Something went wrong with updating the department', {
        toastId: 'departmentUpdateError',
      });
    } else if (ismemberCreateError) {
      toast.error('Something went wrong with creating the member', {
        toastId: 'memberCreateError',
      });
    } else if (ismemberDeleteError) {
      toast.error('Something went wrong with deleting the member', {
        toastId: 'memberDeleteError',
      });
    } else if (ismemberUpdateError) {
      toast.error('Something went wrong with updating the member', {
        toastId: 'memberUpdateError',
      });
    } else if (isManagerCreateError) {
      toast.error('Something went wrong with creating the manager', {
        toastId: 'managerCreateError',
      });
    } else if (isManagerDeleteError) {
      toast.error('Something went wrong with deleting the manager', {
        toastId: 'managerDeleteError',
      });
    } else if (isManagerUpdateError) {
      toast.error('Something went wrong with updating the manager', {
        toastId: 'managerUpdateError',
      });
    } else if (isDepartmentCreateSuccess) {
      toast.success('Department created successfully', {
        toastId: 'departmentCreateSuccess',
      });
    } else if (isDepartmentDeleteSuccess) {
      toast.success('Department deleted successfully', {
        toastId: 'departmentDeleteSuccess',
      });
    } else if (isDepartmentUpdateSuccess) {
      toast.success('Department updated successfully', {
        toastId: 'departmentUpdateSuccess',
      });
    } else if (ismemberCreateSuccess) {
      toast.success('Member created successfully', {
        toastId: 'memberCreateSuccess',
      });
    } else if (ismemberDeleteSuccess) {
      toast.success('Member deleted successfully', {
        toastId: 'memberDeleteSuccess',
      });
    } else if (ismemberUpdateSuccess) {
      toast.success('Member updated successfully', {
        toastId: 'memberUpdateSuccess',
      });
    } else if (isManagerCreateSuccess) {
      toast.success('Manager created successfully', {
        toastId: 'managerCreateSuccess',
      });
    } else if (isManagerDeleteSuccess) {
      toast.success('Manager deleted successfully', {
        toastId: 'managerDeleteSuccess',
      });
    } else if (isManagerUpdateSuccess) {
      toast.success('Manager updated successfully', {
        toastId: 'managerUpdateSuccess',
      });
    }
  }

  return isDepartmentCreateLoading ||
    isDepartmentDeleteLoading ||
    isDepartmentUpdateLoading ||
    ismemberCreateLoading ||
    ismemberDeleteLoading ||
    ismemberUpdateLoading ||
    isManagerCreateLoading ||
    isManagerDeleteLoading ||
    isManagerUpdateLoading ? (
    <Loader/>
  ) : (
    <>
      {displayToast()}
      <h3 className="card-title py-3 px-3 col-lg-12">Teams Fieldset</h3>
      <div className="card-body p-0 m-0">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>Department</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    type="button"
                    className="mr-2 cursor_pointer"
                    title="Add Department"
                    onClick={() => {
                      dispatch(setTeamType('department'));
                      handleCreate('department');
                    }}
                  >
                    Add Department
                  </button>
                </div>
              </div>
              {departmentModalUpdate && (
                <PopUpEditModal
                  handleClose={() => dispatch(closeDepartmentModalUpdate())}
                  showModal={departmentModalUpdate}
                  handleSave={handleEditSave}
                  modalTitle="Edit Department"
                  primaryButtonText="Save Changes"
                  modalBodyTextInput={departmentData.name}
                  value={departmentData && departmentData.name}
                  key={departmentData?.id}
                  onChange={e => {
                    setDepartmentData({
                      ...departmentData,
                      name: e.target.value,
                    });
                  }}
                />
              )}
              {managerPositionModalUpdate && (
                <PopUpEditModal
                  handleClose={() => dispatch(closeManagerPositionModalUpdate())}
                  showModal={managerPositionModalUpdate}
                  handleSave={handleEditSave}
                  key={managerData?.id}
                  modalTitle="Edit Manager"
                  primaryButtonText="Save Changes"
                  modalBodyTextInput={managerData.name}
                  value={managerData && managerData.name}
                  onChange={e => {
                    setManagerData({
                      ...managerData,
                      name: e.target.value,
                    });
                  }}
                />
              )}
              {memberPositionModalUpdate && (
                <PopUpEditModal
                  handleClose={() => dispatch(closememberPositionModalUpdate())}
                  showModal={memberPositionModalUpdate}
                  handleSave={handleEditSave}
                  modalTitle="Edit member"
                  primaryButtonText="Save Changes"
                  modalBodyTextInput={memberData.name}
                  value={memberData && memberData.name}
                  key={memberData?.id}
                  onChange={e => {
                    setmemberData({
                      ...memberData,
                      name: e.target.value,
                    });
                  }}
                />
              )}
              {departmentModalDelete && (
                <PopUpDeleteModal
                  showModal={departmentModalDelete}
                  handleClose={() => dispatch(closeDepartmentModalDelete())}
                  handleConfirm={handleDelete}
                  modalTitle="Delete Department"
                  key={departmentData?.id}
                  modalBodyText="Are you sure you want to delete this department?"
                />
              )}
              {managerPositionModalDelete && (
                <PopUpDeleteModal
                  showModal={managerPositionModalDelete}
                  handleClose={() => dispatch(closeManagerPositionModalDelete())}
                  handleConfirm={handleDelete}
                  modalTitle="Delete Manager"
                  key={managerData?.id}
                  modalBodyText="Are you sure you want to delete this manager?"
                />
              )}
              {memberPositionModalDelete && (
                <PopUpDeleteModal
                  key={memberData?.id}
                  showModal={memberPositionModalDelete}
                  handleClose={() => dispatch(closememberPositionModalDelete())}
                  handleConfirm={handleDelete}
                  modalTitle="Delete member"
                  modalBodyText="Are you sure you want to delete this member?"
                />
              )}

              {teamType === 'department' && departmentModalCreate && (
                <PopUpAddModal
                  key={departmentData?.id}
                  showModal={departmentModalCreate}
                  handleClose={() => dispatch(closeDepartmentModalCreate())}
                  modalTitle="Add Department"
                  primaryButtonText="Create Department"
                  value={departmentData && departmentData.name}
                  onChange={e => {
                    setDepartmentData({
                      ...departmentData,
                      name: e.target.value,
                    });
                  }}
                  handleCreateSave={handleCreateSave}
                >
                  <div className="col">
                    <div className="row">
                      <input type="hidden" id="department_id" value="" />
                      <span className="font-weight-normal">
                        Name <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="department_name"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Department Name"
                        // value={departmentData?.name}
                        onChange={e =>
                          setDepartmentData({
                            ...departmentData,
                            name: e.target.value,
                          })
                        }
                      />
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_name_error" />
                      </div>
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_error_msg" />
                      </div>
                    </div>
                  </div>
                </PopUpAddModal>
              )}
              {teamType === 'member' && memberPositionModalCreate && (
                <PopUpAddModal
                  showModal={memberPositionModalCreate}
                  handleClose={() => dispatch(closememberPositionModalCreate())}
                  modalTitle="Add Member Position"
                  primaryButtonText="Create Member Position"
                  value={memberData && memberData.name}
                  onChange={e => {
                    setmemberData({
                      ...memberData,
                      name: e.target.value,
                    });
                  }}
                  handleCreateSave={handleCreateSave}
                >
                  <div className="col">
                    <div className="row">
                      <input type="hidden" id="department_id" value="" />
                      <span className="font-weight-normal">
                        Name <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="department_name"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Member Name"
                        // value={memberData?.name}
                        onChange={e =>
                          setmemberData({
                            ...memberData,
                            name: e.target.value,
                          })
                        }
                      />
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_name_error" />
                      </div>
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_error_msg" />
                      </div>
                    </div>
                  </div>
                </PopUpAddModal>
              )}
              {teamType === 'manager' && managerPositionModalCreate && (
                <PopUpAddModal
                  showModal={managerPositionModalCreate}
                  handleClose={() => dispatch(closeManagerPositionModalCreate())}
                  modalTitle="Add Manager Position"
                  primaryButtonText="Create Manager Position"
                  value={managerData && managerData.name}
                  onChange={e => {
                    setManagerData({
                      ...managerData,
                      name: e.target.value,
                    });
                  }}
                  handleCreateSave={handleCreateSave}
                >
                  <div className="col">
                    <div className="row">
                      <input type="hidden" id="department_id" value="" />
                      <span className="font-weight-normal">
                        Name <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="department_name"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter Manager Name"
                        // value={managerData?.name}
                        onChange={e =>
                          setManagerData({
                            ...managerData,
                            name: e.target.value,
                          })
                        }
                      />
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_name_error" />
                      </div>
                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="department_error_msg" />
                      </div>
                    </div>
                  </div>
                </PopUpAddModal>
              )}
              <div className="card-body p-0">
                {departments && departments.length > 0 ? (
                  // eslint-disable-next-line no-shadow
                  departments.map(department => (
                    <div
                      className="card edit-category-card-inline"
                      id="department-block-{{department.id}}"
                    >
                      <div className="card-header">
                        <span className="card-title edit-category-card-title-inline">
                          <span
                            title="{{department.department}}"
                            data-target="#modal-add-department"
                            data-toggle="modal"
                          >
                            <b id="department-name-value-{{department.id}}">{department.name}</b>
                          </span>
                        </span>
                        <div className="card-tools">
                          <button
                            className="fa fa-edit m-1 cursor_pointer"
                            title="Edit Department"
                            type="button"
                            onClick={() => {
                              dispatch(setTeamType('department'));
                              handleEdit(department, 'department');
                            }}
                          >
                            {' '}
                          </button>
                          <button
                            className="fa fa-trash m-1 font-danger cursor_pointer"
                            title="Delete Department"
                            type="button"
                            onClick={() => {
                              dispatch(setTeamType('department'));
                              handleConfirm(department.id, 'department');
                            }}
                          >
                            {' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="card edit-category-card-inline custom-card">
                    <div className="card-header">
                      <span className="card-title edit-category-title-inline">
                        <span title="No Work Order Category">
                          <b>No department Category</b>
                        </span>
                      </span>
                    </div>
                  </div>
                )}
                <input
                  type="hidden"
                  id="department-name-{{department.id}}"
                  value="{{department.department}}"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title edit-category-title-inline">
                  <span>
                    <b>Manager Position</b>
                    <i>(Option select)</i>
                  </span>
                </span>
                <div className="card-tools">
                  <button
                    className="mr-2 cursor_pointer"
                    title="Add Position"
                    type="button"
                    onClick={() => {
                      dispatch(setTeamType('manager'));
                      handleCreate('manager');
                    }}
                  >
                    Add Position
                  </button>
                </div>
              </div>
              <div className="card-body p-0 ">
                <div className="card-body p-0 m-0" id="manager_position-block">
                  {managers && managers.length > 0 ? (
                    managers.map(manager => (
                      <div
                        className="card edit-category-card-inline"
                        id={`manager_position-block-${manager.id}`}
                      >
                        <div className="card-header">
                          <span className="card-title edit-category-card-title-inline">
                            <span
                              title={`${manager.name}`}
                              data-target="#modal-add-manager_position"
                              data-toggle="modal"
                            >
                              <b id={`manager_position-name-value-${manager.id}`}>{manager.name}</b>
                            </span>
                          </span>
                          <div className="card-tools">
                            <button
                              type="button"
                              className="fa fa-edit m-1 cursor_pointer"
                              title="Edit Position"
                              data-toggle="modal"
                              data-target="#modal-add-manager_position"
                              onClick={() => {
                                dispatch(setTeamType('manager'));
                                handleEdit(manager, 'manager');
                              }}
                            >
                              {' '}
                            </button>
                            <button
                              type="button"
                              className="fa fa-trash m-1 font-danger cursor_pointer"
                              title="Delete Position"
                              data-target="#modal-delete-manager_position"
                              data-toggle="modal"
                              onClick={() => {
                                dispatch(setTeamType('manager'));
                                handleConfirm(manager.id, 'manager');
                              }}
                            >
                              {' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="card edit-category-card-inline">
                      <div className="card-header">
                        <span className="card-title edit-category-title-inline">
                          <span title="No Work Order Category">
                            <b>No Manager Position</b>
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                  <input
                    type="hidden"
                    id="manager_position-name-{{manager_position.id}}"
                    value="{{manager_position.position}}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="card mx-1 p-0 shadow-none" id="filter-set">
              <div className="card-header">
                <span className="card-title">
                  <b>Member Position</b>
                  <i>(Option select)</i>
                </span>
                <div className="card-tools">
                  <button
                    type="button"
                    className="mr-2 cursor_pointer"
                    title="Add Position"
                    data-target="#modal-add-member_position"
                    data-toggle="modal"
                    onClick={() => {
                      dispatch(setTeamType('member'));
                      handleCreate('member');
                    }}
                  >
                    Add Position
                  </button>
                </div>
              </div>
              <div className="card-body p-0 ">
                <div className="card-body p-0 m-0" id="member_position-block">
                  {members && members.length > 0 ? (
                    members?.map(memberPosition => (
                      <div
                        className="card edit-category-card-inline"
                        id={`member_position-block-${memberPosition.id}`}
                      >
                        <div className="card-header">
                          <span className="card-title edit-category-card-title-inline">
                            <span title={memberPosition.name}>
                              <b id={`member_position-name-value-${memberPosition.id}`}>
                                {memberPosition.name}
                              </b>
                            </span>
                          </span>
                          <div className="card-tools">
                            <button
                              type="button"
                              className="fa fa-edit m-1 cursor_pointer"
                              title="Edit Position"
                              data-target="#modal-add-member_position"
                              data-toggle="modal"
                              onClick={() => {
                                dispatch(setTeamType('member'));
                                handleEdit(memberPosition, 'member');
                              }}
                            >
                              {' '}
                            </button>
                            <button
                              type="button"
                              className="fa fa-trash m-1 font-danger cursor_pointer"
                              title="Delete Position"
                              data-target="#modal-delete-member_position"
                              data-toggle="modal"
                              onClick={() => {
                                dispatch(setTeamType('member'));
                                handleConfirm(memberPosition.id, 'member');
                              }}
                            >
                              {' '}
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="card edit-category-card-inline">
                      <div className="card-header">
                        <span className="card-title edit-category-title-inline">
                          <span title="No Work Order Category">
                            <b>No member Position</b>
                          </span>
                        </span>
                      </div>
                    </div>
                  )}
                  <input
                    type="hidden"
                    id="member_position-name-{{member_position.id}}"
                    value="{{member_position.position}}"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
