import React, { useEffect, useState } from 'react'
import classes from "../../styles/asset.module.css"
import { Drawer } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faPlus } from "@fortawesome/free-solid-svg-icons";
import { closeTeamModalCreate, createTeam, openTeamModalCreate, setTeamSearchValue } from '../../redux/features/teams/teamSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    closeContractorModalCreate,
    closeContractorModalDelete,
    createContractor, getContractor,
    openContractorModalCreate,
    openContractorModalDelete,
    openContractorModalUpdate,
    deleteContractor,
    setContractor,
    setContractorSearchTerm,
    reset as resetContractor,
  } from '../../redux/features/project/projectSclice'
export default function AddContractor() {
    const dispatch = useDispatch();
    const [data, setData] = useState({ first_name: "",last_name:"", email: "", phone_number: "", company_name: "" , category: ""  })
  
    useEffect(() => {
      dispatch(getContractor())
    }, [dispatch])
    const { contractors } = useSelector((state) => state.project)
    useEffect(() => {
      setData({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        phone_number: data.phone_number,
        company_name: data.company_name,
      })
    }, [])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // Getting value from redux
    const {
      contractorModalCreate,
      contractorModalUpdate,
      contractorModalDelete,
      isCreateContractorLoading,
      isUpdateContractorLoading,
      isDeleteContractorLoading,
      isCreateContractorSuccess,
      isUpdateContractorSuccess,
      isDeleteContractorSuccess,
      isCreateContractorError,
      isUpdateContractorError,
      isDeleteContractorError,
    } = useSelector((state) => state.project);
  
    useEffect(() => {
      dispatch(resetContractor());
    }, [
      isCreateContractorSuccess,
      isUpdateContractorSuccess,
      isDeleteContractorSuccess,
      isCreateContractorError,
      isUpdateContractorError,
      isDeleteContractorError,
    ])
  
    const openDrawer = () => {
      dispatch(openContractorModalCreate());
      setIsDrawerOpen(true); // Open the drawer
    };
  
    const closeDrawer = () => {
      dispatch(closeContractorModalCreate());
      setIsDrawerOpen(false); // Close the drawer
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      await dispatch(createContractor(data))
      setData({ first_name: "",last_name:"", email: "", phone_number: "", company_name: "" , category: "" });
      closeDrawer(); // Close the drawer after submission
    };
  
    const handleContractorEditclick = (contractor) => {
      dispatch(openContractorModalUpdate())
      dispatch(setContractor(contractor))
    }
    const [getcontactorID, setContractorId] = useState(null);
    const handleContractorDeleteClick = (contractor) => {
      dispatch(openContractorModalDelete())
      setContractorId(contractor.id)
    }
  
    const handleDeleteContractor = async () => {
      await dispatch(deleteContractor(getcontactorID))
      dispatch(closeContractorModalDelete())
    }
   
  return (
    <div>
    <div className={classes.assets}>
      <div className={classes.assetsTop}>
        <h4 className={classes.assetsTitle}> </h4>
        <div className={classes.assetActions}>
        <input style={{backgroundColor:'white'}}  type="text" placeholder="Search" onChange={(e)=>{dispatch(setContractorSearchTerm(e.target.value.toLowerCase()))}}/>
          <button
            type="button"
            className={classes.assetfaPlus}
            onClick={openDrawer}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </button>

        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <Drawer
          anchor="right"
          open={isDrawerOpen}
          onClose={closeDrawer}
          PaperProps={{
            style: {
              width: "100%",
              maxWidth: "600px",
              height: "100%",
            },
          }}
        >
          <form
            action="{{action}}"
            className="h-100"
            method="POST"
            onSubmit={handleSubmit}
          >
            <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
              <div className="col-12 row px-2">
                <div
                  className="my-auto"
                  style={{ fontWeight: "bold", padding: "9px" }}
                >
                  Add Requester
                </div>
              </div>
            </div>

            <div className="col-12 pb-1">
              <div className="card-body row">
                <div className="col-lg-12 col-sm-12">
                  <span className="font-weight-normal row col-12">
                    First Name:
                    <div className="font-danger">*</div>
                  </span>
                  <input
                    type="text"
                    name="name"
                    className="form-control mb-2"
                    value={data.first_name}
                    onChange={(e) => setData({ ...data, first_name: e.target.value })}
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <span className="font-weight-normal row col-12">
                    Last Name:
                    <div className="font-danger">*</div>
                  </span>
                  <input
                    type="text"
                    name="name"
                    className="form-control mb-2"
                    value={data.last_name}
                    onChange={(e) => setData({ ...data, last_name: e.target.value })}
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <span className="font-weight-normal row col-12">
                    Email:
                    <div className="font-danger">*</div>
                  </span>
                  <input
                    type="text"
                    name="email"
                    className="form-control mb-2"
                    value={data.email}
                    onChange={(e) => setData({ ...data, email: e.target.value })}
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <span className="font-weight-normal row col-12">
                    Phone Number:
                    <div className="font-danger">*</div>
                  </span>
                  <input
                    type="text"
                    name="phone_number"
                    className="form-control mb-2"
                    value={data.phone_number}
                    onChange={(e) => setData({ ...data, phone_number: e.target.value })}
                    placeholder="Insert with county code :'+60168259745'"
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                  <span className="font-weight-normal row col-12">
                    Company Name:
                    <div className="font-danger">*</div>
                  </span>
                  <input
                    type="text"
                    name="organixation"
                    className="form-control mb-2"
                    value={data.company_name}
                    onChange={(e) => setData({ ...data, company_name: e.target.value })}
                  />
                </div>
                <div className="col-lg-12 col-sm-12">
                        <label className="font-weight-normal ">Category: </label>
                        <select className="form-control select-dash custom-select2 mb-2"
                            name="Category" id="Category"
                            onChange={(e) => setData({ ...data, category: e.target.value })}
                            value={data.category}                >
                            <option value="" disabled selected>--SELECT--</option>
                            {[{val:"Internal Requestor" , label:"Internal Requester"}, 
                              {val:"Contractor" , label:"Contractor"}].map(item => (
                              <option key={item.val} value={item.val}>{item.label}</option>
                            ))}
                        </select>
                </div>
              </div>
            </div>
            <div className="row  m-0 p-2 page-profile-footer">
              <button
                className="btn button-btn pmd-btn-fab primary-btn"
                type="submit"
              >
                <span className="button__text">Save</span>
              </button>
            </div>
          </form>
        </Drawer>
  </div>
  )
}
