import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getApproversByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/ptw_approver_group/`);
  return assert(res, res.data, 'Retrieval of approver by its company ID failed', res);
};

const getApproverbyId = async approver => {
  const res = await http.get(`${BASE_URL}/ptw_approver_group/${approver}`);
  return assert(res, res.data, 'Retrieval of approver by its ID failed', res);
};

const createApprover = async data => {
  const res = await http.post(`${BASE_URL}/ptw_approver_group/`, data);
  return assert(res, res.data, 'Creation of approver failed', res);
};

const updateApprover = async data => {
  const res = await http.put(`${BASE_URL}/ptw_approver_group/${data.id}/`, data);
  return assert(res, res.data, 'Update of approver failed', res);
};

const deleteApprover = async approver => {
  const res = await http.delete(`${BASE_URL}/ptw_approver_group/${approver}/`);
  return assert(res, approver, 'Deletion of approver failed', res);
};

const approverService = {
  getApproversByAuthentication,
  getApproverbyId,
  createApprover,
  updateApprover,
  deleteApprover,
};

export default approverService;
