import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classess from "../../../styles/asset.module.css"

import { useDispatch, useSelector } from 'react-redux';
import { deleteInductionSession } from '../../../redux/features/session/sessionSlice';

const InductionDeleteModal = ({ open, onClose, rowData }) => {
    const dispatch = useDispatch();
    // const {
    //     title,
    // } = useSelector(state => state.eptw)
    const confirmDeletion = async (e) => {
        dispatch(deleteInductionSession(rowData))
        // console.log(rowData)
        onClose()
        // dispatch(updateStatus({ id: rowData, status: "Closed" }))
    }
    return (
        <Modal show={open} onHide={onClose} animation={true}>
            <Modal.Header>
                <Modal.Title>Delete Induction</Modal.Title>
                <button
                    className='btn secondary-btn'
                    onClick={onClose} type='button'>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className={classess.eptwButtons}>
                        <h5>Are you sure you want to delete this Induction?</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={classess.eptwButtons}>
                    <Button  variant="contained" size="small" onClick={confirmDeletion}>
                        Delete
                    </Button>
                </div>
                <Button variant="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InductionDeleteModal;