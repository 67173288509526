import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeTaskDetailModal,
  getTaskById,
  resetTaskActions,
  updateTask
} from '../../../redux/features/task/taskSclice'
import { Drawer } from '@mui/material'
import moment from 'moment'
import { getProjectById } from '../../../redux/features/project/projectSclice'
import { toast } from 'react-toastify'
import { SelectAssignedTo } from '../../selectPersons/SelectPersons'
export default function TaskDeatilsPopupModal ({ view }) {
  const dispatch = useDispatch()
  const { singleTask, taskDetailModal, task } = useSelector(state => state.task)
  const { allUsers } = useSelector(state => state.user)
  const userOptions = allUsers.map(user => ({
    id: user.id,
    label: `${user.first_name} ${user.last_name}`
  }))
  // console.log('userOptions', userOptions)
  // console.log('task', task)
  const statusOptions = [
    { id: 1, label: "TODO", name:"To Do" , background : "#db9004" },
    { id: 2, label: "In Progress", name:"In Progress", background : "#0155a3" },
    { id: 3, label: "Completed", name:"Completed" , background : "#00872a" },
  ]

  const [data, setData] = useState({
    id: null,
    name: '',
    status: '',
    description: '',
    start_date: '',
    finish_date: '',
    assigned_to: null,
    assigned_to_Id: null,
    project: '',
    actual_start_date: '',
    actual_finish_date: ''
  })
  
  const handleChange = (event) => {
    setData({
      ...data,
      assigned_to: event,
    });
  };
  // Use useEffect to update data when singleTask changes
  useEffect(() => {
    if (singleTask) {
      setData({
        id: singleTask.id,
        name: singleTask.name,
        status: singleTask.status === "TO DO" ? "TODO" : singleTask.status,
        description: singleTask.description,
        start_date: singleTask.start_date,
        finish_date: singleTask.finish_date,
        // assigned_to: singleTask.assigned_to_Id,
        // assigned_to_Id: singleTask.assigned_to_Id,
        assigned_to: singleTask?.assigned_to?.length > 0 ? singleTask.assigned_to?.map(i =>(
          {
            value: i?.id,
            label: i?.first_name,
            image: i?.image
          }
        )): [],
        project: singleTask.project
      })
    }
  }, [singleTask])
  // console.log(moment.utc(data?.start_date).format('YYYY-MM-DDTHH:mm'))

  const handleSubmit = async e => {
    e.preventDefault()
    const dataUpdated = {...data,status:data.status==='To Do'?'TODO':data.status}
    await dispatch(
      updateTask({
        ...dataUpdated,
        start_date: moment
          .utc(dataUpdated.start_date)
          .format('YYYY-MM-DDTHH:mm:ss.SSZ'),
        finish_date: moment
          .utc(dataUpdated.finish_date)
          .format('YYYY-MM-DDTHH:mm:ss.SSZ'),
        assigned_to : dataUpdated.assigned_to.map(e => e.value)
      })
    )
    await dispatch(getProjectById(singleTask?.projectId))
    dispatch(closeTaskDetailModal())
  }
  const handleFinishDateChange = e => {
    const start = new Date(data.start_date).getTime()
    const end = new Date(e.target.value).getTime()
    if (start <= end) {
      setData({ ...data, finish_date: e.target.value })
    } else {
      toast.error('Please select a valid start date and end date.')
    }
  }
  return (
    <div>
      <Drawer
        anchor='top'
        open={taskDetailModal}
        onClose={() => {
          dispatch(closeTaskDetailModal())
          dispatch(resetTaskActions())
        }}
        PaperProps={{
          style: {
            width: '70%',
            maxWidth: '600px',
            height: '80%',
            top: '5%', // Center vertically
            left: '30%', // Center horizontally
            transform: 'translate(-50%, -50%)' // Translate both vertically and horizontally
          }
        }}
      >
        <form
          action='{{action}}'
          className='h-100'
          method='POST'
          onSubmit={handleSubmit}
        >
          <div className='navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0'>
            <div className='col-12 row px-2'>
              <div
                className='my-auto'
                style={{ fontWeight: 'bold', padding: '9px' }}
              >
                Edit Details
              </div>
            </div>
          </div>

          <div className='col-12'>
            <div className='card-body row pb-2'>
              <div
                className='my-auto'
                style={{ fontWeight: 'bold', padding: '9px' }}
              >
                <h3> Project Name : {data.project}</h3>
              </div>

              <div className='col-lg-12 col-sm-12'>
                <span className='font-weight-normal row col-12'>
                  Task
                  <div className='font-danger'>*</div>
                </span>
                <input
                  type='text'
                  name='name'
                  className='form-control mb-2'
                  value={data.name}
                  onChange={e => setData({ ...data, name: e.target.value })}
                />
              </div>
              <div className='col-lg-12 col-sm-12 pt-3'>
                <span className='font-weight-normal row col-12'>
                  Description
                </span>
                <textarea
                  type='text'
                  name='description'
                  className='form-control mb-2'
                  value={data.description}
                  onChange={e =>
                    setData({ ...data, description: e.target.value })
                  }
                />
              </div>
              <div className='col-lg-12 col-sm-12 pt-3'>
                <span className='font-weight-normal'>
                  Assigned To <span className='font-danger m-0'>*</span>
                </span>
                <SelectAssignedTo teams= {allUsers} handelChange={handleChange} assignedTo={data.assigned_to}/>
              </div>

              <div className='col-lg-12 col-sm-12 pt-3'>
                <label className='font-weight-normal'>
                  Select Status <span className='font-danger'>*</span>
                </label>
                <div className='col-12 p-0'>
                  <select
                    required
                    className="form-control select-dash custom-select2 mb-2"
                    name='status'
                    id='status'
                    value={data.status}
                    onChange={e => {
                      setData({ ...data, status: e.target.value==='To Do'?'TODO':e.target.value })
                    }}
                  >
                <option value="" disabled selected>--SELECT--</option>
                    {statusOptions.map(option => (
                      <option style={{background: option.background , color: "white"}} key={option.id} value={option.label}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className=' col-lg-6 col-md-6 col-sm-12 pt-3'>
                <label className='font-weight-normal'>
                  Start Date <label className='font-danger m-0'>*</label>
                </label>
                <input
                  type='datetime-local'
                  className='form-control mb-2'
                  name='start_date'
                  id='start_date'
                  placeholder='Start Date'
                  value={moment
                    .utc(data?.start_date)
                    .format('YYYY-MM-DD HH:mm')}
                  onChange={e =>
                    setData({ ...data, start_date: e.target.value,actual_finish_date:e.target.value })
                  }
                />
              </div>
              <div className=' col-lg-6 col-md-6 col-sm-12 pt-3'>
                <label className='font-weight-normal'>
                  Finish Date <label className='font-danger m-0'>*</label>
                </label>
                <input
                  type='datetime-local'
                  className='form-control mb-2'
                  name='finish_date'
                  id='finish_date'
                  placeholder='Start Date'
                  value={moment
                    .utc(data?.finish_date)
                    .format('YYYY-MM-DDTHH:mm')}
                  onChange={handleFinishDateChange}
                />
              </div>
              {!view && (
                <div className='col-lg-6 col-md-6 col-sm-12 '>
                  <button
                    className='btn button-btn pmd-btn-fab primary-btn'
                    type='submit'
                    style={{ marginTop: '15px' }}
                  >
                    <span className='button__text'>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </form>
      </Drawer>
    </div>
  )
}
