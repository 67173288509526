import React, { useEffect, useState } from 'react'
import classes from "../../styles/asset.module.css"
import { Drawer } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faPlus } from "@fortawesome/free-solid-svg-icons";
import { closeTeamModalCreate, createTeam, openTeamModalCreate, setTeamSearchValue } from '../../redux/features/teams/teamSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function Addteams() {
  const dispatch = useDispatch();
  const [data,setData]=useState({email:"",role:"",})
  const { teamSearchTerm} = useSelector((state) => state.team);
  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      email: data.email || '',
      role: data.role || '',
    }));
  }, [data.email, data.role]); // Only update when email or role change
 // Trigger effect when 'data' changes

  const dummyOptions1 = [
      { id: 1, label: "Administrator" },
      { id: 2, label: "Manager" },
      { id: 3, label: "Member" },
    ];
    const dummyOptions = [
      { id: 1, label: "Active" },
      { id: 2, label: "Inactive" },
      // Add more dummy options as needed
    ];
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for Drawer

    const openDrawer = () => {
      dispatch(openTeamModalCreate());
      setIsDrawerOpen(true); // Open the drawer
    };
  
    const closeDrawer = () => {
      dispatch(closeTeamModalCreate());
      setIsDrawerOpen(false); // Close the drawer
    };

    const handleSubmit = async(e) => {
      e.preventDefault();
      await dispatch(createTeam(data))
      setData({ email: "", role: "" });
      closeDrawer(); // Close the drawer after submission
    };
   
  return (
    <div>
    <div className={classes.assets}>
      <div className={classes.assetsTop}>
        <h4 className={classes.assetsTitle}> </h4>
        <div className={classes.assetActions}>
        <input style={{backgroundColor:'white'}}  type="text" placeholder="Search" onChange={(e)=>{dispatch(setTeamSearchValue(e.target.value.toLowerCase()))}}/>
          <button
            type="button"
            className={classes.assetfaPlus}
            onClick={openDrawer}
          >
            <span>
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </button>
          {/* <button type="button" disabled className={classes.assetEllipsis}>
            <FontAwesomeIcon icon={faEllipsis} />
          </button> */}
        </div>
      </div>
    </div>
    <br />
    <br />
    <br />
    <Drawer
      anchor="right"
      open={isDrawerOpen}
      onClose={closeDrawer}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "600px",
          height: "100%",
        },
      }}
    >
      <form
        action="{{action}}"
        className="h-100"
        method="POST"
        onSubmit={handleSubmit}
      >
        <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
          <div className="col-12 row px-2">
            <div
              className="my-auto"
              style={{ fontWeight: "bold", padding: "9px" }}
            >
              Add Teams
            </div>
          </div>
        </div>

        <div className="col-12 pb-1">
          <div className="card-body row">
            <div className="col-lg-12 col-sm-12">
              <span className="font-weight-normal row col-12">
                Email:
                <div className="font-danger">*</div>
              </span>
              <input
                type="text"
                name="email"
                className="form-control mb-2"
                value={data.email}
                onChange={(e)=>setData({...data,email:e.target.value})}
              />
            </div>
            <label className="font-weight-normal">
              Select Account Type <span className="font-danger">*</span>
            </label>
            <div className="col-12 p-0">
              <select
                className="form-control select-meter custom-select2 mb-2"
                name="role"
                id="role"
                style={{ width: "100%" }}
                required
                value={data.role}
                onChange={(e)=>setData({...data,role:e.target.value})}
              >
                <option value="" disabled>
                  --SELECT--
                </option>
                {dummyOptions1.map((option) => (
                  <option key={option.id} value={option.label}>
                    {option.label}
                  </option>
                ))}
              </select>
        </div>

           
          </div>
        </div>
        <div className="row  m-0 p-2 page-profile-footer">
          <button
            className="btn button-btn pmd-btn-fab primary-btn"
            type="submit"
          >
            <span className="button__text">Save</span>
          </button>
        </div>
      </form>
    </Drawer>
  </div>
  )
}
