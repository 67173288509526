import React, { useState } from 'react'
import classes from '../../../styles/asset.module.css'
import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import Button from '@mui/material/Button'
// import InductionManagementForm from '../InductionManagement/InductionManagementForm'
import { useDispatch, useSelector } from 'react-redux'
import {
  reset,
  sessionReset,
  setInductionSearchText,
  setSessionId,
  setSessionModal,
  setSessionViewOnlyModal
} from '../../../redux/features/session/sessionSlice'
import InductionManagementForm from '../InductionManagementForm/InductionManagementForm'
import InductionModalViewOnlyBody from '../InductionModalBody/inductionModalViewOnlyBody'
const InductionManagementHeader = () => {
  const { sessionModal } = useSelector(state => state.session)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClose = () => {
    dispatch(sessionReset())
    dispatch(setSessionId(null))
    dispatch(setSessionModal(false))
    dispatch(setSessionViewOnlyModal(false))
  }
  const backButtonStyle = {
    backgroundColor: '#f24660',
    color: 'white',
    '&:hover': {
      backgroundColor: '#f24660'
    }
  }
  const handleShow = () => dispatch(setSessionModal(true))
  return (
    <div>
      <div className={classes.assets}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <div className={classes.assetActions}>
            <input
              style={{ backgroundColor: 'white' }}
              type='text'
              placeholder='Search'
              onChange={e =>
                dispatch(setInductionSearchText(e.target.value.toLowerCase()))
              }
            />
            <button
              title='Create a new session'
              onClick={() => handleShow()}
              type='button'
              className={classes.assetfaPlus}
            >
              <span>
                <FontAwesomeIcon icon={faPlus} />
              </span>
            </button>
          </div>
        </div>
      </div>
      <InductionModalViewOnlyBody/>
      <InductionManagementForm
        show={sessionModal}
        handleShow={handleShow}
        handleClose={handleClose}
      />
    </div>
  )
}

export default InductionManagementHeader
