import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import approverService from "./approverService";
import { smartTryCatch } from '../../../utils';

const initState = {
  // approver
  approvers:[],
  approver: {},
  errorMessage : "",

  name : "",
  priority: "",
  interval: "",
  timeUnit: "",
  assigned_to: [],

  


  // modal
  approverModalCreate: false,
  approverModalDelete: false,
  approverModalUpdate: false,

  // success
  isApproverGetSuccess: false,
  isApproverCreateSuccess: false,
  isApproverDeleteSuccess: false,
  isApproverUpdateSuccess: false,

  // loading
  isApproverGetLoading: false,
  isApproverDeleteLoading: false,
  isApproverUpdateLoading: false,
  isApproverCreateLoading: false,

  // error
  isApproverGetError: false,
  isApproverCreateError: false,
  isApproverDeleteError: false,
  isApproverUpdateError: false,
};

export const getApproversByAuthentication = createAsyncThunk(
  'getApproversByAuthentication',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(
      approverService.getApproversByAuthentication,
      data,
      thunkAPI,
    );
    return res;
  },
);

export const getApproverById = createAsyncThunk(
  'getApproverById',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(approverService.getApproverById, data, thunkAPI);
    return res;
  },
);

export const createApprover = createAsyncThunk(
  'createApprover',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(approverService.createApprover, data, thunkAPI);
    return res;
  },
);

export const deleteApprover = createAsyncThunk('deleteApprover', async (data, thunkAPI) => {
    const res = await smartTryCatch(approverService.deleteApprover, data, thunkAPI);
    return res; 
  },
);

export const updateApprover = createAsyncThunk(
  'updateApprover',
  async (data, thunkAPI) => {
    const res = await smartTryCatch(approverService.updateApprover, data, thunkAPI);
    return res;
  },
);

export const approverSlice = createSlice({
  name: 'approver',
  initialState: initState,
  reducers: {
    setApprover: (state, action) => {
      state.approver = action.payload;
    },
    openApproverModalDelete: state => {
      state.approverModalDelete = true;
    },
    closeApproverModalDelete: state => {
      state.approverModalDelete = false;
    },
    openApproverModalUpdate: state => {
      state.approverModalUpdate = true;
    },
    closeApproverModalUpdate: state => {
      state.approverModalUpdate = false;
    },
    openApproverModalCreate: state => {
      state.approverModalCreate = true;
    },
    closeApproverModalCreate: state => {
      state.approverModalCreate = false;
    },

    setName : (state, action) => {
      state.name = action.payload;
    },
    setPriority : (state, action) => {
      state.priority = action.payload;
    },
    setInterval : (state, action) => {
      state.interval = action.payload;
    },
    setTimeUnit : (state, action) => {
      state.timeUnit = action.payload;
    },
    setAssignedTo : (state, action) => {
      state.assigned_to = action.payload;
    },

    reset: state => {
      state.isApproverCreateError = false;
      state.isApproverCreateSuccess = false;
      state.isApproverCreateLoading = false;
      state.isApproverGetError = false;
      state.isApproverGetSuccess = false;
      state.isApproverGetLoading = false;
      state.isApproverDeleteError = false;
      state.isApproverDeleteSuccess = false;
      state.isApproverDeleteLoading = false;
      state.isApproverUpdateError = false;
      state.isApproverUpdateSuccess = false;
      state.isApproverUpdateLoading = false;
      state.approverModalCreate = false;
      state.approverModalDelete = false;
      state.approverModalUpdate = false;
      // state.approver = {};
      // state.approverName = '';
    },
    resetApprover :state => {
      state.name = ""
      state.assigned_to= []

    }
  },
  extraReducers: builder => {
    builder
      .addCase(getApproversByAuthentication.pending, state => {
        state.isApproverGetLoading = true;
        state.isApproverGetSuccess = false;
        state.isApproverGetError = false;
      })
      .addCase(getApproversByAuthentication.fulfilled, (state, action) => {
        state.isApproverGetLoading = false;
        state.isApproverGetSuccess = true;
        state.isApproverGetError = false;
        state.approvers = action.payload;
      })
      .addCase(getApproversByAuthentication.rejected, state => {
        state.isApproverGetLoading = false;
        state.isApproverGetSuccess = false;
        state.isApproverGetError = true;
      })
      .addCase(getApproverById.pending, state => {
        state.isApproverGetLoading = true;
        state.isApproverGetSuccess = false;
        state.isApproverGetError = false;
      })
      .addCase(getApproverById.fulfilled, (state, action) => {
        state.isApproverGetLoading = false;
        state.isApproverGetSuccess = true;
        state.isApproverGetError = false;
        state.approvers = action.payload;
      })
      .addCase(getApproverById.rejected, state => {
        state.isApproverGetLoading = false;
        state.isApproverGetSuccess = false;
        state.isApproverGetError = true;
      })
      .addCase(createApprover.pending, state => {
        state.isApproverCreateLoading = true;
        state.isApproverCreateSuccess = false;
        state.isApproverCreateError = false;
      })
      .addCase(createApprover.fulfilled, (state, action) => {
        state.isApproverCreateLoading = false;
        state.isApproverCreateSuccess = true;
        state.isApproverCreateError = false;
        state.approvers.push(action.payload);
      })
      .addCase(createApprover.rejected, (state, action) => {
        state.isApproverCreateLoading = false;
        state.isApproverCreateSuccess = false;
        state.isApproverCreateError = true;
        state.errorMessage = action.payload;
      })
      .addCase(deleteApprover.pending, state => {
        state.isApproverDeleteLoading = true;
        state.isApproverDeleteSuccess = false;
        state.isApproverDeleteError = false;
      })
      .addCase(deleteApprover.fulfilled, (state, action) => {
        state.isApproverDeleteLoading = false;
        state.isApproverDeleteSuccess = true;
        state.isApproverDeleteError = false;
        state.approvers = state.approvers.filter(
          approver => approver.id !== action.payload,
        );
      })
      .addCase(deleteApprover.rejected, state => {
        state.isApproverDeleteLoading = false;
        state.isApproverDeleteSuccess = false;
        state.isApproverDeleteError = true;
      })
      .addCase(updateApprover.pending, state => {
        state.isApproverUpdateLoading = true;
        state.isApproverUpdateSuccess = false;
        state.isApproverUpdateError = false;
      })
      .addCase(updateApprover.fulfilled, (state, action) => {
        state.isApproverUpdateLoading = false;
        state.isApproverUpdateSuccess = true;
        state.isApproverUpdateError = false;
        state.approvers = state.approvers.map(approver =>
          approver.id === action.payload.id ? action.payload : approver,
        );
      })
      .addCase(updateApprover.rejected, state => {
        state.isApproverUpdateLoading = false;
        state.isApproverUpdateSuccess = false;
        state.isApproverUpdateError = true;
      });
  },
});

export const {
  setApprover,
  openApproverModalDelete,
  closeApproverModalDelete,
  openApproverModalUpdate,
  closeApproverModalUpdate,
  openApproverModalCreate,
  closeApproverModalCreate,
  reset,
  resetApprover,
  setName,
  setPriority,
  setInterval,
  setTimeUnit,
  setAssignedTo
} = approverSlice.actions;

export default approverSlice.reducer;
