import React, { useEffect } from "react";
import classes from "../../styles/asset.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";

import {opentemplateDetailModal,setTemplateSearchTerm } from '../../redux/features/template/templateSclice';

export default function AddTemplate() {

  const {
  }= useSelector((state) => state.project)

//   useEffect(() => {
//     getContractorList()
//   }, []);


  const dispatch = useDispatch();

  return (
    <div>
      <div className={classes.assets}>
        <div className={classes.assetsTop}>
          <h4 className={classes.assetsTitle}>Permit Templates</h4>
          <div className={classes.assetActions}>
            <input style={{backgroundColor:'white'}} type="text" placeholder="Search" onChange={(e)=>{dispatch(setTemplateSearchTerm(e.target.value.toLowerCase()))}}/>
            {/* <button type="button" disabled className={classes.assetEllipsis}>
              <FontAwesomeIcon icon={faEllipsis} />
            </button> */}
            <button
            onClick={()=>
              dispatch(opentemplateDetailModal())
            }
              type='button'  
              className={classes.assetfaPlus}>
            <span><FontAwesomeIcon icon={faPlus}  /></span>
            </button>
          </div>
        </div>
      </div>

    </div>
  );
}
