/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import React from 'react';
import Chart from 'react-apexcharts';

const SemiCircleChart = (props) => {
  const data = [props.percentage];
  const options = {
    colors: ['#D9314A', '#5EEA8D', '#14CC52'],
    chart: {
      width: 350,
      type: 'radialBar',
      offsetY: -10,
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            show: false,
            fontSize: '16px',
            color: undefined,
            offsetY: 120,
          },
          value: {
            offsetY: 76,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              return val + '%';
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 300,
        options: {
          plotOptions: {
            bar: {
              horizontal: false
            }
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ],
    fill: {
      type: 'solid',
      colors: [
        function ({ value, seriesIndex, w }) {
          if (value < 55) {
            return '#FB0303';
          } else if (value >= 55 && value < 80) {
            return '#10A130';
          } else {
            return '#035C1B';
          }
        },
      ],
      gradient: {
        shade: 'dark',
        type: 'horizontal',
        shadeIntensity: 0.15,
        gradientToColors: [],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    /* legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'top',
      horizontalAlign: 'center',
      formatter: undefined,
      floating: false,
      fontSize: '14px',
      fontFamily: 'Helvetica, Arial',
      fontWeight: 400,
      inverseOrder: false,
      customLegendItems: ['<50', '50-80', '>80'],
      offsetX: 0,
      offsetY: 0,
      labels: {
        colors: undefined,
        useSeriesColors: false,
      },
    }, */
  };

  return (
    <div className="me-5">
      <div className="progress" style={{ maxWidth: '100%', marginBottom: '23px' }}>
        <div
          className="progress-bar"
          style={{ width: '20%', backgroundColor: '#D9314A', height: '100%' }}
        >
          55%
        </div>
        <div
          className="progress-bar progress-bar-stripped"
          style={{ width: '40%', backgroundColor: '#5EEA8D', height: '100%' }}
        >
          {' '}
          56% - 80%
        </div>
        <div
          className="progress-bar progress-bar-stripped"
          style={{ width: '60%', backgroundColor: '#14CC52', height: '100%' }}
        >
          81% - 100%
        </div>
      </div>
      <Chart options={options} series={data} type="radialBar" height={"317px"} width={"100%"} />
    </div>
  );
};

export default SemiCircleChart;
