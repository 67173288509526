import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { smartTryCatch } from '../../../utils';
import companyService from './companyService';


const initState = {
    company: null,
    isError: false,
    isSuccess: false,
    isLoading: false,
  };

  export const getCompanyProfile = createAsyncThunk('getComapnyProfile', async (data, thunkAPI) => {
    const get = await smartTryCatch(companyService.getComapnyProfile, data, thunkAPI);
    return get;
  });
  
  export const updateCompanyProfile = createAsyncThunk('updateCompanyProfile' , async(data, thunkAPI)=>{
    const get = await smartTryCatch(companyService.updateCompanyProfile, data, thunkAPI);
    return get;
  })

  export const companySlice = createSlice({
    name :'company',
    initialState:initState , 
    reducers:{   reset :(state)=>{
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = false;  
    }
  },
 
  extraReducers :(builder)=>{
    builder
    .addCase(getCompanyProfile.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(getCompanyProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
        state.company = action.payload;
      })
      .addCase(getCompanyProfile.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })
    .addCase(updateCompanyProfile.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
      })
      .addCase(updateCompanyProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.company = action.payload;
      })
      .addCase(updateCompanyProfile.rejected, (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
      })

  }
  
}
  )


  export const {reset} = companySlice.actions;
  export default companySlice.reducer;