/* eslint-disable no-unused-vars */
import { assert, http } from '../../../utils'

import { BASE_URL } from '../../../utils/axios'

const extendVideoConsent = async id => {
  const res = await http.get(
    `${BASE_URL}/video_induction_session/${id}/extend_session`
  )
  return res, res.data, 'Extend safety induction session failed', res
}

const expireVideoConsent = async id => {
  const res = await http.get(
    `${BASE_URL}/video_induction_session/${id}/expire_session`
  )
  return res, res.data, 'Expire safety induction session failed', res
}

const updateVideoConsentStatus = async data => {
  const res = await http.post(
    `${BASE_URL}/video_induction_session/update_status/${data.id}/${data.contractor_id}/`,
    {
      pin_code: data.pin_code,
      video_induction: data.video_induction
    }
  )
  return assert(
    res,
    res.data,
    'Update of status of video induction session failed',
    res
  )
}

const getVideoConsent = async data => {
  const res = await http.get(`${BASE_URL}/video_induction_session/`)
  return assert(res, res.data, 'Post of video consent failed', res)
}

const postVideoConsent = async data => {
  const res = await http.post(`${BASE_URL}/video_induction_session/`, data)
  return assert(res, res.data, 'Post of video consent failed', res)
}

const getSafetyVideo = async data => {
  const res = await http.get(`${BASE_URL}/safety_video/`)
  return assert(res, res.data, 'Retrieval of Safety video failed', res)
}

const getSafetyVideoById = async data => {
  const res = await http.get(`${BASE_URL}/safety_video/${data}/`)
  return assert(res, res.data, 'Retrieval of Safety video failed', res)
}

const deleteSafetyVideo = async safetyId => {
  const res = await http.delete(`${BASE_URL}/safety_video/${safetyId}/`)
  return assert(res, res.data, 'Deletion of safety_video failed', res)
}

const updateSafetyVideo = async data => {
  const res = await http.patch(
    `${BASE_URL}/safety_video/${data.id}/`,
    data.data
  )
  return assert(res, res.data, 'Update of safety_video failed', res)
}

const postSafetyVideo = async data => {

  const res = await http.post(`${BASE_URL}/safety_video/`, data)
  return assert(res, res.data, 'Post of safety video failed', res)
}

const SafetyService = {
  extendVideoConsent,
  expireVideoConsent,
  updateVideoConsentStatus,
  getVideoConsent,
  getSafetyVideoById,
  postVideoConsent,
  getSafetyVideo,
  postSafetyVideo,
  deleteSafetyVideo,
  updateSafetyVideo
}
export default SafetyService
