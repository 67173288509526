import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { smartTryCatch } from '../../../utils'
import sessionService from './sessionService'

const initialState = {
  //post induction session data
  session_id: null,
  quizName: '',
  video_details: null,
  quizDetails: null,
  inductionSearchText: '',
  sessionModal: false,
  sessionViewOnlyModal: false,

  sessions: [],
  session: null,
  contractor_session: {},
  contractor_session_details_pin: {},
  selected_video: null,
  quiz_id: null,
  sesstionTitle: null,

  isPinCodeVerified: false,

  // State of getting the session
  isSessionGetError: false,
  isSessionGetSuccess: false,
  isSessionGetPending: false,

  // State of create the session
  isSessionPostError: false,
  isSessionPostSuccess: false,
  isSessionPostPending: false,

  // State of update the session
  isSessionUpdateError: false,
  isSessionUpdateSuccess: false,
  isSessionUpdatePending: false,

  // State of delete the session
  isSessionDeleteError: false,
  isSessionDeleteSuccess: false,
  isSessionDeletePending: false,

  // state of getting session attached to contractor
  isContractorSessionGetError: false,
  isContractorSessionGetSuccess: false,
  isContractorSessionGetPending: false,

  // state of Post session attached to contractor
  isContractorSessionPostError: false,
  isContractorSessionPostSuccess: false,
  isContractorSessionPostPending: false
}

export const getInductionSession = createAsyncThunk(
  'getInductionSession',
  async (data, thunkAPI) => {
    const result = smartTryCatch(
      sessionService.getInductionSession,
      data,
      thunkAPI
    )
    return result
  }
)

export const getInductionSessionByID = createAsyncThunk(
  'getInductionSessionByID',
  async (data, thunkAPI) => {
    const result = smartTryCatch(
      sessionService.getInductionSessionByID,
      data,
      thunkAPI
    )
    return result
  }
)
export const getInductionSessionFillingDataById = createAsyncThunk(
  'getInductionSessionFillingDataById',
  async (data, thunkAPI) => {
    const result = smartTryCatch(
      sessionService.getInductionSessionFillingDataById,
      data,
      thunkAPI
    )
    return result
  }
)

export const postInductionSession = createAsyncThunk(
  'postInductionSession',
  async (data, thunkAPI) => {
    const result = smartTryCatch(
      sessionService.postInductionSession,
      data,
      thunkAPI
    )
    return result
  }
)

export const deleteInductionSession = createAsyncThunk(
  'deleteInductionSession',
  async (data, thunkAPI) => {
    const result = smartTryCatch(
      sessionService.deleteInductionSession,
      data,
      thunkAPI
    )
    return result
  }
)
export const updateInductionSession = createAsyncThunk(
  'updateInductionSession',
  async (data, thunkAPI) => {
    const result = smartTryCatch(
      sessionService.updateInductionSession,
      data,
      thunkAPI
    )
    return result
  }
)

export const getContractorSession = createAsyncThunk(
  'getContractorSession',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      sessionService.getContractorSession,
      data,
      thunkAPI
    )
    return result
  }
)

export const postContractorSession = createAsyncThunk(
  'postContractorSession',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      sessionService.postContractorSession,
      data,
      thunkAPI
    )
    return result
  }
)

export const getContractorSessionDetailsPin = createAsyncThunk(
  'getContractorSessionDetailsPin',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      sessionService.getContractorSessionDetailsPin,
      data,
      thunkAPI
    )
    return result
  }
)

const sessionSlice = createSlice({
  name: 'session',
  initialState,

  reducers: {
    reset: state => {
      state.isPinCodeVerified = false

      // State of getting the session
      state.isSessionGetError = false
      state.isSessionGetSuccess = false
      state.isSessionGetPending = false

      // State of create the session
      state.isSessionPostError = false
      state.isSessionPostSuccess = false
      state.isSessionPostPending = false

      // State of update the session
      state.isSessionUpdateError = false
      state.isSessionUpdateSuccess = false
      state.isSessionUpdatePending = false

      // State of delete the session
      state.isSessionDeleteError = false
      state.isSessionDeleteSuccess = false
      state.isSessionDeletePending = false

      // state of getting session attached to contractor
      state.isContractorSessionGetError = false
      state.isContractorSessionGetSuccess = false
      state.isContractorSessionGetPending = false

      // state of Post session attached to contractor
      state.isContractorSessionPostError = false
      state.isContractorSessionPostSuccess = false
      state.isContractorSessionPostPending = false
    },
    sessionReset: state => {
      state.session = null
      state.quizName = ''
    },
    setSelectedVideoId: (state, action) => {
      state.selected_video = action.payload
    },
    setQuizId: (state, action) => {
      state.quiz_id = action.payload
    },
    setSessiontitle: (state, action) => {
      state.sesstionTitle = action.payload
    },
    setPinCodeVerified: (state, action) => {
      state.isPinCodeVerified = action.payload
    },
    setInductionSearchText: (state, action) => {
      state.inductionSearchText = action.payload
    },
    setVideoDetails: (state, action) => {
      state.video_details = action.payload
    },
    setQuizDetails: (state, action) => {
      state.quizDetails = action.payload
    },
    setQuizName: (state, action) => {
      state.quizName = action.payload
    },
    setSessionId: (state, action) => {
      state.session_id = action.payload
    },
    setSessionModal: (state, action) => {
      state.sessionModal = action.payload
    },
    setSessionViewOnlyModal: (state, action) => {
      state.sessionViewOnlyModal = action.payload
    }
  },

  extraReducers: builder => {
    builder
      .addCase(postInductionSession.pending, state => {
        state.isSessionPostError = false
        state.isSessionPostSuccess = false
        state.isSessionPostPending = true
      })
      .addCase(postInductionSession.fulfilled, state => {
        state.isSessionPostError = false
        state.isSessionPostSuccess = true
        state.isSessionPostPending = false
      })
      .addCase(postInductionSession.rejected, state => {
        state.isSessionPostError = true
        state.isSessionPostSuccess = false
        state.isSessionPostPending = false
      })
      .addCase(getInductionSession.pending, (state, action) => {
        state.isSessionGetError = false
        state.isSessionGetSuccess = false
        state.isSessionGetPending = true
      })
      .addCase(getInductionSession.fulfilled, (state, action) => {
        state.isSessionGetError = false
        state.isSessionGetSuccess = true
        state.isSessionGetPending = false
        state.sessions = action.payload
      })
      .addCase(getInductionSession.rejected, (state, action) => {
        state.isSessionGetError = true
        state.isSessionGetSuccess = false
        state.isSessionGetPending = false
      })
      .addCase(getInductionSessionByID.pending, (state, action) => {
        state.isSessionGetError = false
        state.isSessionGetSuccess = false
        state.isSessionGetPending = true
      })
      .addCase(getInductionSessionByID.fulfilled, (state, action) => {
        state.isSessionGetError = false
        state.isSessionGetSuccess = true
        state.isSessionGetPending = false
        state.session = action.payload
      })
      .addCase(getInductionSessionFillingDataById.rejected, (state, action) => {
        state.isSessionGetError = true
        state.isSessionGetSuccess = false
        state.isSessionGetPending = false
      })
      .addCase(getInductionSessionFillingDataById.pending, (state, action) => {
        state.isSessionGetError = false
        state.isSessionGetSuccess = false
        state.isSessionGetPending = true
      })
      .addCase(
        getInductionSessionFillingDataById.fulfilled,
        (state, action) => {
          state.isSessionGetError = false
          state.isSessionGetSuccess = true
          state.isSessionGetPending = false
          state.session = action.payload
        }
      )
      .addCase(getInductionSessionByID.rejected, (state, action) => {
        state.isSessionGetError = true
        state.isSessionGetSuccess = false
        state.isSessionGetPending = false
      })
      .addCase(deleteInductionSession.pending, (state, action) => {
        state.isSessionDeleteError = false
        state.isSessionDeleteSuccess = false
        state.isSessionDeletePending = true
      })
      .addCase(deleteInductionSession.fulfilled, (state, action) => {
        state.isSessionDeleteError = false
        state.isSessionDeleteSuccess = true
        state.isSessionDeletePending = false
        state.sessions = state.sessions.filter(
          ses => ses.id !== +action.payload
        )
      })
      .addCase(deleteInductionSession.rejected, (state, action) => {
        state.isSessionDeleteError = true
        state.isSessionDeleteSuccess = false
        state.isSessionDeletePending = false
      })
      .addCase(updateInductionSession.pending, (state, action) => {
        state.isSessionUpdateError = false
        state.isSessionUpdateSuccess = false
        state.isSessionUpdatePending = true
      })
      .addCase(updateInductionSession.fulfilled, (state, action) => {
        state.isSessionUpdateError = false
        state.isSessionUpdateSuccess = true
        state.isSessionUpdatePending = false
        // console.log(state.sessions)
        // state.sessions = state.sessions.map(ses =>
        //   ses.id === +action.payload ? action.payload : ses.id
        // )
      })
      .addCase(updateInductionSession.rejected, (state, action) => {
        state.isSessionUpdateError = true
        state.isSessionUpdateSuccess = false
        state.isSessionUpdatePending = false
      })
      .addCase(getContractorSession.pending, state => {
        state.isContractorSessionGetError = false
        state.isContractorSessionGetSuccess = false
        state.isContractorSessionGetPending = true
      })
      .addCase(getContractorSession.fulfilled, (state, action) => {
        state.isContractorSessionGetError = false
        state.isContractorSessionGetSuccess = true
        state.isContractorSessionGetPending = false
        state.contractor_session = action.payload
      })
      .addCase(getContractorSession.rejected, (state, action) => {
        state.isContractorSessionGetError = true
        state.isContractorSessionGetSuccess = false
        state.isContractorSessionGetPending = false
      })
      .addCase(postContractorSession.pending, state => {
        state.isContractorSessionPostError = false
        state.isContractorSessionPostSuccess = false
        state.isContractorSessionPostPending = true
      })
      .addCase(postContractorSession.fulfilled, state => {
        state.isContractorSessionPostError = false
        state.isContractorSessionPostSuccess = true
        state.isContractorSessionPostPending = false
      })
      .addCase(postContractorSession.rejected, state => {
        state.isContractorSessionPostError = true
        state.isContractorSessionPostSuccess = false
        state.isContractorSessionPostPending = false
      })
      .addCase(getContractorSessionDetailsPin.fulfilled, (state, action) => {
        state.isPinCodeVerified = true
        state.contractor_session_details_pin = action.payload
      })
      .addCase(getContractorSessionDetailsPin.rejected, (state, action) => {
        state.isPinCodeVerified = false
      })
  }
})

export const {
  setPinCodeVerified,
  setQuizId,
  setSelectedVideoId,
  setSessiontitle,
  isContractorSessionPostError,
  isContractorSessionPostSuccess,
  reset,
  sessionReset,
  setSessionModal,
  setSessionId,
  setVideoDetails,
  setQuizDetails,
  setQuizName,
  setInductionSearchText,
  setSessionViewOnlyModal
} = sessionSlice.actions

export default sessionSlice.reducer
