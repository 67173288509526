import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import Box from '@mui/material/Box'
import StepLabel from '@mui/material/StepLabel'
import classes from '../../styles/asset.module.css'
import LooksOneRoundedIcon from '@mui/icons-material/LooksOneRounded';
import LooksTwoRoundedIcon from '@mui/icons-material/LooksTwoRounded';
import Looks3RoundedIcon from '@mui/icons-material/Looks3Rounded';
import SettingsIcon from '@mui/icons-material/Settings'
import VideoLabelIcon from '@mui/icons-material/VideoLabel'
import VisibilityIcon from '@mui/icons-material/Visibility'
import styles from "../../styles/asset.module.css";
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector'
import TextField from '@mui/material/TextField'
import EptwTemplateDetails from './EptwTemplateDetails'
import { useHistory } from 'react-router-dom'
import {
  createTemplate,
  setIsErr,
  setStep,
  setTemplateName,
  setRevisionNumber,
  setRevisionDate,
  setDocumentNumber,
  setTemplateNameError,
  updateTemplate
} from '../../redux/features/template/templateSclice'
import TemplateReview from './templateReview'
import PremadeTemplate from '../premadeTemplate/PremadeTemplate'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}))

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)'
  })
}))

function ColorlibStepIcon (props) {
  const { active, completed, className } = props

  const icons = {
    1: <LooksOneRoundedIcon />,
    2: <LooksTwoRoundedIcon />,
    3: <Looks3RoundedIcon />
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node
}
const steps = ['Select a name for template', 'Enter details', 'Review Template']

export default function Steps ({
  inputList,

  setInputList,
  customFeild,
  setCustomFeild,
  saftyList,
  setSaftyList,
  customSection,
  revisionDate,
  revisionNumber,
  documentNumber,
  setCustomSection
}) {
  const history = useHistory()
  const [addCustomInputs, setAddCustomInputs] = React.useState(false)
  const [disabledFeild, setDisabledFeild] = React.useState(false)
  const [disabledSection, setDisabledSection] = React.useState(false)
const [showPreview,setShowPreview] = React.useState(false)
  const { step, templateName, templateNameError, template } = useSelector(
    state => state.template
  )
  const dispatch = useDispatch()

  const handleNextStep = () => {
    if (templateName) {
      if (step < steps.length - 1) {
        // dispatch(setStep(step + 1))
        setShowPreview(true)
      }
    } else {
      dispatch(setTemplateNameError(true))
    }
  }
  const handlePrevStep = () => {
    if (step < steps.length + 1) {
      // dispatch(setStep(step - 1))
      setShowPreview(false)
    }
  }

  const addCustomSectionHandler = type => {
    if (type === 'section') {
      if (customSection.length > 0) {
        if (customSection.some(obj => obj.title === '')) {
          return
        }
        if (
          customSection[customSection.length - 1].fields.length === 0 ||
          customSection.some(obj => obj.fields.some(item => item.name === ''))
        ) {
          return
        }
        setCustomSection([...customSection, { title: '', fields: [] }])
      }
      setCustomSection([...customSection, { title: '', fields: [] }])
    }

    if (type === 'feild') {
      if (customSection.some(obj => obj.title === '')) {
        return
      }
      if (
        customSection.some(obj => obj.fields.some(item => item.name === ''))
      ) {
        return
      }
      const idx = customSection?.length - 1
      const updatedItems = customSection.map((item, index) => {
        if (index === idx) {
          return {
            ...item,
            fields: [...item.fields, { name: '', type: 'String' }] // Update the title within the 'section' object
          }
        }
        return item
      })
      setCustomSection(updatedItems)
    }
  }
  // const addnewFeildHandler = (i, type, idx) => {
  //   const arr = [...customSection]
  //   console.log("arr" , arr[i].fields);
  //   if (type === 'feild') {
  //     arr[i].fields.splice(idx + 1, 0, { name: '', type: 'String' })
  //   }
  //   // else if (type === "section") {
  //   //   arr.splice(i + 1 , 0 , { title: "", fields: [] })
  //   // }
  //   setCustomSection(arr)
  // }

  const addnewFeildHandler = (i, type, idx) => {
    const arr = [...customSection];
    const sectionCopy = { ...arr[i] }; // Shallow copy of the section
    const fieldsCopy = [...sectionCopy.fields]; // Shallow copy of the fields array
  
    if (type === 'feild') {
      fieldsCopy.splice(idx + 1, 0, { name: '', type: 'String' });
    }
  
    sectionCopy.fields = fieldsCopy; // Update the section copy with the new fields array
    arr[i] = sectionCopy; // Update the original array with the modified section copy
  
    setCustomSection(arr);
  };
  

  // const removeCustomSectionHandler = (i, type, idx) => {
  //   const list = [...customSection]
  //   if (type === 'section') {
  //     list.splice(i, 1)
  //   }
  //   if (type === 'feild') {
  //     list[i].fields.splice(idx, 1)
  //   }
  //   setCustomSection(list)
  // }

  const removeCustomSectionHandler = (i, type, idx) => {
    const list = [...customSection];
    if (type === 'section') {
      const updatedList = [...list.slice(0, i), ...list.slice(i + 1)];
      setCustomSection(updatedList);
    }
    if (type === 'feild') {
      const sectionCopy = { ...list[i] };
      const updatedFields = [...sectionCopy.fields.slice(0, idx), ...sectionCopy.fields.slice(idx + 1)];
      sectionCopy.fields = updatedFields;

      const updatedList = [...list.slice(0, i), sectionCopy, ...list.slice(i + 1)];
      setCustomSection(updatedList);
    }
  };

  const handleDocumentNumberChange =async (e)=>{
    await dispatch(setDocumentNumber(e))
  }
const handleRevisionNumberChange =async (e)=>{
  await dispatch(setRevisionNumber(e))
}
const handleRevisionDateChange= async (e)=>{
  await dispatch(setRevisionDate(e))
}

  // const handelCutomSectionChange = (e, i, id, type, idx) => {
  //   const { name, value } = e.target
  //   let updatedItems
  //   if (type === 'title') {
  //     updatedItems = customSection.map((item, index) => {
  //       if (index === i) {
  //         return { ...item, title: value } // Update the title within the 'section' object
  //       }
  //       return item
  //     })
  //   } else if (type === 'feild') {
  //     updatedItems = [...customSection]
  //     if (name === 'name') {
  //       console.log(updatedItems[i].fields[idx].name);
  //       console.log(updatedItems);
  //       // updatedItems[i].fields[idx].name = value
  //       // updatedItems[i].fields.map((ele , index)=>{
  //       //   if (index === idx) {
  //       //     return [...updatedItems , fields]
  //       //   }
  //       // })
  //     }
  //     if (name === 'type') {
  //       updatedItems[i].fields[idx].type = value
  //     }
  //   }
  //   setCustomSection(updatedItems)
  // }

  const handelCutomSectionChange = (e, i, id, type, idx) => {
    const { name, value } = e.target;
    setCustomSection((prevCustomSection) => {
      return prevCustomSection.map((item, index) => {
        console.log("item in setCustomSection",item);
        if (index !== i) {
          return item;
        }
  
        if (type === 'title') {
          return { ...item, title: value };
        } else if (type === 'feild') {
          const updatedFields = item.fields.map((field, fieldIndex) => {
            if (fieldIndex !== idx) {
              return field;
            }
  
            if (name === 'name') {
              return { ...field, name: value };
            } else if (name === 'type') {
              return { ...field, type: value };
            }
            else if (name === 'isRequired') {
              return { ...field, isRequired: e.target?.checked };
            }
            return field;
          });
  
          return { ...item, fields: updatedFields };
        }
  
        return item;
      });
    });
  };
  const conditionMet = inputList?.some(obj => obj.name === '')

  const handelSaveTemplate = () => {
    // Initialize the output object
    // const sections = {}
    // // Iterate through the input array
    // customSection?.forEach(item => {
    //   const sectionName = item.title
    //   const sectionFields = {}

    //   // Iterate through the fields in the current section
    //   item?.fields?.forEach(field => {
    //     sectionFields[field.name] = field.type
    //   })

    //   sections[sectionName] = sectionFields
    // })
    //todo sending it as an array maintaining order for sections
    const sections = [];
    customSection?.forEach(item => {
        const sectionName = item.title;
        const sectionFields = item.fields.map(field => ({ name: field.name, type: field.type ,isRequired:field.isRequired}));
        sections.push({ title: sectionName, fields: sectionFields });
    });
    const data = {
      name: templateName,
      sections,
      revision_date:revisionDate,
      revision_number:revisionNumber,
      document_number:documentNumber,
      saftey_percautions: 'test \n test'
    }
// console.log("the data to submit",data);
    if (templateName) {
      if (template?.id) {
        dispatch(updateTemplate({ id: template?.id, data: data }))
      } else {
        dispatch(createTemplate(data))
        // console.log("about to create",data.sections);
      }
    } else {
      dispatch(setIsErr(true))
    }
  }

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={step}
        connector={<ColorlibConnector />}
      >
        {/* {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))} */}
      </Stepper>
      {/* Render different content for each step */}
      {step === 0 && (
        <>
        {/* <div>
        <h3 className={styles.templateNamePreview}>{templateName}</h3> 
          <TextField
            error={templateNameError}
            helperText={templateNameError && 'Incorrect entry.'}
            label='Template Name'
            value={templateName}
            variant='outlined'
            fullWidth
            margin='normal'
            onChange={event => {
              dispatch(setTemplateName(event.target.value))
              dispatch(setTemplateNameError(false))
            }}
          />
          <button
            className={
              !templateName
                ? `${classes.disableBtn}`
                : `${classes.threeDButton}`
            }
            onClick={handleNextStep}
            disabled={!templateName}
          >
            Next
          </button>
        </div> */}
      {!showPreview &&(
         <div>
         <EptwTemplateDetails
        //  name={templateName}
           // inputList={inputList}
           // addInputHandler={addInputHandler}
           // removeInputHandler={removeInputHandler}
           // handelSpChange={handelSpChange}
           // showCheckbox={showCheckboxs}
           // setShowCheckbox={setShowCheckboxs}
           // addCustomSection={addCustomSection}
           // setAddCustomSection={setAddCustomSection}
           // customFeild={customFeild}
           // setCustomFeild={setCustomFeild}
           // saftyList={saftyList}
           // setSaftyList={setSaftyList}
           // showSafty={showSafty}
           // setShowSafty={setShowSafty}
           // newInputRef={newInputRef}
           // handleInputKeyPress={handleInputKeyPress}
           // removeSaftyInputHandler={removeSaftyInputHandler}
           // handelSaftyChange={handelSaftyChange}
           setRevisionDate={handleRevisionDateChange}
           setDocumentNumber={handleDocumentNumberChange}
           setRevisionNumber={handleRevisionNumberChange}
           addCustomSectionHandler={addCustomSectionHandler}
           removeCustomSectionHandler={removeCustomSectionHandler}
           handelCutomSectionChange={handelCutomSectionChange}
           addCustomInputs={addCustomInputs}
           customSection={customSection}
           setCustomSection={setCustomSection}
           setAddCustomInputs={setAddCustomInputs}
           addnewFeildHandler={addnewFeildHandler}
           revisionDate={revisionDate}
           revisionNumber={revisionNumber}
           documentNumber={documentNumber}
           />
         <button
           className={
            !customSection[0]?.title || !customSection[0]?.fields[0]?.name||!documentNumber||!revisionNumber||!revisionDate||!templateName
            ? `${classes.disableBtn} mr-2`
            : `${classes.threeDButton} mr-2`
           }
           onClick={handelSaveTemplate}
           disabled={
            !customSection[0]?.title || !customSection[0]?.fields[0]?.name||!documentNumber||!revisionNumber||!revisionDate||!templateName
          }
           >
           Save
         </button>
         <button
           className={
             !customSection[0]?.title || !customSection[0]?.fields[0]?.name||!documentNumber||!revisionNumber||!revisionDate||!templateName
             ? `${classes.disableBtn}`
             : `${classes.threeDButton}`
            }
            onClick={handleNextStep}
            disabled={
              !customSection[0]?.title || !customSection[0]?.fields[0]?.name||!documentNumber||!revisionNumber||!revisionDate||!templateName
            }
            >
           Preview
         </button>
       </div>)}
       {showPreview &&(
         <div>
           {/* <PremadeTemplate/> */}
          <TemplateReview
            templateName={templateName}
            customSection={customSection}
            revisionDate={revisionDate}
            revisionNumber={revisionNumber}
            documentNumber={documentNumber}
          />
          {/* <button
            className={`${classes.threeDButton} mr-2`}
            onClick={handlePrevStep}
          >
            Back
          </button> */}
          <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'}} >
          <button
            // className={`${classes.threeDButton} mr-2`}
            className={
              !customSection[0]?.title || !customSection[0]?.fields[0]?.name||!documentNumber||!revisionNumber||!revisionDate||!templateName
              ? `${classes.disableBtn} mr-4`
              : `${classes.threeDButton} mr-4`
             }
            onClick={handelSaveTemplate}
            disabled={
              !customSection[0]?.title || !customSection[0]?.fields[0]?.name||!documentNumber||!revisionNumber||!revisionDate||!templateName
            }
            >
            Save
          </button>
          <button
            className={`${classes.threeDButton}`}
            onClick={handlePrevStep}
          >
            Back
          </button>
        </Box>
            </div>
       )}
       </>
      )}
      {/* {step === 1 && (
       
      )} */}
      {/* {step === 2 && (

      )} */}
    </Stack>
  )
}
