import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

export function SelectPersons ({assignedTo , setAssignedTo , teams }) {
    const dispatch = useDispatch();

    const modifiedDataFun = (array , data)=>{
      data?.forEach((item) => {
        if (item?.role === "Administrator") {
          array.push({...item , role: "Admin"}) 
        }else array.push(item)
      })
    }

  
    const teamData  = teams && teams.length > 0 ?  teams?.filter(team =>  team?.role !== "Requester") : []
    const modifiedTeam = []
    modifiedDataFun(modifiedTeam , teamData)
  
  
      const options = modifiedTeam?.map((i) => ({
        value: i?.id,
        label: `${i?.first_name} ${i?.last_name} (${i?.role})`,
        // image: i?.image
      }));
    
  
    const formatOptionLabel = ({  label, image }) => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* <img src={image} alt={label} style={{ width: '32px', height: '32px', marginRight: '8px' }} /> */}
        <span>{label}</span>
      </div>
    );
    
    const getTeamData = (res)=>{
      const response  = res.filter(team =>  team?.role !== "Requester")
      const modifiedData = []
    
      modifiedDataFun(modifiedData , response)

      return modifiedData?.map((i) => ({
        value: i?.id,
        label: `${i?.first_name} ${i?.last_name} (${i?.role})`,
        // image: i?.image
      }));
    }
  
  
    const promiseOptions = async  (inputValue) =>{
        const res =  teams?.filter(item => item.first_name?.toLowerCase()?.includes(inputValue.toLowerCase()))
        return getTeamData(res)
    };
  
    const debounce = (fn, delay) => {
      let timer;
      return function (...args) {
        clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(this, args);
        }, delay);
      };
    };
  
    // Function to handle input value changes and call loadOptions
    const handleInputChange = debounce((newValue) => {
      promiseOptions(newValue);
    }, 500); // Adjust the delay (in milliseconds) to suit your needs
  
    const handelAssignedTo = (selectedOptions)=>{
      const previousSelectedLength = assignedTo.length;
      const newSelectedLength = selectedOptions.length;
      if (newSelectedLength > previousSelectedLength && newSelectedLength > 3) {
        toast.warning('You cannot add more than three approvers', {
          toastId: 'ApproverGeWarning',
        });
      }else{
        dispatch(setAssignedTo(selectedOptions))
      }
  }
          return (
            <AsyncSelect
            isMulti 
            value={assignedTo}
            defaultOptions={options}
            loadOptions={promiseOptions}
            onChange={handelAssignedTo} 
            formatOptionLabel={formatOptionLabel}
            onInputChange={handleInputChange} // Handle input value changes with debounce
            />
          )
      }


      export function SelectTemplates({templates , handelChange }){
        function covertData(template){
          return templates?.length > 0 ? template?.slice(0 , 5)?.map((i) => ({
            value: i?.id,
            label: i?.name
          })) : [];
        }
      
        const options = covertData(templates)
      
        const formatOptionLabel = ({ label }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
          </div>
        );
        
        
        const promiseOptions = async (inputValue) => {
          if (!inputValue) {
            return
          }
          const data = []
          templates.length > 0 && templates.map((e) => {
              if (e?.name.toLowerCase()?.includes(inputValue?.toLowerCase())) {
              data.push(e)
            }
          })
          return covertData(data)
        };
      
        const debounce = (fn, delay) => {
          let timer;
          return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
              fn.apply(this, args);
            }, delay);
          };
        };
      
        // Function to handle input value changes and call loadOptions
        const handleInputChange = debounce((newValue) => {
          promiseOptions(newValue);
        }, 500); // Adjust the delay (in milliseconds) to suit your needs
        return (
        <AsyncSelect
            isMulti
            options={templates}
            defaultOptions={options}
            onChange={(e)=> handelChange(e)}
            formatOptionLabel={formatOptionLabel}
            onInputChange={handleInputChange} // Handle input value changes with debounce
            loadOptions={promiseOptions}
      />
      )
      }


      export function SelectContractors({contractors , handelChange }){
        function covertData(template){
          return contractors?.length > 0 ? template?.slice(0 , 5)?.map((i) => ({
            value: i?.id,
            label: `${i?.first_name} ${i?.last_name}`
          })) : [];
        }
      
        const options = covertData(contractors)
      
        const formatOptionLabel = ({ label }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
          </div>
        );
        
        
        const promiseOptions = async (inputValue) => {
          if (!inputValue) {
            return
          }
          const data = []
          contractors.length > 0 && contractors.map((e) => {
              if (e?.first_name.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
              e?.last_name.toLowerCase()?.includes(inputValue?.toLowerCase()) ) {
              data.push(e)
            }
          })
          return covertData(data)
        };
      
        const debounce = (fn, delay) => {
          let timer;
          return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
              fn.apply(this, args);
            }, delay);
          };
        };
      
        // Function to handle input value changes and call loadOptions
        const handleInputChange = debounce((newValue) => {
          promiseOptions(newValue);
        }, 500); // Adjust the delay (in milliseconds) to suit your needs
        return (
        <AsyncSelect
            isMulti={false}
            options={contractors}
            defaultOptions={options}
            onChange={(e)=> handelChange(e)}
            formatOptionLabel={formatOptionLabel}
            onInputChange={handleInputChange} // Handle input value changes with debounce
            loadOptions={promiseOptions}
      />
      )
      }
      export function SelectAssignedTo({teams , handelChange, assignedTo }){
        function covertData(teams){
          return teams?.length > 0 ? teams?.slice(0 , 5)?.map((i) => ({
            value: i?.id,
            label: i?.first_name,
            image: i?.image
          })) : [];
        }
        const options = covertData(teams)
      
        const formatOptionLabel = ({ label , image}) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={image} alt={label} style={{ width: '32px', height: '32px', marginRight: '8px', borderRadius: "50%" }} />
            <span>{label}</span>
          </div>
        );
        
        
        const promiseOptions = async (inputValue) => {
          if (!inputValue) {
            return
          }
          const data = []
          teams.length > 0 && teams.map((e) => {
              if (e?.first_name.toLowerCase()?.includes(inputValue?.toLowerCase())
              || e?.last_name.toLowerCase()?.includes(inputValue?.toLowerCase())) {
              data.push(e)
            }
          })
          return covertData(data)
        };
      
        const debounce = (fn, delay) => {
          let timer;
          return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
              fn.apply(this, args);
            }, delay);
          };
        };
      
        // Function to handle input value changes and call loadOptions
        const handleInputChange = debounce((newValue) => {
          promiseOptions(newValue);
        }, 500); // Adjust the delay (in milliseconds) to suit your needs
        return (
        <AsyncSelect
            isMulti
            value={assignedTo}
            options={teams}
            defaultOptions={options}
            onChange={(e)=> handelChange(e)}
            formatOptionLabel={formatOptionLabel}
            onInputChange={handleInputChange} // Handle input value changes with debounce
            loadOptions={promiseOptions}
      />
      )
      }



      export function SelectEptws({eptws , handelChange }){
        function covertData(eptws){
          return eptws?.length > 0 ? eptws?.slice(0 , 5)?.map((i) => ({
            value: i?.id,
            label: i?.title
          })) : [];
        }
      
        const options = covertData(eptws)
      
        const formatOptionLabel = ({ label,value }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{value}</span>
          </div>
        );
        
        
        // const promiseOptions = async (inputValue) => {
        //   if (!inputValue) {
        //     return
        //   }
        //   const data = []
        //   eptws.length > 0 && eptws.map((e) => {
        //       if (e?.id.toLowerCase()?.includes(inputValue?.toLowerCase())) {
        //       data.push(e)
        //     }
        //   })
        //   return covertData(data)
        // };
        const promiseOptions = async (inputValue) => {
          if (!inputValue) {
            return [];
          }
        
          const data = eptws.filter((e) => {
            // Assuming e.id is a number, convert it to a string for comparison
            const idString = e.id.toString();
        
            // Check if the id contains the inputValue
            return idString.includes(inputValue.toString());
          });
        
          return covertData(data);
        };
      
        const debounce = (fn, delay) => {
          let timer;
          return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
              fn.apply(this, args);
            }, delay);
          };
        };
      
        // Function to handle input value changes and call loadOptions
        const handleInputChange = debounce((newValue) => {
          promiseOptions(newValue);
        }, 500); // Adjust the delay (in milliseconds) to suit your needs
        return (
        <AsyncSelect
            isMulti={false}
            options={eptws}
            defaultOptions={options}
            onChange={(e)=> handelChange(e)}
            formatOptionLabel={formatOptionLabel}
            onInputChange={handleInputChange} // Handle input value changes with debounce
            loadOptions={promiseOptions}
      />
      )
      }


      export function SelectProject({projects , handelChange , projectInfo }){
        function covertData(template){
          return projects?.length > 0 ? template?.slice(0 , 5)?.map((i) => ({
            value: i?.id,
            label: `${i?.name}`
          })) : [];
        }
      
        const options = covertData(projects)
      
        const formatOptionLabel = ({ label }) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{label}</span>
          </div>
        );
        
        
        const promiseOptions = async (inputValue) => {
          if (!inputValue) {
            return
          }
          const data = []
          projects.length > 0 && projects.map((e) => {
              if (e?.name.toLowerCase()?.includes(inputValue?.toLowerCase()) ) {
              data.push(e)
            }
          })
          return covertData(data)
        };
      
        const debounce = (fn, delay) => {
          let timer;
          return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
              fn.apply(this, args);
            }, delay);
          };
        };
      
        // Function to handle input value changes and call loadOptions
        const handleInputChange = debounce((newValue) => {
          promiseOptions(newValue);
        }, 500); // Adjust the delay (in milliseconds) to suit your needs
        return (
        <AsyncSelect
            isMulti={false}
            options={projects}
            defaultOptions={options}
            value={projectInfo}
            onChange={(e)=> handelChange(e)}
            formatOptionLabel={formatOptionLabel}
            onInputChange={handleInputChange} // Handle input value changes with debounce
            loadOptions={promiseOptions}
      />
      )
      }
  
  
  
  