import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getmemberPositionByAuthentication = async () => {
  const res = await http.get(
    `${BASE_URL}/position/member/`
  );
  return assert(
    res,
    res.data,
    "Retrieval of memberPosition by its company ID failed",
    res
  );
};

const getmemberPositionById = async (memberPositionId) => {
  const res = await http.get(
    `${BASE_URL}/position/member/${memberPositionId}/`
  );

  return assert(
    res,
    res.data,
    "Retrieval of memberPosition by its ID failed",
    res
  );
};

const creatememberPosition = async (data) => {
  const res = await http.post(
    `${BASE_URL}/position/member/`,
    data
  );

  return assert(res, res.data, "Creation of memberPosition failed", res);
};

const updatememberPosition = async (data) => {
  const res = await http.put(
    `${BASE_URL}/position/member/${data.id}/`,
    data
  );
  return assert(res, res.data, "Update of memberPosition failed", res);
};

const deletememberPosition = async (memberPositionId) => {
  const res = await http.delete(
    `${BASE_URL}/position/member/${memberPositionId}/`
  );
  return assert(res, memberPositionId, "Deletion of memberPosition failed", res);
};

const memberPositionService = {
  getmemberPositionByAuthentication,
  getmemberPositionById,
  creatememberPosition,
  updatememberPosition,
  deletememberPosition,
};

export default memberPositionService;
