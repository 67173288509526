import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import foxSafety from '../../../assets/img/assessment.jpg'
import sessionCreated from '../../../assets/img/sessionCreatedSuccess.jpg'
import Grid from '@mui/material/Grid'
import { useDispatch, useSelector } from 'react-redux'
import {
  postInductionSession,
  setQuizName,
  updateInductionSession
} from '../../../redux/features/session/sessionSlice'
import SafetyVideoForm from '../SafetyVideoForm/SafetyVideoForm'
import InductionQuizForm from '../InductionQuizForm/InductionQuizForm'
import InductionManagementReview from '../InductionManagementReview/InductionManagementReview'

const steps = ['Create Induction Video', 'Create Quiz', 'Review Induction']
const InductionModalBody = () => {
  const {
    session_id,
    sessions,
    session,
    quizName,
    video_details,
    sesstionTitle,
    setQuizDetails,
    sessionViewOnlyModal
  } = useSelector(state => state.session)

  const dispatch = useDispatch()

  const backButtonStyle = {
    backgroundColor: '#f63854',
    color: 'white',
    '&:hover': {
      backgroundColor: '#f63854'
    }
  }

  const disableButton = {
    backgroundColor: 'White',
    color: 'Black'
  }

  const history = useHistory()
  const [inputList, setInputList] = React.useState([{ name: '' }])
  const [customSection, setCustomSection] = useState([
    { title: '', feilds: [] }
  ])
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set())

  useEffect(() => {
    if (sessionViewOnlyModal) {
      setActiveStep(2)
    } else {
      setActiveStep(0)
    }
  }, [sessionViewOnlyModal])
  const isStepOptional = step => {
    return step === 1
  }

  const isStepSkipped = step => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  function reformateQuiz () {
    const questions = []
    customSection?.forEach((ques, index) => {
      const questionList = {
        question: '',
        answer1: '',
        answer2: '',
        answer3: '',
        answer4: '',
        correct_answer: 0
      }
      let corrected_answer = 0
      ques.feilds.forEach((ans, i) => {
        if (ans.type === 'Right') corrected_answer = i + 1
      })
      questionList.question = customSection[index]?.title
      questionList.answer1 = customSection[index]?.feilds[0]?.name
      questionList.answer2 = customSection[index]?.feilds[1]?.name
      questionList.answer3 = customSection[index]?.feilds[2]?.name
      questionList.answer4 = customSection[index]?.feilds[3]?.name
      questionList.correct_answer = corrected_answer
      questions.push(questionList)
    })

    const quiz = {
      title: quizName,
      questions: questions
    }
    return quiz
  }

  const handleSubmit = () => {
    const quizData = reformateQuiz()
    const inductionData = {
      title: sesstionTitle,
      video: {
        link: video_details?.link,
        title: video_details?.title,
        quiz: quizData
      }
    }
    try {
      if (session?.id) {
        dispatch(
          updateInductionSession({ id: session.id, data: inductionData })
        )
      } else {
        dispatch(postInductionSession(inductionData))
      }
    } catch (err) {
      // console.log('the error ',err)
    }
    setActiveStep(3)
    setCustomSection([{ title: '', feilds: [] }])
  }
  useEffect(() => {
    // const template = sessions.find(item => item?.id === session_id)
    // const sections = [];
    const sections = session?.video?.quizes[0]?.questions || []
    if (sections.length === 0) return
    const newArray = []
    for (const sectionNames of sections) {
      const section = {
        title: sectionNames.question,
        feilds: [
          {
            name: sectionNames.answer1,
            type: sectionNames.correct_answer === 1 ? 'Right' : 'Wrong'
          },
          {
            name: sectionNames.answer2,
            type: sectionNames.correct_answer === 2 ? 'Right' : 'Wrong'
          },
          {
            name: sectionNames.answer3,
            type: sectionNames.correct_answer === 3 ? 'Right' : 'Wrong'
          },
          {
            name: sectionNames.answer4,
            type: sectionNames.correct_answer === 4 ? 'Right' : 'Wrong'
          }
        ]
      }
      newArray.push(section)
    }
    console.log("newArray" , newArray);
// Loop through the array and remove objects inside 'fields' without a name
const filteredData = newArray.map(section => ({
  ...section,
  feilds: section.feilds.filter(field => field.name !== ''),
}));
    setCustomSection(filteredData)
    dispatch(setQuizName(session?.video?.quizes[0]?.title))
  }, [session])


  return (
    <Box
      sx={{
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center'
      }}
    >
      <section>
        <Box
          sx={{
            bgcolor: 'white',
            borderRadius: '20px',
            flexGrow: 1,
            padding: 3
          }}
        >
          {/* <Button
            style={backButtonStyle}
            onClick={e => history.push('/safety')}
          >
            Back
          </Button> */}
          <Grid container spacing={6}>
            <Grid item xs={12} md={4}>
              <div>
                <img src={foxSafety} alt='' />
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <div>
                <h2>Welcome to safety induction session creation page</h2>
                <p>
                  Use FOX safety induction sessions can be created in few steps.
                </p>
                {/* <Button
                  sx={{ mt: 4 }}
                  style={backButtonStyle}
                  onClick={e => history.push('/safetyDashBoard')}
                >
                  Safety Dashboard
                </Button> */}
              </div>
            </Grid>
          </Grid>
          <Box sx={{ width: '100%', mt: 5 }}>
            <Stepper activeStep={activeStep} sx={{ mb: 10 }}>
              {steps.map((label, index) => {
                const stepProps = {}
                const labelProps = { sx: { color: 'red' } }
                //if we want to skip a step the following can be used
                // if (isStepOptional(index)) {
                //     labelProps.optional = (
                //         <Typography variant="caption">Optional</Typography>
                //     );
                // }
                // if (isStepSkipped(index)) {
                //     stepProps.completed = false;
                // }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel sx={{ color: 'red' }} {...labelProps}>
                      {label}
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            {activeStep === 0 ? (
              <React.Fragment>
                <SafetyVideoForm />
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {/* {isStepOptional(activeStep) && (
                                          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                              Skip
                                          </Button>
                                      )} */}
                  <Button
                    style={
                      video_details?.link ? backButtonStyle : disableButton
                    }
                    disabled={!video_details?.link}
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                  {/* <Button style={backButtonStyle} onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button> */}
                </Box>
              </React.Fragment>
            ) : activeStep === 1 ? (
              <React.Fragment>
                <InductionQuizForm
                  customSection={customSection}
                  setCustomSection={setCustomSection}
                />
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {/* {isStepOptional(activeStep) && (
                                              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                  Skip
                                              </Button>
                                          )} */}

                  <Button
                    style={backButtonStyle}
                    onClick={() => {
                      handleNext()
                      reformateQuiz()
                    }}
                  >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </Box>
              </React.Fragment>
            ) : activeStep === steps.length ? (
              <React.Fragment>
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Typography
                    variant='h6'
                    sx={{ fontWeight: 'bold', mt: 4, mb: 1 }}
                  >
                    Done! your induction session is available
                  </Typography>
                  <img
                    height={10}
                    src={sessionCreated}
                    alt='session created image'
                    style={{ height: '20em', marginBottom: '10px' }}
                  />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button style={backButtonStyle} onClick={handleReset}>
                    Create new Induction
                  </Button>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <InductionManagementReview customSection={customSection} />
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button
                    color='inherit'
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  {/* {isStepOptional(activeStep) && (
                                                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                      Skip
                                                  </Button>
                                              )} */}

                  <Button
                    style={sesstionTitle ? backButtonStyle : disableButton}
                    disabled={!sesstionTitle || sessionViewOnlyModal}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </Box>
        </Box>
      </section>
    </Box>
  )
}

export default InductionModalBody
