import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    Button,
    Modal,
    TextField,
    Paper,
    Backdrop,
    Fade,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { deleteSafetyVideo, getSafetyVideo } from '../../redux/features/safety/safetySlice';
function DeleteVideoModal({ isOpen, handleOpen, handleClose, id, safetyLink, videoName }) {

    const dispatch = useDispatch();



    const backButtonStyle = {
        backgroundColor: '#f63854',
        color: 'white',
        '&:hover': {
            backgroundColor: '#f63854',
        },
    };


    const handleDelete = () => {
        dispatch(deleteSafetyVideo(id))
        dispatch(getSafetyVideo())
        handleClose();
    };


    return (
        <div>


            <Modal
                open={isOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={isOpen}>
                    <Paper
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            padding: '30px',
                        }}
                    >
                        <Typography variant="h5" padding={3} fullWidth>
                            Are you sure you want to delete the video ?
                        </Typography>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleDelete}
                            style={backButtonStyle}
                        >
                            Delete
                        </Button>
                    </Paper>
                </Fade>
            </Modal>

        </div>
    );
}

export default DeleteVideoModal;
