import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import foxLogo from '../../assets/img/FOX_Logo2.png.webp';
import'./userNewPassword.scss'
import { registerNewUserPassword,resetAuth } from '../../redux/features/auth/authSilice';
export default function UserNewPassword() {
  const history = useHistory();
  const { uidb, token } = useParams();
  const { isLoading, isError, isSuccess, isPasswordCreatedSuccess } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [newPassword, setnewPassword] = useState('');
  const [firstname, setfirstName] = useState('');
  const [lastname, setLastName] = useState('');
  // const { showSpinner } = useApiCallStatusNotificationHandler({
  //   isSuccess,
  //   isLoading,
  //   isError,
  //   successCallBack: () =>
  //     setTimeout(() => {
  //       history.push('/login');
  //     }, 3500),
  // });
  const submitConfirmPass = e => {
    e.preventDefault();
    const info = { new_password: newPassword, first_name: firstname, last_name: lastname };
    const userInfo = {
      uid: uidb,
      token: token,
      ...info
    };
    dispatch(registerNewUserPassword(userInfo));
  };
  function displayToast () {
    if (isPasswordCreatedSuccess) {
        toast.success('Your password Created successfully', {
            toastId: 'passwordCreatedSuccess',
        });
      setTimeout(() => {
      history.push('/login');
      }, 3000)
    }
    if (isError) {
        toast.error('Something went wrong', {
            toastId: 'passwordCreatedError',
        });
    }
  }
  useEffect(() => {
    dispatch(resetAuth());
  }, [isError, isSuccess, isLoading, dispatch, history ]);
  return (
    <div>
      {/* {showSpinner ? <div id="loader" /> : null} */}
      {displayToast()}
      <div className="row d-flex justify-content-center mt-4">
        <div className="col-lg-7 col-sm-12 col-md-10 ">
          <div className="login-logo-check-email">
            <img src={foxLogo} alt="fox logo" height="130" width="250" />
          </div>

          {/* <p className="mb-2 text-success">{{msg}}</p> */}

          <form action="{{action}}" method="POST" onSubmit={submitConfirmPass}>
            <div className="card col-lg-12 col-sm-12 col-xs-12 col-md-12 reset-pass-card">
              <div className="card-header medium-title" style={{ borderBottom: 'none', backgroundColor:'white' }}>
                <b>Create new password</b>
              </div>
              <div className="card-body">
                <div className="row">
                  <span> </span>
                  <label className="font-weight-normal" htmlFor="newpassword">
                    New Password{' '}
                    <label className="font-danger m-0" htmlFor="newpassword">
                      *
                    </label>
                  </label>
                  <input
                    name="newpassword"
                    type="password"
                    className="form-control mb-2"
                    placeholder="New password"
                    required
                    id="newpassword"
                    onChange={e => setnewPassword(e.target.value)}
                  />
              <label className="font-weight-normal" htmlFor="firstName">
                    First Name{' '}
                    <label className="font-danger m-0" htmlFor="firstName">
                      *
                    </label>
                  </label>
                  <input
                    name="firstName"
                    type="text"
                    className="form-control mb-2"
                    required
                    placeholder="First Name"
                    id="firstName"
                    onChange={e => setfirstName(e.target.value)}
                  />
                  <span
                    className="font-danger col-lg-12 col-sm-12 ml-0"
                    id="confirm_password_error"
                  >
                    {' '}
                  </span>

                  <label className="font-weight-normal" htmlFor="lastName">
                    Last Name{' '}
                    <label className="font-danger m-0" htmlFor="lastName">
                      *
                    </label>
                  </label>
                  <input
                    name="last name"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Last Name"
                    id="lastName"
                    onChange={e => setLastName(e.target.value)}
                  />
                  <span className="font-danger col-lg-12 col-sm-12 ml-0" id="otp_error">
                    {' '}
                  </span>
              </div>
             </div> 
            </div>

            
              <div className="float-right">
                <button className="btn primary-btn" type="submit">
                  Save
                </button>
                {/* <button  className="btn secondary-btn ml-2" type="button">Back</button> */}
              </div>
            
          </form>
        </div>
      </div>
      <div className="text-center my-3" style={{ color: '#9B9B9B !important' }}>
        {' '}
        © 2023 Infinity Wave Sdn. Bhd.
      </div>
      
    </div>
  )
}
