import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { smartTryCatch } from '../../../utils';
import templateService from './templateService';

const initState = {

    templates: [],
    template: {},


    //template fields
    step : 0,
    templateName : "",
    documentNumber:"",
    revisionNumber:"",
    revisionDate:null,
    isErr : false,
    templateNameError : false,
    templateSearchTerm:"",
    inputList :[
        {name : ""}
      ],
      // modal
      templateDetailModal:false,
      tepmlateModalCreate: false,
      tepmlateModalDelete: false,
      tepmlateModalUpdate: false,

  // success
  isTepmlateGetSuccess: false,
  isTepmlateCreateSuccess: false,
  isTepmlateDeleteSuccess: false,
  isTepmlateUpdateSuccess: false,

  // loading
  isTepmlateGetLoading: false,
  isTepmlateDeleteLoading: false,
  isTepmlateUpdateLoading: false,
  isTepmlateCreateLoading: false,

  // error
  isTepmlateGetError: false,
  isTepmlateCreateError: false,
  isTepmlateDeleteError: false,
  isTepmlateUpdateError: false,
}


export const getTemplateById = createAsyncThunk('getTemplateById', async (data, thunkAPI) => {
    const get = await smartTryCatch(templateService.getTemplateById, data, thunkAPI);
    return get;
});
export const getTemplates = createAsyncThunk('getTemplates', async (data, thunkAPI) => {
    const get = await smartTryCatch(templateService.getTemplates, data, thunkAPI);
    return get;
});
export const templateDelete = createAsyncThunk('templateDelete', async (data, thunkAPI) => {
    const response = await smartTryCatch(templateService.templateDelete, data, thunkAPI);
    return response;
});
export const createTemplate = createAsyncThunk('createTemplate', async (data, thunkAPI) => {
    const response = await smartTryCatch(templateService.createTemplate, data, thunkAPI);
    return response;
});
export const updateTemplate = createAsyncThunk('updateTemplate', async (data, thunkAPI) => {
    const response = await smartTryCatch(templateService.updateTemplate, data, thunkAPI);
    return response;
});

export const templateSlice = createSlice({
    name: 'template',
    initialState: initState,
    reducers: {
        resetTemplate: (state) => {
            state.step = 0
            state.isErr = false
            state.templateName  = ""
            state.documentNumber = ""
            state.revisionNumber=""
            state.revisionDate = null
            state.templateNameError  = false
            state.inputList  = [
                {name : ""}
            ]
            state.template = {}
            state.templateDetailModal = false
            state.tepmlateModalDelete = false
        },

        opentemplateDetailModal:state =>{
            state.templateDetailModal=true
        },
        closetemplateDetailModal:state =>{
            state.templateDetailModal=false
        },
        opentemplateDeleteModal:state =>{
            state.tepmlateModalDelete=true
        },
        closetemplateDeleteModal:state =>{
            state.tepmlateModalDelete=false
        },
        setTemplate:(state,action)=>{
            state.template = action.payload
           },
        setStep:(state,action)=>{
         state.step = action.payload
        },
        setTemplateName:(state,action)=>{
         state.templateName = action.payload
        },
        setRevisionNumber:(state,action)=>{
         state.revisionNumber = action.payload
        },
        setRevisionDate:(state,action)=>{
         state.revisionDate = action.payload
        },
        setDocumentNumber:(state,action)=>{
         state.documentNumber = action.payload
        },
        setTemplateNameError:(state,action)=>{
         state.templateNameError = action.payload
        },
        setTemplateSearchTerm :(state,action)=>{
            state.templateSearchTerm = action.payload
          },
        setIsErr:(state,action)=>{
         state.isErr = action.payload
        },
        reset: state => {
            state.isTemplateCreateError = false;
            state.isTemplateCreateSuccess = false;
            state.isTemplateCreateLoading = false;
            state.isTemplateGetError = false;
            state.isTemplateGetSuccess = false;
            state.isTemplateGetLoading = false;
            state.isTemplateDeleteError = false;
            state.isTemplateDeleteSuccess = false;
            state.isTemplateDeleteLoading = false;
            state.isTemplateUpdateError = false;
            state.isTemplateUpdateSuccess = false;
            state.isTemplateUpdateLoading = false;
            state.templateModalCreate = false;
            state.tepmlateModalDelete = false;
            state.templateModalUpdate = false;
          },

    },
    extraReducers: (builder) => {
        builder
            .addCase(getTemplateById.pending, (state) => {
                state.isTemplateGetLoading = true;
                state.isTemplateGetSuccess = false;
                state.isTemplateGetError = false;
            })
            .addCase(getTemplateById.fulfilled, (state, action) => {
                state.isTemplateGetLoading = false;
                state.isTemplateGetSuccess = true;
                state.isTemplateGetError = false;
                state.template = action.payload
            })
            .addCase(getTemplateById.rejected, (state) => {
                state.isTemplateGetLoading = false;
                state.isTemplateGetSuccess = false;
                state.isTemplateGetError = true;
            })
            .addCase(getTemplates.pending, (state) => {
                state.isTemplateGetLoading = true;
                state.isTemplateGetSuccess = false;
                state.isTemplateGetError = false;
            })
            .addCase(getTemplates.fulfilled, (state, action) => {
                state.isTemplateGetLoading = false;
                state.isTemplateGetSuccess = true;
                state.isTemplateGetError = false;
                state.templates = action.payload
            })
            .addCase(getTemplates.rejected, (state) => {
                state.isTemplateGetLoading = false;
                state.isTemplateGetSuccess = false;
                state.isTemplateGetError = true;
            })
            .addCase(templateDelete.pending, (state) => {
                state.isTemplateDeleteLoading = true;
                state.isTemplateDeleteSuccess = false;
                state.isTemplateDeleteError = false;
            })
            .addCase(templateDelete.fulfilled, (state, action) => {
                state.isTemplateDeleteLoading = false;
                state.isTemplateDeleteSuccess = true;
                state.isTemplateDeleteError = false;
                state.templates = state.templates.filter((i) => i.id !== action.payload)
            })
            .addCase(templateDelete.rejected, (state) => {
                state.isTemplateDeleteLoading = false;
                state.isTemplateDeleteSuccess = false;
                state.isTemplateDeleteError = true;
            })
            .addCase(createTemplate.pending, (state) => {
                state.isTemplateCreateLoading = true;
                state.isTemplateCreateSuccess = false;
                state.isTemplateCreateError = false;
            })
            .addCase(createTemplate.fulfilled, (state, action) => {
                state.isTemplateCreateLoading = false;
                state.isTemplateCreateSuccess = true;
                state.isTemplateCreateError = false;
                state.templates.push(action.payload);
            })
            .addCase(createTemplate.rejected, (state) => {
                state.isTemplateCreateLoading = false;
                state.isTemplateCreateSuccess = false;
                state.isTemplateCreateError = true;
            })
            .addCase(updateTemplate.pending, (state) => {
                state.isTemplateUpdateLoading = true;
                state.isTemplateUpdateSuccess = false;
                state.isTemplateUpdateError = false;
            })
            .addCase(updateTemplate.fulfilled, (state, action) => {
                state.isTemplateUpdateLoading = false;
                state.isTemplateUpdateSuccess = true;
                state.isTemplateUpdateError = false;
                const templateIndex = state.templates.findIndex(t => t.id === action.payload.id);
                if (templateIndex !== -1) {
                    state.templates[templateIndex] = { ...state.templates[templateIndex], ...action.payload };
                }

            })
            .addCase(updateTemplate.rejected, (state) => {
                state.isTemplateUpdateLoading = false;
                state.isTemplateUpdateSuccess = false;
                state.isTemplateUpdateError = true;
            })


    }
});

export const {
opentemplateDetailModal,
closetemplateDetailModal,
settemplate,
reset,
resetTemplate,
setStep,
setTemplateName,
setTemplateNameError,
setIsErr,
setTemplate,
setRevisionNumber,
setRevisionDate,
setDocumentNumber,
setTemplateSearchTerm,
opentemplateDeleteModal,
closetemplateDeleteModal
}=templateSlice.actions;

export default templateSlice.reducer;