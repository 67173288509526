/* eslint-disable no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import React from 'react';
import Chart from 'react-apexcharts';

const TrendWorkChart = (props) => {
  
  const series = [
    {
      name: 'Task completed',
      data: [28, 29, 33, 36, 32, 32, 33],
    },
    {
      name: 'Tasks created',
      data: [12, 11, 14, 18, 17, 13, 13],
    },
  ];
  const options = {
    colors: ['#14cc52', '#D9314A'],
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    markers: {
      size: 5,
    },
    title: {
      text: 'No. of Tasks',
      align: 'center',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: ['1-Feb-23','1-Mar-23','1-Apr-23', '1-May-23', '1-Jun-23', '1-Jul-23', '1-Aug-23'], // takes an array which will be repeated on columns
      title: {
        text: 'Week',
      },
    },
    yaxis: {
      title: {
        text: 'Tasks',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <div id="chart">
      <Chart options={options} series={series} type="line" height={350} />
    </div>
  );
};

export default TrendWorkChart;
