import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, date, mobile, consented, status) {
    return { name, date, mobile, consented, status };
}

export default function InductionSuperVisorTable({ filledBy }) {
    const boldTextStyle = { fontWeight: 'bold' };
    if (!filledBy || !filledBy.submitted_by) {
        return null; 
    }
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={boldTextStyle}>Full Name</TableCell>
                        <TableCell style={boldTextStyle} align="right">Inducted On</TableCell>
                        <TableCell style={boldTextStyle} align="right">Email</TableCell>
                        <TableCell style={boldTextStyle} align="right">Mobile Number</TableCell>
                        <TableCell style={boldTextStyle} align="right">IC</TableCell>
                        <TableCell style={boldTextStyle} align="right">Role</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filledBy.submitted_by.map((row) => (
                        <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{new Date(row.created_at).toLocaleDateString()}</TableCell>
                            <TableCell align="right">{row.email}</TableCell>
                            <TableCell align="right">{row.phone_number}</TableCell>
                            <TableCell align="right">{row.id_number}</TableCell>
                            <TableCell align="right">{row.role}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
