import React, { useEffect, useRef, useState } from 'react';
import { w3cwebsocket as W3cwebsocket} from 'websocket';
import moment from 'moment';
// import {toast} from "react-toastify";
import { Link, NavLink , useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faGear } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import styles from './layout.scss';
import { tabs } from '../../utils/navData';
import profilePicture from '../../assets/img/profile.png';
import altCompanyPic from '../../assets/img/FOX_Logo2.png.webp';
import { logout } from '../../redux/features/auth/authSilice'; // import your logout action
import { getUserProfile } from '../../redux/features/user/userSlice';
import { getCompanyProfile } from '../../redux/features/company/companySlice';
import { getNotificationSettings, getNotificationUuid, setNotificationSettings, setNotifications, updateNotificationSettings } from '../../redux/features/notification/notificationSlice';
import LogoDef from '../../assets/img/FOX_Logo2.png.webp';
// import Loader from '../loader/loader';
import { FRONT_URL } from '../..//utils/axios';
import PopUpEditModal from '../popUpModals/popUpEditModal';
// import { changeFavicon } from '../../utils/changeFavicon';
// import { getProjectById } from '../../redux/features/project/projectSclice';




export default function Layout({ children }) {

  const [open, setOpen] = useState(true);
  const { user } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  const [selectedIndex,setSelectedIndex] = useState(-1);
  const [logo , setLogo]= useState(profilePicture)
  const [tabsForPermisions , setTabsForPermisions]= useState([])
  const [isShowNot, setIsShowNot] = useState(false);
  const [isShowOnlyUnread, setIsShowOnlyUnread] = useState(JSON.parse(localStorage.getItem('showOnlyUnRead')) || false);
  const [isNotSettingsModalUpdate, setIsNotSettingsModalUpdate] = useState(false);
  const [isNotComming, setIsNotComming] = useState(false);
  const { uuid , notifications , notificationSettings , isNotificationSettingUpdateError , isNotificationSettingUpdateSuccess} = useSelector(state => state.notification);
  const menuRef = useRef(); 
  const dispatch = useDispatch();
  const history = useHistory();
  const toggleOpen = () => {
    setOpen(!open);
  };
  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    dispatch(logout()).then(() => {
      // history.push('/login');
      window.location.reload();
      window.location.href = FRONT_URL;
    });
  };


 

  useEffect(() => {
    dispatch(getUserProfile());
    dispatch(getCompanyProfile())
  }, []);

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = company?.logo ? company?.logo : LogoDef;
    document.head.appendChild(favicon);
    document.title = company?.name ? company?.name : "FOX Project";
  }, [company]);

  const token = localStorage.getItem('accessToken');
  if (!token) {
    history.push('/login');
  }

  // changeFavicon();

  useEffect(() => {
    const handler = (e) => {
      if (!menuRef.current.contains(e.target) && window.innerWidth < 992) {
        setOpen(false);
      }
    };  
    document.addEventListener('mousedown', handler);
    return () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (user?.role === "Administrator") {
        setTabsForPermisions(tabs)
      }
      else if (user?.role === "Member") {
        setTabsForPermisions(
          tabs?.filter(tab => tab.id !== 5 && tab.id !== 6)
        )
      }else if (user?.role === "Manager") {
        setTabsForPermisions(
          tabs?.filter(tab => tab.id !== 6)
        )
      }
    }
   }, [user])

   
  const handleTabClick = (index) => {
    setSelectedIndex(index);
  };

// Notification part
const myRef = useRef();
useEffect(() => {
  dispatch(getNotificationUuid())
  const notifiData =  JSON.parse(localStorage.getItem("notification"))  || []
  if (isShowOnlyUnread) {
    const notShowOnlyUnread = notifiData?.filter(item => {
      return  item.read === false 
    })
    dispatch(setNotifications(notShowOnlyUnread))
  }else{
   dispatch(setNotifications(notifiData))
  }
  dispatch(getNotificationSettings())
}, []);

let socket;
useEffect(() => {
  if (uuid) {
     socket = new W3cwebsocket(`wss://project.ifmtnbes.com.my/ws/notification/?uuid=${uuid}`);

    socket.onopen = () => {
      // console.log('WebSocket connection opened.');
    };

    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      let notifi= JSON.parse(localStorage.getItem("notification"))  || [];
      const currentDate = moment() 
      const futureDate = currentDate.add(3, 'days').format("YYYY-MM-DDTHH:mm");
      if (notifi.length > 0) {
        notifi =  notifi.filter(item =>  (moment().diff(moment(item?.date).format("YYYY-MM-DDTHH:mm:ss") , "seconds")) < 0);
      }
      // console.log("Socket data",data);
     
     // Modify the properties of the payload object directly
      if (data?.payload) {
        data.payload.date = futureDate;
        notifi.unshift(data.payload);
        localStorage.setItem("notification", JSON.stringify(notifi));
        dispatch(setNotifications(notifi));
        setIsNotComming(true);
      }
      socket.send(JSON.stringify({"type": "delete.notifications","data":{}})); // Send the message
    };
  }
  
    // Clean up the WebSocket connection when the component unmounts
    return () => {
      if (socket) {
        socket.close();
      }
    };
}, [uuid]);


useEffect(() => {
  if (isNotComming && isShowOnlyUnread) {
    const notifi= JSON.parse(localStorage.getItem("notification"))  || [];
    const notShowOnlyUnread = notifi?.filter(item => {
    return  item.read === false 
  })
  dispatch(setNotifications(notShowOnlyUnread))
  setIsNotComming(false)
  }
}, [isNotComming , isShowOnlyUnread])

const handelSettingsChange = (e)=>{
  dispatch(setNotificationSettings({...notificationSettings , [e.target.name] : e.target.checked}))
}

const handleEditSave = () => {
setIsNotSettingsModalUpdate(false)
dispatch(updateNotificationSettings(notificationSettings));
}
const handelOpenNotification = async (type, uuidd, id) => {
  const parsedId = parseInt(id, 10); // Convert 'id' to an integer using base 10

  // console.log("ticketId", parsedId);
  setIsShowNot(false);
  modfiyMarked(uuidd, "toRead");

  if (type === "Project" && !isNaN(parsedId)) {
    history.push(`/project/${parsedId}`);
  }else if(type == "Task"&& !isNaN(parsedId)){
    history.push(`/task-neviagtion-table/${parsedId}`)  
  }
};
const handelMarkNotification = (uuidd)=> {
  modfiyMarked(uuidd , "toMark")
}
const handelMarkAll = ()=> {
  const notMarkAll = notifications?.map(item => {
    return {...item , read : true }
  })
  dispatch(setNotifications(notMarkAll))
  localStorage.setItem("notification", JSON.stringify(notMarkAll))
}
const handelShowOnlyUnread = ()=> {
  myRef.current.scrollTo({ top: 0, behavior: 'smooth' });  
  const notShowOnlyUnread = notifications?.filter(item => {
    return  item.read === false 
  })
  dispatch(setNotifications(notShowOnlyUnread))
  setIsShowOnlyUnread(true)
}
const handelShowAll = ()=> {
  myRef.current.scrollTo({ top: 0, behavior: 'smooth' });  
  const notifi= JSON.parse(localStorage.getItem("notification")) || [];
  dispatch(setNotifications(notifi))
  localStorage.setItem("notification", JSON.stringify(notifi))
  setIsShowOnlyUnread(false)
}

const modfiyMarked = (uuidd , type)=>{
  const modNotiArray= []
  const notifi= JSON.parse(localStorage.getItem("notification"))  || [];
  notifi.forEach(item =>{
    if (item?.uuid === uuidd) {
      if (type === "toRead") {
        modNotiArray.push({...item , read: true })
      }
      if (type === "toMark") {
        modNotiArray.push({...item , read: !item.read })
      }
    }else{
      modNotiArray.push(item)
    }
  })
  if (isShowOnlyUnread) {
    const data = [...modNotiArray]
    const modNotiWithOnlyUnread = data.filter(item => item.read  === false)
    dispatch(setNotifications(modNotiWithOnlyUnread))
  }else{
    dispatch(setNotifications(modNotiArray))
  }

  // console.log("modNotiArray", modNotiArray);
  localStorage.setItem("notification", JSON.stringify(modNotiArray))
  
}

// console.log("notifications" , notifications);
// console.log("uuid for Notification ",uuid);
// console.log("notificationSettings",notificationSettings);
  return (
    <div
    className={
      open
        ? 'sidebar-mini layout-fixed sidebar-open'
        : 'sidebar-mini layout-fixed sidebar-closed sidebar-collapse'
    }
      style={{height:'auto'}}
    >
      {/* Start notification Settings */}
      {isNotSettingsModalUpdate && (
      <PopUpEditModal
          handleClose={() =>{
            setIsNotSettingsModalUpdate(false)
            dispatch(getNotificationSettings())
          }}
          showModal={isNotSettingsModalUpdate}
          handleSave={handleEditSave}
          modalTitle="Notification Settings"
          primaryButtonText="Save Changes"
        >
          <table className="table table-borderless">
            <thead>
              <tr>
                <td>{" "}</td>
                <td >Email</td>
                <td >Notification</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Assigned Task</td>
                <td><span > 
          <input type="checkbox" checked ={notificationSettings?.email} 
          onChange={handelSettingsChange} 
          name='email'  className='businessHours-status'/>
          </span></td>
                <td> <span> 
          <input type="checkbox" checked ={notificationSettings?.assigned_task} 
          onChange={handelSettingsChange} 
          name='assigned_task' className='businessHours-status'/>
          </span></td>
              </tr>
              <tr>
                <td>Task Updates</td>
                <td> <span> 
          <input type="checkbox" checked ={notificationSettings?.email} 
          onChange={handelSettingsChange} 
          name='email'  className='businessHours-status'/>
          </span></td>
                <td> <span > 
          <input type="checkbox" checked ={notificationSettings?.task_updates} 
          onChange={handelSettingsChange} 
          name='task_updates'  className='businessHours-status'/>
          </span></td>
              </tr>
            </tbody>
          </table>
          </PopUpEditModal> 
      )}
        {/* End notification Settings */}
      <div className='wrapper background-grey-white'>
      {isShowNot && <div onClick={()=> setIsShowNot(false)} className='not-overlay' />}
        <nav className='main-header navbar navbar-expand navbar-trans sticky-top nav-styles'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <button type="button" className={styles.menuBtn} onClick={toggleOpen}  ref={menuRef}>
                <FontAwesomeIcon className='nav-icon' icon={faBars} />
              </button>
            </li>
          </ul>
          {/* Start notification */}
          <ul className="navbar-nav align-items-center ml-auto">
            <div className="notification" style={{marginRight: "20px", border: "none", outline: "none"}}>
            <button onClick={()=> setIsShowNot(!isShowNot)} type="button" className="icon-button" style={{border: "none", outline: "none"}} >
            <i className="nav-icon far fa-bell font-grey font-danger" />
            <span className="icon-button__badge">
            <p  style={{paddingTop: "2px", fontSize: "10px"}}>{notifications.filter(item=>item.read === false)?.length} </p>
            </span>
            </button>
            <ul className={isShowNot ? "notification-menu notification-menu-active"  : "notification-menu"}>
              <li><h3>Notification</h3></li>
              <li className='not-actions'>
                <button onClick={handelMarkAll} className='red' type='button' 
                disabled = {notifications?.filter(item => {
                  return  item.read === false 
                })?.length === 0}
                  > Mark all as read </button>
                {!isShowOnlyUnread ?  <button onClick={handelShowOnlyUnread} className='red' type='button'> Show only Unread</button> :
                <button onClick={handelShowAll} className='red' type='button'> Show All</button>}
                </li>
                <li ref={myRef} className='notification-items'>
                {notifications?.length === 0 ?  <li>
                  <p style={{ textAlign: 'center' }}>There is no notification</p>
              </li> : 
                notifications?.map((noti,index) =>{
                return <li key={index}>
                <div  className={noti?.read ? "marked-message" : "message"} >
                <p  onClick={()=> handelOpenNotification(noti?.object_type, noti?.uuid,noti?.object_id)} >{noti?.message} </p>
                <div className='date-and-read'>
                  <span>
                  {moment(noti?.created_at).fromNow(true)} ago
                  </span>
                  <div>
                  <button onClick={()=> handelMarkNotification(noti?.uuid)} className='red' type='button'> {noti?.read ?" Mark Unread" : "Mark read"}</button>
                  </div>
                </div>
                </div>
              </li>
              })}
              </li>
              <li onClick={()=> setIsNotSettingsModalUpdate(true)} className='notification-settings'>
                <span>
                <FontAwesomeIcon icon={faGear} /> Notification Settings
                </span>
              </li>
            </ul>
            {/* End notification */}
            
        </div>
            <li className="nav-item ">
              <Link to="/profile/" className="profile-link">
                <div className="icon fa profile-section">
                  <img
                    className="prof_img img-circle"
                    alt="profilePic"
                    height="30"
                    width="30"
                    src={user?.image||logo}
                    id="result_base"
                    title="Change profile picture"
                  />
                </div>
                {user && <span className="profile-name-styles font-grey">{user.first_name}</span>}
              </Link>
            </li>
          </ul>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <button className='nav-link success gcolor' type='button' onClick={handleLogout}>
                <i className='nav-icon' /> Logout
              </button>
            </li>
          </ul>
        </nav>
        <div>
          <aside className="main-sidebar sidebar-light-danger elevation-0">
            <Link to='/company-profile' className='brand-link brand-link-styles'>
              <img
                src={company?.logo? company?.logo : altCompanyPic}
                alt=''
                className='brand-image img-company'
                id='result_company_base'
              />
              <div>
                <span className='brand-text font-weight-strong font-grey company-name-styles'>
                {user?.company_name? user?.company_name : 'FOX Project'}
                </span>
              </div>
            </Link>
            <div className='sidebar h-100'>
              <nav className='h-100'>
                <ul
                  className='nav nav-pills nav-sidebar flex-column'
                  data-widget='treeview'
                  role='menu'
                  data-accordion='false'
                  data-auto-collapse-size='375'
                >
                 
                 {tabsForPermisions?.map(({ id, style, text, icon,path }, index) => {
                      let className = 'nav-link';
                      if (selectedIndex === -1) {
                        className += '';
                      } else if (selectedIndex + 1 === id) {
                        className += ' active';
                      }

                      let modifiedText = text; // Create a new variable to hold the modified value

                      if(text ==='Analytic Report'){
                        modifiedText='Dashboard'
                      }
                      if(text ==='Edit Categories'){
                        modifiedText='Customization'
                      }

                   

                      return (
                        <li key={id} className={`nav-item ${style}`}>
                          <NavLink
                            className={className}
                            id={id}
                            to={path}
                            exact
                            // onClick={() => handleTabClick(index)}
                          >
                            <FontAwesomeIcon className="nav-icon" icon={icon} />
                            <p className="font-grey sb-item">{modifiedText}</p>
                            
                          </NavLink>
                        </li>
                      );
                    })}

                  
                  <li className='nav-item text-center mb-5'>
                    <a
                      href='https://app.startinfinity.com/form/96c5bb22-1c81-4d31-b2cf-469c49fc311b'
                      target='_blank'
                      className='nav-link report-styles '
                      id='trouble'
                      title='Report a bug' rel="noreferrer"
                    >
                      <span className=' gcolor'>Having troubles?</span>
                      <span className='a-color'>Report here</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </aside>
        </div>
        <div className='content-wrapper'>{children}</div>
      </div>
    </div>
  );
}