/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/function-component-definition */
import React from 'react';
import './FilterField.css';

const FilterField = ({staff}) => {
  return (
    <div className="row mb-3">
      <div className="col-4 input-field me-5">
        <label style={{ color: 'red' }}>Date</label>
        <select>
          <option selected value="Monday">
            Today
          </option>
          <option value="Tuesday">Yesterday</option>
          <option disabled>─────────</option>
          <option value="Wednesday">Last 30 days</option>
          <option value="Thursday">Last 14 days</option>
          <option value="Friday">last 28 days</option>
          <option disabled>─────────</option>
          <option value="Saturday">Last 30 days</option>
          <option value="Sunday">Last 90 days</option>
          <option value="Sunday">Last 180 days</option>
          <option value="Sunday">Last 365 days</option>
          <option disabled>─────────</option>
          <option value="Sunday">This Week</option>
          <option value="Sunday">This Month</option>
          <option value="Sunday">This Quarter</option>
          <option value="Sunday">This year</option>
          <option disabled>─────────</option>
          <option value="Sunday">Previous Week</option>
          <option value="Sunday">Previous Month</option>
          <option value="Sunday">Previous Quarter</option>
          <option value="Sunday">Previous Year</option>
        </select>
      </div>
      {/* <div className="col-4 input-field me-5">
        <label style={{ color: 'red' }}>Site</label>
        <select>
          <option selected value="Monday">
            IWSB JB
          </option>
          <option value="Tuesday">IWSB KL</option>
          <option value="Wednesday">IWSB Penang</option>
          <option value="Thursday">UTP</option>
        </select>
      </div>
      <div className="col-4 input-field me-5">
        <label style={{ color: 'red' }}>Location</label>
        <select>
          <option selected value="">
            All
          </option>
          <option value="Monday">Storage Room</option>
          <option value="Tuesday">Electric Room</option>
          <option value="Wednesday">Pantry</option>
          <option value="Thursday">Working space</option>
          <option value="Thursday">Meeting Room</option>
          <option value="Thursday">Washroom Male</option>
          <option value="Thursday">Washroom</option>
          <option value="Thursday">Female</option>
        </select>
      </div> */}
      <div className="col-4 input-field me-5">
        <label style={{ color: 'red' }}>Staff</label>
        <select>
          <option selected value="">
            All
          </option>
          {staff.map((singlePerson)=>{
         return   <option>{singlePerson.first_name}</option>  
          })}
        </select>
      </div>
    </div>
  );
};

export default FilterField;
