import { configureStore } from '@reduxjs/toolkit'
import auth from './features/auth/authSilice'
import department from './features/department/departmentSlice'
import user from './features/user/userSlice'
import company from './features/company/companySlice'
import project from './features/project/projectSclice'
import task from './features/task/taskSclice'
import team from './features/teams/teamSlice'
import memberPosition from './features/memberPosition/memberPositionSlice'
import editCategory from './features/editCategory/editCategorySlice'
import managerPosition from './features/managerPosition/managerPositionSlice'
import template from './features/template/templateSclice'
import eptw from './features/eptw/eptwSlice'
import approver from './features/approver/approverSlice'
import notification from './features/notification/notificationSlice'
import approveReject from './features/approveRejectPtw/approveRejectPtwSlice'
import analytics from './features/analytics/analyticsSlice'
import safety from './features/safety/safetySlice'
import quiz from './features/quiz/quizSlice'
import session from './features/session/sessionSlice'

export default configureStore({
  reducer: {
    auth,
    department,
    user,
    company,
    project,
    task,
    team,
    managerPosition,
    memberPosition,
    editCategory,
    template,
    eptw,
    approver,
    notification,
    approveReject,
    analytics,
    safety,
    quiz,
    session
  }
})
