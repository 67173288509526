// import 'react-tooltip/dist/react-tooltip.css';

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetAuth, changePassword } from '../../redux/features/auth/authSilice';
import { toast } from 'react-toastify';
import './resetPassword.scss';



export default function ChangePassword () {
    const dispatch = useDispatch();
    const { aut, isLoading, isError, isSuccess ,isPasswordChangeError,isPasswordChangeSuccess} = useSelector(state => state.auth);
  
    const history = useHistory();
    const [oldpass, setOld] = useState('');
    const [newpass, setNew] = useState('');
    const checkPassword = e => {
      e.preventDefault();
  
      const userInfo = { current_password: oldpass, new_password: newpass };
      dispatch(changePassword(userInfo));
    };
    function displayToast() {
      if (isPasswordChangeSuccess) {
        toast.success('Password updated successfully', {
          toastId: 'pwchangesuccess',
        });
      } else if (isPasswordChangeError) {
        toast.error('Error updating password, please ensure the password is atleast 8 characters long and is not similar to username', {
          toastId: 'pwchangeerror'
        })
    }
  }
    // useEffect(() => {
    //   dispatch(resetAuth());
    // }, [aut, isError, isSuccess, isLoading, dispatch, history]);
    // const { showSpinner } = useApiCallStatusNotificationHandler({
    //   isSuccess,
    //   isLoading,
    //   isError,
    //   successCallBack: () =>
    //     setTimeout(() => {
    //       history.push('/');
    //     }, 3500),
    // });
    useEffect(() => {
      if(isPasswordChangeSuccess){
          setTimeout(() => {
            history.push('/');
          }, 3500)}
    }, [isPasswordChangeSuccess]);

    return (
      <div className="password">
        {displayToast()}
        <div className="row mt-5">
          <div className="col col-lg-12 col-sm-12 col-md-12 col-xs-12">
            <div className="login-logo">
              {/* <img src="{% static '/img/FOX-Logo3.png' %}" height="130" width="250" /> */}
            </div>
            <form action="{{action}}{{uidb64}}/{{token}}" method="POST" onSubmit={checkPassword}>
              {/* {showSpinner ? <div id="loader" /> : null} */}
              <div className="col-lg-12 col-sm-12 col-md-12 row justify-content-center">
                <div className="card col-lg-7 col-sm-12 col-xs-12 col-md-12">
                  <div className="card-body row">
                    <h3 className=" mb-3 ml-0 pl-0 col-12">
                      <b>Change Password</b>
                    </h3>
                    <br />
                    <div className="row col-12">
                      <span> </span>
                      <label className="font-weight-normal" htmlFor="newpassword" >
                        Old Password
                      </label>
                      <input
                        name="newpassword"
                        type="password"
                        className="form-control mb-2"
                        placeholder="Old password"
                        id="newpassword"
                        onChange={e => setOld(e.target.value)}
                        required
                        data-toggle="tooltip" title="Your password cant't be too similar to your other personal information"
                      />
                      <span className="font-danger col-lg-12 col-sm-12 ml-0" id="newpassword_error">
                        {' '}
                      </span>
  
                      <label className="font-weight-normal" htmlFor="confirm_password">
                        New Password
                      </label>
                      <input
                        name="confirm_password"
                        type="password"
                        className="form-control mb-2"
                        placeholder="New password"
                        id="confirm_password"
                        onChange={e => setNew(e.target.value)}
                        required
                        data-toggle="tooltip" title="Password must contain at least 8 characters.
                        Your password cant't be entirely numeric"
                      />
                      <span
                        className="font-danger col-lg-12 col-sm-12 ml-0"
                        id="confirm_password_error"
                      >
                        {' '}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-sm-12 col-xs-12 col-md-12">
                  <button className="btn primary-btn" type="submit">
                    Save
                  </button>
                  <button
                    onClick={() => history.push('/profile')}
                    className="btn secondary-btn ml-2"
                    type="button"
                  >
                    Back
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    );
  }