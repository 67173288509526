import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { smartTryCatch } from '../../../utils'
import quizService from './quizService'

const initState = {
  templates: [],
  template: {},
  quizOfVideo: [],
  //template fields
  video_id: 0,
  step: 0,
  templateName: '',
  isErr: false,
  templateNameError: false,
  inputList: [{ name: '' }],

  // modal
  tepmlateModalCreate: false,
  tepmlateModalDelete: false,
  tepmlateModalUpdate: false,

  // success
  isTepmlateGetSuccess: false,
  isTepmlateCreateSuccess: false,
  isTepmlateDeleteSuccess: false,
  isTepmlateUpdateSuccess: false,

  // loading
  isTepmlateGetLoading: false,
  isTepmlateDeleteLoading: false,
  isTepmlateUpdateLoading: false,
  isTepmlateCreateLoading: false,

  // error
  isTepmlateGetError: false,
  isTepmlateCreateError: false,
  isTepmlateDeleteError: false,
  isTepmlateUpdateError: false,

  // get quiz of specific video
  isGetQuizVideoByIdError: false,
  isGetQuizVideoByIdSuccess: false,
  isGetQuizVideoByIdPending: false
}

export const getTemplateById = createAsyncThunk(
  'getTemplateById',
  async (data, thunkAPI) => {
    const get = await smartTryCatch(quizService.getTemplateById, data, thunkAPI)
    return get
  }
)

export const getQuizOfVideoByID = createAsyncThunk(
  'getQuizOfVideoByID',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      quizService.getQuizOfVideoByID,
      data,
      thunkAPI
    )
    return result
  }
)

export const getTemplates = createAsyncThunk(
  'getQuizTemplates',
  async (data, thunkAPI) => {
    const get = await smartTryCatch(quizService.getTemplates, data, thunkAPI)
    return get
  }
)
export const templateDelete = createAsyncThunk(
  'templateDelete',
  async (data, thunkAPI) => {
    const response = await smartTryCatch(
      quizService.templateDelete,
      data,
      thunkAPI
    )
    return response
  }
)
export const createQuizTemplate = createAsyncThunk(
  'createQuizTemplate',
  async (data, thunkAPI) => {
    const response = await smartTryCatch(
      quizService.createQuizTemplate,
      data,
      thunkAPI
    )
    return response
  }
)
export const updateTemplate = createAsyncThunk(
  'updateTemplate',
  async (data, thunkAPI) => {
    const response = await smartTryCatch(
      quizService.updateTemplate,
      data,
      thunkAPI
    )
    return response
  }
)

export const quizSlice = createSlice({
  name: 'quiz',
  initialState: initState,
  reducers: {
    resetTemplate: state => {
      state.video_id = 0
      state.step = 0
      state.isErr = false
      state.templateName = ''
      state.templateNameError = false
      state.inputList = [{ name: '' }]
      state.template = {}
    },
    setVideoId: (state, action) => {
      state.video_id = action.payload
    },
    opentemplateDetailModal: state => {
      state.templateDetailModal = true
    },
    closetemplateDetailModal: state => {
      state.templateDetailModal = false
    },
    setTemplate: (state, action) => {
      state.template = action.payload
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
    setTemplateName: (state, action) => {
      state.templateName = action.payload
    },
    setTemplateNameError: (state, action) => {
      state.templateNameError = action.payload
    },
    setIsErr: (state, action) => {
      state.isErr = action.payload
    },
    reset: state => {
      state.isTemplateCreateError = false
      state.isTemplateCreateSuccess = false
      state.isTemplateCreateLoading = false
      state.isTemplateGetError = false
      state.isTemplateGetSuccess = false
      state.isTemplateGetLoading = false
      state.isTemplateDeleteError = false
      state.isTemplateDeleteSuccess = false
      state.isTemplateDeleteLoading = false
      state.isTemplateUpdateError = false
      state.isTemplateUpdateSuccess = false
      state.isTemplateUpdateLoading = false
      state.templateModalCreate = false
      state.templateModalDelete = false
      state.templateModalUpdate = false
      // get quiz of specific video
      state.isGetQuizVideoByIdError = false
      state.isGetQuizVideoByIdSuccess = false
      state.isGetQuizVideoByIdPending = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getTemplateById.pending, state => {
        state.isTemplateGetLoading = true
        state.isTemplateGetSuccess = false
        state.isTemplateGetError = false
      })
      .addCase(getTemplateById.fulfilled, (state, action) => {
        state.isTemplateGetLoading = false
        state.isTemplateGetSuccess = true
        state.isTemplateGetError = false
        state.template = action.payload
      })
      .addCase(getTemplateById.rejected, state => {
        state.isTemplateGetLoading = false
        state.isTemplateGetSuccess = false
        state.isTemplateGetError = true
      })
      .addCase(getTemplates.pending, state => {
        state.isTemplateGetLoading = true
        state.isTemplateGetSuccess = false
        state.isTemplateGetError = false
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.isTemplateGetLoading = false
        state.isTemplateGetSuccess = true
        state.isTemplateGetError = false
        state.templates = action.payload
      })
      .addCase(getTemplates.rejected, state => {
        state.isTemplateGetLoading = false
        state.isTemplateGetSuccess = false
        state.isTemplateGetError = true
      })
      .addCase(templateDelete.pending, state => {
        state.isTemplateDeleteLoading = true
        state.isTemplateDeleteSuccess = false
        state.isTemplateDeleteError = false
      })
      .addCase(templateDelete.fulfilled, (state, action) => {
        state.isTemplateDeleteLoading = false
        state.isTemplateDeleteSuccess = true
        state.isTemplateDeleteError = false
        state.templates = state.templates.filter(i => i.id !== action.payload)
      })
      .addCase(templateDelete.rejected, state => {
        state.isTemplateDeleteLoading = false
        state.isTemplateDeleteSuccess = false
        state.isTemplateDeleteError = true
      })
      .addCase(createQuizTemplate.pending, state => {
        state.isTemplateCreateLoading = true
        state.isTemplateCreateSuccess = false
        state.isTemplateCreateError = false
      })
      .addCase(createQuizTemplate.fulfilled, (state, action) => {
        state.isTemplateCreateLoading = false
        state.isTemplateCreateSuccess = true
        state.isTemplateCreateError = false
        state.templates.push(action.payload)
      })
      .addCase(createQuizTemplate.rejected, state => {
        state.isTemplateCreateLoading = false
        state.isTemplateCreateSuccess = false
        state.isTemplateCreateError = true
      })
      .addCase(updateTemplate.pending, state => {
        state.isTemplateUpdateLoading = true
        state.isTemplateUpdateSuccess = false
        state.isTemplateUpdateError = false
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.isTemplateUpdateLoading = false
        state.isTemplateUpdateSuccess = true
        state.isTemplateUpdateError = false
        const templateIndex = state.templates.findIndex(
          t => t.id === action.payload.id
        )
        if (templateIndex !== -1) {
          state.templates[templateIndex] = {
            ...state.templates[templateIndex],
            ...action.payload
          }
        }
      })
      .addCase(updateTemplate.rejected, state => {
        state.isTemplateUpdateLoading = false
        state.isTemplateUpdateSuccess = false
        state.isTemplateUpdateError = true
      })
      .addCase(getQuizOfVideoByID.pending, (state, action) => {
        state.isGetQuizVideoByIdError = false
        state.isGetQuizVideoByIdSuccess = false
        state.isGetQuizVideoByIdPending = true
      })
      .addCase(getQuizOfVideoByID.fulfilled, (state, action) => {
        state.isGetQuizVideoByIdError = false
        state.isGetQuizVideoByIdSuccess = true
        state.isGetQuizVideoByIdPending = false
        state.quizOfVideo = action.payload
      })
      .addCase(getQuizOfVideoByID.rejected, (state, action) => {
        state.isGetQuizVideoByIdError = true
        state.isGetQuizVideoByIdSuccess = false
        state.isGetQuizVideoByIdPending = false
      })
  }
})

export const {
  setVideoId,
  opentemplateDetailModal,
  closetemplateDetailModal,
  settemplate,
  reset,
  resetTemplate,
  setStep,
  setTemplateName,
  setTemplateNameError,
  setIsErr,
  setTemplate
} = quizSlice.actions

export default quizSlice.reducer
