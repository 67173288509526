import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classess from "../../../styles/asset.module.css"

import { useDispatch, useSelector } from 'react-redux';
import { updateStatus } from '../../../redux/features/approveRejectPtw/approveRejectPtwSlice';
import { deleteEptw } from '../../../redux/features/eptw/eptwSlice';

function DeleteModal({ open, onClose, rowData }) {
    const dispatch = useDispatch();
    // const {
    //     title,
    // } = useSelector(state => state.eptw)
    const confirmDeletion = async (e) => {
        dispatch(deleteEptw(rowData))
        onClose()
        // dispatch(updateStatus({ id: rowData, status: "Closed" }))
    }

    return (
        <Modal show={open} onHide={onClose} animation={true}>
            <Modal.Header>
                <Modal.Title>Delete Permit</Modal.Title>
                <button
                    className='btn secondary-btn'
                    onClick={onClose} type='button'>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className={classess.eptwButtons}>
                        <h5>Are you sure you want to delete this permit?</h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={classess.eptwButtons}>
                    <Button  variant="contained" size="small" onClick={confirmDeletion}>
                        Delete
                    </Button>
                </div>
                <Button variant="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DeleteModal
