/* eslint-disable import/order */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable spaced-comment */
import React, { useState, useEffect } from 'react';
import SemiCircleChart from './SemiCircleChart/SemiCircleChart';
import ProgressChart from './ProgressChart/ProgressChart';
import TrendWorkChart from './TrendWorkChart/TrendWorkChart';
import FilterField from './FilterField/FilterField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/fontawesome-free-solid';
import PieChart from './PieChart/PieChart';
import { faChartLine, faClock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAnalyticsForDashboard } from '../../redux/features/analytics/analyticsSlice';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import "./analytics.css"
import { getTeamsByAuthentication } from '../../redux/features/teams/teamSlice';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



function Analytics() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTeamsByAuthentication());
  }, [dispatch]);
  
  const { teams, teamModalCreate,
    isTeamGetSuccess,
    // loading
    isTeamGetLoading,
    // error
    isTeamGetError,
    team,
  } = useSelector((state) => state.team)
  const chartContainerStyle = {
    maxHeight: '400px', // Set a maximum height for the chart containers
    overflowY: 'none',   // Add vertical scrolling if needed
  };
  const { analytics, filter, staff } = useSelector(state => state.analytics);
  // const [value, setValue] = useState(50);
  const [duration, setDuration] = useState('year');
  const [ptwCategory, setEptwCategory] = useState([0, 0, 0, 0, 0]);
  const [pendingptwCount, SetPendingPetCount] = useState(0);
  const [taskData, setTaskData] = useState([0, 0, 0, 0]);
  const [projectTotalAndActive, setProjectTotalAndActive] = useState([0, 0]);
  
  const trendData = {
    createTask:[],
    completedTask:[],
    dates:[]
  }

  useEffect(() => {
    dispatch(getAnalyticsForDashboard({ filter, staff }));
  }, [filter, staff]);
  useEffect(() => {
    setEptwCategory([
      parseInt(analytics?.ptws_analytics?.percentage.approved_ptws, 10),
      parseInt(analytics?.ptws_analytics?.percentage.rejected_ptws, 10),
      parseInt(analytics?.ptws_analytics?.percentage.pending_ptws, 10),
      parseInt(analytics?.ptws_analytics?.percentage.submitted_ptws, 10),
      parseInt(analytics?.ptws_analytics?.percentage.resubmitted_ptws, 10),
    ]);
    //   //total project -> open active project ->overdue tasks -> penidng eptw
    //   //eptw status category task completed task compliance trends of task
    setProjectTotalAndActive([
      parseInt(analytics?.projects_analytic?.total_projects, 10),
      parseInt(analytics?.projects_analytic?.active_projects, 10),]
    );
    SetPendingPetCount(analytics?.ptws_analytics?.pending_ptws)
    setTaskData([
      analytics?.tasks_analytic?.total_tasks || 0,
      analytics?.tasks_analytic?.total_overdue || 0,
      analytics?.tasks_analytic?.task_compliance || 0,
      analytics?.tasks_analytic?.task_completion || 0,
      // analytics?.projects_analytic?.active_projects
    ])

  }, [analytics, duration]);
  return (
    <div className="row col-lg-10 col-12 mx-auto mt-3">

      <div className="col-12">
        <FilterField staff={teams ||[]}/>
        <div className="analytic-cards m-0 p-0">
          <div className="analytic-card mb-4">

            <Card elevation={3}>
              <CardContent>
                <Typography variant="h5" color="#f63854">
                  <FontAwesomeIcon icon={faBriefcase} style={{ marginRight: '2px' }} />
                  Total Projects
                </Typography>
                <Typography variant="h5" color="#f63854" m={2}>
                  {projectTotalAndActive[0]}
                </Typography>
              </CardContent>
            </Card>
          </div>

          <div className="analytic-card mb-4">
            <Card elevation={3}>
              <CardContent>
                <Typography variant="h5" color="#f63854">
                  <FontAwesomeIcon icon={faSpinner} style={{ marginRight: '2px' }} />
                  Active Projects
                </Typography>
                <Typography variant="h5" color="#f63854" m={2}>
                  {projectTotalAndActive[1]}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="analytic-card mb-4">

            <Card elevation={3}>
              <CardContent>
                <Typography variant="h5" color="#f63854">
                  <FontAwesomeIcon icon={faClock} style={{ marginRight: '2px' }} />
                  Overdue tasks
                </Typography>
                <Typography variant="h5" color="#f63854" m={2}>
                  {taskData[1]}
                </Typography>
              </CardContent>
            </Card>
          </div>
          <div className="analytic-card mb-4">

            <Card elevation={3}>
              <CardContent>
                <Typography variant="h5" color="#f63854">
                  <FontAwesomeIcon icon={faChartLine} style={{ marginRight: '2px' }} />
                  pending PTW
                </Typography>
                <Typography variant="h5" color="#f63854" m={2}>
                  {pendingptwCount}
                </Typography>
              </CardContent>
            </Card>
          </div>
        </div>

        {/* Container of the chart*/}
        <div className="analytic-charts">
          <div className="analytic-chart mb-4">
            <div className="card">
              <div className="card-body" style={{ paddingBottom: '0' }}>
                <center>
                  <span className="info-box-text info-box-text-styles">
                    <span className="font-weight-bold">e-PTW Approval </span>
                  </span>
                  <div style={chartContainerStyle}>
                    <div id="chart">
                      <PieChart categories={ptwCategory} />
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
          <div className="analytic-chart mb-4">
            <div className="card">
              <div className="card-body" style={{ paddingBottom: '0px' }}>
                <center>
                  <span className="info-box-text info-box-text-styles">
                    <span className="font-weight-bold">Task Completion</span>
                  </span>
                  <div style={chartContainerStyle}>
                    <div id="chart">
                      <ProgressChart percentage={taskData[3] | 0} />
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
          <div className="analytic-chart mb-4">
            <div className="card">
              <div className="card-body" style={{ paddingBottom: '0px' }}>
                <center>
                  <span className="info-box-text info-box-text-styles">
                    <span className="font-weight-bold">Task Compliance</span>
                  </span>

                  <div className="mt-2" style={chartContainerStyle}>
                    <div id="chart">
                      {/* task compliance chart */}
                      <SemiCircleChart percentage={taskData[2]} />
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body">
                <center>
                  <div className="">
                    <center>
                      <span className="info-box-text info-box-text-styles mt-2">
                        <span className="font-weight-bold">Trend of Tasks</span>
                      </span>
                    </center>
                  </div>
                </center>
                <div className="" style={{ marginTop: '-30px' }}>
                  <button
                    id="{{data.value}}_complition"
                    className="btn tertiary-btn float-right ml-1 pl-1"
                    title="Time interval"
                    value="{{data.value}}"
                    type="button"
                  >
                    Week
                  </button>
                  <button
                    id="{{data.value}}_complition"
                    className="btn tertiary-btn float-right pr-1"
                    title="Time interval"
                    value="{{data.value}}"
                    type="button"
                  >
                    Day
                  </button>
                </div>
                <center>
                  <div className="mt-5">
                    <div id="chart">
                      <TrendWorkChart tasks={taskData} />
                    </div>
                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
