import React  ,{useState , useEffect} from  'react'
import { useHistory } from 'react-router-dom';
import { useDispatch  , useSelector} from 'react-redux';
import { forgotPassword , resetAuth } from '../../redux/features/auth/authSilice';
import useApiCallStatusNotificationHandler from '../../utils/apiCallStatusHandler';
import foxLogo from '../../assets/img/FOX-Logo3.png';
import './forgetPassword.scss'

export default function ForgetPassword(){
    const [email , setEmail] = useState("")
    const history = useHistory();
    const {  isLoading, isError, isSuccess } = useSelector((state) => state.auth);
    const dispatch = useDispatch()
    const submitEmail =(e)=>{
        e.preventDefault()
        const userinfo = {email}

        dispatch(forgotPassword(userinfo))
    }

    const { showSpinner } = useApiCallStatusNotificationHandler({
        isSuccess,
        isLoading,
        isError,
        successCallBack: () =>
          setTimeout(() => {
            history.push('/reset-confirm-password');
          }, 3500),
      });
      useEffect(() => {
        dispatch(resetAuth());
      }, [isError, isSuccess, isLoading, dispatch, history]);
    return (
<div className="hold-transition login-page" style={{backgroundColor:" #f8f8f8 !important;"}}>
{showSpinner ? <div id='loader' /> : null}

        <div className="login-box">
            
        <div className="login-logo">
            <img src={foxLogo}  alt="main logo foret passowrd" height="130" width="250" />
        </div>
            <div className="card-body">
                <h5 className="login-box-msg pb-0">Request password reset</h5>
                    {/* {% if msg %}/ */}
                    {/* <span className="font-danger">hi</span> */}
                    {/* {% endif %}// */}
                    <span  className="font-danger col-lg-12 col-sm-12 ml-0" id='email_error'>{" "}</span>
                <form 
                // action="{{action}}" 
                // method="POST"
                 onSubmit={submitEmail}
                >
                    {/* {% csrf_token %} */}
                    <div className="input-group mb-3 input-group-email-reset">
                        <div className="row">
                            <label htmlFor="emailreset" className="font-weight-normal email-reset">Enter your registered E-mail
                            </label>
                            <input 
                            name='email' 
                            type="email" 
                            className="form-control email-input"
                             required placeholder="Email" 
                             id="emailreset"
                              onChange={(e)=>setEmail(e.target.value)}
                              />
                        </div>
                        
                        <br/>
    
                        <div className="input-group-append">
                         {" "}
                        </div>
                    </div>
                    <div className="row">
                            <button type="submit" 
                            style={{padding:"4px"}}
                            className="btn btn-block primary-btn" 
                            
                            >Confirm</button>
                    </div>
    
                </form>
    
            </div>
    
    </div>
<div className="footer text-center mt-2" style={{color: "#9B9B9B"}}> © 2022 Infinity Wave Sdn. Bhd.</div>

    </div>
    )
}