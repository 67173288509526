import * as React from 'react'
import Box from '@mui/material/Box'
import InductionManagementHeader from '../../../components/InductionComponent/InductionManagementHeader/InductionManagementHeader'
import InductionManagementTable from '../../../components/InductionComponent/InductionManagementTable/InductionManagementTable'
import BasicCard from '../../../components/materialCard/materialCard'

export default function InductionManagement() {
  return (
    <>
    <div>
      <InductionManagementHeader />
    </div>
    <Box sx={{mt:18}}>
      <BasicCard component={<InductionManagementTable />} m={2} />
    </Box>
  </>
  )
}
