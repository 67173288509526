import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import memberPositionService from "./memberPositionService";
import { smartTryCatch } from "../../../utils";

const initState = {
  // data
  members: [],
  member: {},
  
  // fields
  memberPostionName: "",

  // modals
  memberPositionModalDelete: false,
  memberPositionModalUpdate: false,
  memberPositionModalCreate: false,

  // success
  ismemberCreateSuccess: false,
  ismemberGetSuccess: false,
  ismemberDeleteSuccess: false,
  ismemberUpdateSuccess: false,

  // loading
  ismemberCreateLoading: false,
  ismemberGetLoading: false,
  ismemberDeleteLoading: false,
  ismemberUpdateLoading: false,

  // error
  ismemberCreateError: false,
  ismemberGetError: false,
  ismemberDeleteError: false,
  ismemberUpdateError: false,
};

export const creatememberPosition = createAsyncThunk(
  "creatememberPosition",
  async (data, thunkAPI) => {
    const create = await smartTryCatch(
      memberPositionService.creatememberPosition,
      data,
      thunkAPI
    );
    return create;
  }
);

export const getmemberPositionsByAuthentication = createAsyncThunk(
  "getmemberPositionsByAuthentication",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      memberPositionService.getmemberPositionByAuthentication,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const getmemberPositionById = createAsyncThunk(
  "getmemberPositionById",
  async (data, thunkAPI) => {
    const getTech = await smartTryCatch(
      memberPositionService.getmemberPositionById,
      data,
      thunkAPI
    );
    return getTech;
  }
);

export const deletememberPosition = createAsyncThunk(
  "deletememberPosition",
  async (data, thunkAPI) => {
    const deleteTech = await smartTryCatch(
      memberPositionService.deletememberPosition,
      data,
      thunkAPI
    );
    return deleteTech;
  }
);

export const updatememberPosition = createAsyncThunk(
  "updatememberPosition",
  async (data, thunkAPI) => {
    const updateTech = await smartTryCatch(
      memberPositionService.updatememberPosition,
      data,
      thunkAPI
    );
    return updateTech;
  }
);

export const memberPositionSlice = createSlice({
  name: "memberPosition",
  initialState: initState,
  reducers: {
    reset: (state) => {
      state.ismemberCreateError = false;
      state.ismemberCreateSuccess = false;
      state.ismemberCreateLoading = false;
      state.ismemberGetError = false;
      state.ismemberGetSuccess = false;
      state.ismemberGetLoading = false;
      state.ismemberDeleteError = false;
      state.ismemberDeleteSuccess = false;
      state.ismemberDeleteLoading = false;
      state.ismemberUpdateError = false;
      state.ismemberUpdateSuccess = false;
      state.ismemberUpdateLoading = false;
  
      state.memberPositionModalCreate = false;
      state.memberPositionModalDelete = false;
      state.memberPositionModalUpdate = false;
    },
    openmemberPositionModalDelete: (state) => {
      state.memberPositionModalDelete = true;
    },
    closememberPositionModalDelete: (state) => {
      state.memberPositionModalDelete = false;
    },
    openmemberPositionModalUpdate: (state) => {
      state.memberPositionModalUpdate = true;
    },
    closememberPositionModalUpdate: (state) => {
      state.memberPositionModalUpdate = false;
    },
    openmemberPositionModalCreate: (state) => {
      state.memberPositionModalCreate = true;
    },
    closememberPositionModalCreate: (state) => {
      state.memberPositionModalCreate = false;
    },
    setmember: (state, action) => {
      state.member = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getmemberPositionsByAuthentication.pending, (state) => {
        state.ismemberGetLoading = true;
        state.ismemberGetSuccess = false;
        state.ismemberGetError = false;
      })
      .addCase(
        getmemberPositionsByAuthentication.fulfilled,
        (state, action) => {
          state.ismemberGetLoading = false;
          state.ismemberGetSuccess = true;
          state.ismemberGetError = false;
          state.members = action.payload;
        }
      )
      .addCase(getmemberPositionsByAuthentication.rejected, (state) => {
        state.ismemberGetLoading = false;
        state.ismemberGetSuccess = false;
        state.ismemberGetError = true;
      })
      .addCase(getmemberPositionById.pending, (state) => {
        state.ismemberGetLoading = true;
        state.ismemberGetSuccess = false;
        state.ismemberGetError = false;
      })
      .addCase(getmemberPositionById.fulfilled, (state, action) => {
        state.ismemberGetLoading = false;
        state.ismemberGetSuccess = true;
        state.ismemberGetError = false;
        state.members = action.payload;
      })
      .addCase(getmemberPositionById.rejected, (state) => {
        state.ismemberGetLoading = false;
        state.ismemberGetSuccess = false;
        state.ismemberGetError = true;
      })
      .addCase(creatememberPosition.pending, (state) => {
        state.ismemberCreateLoading = true;
        state.ismemberCreateSuccess = false;
        state.ismemberCreateError = false;
      })
      .addCase(creatememberPosition.fulfilled, (state, action) => {
        state.ismemberCreateLoading = false;
        state.ismemberCreateSuccess = true;
        state.ismemberCreateError = false;
        state.members.push(action.payload);
      })
      .addCase(creatememberPosition.rejected, (state) => {
        state.ismemberCreateLoading = false;
        state.ismemberCreateSuccess = false;
        state.ismemberCreateError = true;
      })
      .addCase(deletememberPosition.pending, (state) => {
        state.ismemberDeleteLoading = true;
        state.ismemberDeleteSuccess = false;
        state.ismemberDeleteError = false;
      })
      .addCase(deletememberPosition.fulfilled, (state, action) => {
        state.ismemberDeleteLoading = false;
        state.ismemberDeleteSuccess = true;
        state.ismemberDeleteError = false;
        state.members = state.members.filter(
          (member) => member.id !== action.payload
        );
      })
      .addCase(deletememberPosition.rejected, (state) => {
        state.ismemberDeleteLoading = false;
        state.ismemberDeleteSuccess = false;
        state.ismemberDeleteError = true;
      })
      .addCase(updatememberPosition.pending, (state) => {
        state.ismemberUpdateLoading = true;
        state.ismemberUpdateSuccess = false;
        state.ismemberUpdateError = false;
      })
      .addCase(updatememberPosition.fulfilled, (state, action) => {
        state.ismemberUpdateLoading = false;
        state.ismemberUpdateSuccess = true;
        state.ismemberUpdateError = false;
        state.members = state.members.map((member) =>
          member.id === action.payload.id ? action.payload : member
        );
      })
      .addCase(updatememberPosition.rejected, (state) => {
        state.ismemberUpdateLoading = false;
        state.ismemberUpdateSuccess = false;
        state.ismemberUpdateError = true;
      });
  },
});

export const {
  reset,
  closememberPositionModalCreate,
  closememberPositionModalDelete,
  closememberPositionModalUpdate,
  openmemberPositionModalCreate,
  openmemberPositionModalDelete,
  setmember,
  openmemberPositionModalUpdate,
} = memberPositionSlice.actions;

export default memberPositionSlice.reducer;
