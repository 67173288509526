import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Box from '@mui/material/Box'
import { useDispatch, useSelector } from 'react-redux'
import { setSessionViewOnlyModal, setVideoDetails } from '../../../redux/features/session/sessionSlice'
import InductionModalViewOnlyComponent from './InductionModalViewOnlyComponent'

const InductionModalViewOnlyBody = () => {
  const dispatch = useDispatch()
  const { sessionViewOnlyModal } = useSelector(state => state.session)

  return (
    <>
      <Modal
        show={sessionViewOnlyModal}
        onHide={() => {
          dispatch(setVideoDetails(null))
          dispatch(setSessionViewOnlyModal(false))
        }}
        size='lg'
        aria-labelledby='example-modal-sizes-title-lg'
        centered
      >
        <Modal.Header>
          <Modal.Title id='example-modal-sizes-title-lg'>
            Create Induction session
          </Modal.Title>
          <div>
            <Button
              variant='primary'
              onClick={() => {
                dispatch(setVideoDetails(null))
                dispatch(setSessionViewOnlyModal(false))
              }}
            >
              X
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <InductionModalViewOnlyComponent />
          <Box
            sx={{
              display: 'flex',
              // flexDirection: 'row',
              justifyContent: 'flex-end !important',
              pt: 2
            }}
          >
            <Button
              variant='primary'
              onClick={() => {
                dispatch(setSessionViewOnlyModal(false))
              }}
            >
              Close
            </Button>
          
          </Box>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default InductionModalViewOnlyBody
