import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import projectService from './projectService';
import { smartTryCatch } from '../../../utils';

const initState = {
   //Project data
   project: [],
   projectById: {},
   projectSearchTerm: "",
   contractors: [],
   contractor: {},
   //project feilds
   id: null,
   name: "",
   location: "",
   status: "",
   assigned_to: [],
   contractor: [],
   description: "",
   start_date: "",
   finish_date: "",
   actual_start_date: "",
   actual_finish_date: "",
   customized_fields: {},
   contractorSearchTerm: "",
   //project model 
   projectModalDelete: false,
   projectModalUpdate: false,
   projectModalCreate: false,
   contractorModalCreate: false,
   contractorModalUpdate: false,
   contractorModalDelete: false,
   openSafetyPopup: false,
   objectData: null,


   //success project
   isGetprojectSuccess: false,
   isCreateprojectSuccess: false,
   isUpdateprojectSuccess: false,
   isDeleteprojectSuccess: false,

   // Success Contractor
   isCreateContractorSuccess: false,
   isUpdateContractorSuccess: false,
   isDeleteContractorSuccess: false,

   //loading project
   isGetprojectLoading: false,
   isCreateprojectLoading: false,
   isUpdateprojectLoading: false,
   isDeleteprojectLoading: false,
   // lodading contractor
   isCreateContractorLoading: false,
   isUpdateContractorLoading: false,
   isDeleteContractorLoading: false,
   //error
   isGetprojectError: false,
   isCreateprojectError: false,
   isUpdateprojectError: false,
   isDeleteprojectError: false,
   // contactor error
   isCreateContractorError: false,
   isUpdateContractorError: false,
   isDeleteContractorError: false,
}

export const getProject = createAsyncThunk('getProject', async (data, thunkAPI) => {
   const get = await smartTryCatch(projectService.getProject, data, thunkAPI);
   return get;
});
export const getContractor = createAsyncThunk('getContractor', async (data, thunkAPI) => {
   const get = await smartTryCatch(projectService.getContractor, data, thunkAPI);
   return get;
});

export const getProjectById = createAsyncThunk('getProjectById', async (data, thunkAPI) => {
   const get = await smartTryCatch(projectService.getProjectById, data, thunkAPI);
   return get;
});
export const projectDelete = createAsyncThunk('projectDelete', async (data, thunkAPI) => {
   const response = await smartTryCatch(projectService.projectDelete, data, thunkAPI);
   return response;
});
export const addProject = createAsyncThunk('addProject', async (data, thunkAPI) => {
   const response = await smartTryCatch(projectService.addProject, data, thunkAPI);
   return response;
});
export const createContractor = createAsyncThunk('createContractor', async (data, thunkAPI) => {
   const response = await smartTryCatch(projectService.createContractor, data, thunkAPI);
   return response;
});
export const updateContractor = createAsyncThunk('updateContractor', async (data, thunkAPI) => {
   const response = await smartTryCatch(projectService.updateContractor, data, thunkAPI);
   return response;
});

export const deleteContractor = createAsyncThunk('deleteContractor', async (data, thunkAPI) => {
   const response = await smartTryCatch(projectService.deleteContractor, data, thunkAPI);
   return response;
});

export const updateProject = createAsyncThunk('updateProject', async (data, thunkAPI) => {
   const response = await smartTryCatch(projectService.updateProject, data, thunkAPI);
   return response;
});

export const projectSlice = createSlice({
   name: 'project',
   initialState: initState,
   reducers: {
      reset: (state) => {
         state.isGetprojectSuccess = false;
         state.isCreateprojectSuccess = false;
         state.isUpdateprojectSuccess = false;
         state.isDeleteprojectSuccess = false;
         state.isCreateContractorSuccess = false;
         state.isGetprojectLoading = false;
         state.isCreateprojectLoading = false;
         state.isUpdateprojectLoading = false;
         state.isDeleteprojectLoading = false;
         state.isCreateContractorLoading = false;
         state.isGetprojectError = false;
         state.isCreateprojectError = false;
         state.isUpdateprojectError = false;
         state.isDeleteprojectError = false;
         state.isCreateContractorError = false;
         state.isUpdateContractorError = false;
         state.isDeleteContractorError = false;
         state.isCreateContractorSuccess = false;
         state.isUpdateContractorSuccess = false;
         state.isDeleteContractorSuccess = false;
         state.isCreateContractorLoading = false;
         state.isUpdateContractorLoading = false;
         state.isDeleteContractorLoading = false;
         state.projectSearchTerm = "";
      },
      openProjectModalCreate: (state) => {
         state.projectModalCreate = true
      },
      closeProjectModalCreate: (state) => {
         state.projectModalCreate = false
      },
      openProjectModalUpdate: (state) => {
         state.projectModalUpdate = true
      },
      closeProjectModalUpdate: (state) => {
         state.projectModalUpdate = false
      },
      openProjectModalDelete: (state) => {
         state.projectModalDelete = true
      },
      closeProjectModalDelete: (state) => {
         state.projectModalDelete = false
      },
      setId: (state, action) => {
         state.id = action.payload
      },
      setName: (state, action) => {
         state.name = action.payload
      },
      setStatus: (state, action) => {
         state.status = action.payload
      },
      setLocation: (state, action) => {
         state.location = action.payload
      },
      setAssignTo: (state, action) => {
         state.assign_to = action.payload
      },
      setDiscription: (state, action) => {
         state.description = action.payload
      },
      setStartDate: (state, action) => {
         state.start_date = action.payload
      },
      setFinishDate: (state, action) => {
         state.finish_date = action.payload
      },
      setActualStartDate: (state, action) => {
         state.actual_start_date = action.payload
      },
      setActualFinishDate: (state, action) => {
         state.actual_finish_date = action.payload
      },
      setCustomizedFeilds: (state, action) => {
         state.customized_fields = action.payload
      },
      setContractor: (state, action) => {
         state.contractor = action.payload
      },
      setContractors: (state, action) => {
         state.contractors = action.payload
      },
      setContractorSearchTerm: (state, action) => {
         state.contractorSearchTerm = action.payload
      },
      openContractorModalCreate: state => {
         state.contractorModalCreate = true;
      },
      closeContractorModalCreate: state => {
         state.contractorModalCreate = false;
      },
      openContractorModalUpdate: state => {
         state.contractorModalUpdate = true;
      },
      closeContractorModalUpdate: state => {
         state.contractorModalUpdate = false;
      },
      openContractorModalDelete: state => {
         state.contractorModalDelete = true;
      },
      closeContractorModalDelete: state => {
         state.contractorModalDelete = false;
      },
      setProjectSearchTerm: (state, action) => {
         state.projectSearchTerm = action.payload;
      },
      setProjectTasks: (state, action) => {
         state.projectById.tasks = action.payload;
      },
      setOpenSafetyPopup: (state, action) => {
         state.openSafetyPopup = action.payload;
      },
      setObjectData: (state, action) => {
         state.objectData = action.payload;
      },

      resetProject: (state) => {
         state.id = null
         state.name = ""
         state.location = ""
         state.status = ""
         state.assigned_to = []
         state.contractor = []
         state.description = ""
         state.start_date = ""
         state.finish_date = ""
         state.actual_start_date = ""
         state.actual_finish_date = ""
         state.customized_fields = {}
         state.projectSearchTerm = ""
         state.openSafetyPopup = false
         state.objectData = null
      },
      resetProjectActions: state => {
         state.isCreateprojectError = false;
         state.isUpdateprojectError = false;
         state.isDeleteprojectError = false;
         state.isCreateprojectSuccess = false;
         state.isUpdateprojectSuccess = false;
         state.isDeleteprojectSuccess = false;
         state.isGetprojectLoading = false;
         state.isCreateprojectLoading = false;
         state.isUpdateprojectLoading = false;
         state.isDeleteprojectLoading = false;
      },


   },
   extraReducers: (builder) => {
      builder
         .addCase(getProject.pending, (state) => {
            state.isGetprojectError = false;
            state.isGetprojectLoading = true;
            state.isGetprojectSuccess = false;
         })
         .addCase(getProject.fulfilled, (state, action) => {
            state.isGetprojectError = false;
            state.isGetprojectLoading = false;
            state.isGetprojectSuccess = true;
            state.project = action.payload;

         })
         .addCase(getProject.rejected, (state) => {
            state.isGetprojectError = true;
            state.isGetprojectLoading = false;
            state.isGetprojectSuccess = true;
         })
         .addCase(getProjectById.pending, (state) => {
            state.isGetprojectError = false;
            state.isGetprojectLoading = true;
            state.isGetprojectSuccess = false;
         })
         .addCase(getProjectById.fulfilled, (state, action) => {
            state.isGetprojectError = false;
            state.isGetprojectLoading = false;
            state.isGetprojectSuccess = true;
            state.projectById = action.payload;
         })
         .addCase(getProjectById.rejected, (state) => {
            state.isGetprojectError = true;
            state.isGetprojectLoading = false;
            state.isGetprojectSuccess = true;
         })
         .addCase(projectDelete.pending, (state) => {
            state.isDeleteprojectError = false;
            state.isDeleteprojectLoading = true;
            state.isDeleteprojectSuccess = false;
         })
         .addCase(projectDelete.fulfilled, (state, action) => {
            state.isDeleteprojectError = false;
            state.isDeleteprojectLoading = false;
            state.isDeleteprojectSuccess = true;
            state.project = state.project.filter((i) => i.id !== action.payload)
         })
         .addCase(projectDelete.rejected, (state) => {
            state.isDeleteprojectError = true;
            state.isDeleteprojectLoading = false;
            state.isDeleteprojectSuccess = false;
         })
         .addCase(addProject.pending, (state) => {
            state.isCreateprojectError = false;
            state.isCreateprojectLoading = true;
            state.isCreateprojectSuccess = false;
         })
         .addCase(addProject.fulfilled, (state, action) => {
            state.isCreateprojectError = false;
            state.isCreateprojectLoading = false;
            state.isCreateprojectSuccess = true;
            // Assuming action.payload is a single project object
            state.project.push(action.payload);

         })
         .addCase(addProject.rejected, (state) => {
            state.isCreateprojectError = true;
            state.isCreateprojectLoading = false;
            state.isCreateprojectSuccess = false;
         })
         .addCase(updateProject.pending, (state) => {
            state.isUpdateprojectError = false;
            state.isUpdateprojectLoading = true;
            state.isUpdateprojectSuccess = false;
         })

         .addCase(updateProject.fulfilled, (state, action) => {
            state.isUpdateprojectError = false;
            state.isUpdateprojectLoading = false;
            state.isUpdateprojectSuccess = true;
            const projectIndex = state.project.findIndex(proj => proj.id === action.payload.id);
            if (projectIndex !== -1) {
               state.project[projectIndex] = { ...state.project[projectIndex], ...action.payload };
            }
         })

         .addCase(updateProject.rejected, (state) => {
            state.isUpdateprojectError = true;
            state.isUpdateprojectLoading = false;
            state.isUpdateprojectSuccess = false;
         })
         .addCase(getContractor.pending, (state) => {
            state.isGetprojectError = false;
            state.isGetprojectLoading = true;
            state.isGetprojectSuccess = false;
         })
         .addCase(getContractor.fulfilled, (state, action) => {
            state.isGetprojectError = false;
            state.isGetprojectLoading = false;
            state.isGetprojectSuccess = true;
            state.contractors = action.payload;
         })
         .addCase(getContractor.rejected, (state) => {
            state.isGetprojectError = true;
            state.isGetprojectLoading = false;
            state.isGetprojectSuccess = false;
         })
         .addCase(createContractor.pending, (state) => {
            state.isCreateContractorError = false;
            state.isCreateContractorLoading = true;
            state.isCreateContractorSuccess = false;
         })
         .addCase(createContractor.fulfilled, (state, action) => {
            state.isCreateContractorError = false;
            state.isCreateContractorLoading = false;
            state.isCreateContractorSuccess = true;
            state.contractors.push(action.payload);

         })
         .addCase(createContractor.rejected, (state) => {
            state.isCreateContractorError = true;
            state.isCreateContractorLoading = false;
            state.isCreateContractorSuccess = false;
         })
         .addCase(updateContractor.pending, (state) => {
            state.isUpdateContractorError = false;
            state.isUpdateContractorLoading = true;
            state.isUpdateContractorSuccess = false
         })
         .addCase(updateContractor.fulfilled, (state, action) => {
            state.isUpdateContractorError = false;
            state.isUpdateContractorLoading = false;
            state.isUpdateContractorSuccess = true;
            const contractorIndex = state.contractors.findIndex(proj => proj.id === action.payload.id);
            if (contractorIndex !== -1) {
               state.contractors[contractorIndex] = { ...state.contractors[contractorIndex], ...action.payload };
            }
         })
         .addCase(updateContractor.rejected, (state) => {
            state.isUpdateContractorError = true;
            state.isUpdateContractorLoading = false;
            state.isUpdateContractorSuccess = false
         })
         .addCase(deleteContractor.pending, (state) => {
            state.isDeleteContractorError = false;
            state.isDeleteContractorLoading = true;
            state.isDeleteContractorSuccess = false
         })
         .addCase(deleteContractor.fulfilled, (state, action) => {
            state.isDeleteContractorError = false;
            state.isDeleteContractorLoading = false;
            state.isDeleteContractorSuccess = true;
            state.contractors = state.contractors.filter((i) => i.id !== action.payload)
         })
         .addCase(deleteContractor.rejected, (state) => {
            state.isDeleteContractorError = true;
            state.isDeleteContractorLoading = false;
            state.isDeleteContractorSuccess = false
         })

   }
});

export const {
   openProjectModalCreate,
   closeProjectModalCreate,
   openProjectModalUpdate,
   closeProjectModalUpdate,
   openProjectModalDelete,
   closeProjectModalDelete,
   resetProject,
   setId,
   setName,
   setStatus,
   setLocation,
   setAssignTo,
   setDiscription,
   setStartDate,
   setFinishDate,
   setActualStartDate,
   setActualFinishDate,
   setCustomizedFeilds,
   setContractor,
   setContractors,
   setContractorSearchTerm,
   openContractorModalCreate,
   closeContractorModalCreate,
   openContractorModalUpdate,
   closeContractorModalUpdate,
   openContractorModalDelete,
   closeContractorModalDelete,
   reset,
   resetProjectActions,
   setProjectSearchTerm,
   setProjectTasks,
   setOpenSafetyPopup,
   setObjectData
} = projectSlice.actions;

export default projectSlice.reducer;