import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import classes from "../../../styles/asset.module.css";
import effect from "../../../styles/assetTable.module.css";
import { DataGrid } from "@mui/x-data-grid";
import AddtaskModal from "../../Task/addtaskModal";
import { getProjectById, setProjectTasks } from "../../../redux/features/project/projectSclice";
import TaskDataTable from "./TaskDataTable";
import {Typography } from '@mui/material';
import { toast } from 'react-toastify';
import { resetTaskActions, setSearchValue } from "../../../redux/features/task/taskSclice";
export default function ProjectTaskTable() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { allUsers } = useSelector((state) => state.user);
  const { 
    isCreateTaskSuccess,
    isUpdateTaskSuccess,
    isDeleteTasksSuccess,
    isCreateTaskError,
    isUpdateTaskError,
    isDeleteTaskError,
    searchValue
  } = useSelector((state) => state.task)

  const userOptions = allUsers.map((user) => ({
    id: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));
  const location = useLocation();
  const dispatch = useDispatch();
  const formDataFromLocation = location.state?.formData;
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
       dispatch(getProjectById(formDataFromLocation?.id));
  }, [dispatch, formDataFromLocation?.id]);

  const { projectById } = useSelector((state) => state.project);
// console.log("projectById" , projectById);
  const assigned_toString = projectById?.assigned_to
    ?.map((item) => item.name)
    .join(", ");
  const contractorString = projectById?.contractor
    ?.map((item) => item.name)
    .join(", ");
  const tasksString = projectById?.tasks?.map((task) => task.name).join(", ");
  
  const subtasks = projectById?.tasks?.reduce((allSubtasks, task) => {
    return allSubtasks.concat(task.subtasks || []);
  }, []);

  const subtasksString = subtasks
    ?.map((subtask) => subtask.name)
    .join(", ");
  
  const rows = [
    {
      id: formDataFromLocation.id,
      name: projectById?.name,
      status: projectById?.status,
      project_contractor: contractorString,
      project_assign_to: assigned_toString,
      task: tasksString,
      subtask: subtasksString,
    },
  ];
  const getHeaderName = (key) => {
    const headerNameMap = {
      name: 'Project Name',
      status: 'Status', // You can continue adding more mappings as needed
      project_contractor:"Contractor",
      project_assign_to:"Assign To",
      task:"Task",
      subtask:"Sub Task"
    };
  
    return headerNameMap[key] || key; // Return the mapped header name, or use the key if not found
  };
  const columns = Object.keys(rows[0]).map((key) => {
    const column = {
      field: key,
      headerName: getHeaderName(key), // Use the getHeaderName function to determine the header name
      flex: 1,
    };

    if (key === 'status') {
      column.renderCell = (params) => {
        let statusClass = effect.complete;
        if (params.value === 'In Progress') {
          statusClass = effect.delay;
        } else if (params.value === 'Pending') {
          statusClass = effect.schedule;
        } else if (params.value === 'Completed') {
          statusClass = effect.completed;
        }else if (params.value === 'TODO') {
          statusClass = effect.schedule;}

        return <span className={statusClass}>{params.value || '—'}</span>;
      };
    }

    return column;
  });

  const [showProjectDetails, setShowProjectDetails] = useState(false); // State to track Project Details visibility
  const projectDetailsHeight = showProjectDetails ? '20vh' : '1vh'; // Determine the height based on visibility

  const toggleProjectDetails = () => {
    setShowProjectDetails(!showProjectDetails);
  };
  useEffect(()=>{
    dispatch(resetTaskActions())
   },[isCreateTaskError,
     isCreateTaskSuccess,
      isUpdateTaskError,
      isUpdateTaskSuccess,
      isDeleteTaskError,
      isDeleteTasksSuccess])

  function displayToast() {
    if (isCreateTaskError) {
       toast.error('Something went wrong Creating Task', {
       toastId: 'CreatingTaskError',
     });
    }else if(isCreateTaskSuccess){
     toast.success('Task added successfully', {
       toastId: 'Task added Success',
     });
    }else if(isDeleteTaskError){
     toast.error('Something Went Wrong Deleting Task', {
       toastId: 'Tasknot added',
     });
    }else if(isUpdateTaskError){
     toast.error('Something Went Wrong Updating Task', {
       toastId: 'Tasknot updated',
     });
    }else if(isDeleteTasksSuccess){
     toast.success('Task Deleted successfully', {
       toastId: 'Task deleted Success',
     });
    }else if(isUpdateTaskSuccess){
     toast.success('Task Updated successfully', {
       toastId: 'Task Updated Success',
     });
    }
 }

 

  return (
    <>
    <div className={classes.assets}>
    <div className={classes.assetsTop}>
      <h3>{formDataFromLocation.name}</h3>
      <div className={classes.assetActions}>
        <input style={{backgroundColor:'white'}} type="text" placeholder="Search"
         onChange={(e)=> dispatch(setSearchValue(e.target.value))} value={searchValue} />
           <button
           onClick={openModal}
              type='button'  
              className={classes.assetfaPlus}>
            <span><FontAwesomeIcon icon={faPlus}  /></span>
            </button>
      </div>
    </div>
  </div>
      
      <div style={{ height: '60vh', width: '100%', marginTop: '5rem' }}>
      {displayToast()}
        <TaskDataTable projectById={projectById} userOptions={userOptions} />
      </div>
      <AddtaskModal
        openModalCreate={isModalOpen}
        closeModalCreate={closeModal}
        formDataFromLocation={formDataFromLocation}
      />
     </>
  
  );
}

