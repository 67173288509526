import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  CardActions
} from '@mui/material'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { setVideoDetails } from '../../../redux/features/session/sessionSlice'

const SafetyVideoForm = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [videoLink, setVideoLink] = useState('')
  const [videoTitle, setVideoTitle] = useState('')
  const [submittedLink, setSubmittedLink] = useState({ link: '', title: '' })
  const { session, video_details } = useSelector(state => state.session)

  useEffect(() => {
    dispatch(
      setVideoDetails({
        title: session?.video?.title,
        link: session?.video?.link
      })
    )
    setSubmittedLink({
      title: session?.video?.title,
      link: session?.video?.link
    })
    setVideoTitle(session?.video?.title)
    setVideoLink(session?.video?.link)
  }, [session])
  const handleSubmit = () => {
    try {
      const theVideo = `https://www.youtube.com/embed/${getYoutubeVideoId(
        videoLink
      )}`
      if (videoLink.includes('youtube.com') && videoTitle !== '') {
        setSubmittedLink({ title: videoTitle, link: theVideo })
        dispatch(setVideoDetails({ title: videoTitle, link: theVideo }))
        // setVideoLink('')
        // setVideoTitle('')
      } else {
        toast.error(
          'Empty video title or invalid youtube link provided, the video might not support embedding , please contact the video owner to enable embedding.',
          {
            toastId: 'invalid induction video'
          }
        )
      }
    } catch (err) {
      toast.error(`error`, {
        toastId: 'invalid induction video2'
      })
    }
  }

  const getYoutubeVideoId = url => {
    const videoId = url?.match(
      /(?:\?v=|\/embed\/|\/\d+\/|\/v\/|https:\/\/youtu.be\/|\/e\/|\/embed\/|\/v=|^youtu.be\/)([^#\&\?]*).*/i
    )
    return videoId[1]
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant='h4' gutterBottom>
        Safety Video Submission
      </Typography>
      <Card
        variant='outlined'
        style={{ maxWidth: 600, margin: '0 auto', marginTop: 20 }}
      >
        {/* <Button sx={{ color: '#f63854' }} onClick={(e) => history.push('/videos')}><FontAwesomeIcon icon="fa-solid fa-arrow-left" style={{color: "#f63854",}} /></Button> */}
        <CardContent>
          <Typography variant='h6'>Video Title</Typography>
          <TextField
            sx={{
              mb: 1
            }}
            id='outlined-basic'
            label='Video Title'
            variant='outlined'
            fullWidth
            value={videoTitle}
            onChange={e => setVideoTitle(e.target.value)}
          />

          <Typography variant='h6'>Submit Video Link</Typography>
          <TextField
            sx={{
              '& input:focus': {
                borderColor: '#F63854',
                boxShadow: '0 0 0 0.2rem rgba(246, 56, 84, 0.25)'
              }
            }}
            label='Video Link'
            // variant="outlined"
            fullWidth
            value={videoLink}
            onChange={e => setVideoLink(e.target.value)}
            margin='normal'
          />
        </CardContent>
        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant='contained'
            style={{ backgroundColor: '#F63854', color: '#FFFFFF' }}
            onClick={handleSubmit}
          >
            Preview
          </Button>
        </CardActions>
      </Card>
      {submittedLink.link !== '' && submittedLink.link !== undefined && (
        <Card variant='outlined' style={{ maxWidth: 600, margin: '20px auto' }}>
          <CardContent>
            {/* <Typography variant="body1" gutterBottom>
              Submitted Video Link
            </Typography> */}
            <h4>{submittedLink.title}</h4>
            <div style={{ position: 'relative', paddingTop: '56.25%' }}>
              <iframe
                title='Embedded YouTube Video'
                src={submittedLink.link}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
                frameBorder='0'
              ></iframe>
            </div>
          </CardContent>
          {/* <CardActions>
            <Button
              variant='contained'
              style={{ backgroundColor: '#F63854', color: '#FFFFFF' }}
              onClick={handlePostVideo}
            >
              Submit
            </Button>
          </CardActions> */}
        </Card>
      )}
    </div>
  )
}

export default SafetyVideoForm
