import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classess from "../../../styles/asset.module.css"

import { useDispatch, useSelector } from 'react-redux';
import { updateStatus } from '../../../redux/features/approveRejectPtw/approveRejectPtwSlice';

function StatusModal({ open, onClose, rowData, status }) {
    const dispatch = useDispatch();
    // const {
    //     title,
    // } = useSelector(state => state.eptw)
    const update = async (e) => {

        dispatch(updateStatus({ id: rowData.id, status: status }))
        // console.log("the status",status)
    }

    return (
        <Modal show={open} onHide={onClose} animation={true}>
            <Modal.Header>
                <Modal.Title>{status === 'Approved' ? "Approve ePTW" : status === 'Closed' ? "Close ePTW" : ""}
                </Modal.Title>
                <button
                    className='btn secondary-btn'
                    onClick={onClose} type='button'>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className={classess.eptwButtons}>
                        {status === 'Approved' ? <h5>Are you sure you want to proceed with approving this ePTW?</h5> : status === 'Closed' ? <h5>Are you sure you want to mark this ePTW as completed?</h5> : ""}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className={classess.eptwButtons}>
                    <Button variant="contained" size="small" onClick={update}>
                        {status === 'Approved' ? "Confirm Approval" : status === 'Closed' ? "Confirm Completion" : ""}

                    </Button>
                </div>
                <Button variant="primary"
                    onClick={onClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default StatusModal
