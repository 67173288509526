import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers } from '../../../redux/features/user/userSlice'
import {
  getContractor,
  updateProject
} from '../../../redux/features/project/projectSclice'
import { Drawer } from '@mui/material'
import { toast } from 'react-toastify'
import moment from 'moment'
export default function Editproject ({ openModal, closeModal, formData }) {
  const getUserProfileData = async () => {
    await dispatch(getAllUsers())
  }
  useEffect(() => {
    getUserProfileData()
  }, [])

  const getContractorList = async () => {
    await dispatch(getContractor())
  }
  useEffect(() => {
    getContractorList()
  }, [])

  const { allUsers } = useSelector(state => state.user)
  const { contractors } = useSelector(state => state.project)
  const contractorOptions = contractors.map(contractor => ({
    id: contractor.id,
    label: contractor.name
  }))
  const userOptions = allUsers.map(user => ({
    id: user.id,
    label: `${user.first_name} ${user.last_name}`
  }))

  const dummyOptions1 = [
    { id: 1, label: 'TODO' },
    { id: 2, label: 'In Progress' },
    { id: 3, label: 'Completed' },
    { id: 4, label: 'Cancelled' }
    // Add more dummy options as needed
  ]

  const dispatch = useDispatch()

  const { projectById } = useSelector(state => state.project)
  const [data, setData] = React.useState({
    id: null,
    name: '',
    location: '',
    status: '',
    assigned_to: [],
    description: '',
    start_date: '',
    finish_date: '',
    actual_start_date: '',
    actual_finish_date: '',
    contractor: [],
    customized_fields: ''
  })

  useEffect(() => {
    if (projectById.id) {
      setData({
        id: projectById.id,
        name: projectById.name || '',
        location: projectById.location || '',
        status: projectById.status || '',
        assigned_to: projectById?.assigned_to?.map(user => user.id) || [],
        description: projectById.description || '',
        start_date: projectById.start_date || '',
        finish_date: projectById.finish_date || '',
        actual_start_date: projectById.actual_start_date || '',
        actual_finish_date: projectById.actual_finish_date || '',
        contractor:
          projectById?.contractor?.map(contractor => contractor.id) || [],
        customized_fields: projectById.customized_fields || ''
      })
    }
  }, [projectById])

  const handleFormSubmit = async e => {
    e.preventDefault()

    // Ensure the required fields are populated
    const updatedData = {
      id: formData?.id,
      name: data.name,
      // assigned_to: data.assigned_to,
      description: data.description,
      // Add other optional fields here
      location: data.location
      // status: data.status,
      // ...and so on
    }
    await dispatch(updateProject(updatedData)) // Wait for the taskDelete action to complete
    closeModal()
  }
  const handleFinishDateChange = e => {
    const start = new Date(data.start_date).getTime()
    const end = new Date(e.target.value).getTime()
    if (start <= end) {
      setData({ ...data, finish_date: e.target.value })
    } else {
      toast.error('Please select a valid start date and end date.')
    }
  }
  return (
    <div>
      <Drawer
        anchor='right'
        open={openModal}
        onClose={closeModal}
        PaperProps={{
          style: {
            width: '100%',
            maxWidth: '600px',
            height: '100%'
          }
        }}
      >
        <form
          action='{{action}}'
          className='h-100'
          method='POST'
          onSubmit={handleFormSubmit}
        >
          <div className='navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0'>
            <div className='col-12 row px-2'>
              <div
                className='my-auto'
                style={{ fontWeight: 'bold', padding: '9px' }}
              >
                Edit Project
              </div>
            </div>
          </div>

          <div className='col-12 pb-1'>
            <div className='card-body row'>
              <div className='col-lg-12 col-sm-12'>
                <span className='font-weight-normal row col-12'>
                  Project:
                  <div className='font-danger'>*</div>
                </span>
                <input
                  type='text'
                  name='name'
                  className='form-control mb-2'
                  value={data.name}
                  onChange={e => setData({ ...data, name: e.target.value })}
                />
              </div>
              <div className='col-lg-12 col-sm-12'>
                <span className='font-weight-normal row col-12'>
                  Location:
                  <div className='font-danger'>*</div>
                </span>
                <input
                  type='text'
                  name='location'
                  className='form-control mb-2'
                  value={data.location}
                  onChange={e => setData({ ...data, location: e.target.value })}
                />
              </div>
              <div className='col-lg-12 col-sm-12'>
                <span className='font-weight-normal row col-12'>
                  Description:
                  <div className='font-danger'>*</div>
                </span>
                <textarea
                  type='text'
                  name='location'
                  className='form-control mb-2'
                  value={data.description}
                  onChange={e =>
                    setData({ ...data, description: e.target.value })
                  }
                />
              </div>
              {/* <div className=" col-lg-6 col-md-6 col-sm-12">
              <label className="font-weight-normal">
                Start Date <label className="font-danger m-0">*</label>
              </label>
              <input
                type="datetime-local"
                className="form-control mb-2"
                name="start_date"
                id="start_date"
                placeholder="Start Date"
                value={moment.utc(data?.start_date).format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => setData({...data,start_date:e.target.value})}
              />
            </div> */}
              {/* <div className=" col-lg-6 col-md-6 col-sm-12">
              <label className="font-weight-normal">
                Finish Date <label className="font-danger m-0">*</label>
              </label>
              <input
                type="datetime-local"
                className="form-control mb-2"
                name="finish_date"
                id="finish_date"
                placeholder="Start Date"
              
                value={moment.utc(data?.finish_date).format("YYYY-MM-DDTHH:mm")}
                // onChange={(e) =>setData({...data,finish_date:e.target.value})}
                onChange={handleFinishDateChange} 
              />
            </div> */}
              {/* <div className=" col-lg-6 col-md-6 col-sm-12">
              <label className="font-weight-normal">
                Actusal Start Date{" "}
                <label className="font-danger m-0">*</label>
              </label>
              <input
                type="datetime-local"
                className="form-control mb-2"
                name="actual_start_date"
                id="actual_start_date"
                placeholder="Start Date"
              
                value={data.actual_start_date}
                onChange={(e) =>setData({...data,actual_start_date:e.target.value})}
              />
            </div> */}
              {/* <div className=" col-lg-6 col-md-6 col-sm-12">
              <label className="font-weight-normal">
                Actusal Finish Date{" "}
                <label className="font-danger m-0">*</label>
              </label>
              <input
                type="datetime-local"
                className="form-control mb-2"
                name="actual_finish_date"
                id="actual_finish_date"
                placeholder="Start Date"
              
                value={data.actual_finish_date}
                onChange={(e) => setData({...data,actual_finish_date:e.target.value})}
              />
            </div> */}
              {/* <div className="col-lg-12 col-sm-12">
              <label className="font-weight-normal">
                Select Status <span className="font-danger">*</span>
              </label>
              <div className="col-12 p-0">
                <select
                  className="form-control select-meter custom-select2 mb-2"
                  name="status"
                  id="status"
                  style={{ width: "100%" }}
                
                  value={data.status}
                  onChange={(e) => setData({...data,status:e.target.value})}
                >
                  <option value="" disabled>
                    --SELECT--
                  </option>
                  {dummyOptions1.map((option) => (
                    <option key={option.id} value={option.label}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>

              <label className="font-weight-normal">
                Assign To <span className="font-danger">*</span>
              </label>
              <div className="col-12 p-0">
                <select
                  className="form-control select-meter custom-select2 mb-2"
                  name="assignto"
                  id="assignto"
                  style={{ width: "100%" }}
                
                  value={data.assigned_to}
                  onChange={(e) => setData({...data,assigned_to:[parseInt(e.target.value)]})}
                >
                  <option value="" disabled>
                    --SELECT--
                  </option>
                  {userOptions.map((option) => (
                    <option key={`${option.id}-${option.label}`} value={option.id}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <label className="font-weight-normal">
                Contractor <span className="font-danger">*</span>
              </label>
              <div className="col-12 p-0">
                <select
                  className="form-control select-meter custom-select2 mb-2"
                  name="contractor"
                  id="contractor"
                  style={{ width: "100%" }}
                
                  value={data.contractor}
                  onChange={(e) => setData({...data,contractor:[parseInt(e.target.value)]})}
                >
                  <option value="" disabled>
                    --SELECT--
                  </option>
                  {contractorOptions.map((option) => (
                    <option key={`${option.id}-${option.label}`} value={option.id}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div> */}
              {/* <div className="col-lg-12 col-sm-12">
                <span className="font-weight-normal row col-12">
                  Customized Feilds
                  <div className="font-danger">*</div>
                </span>
                <input
                  type="text"
                  name="project"
                  className="form-control mb-2"
                  value={data.customized_fields}
                  onChange={(e) =>setData({...data,customized_fields:e.target.value})}
                />
              </div> */}
              {/* </div> */}
            </div>
          </div>

          <div className='row  m-0 p-2 page-profile-footer'>
            <button
              className='btn button-btn pmd-btn-fab primary-btn'
              type='submit'
              onClick={handleFormSubmit}
            >
              <span className='button__text'>Save</span>
            </button>
          </div>
        </form>
      </Drawer>
    </div>
  )
}
