import React, { useState } from 'react'
import CloseSharpIcon from '@mui/icons-material/CloseSharp'
import AddSharpIcon from '@mui/icons-material/AddSharp'
import TextField from '@mui/material/TextField'
import styles from '../../../styles/asset.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { setStep, setTemplate, setTemplateName } from '../../../redux/features/quiz/quizSlice'
import { setQuizName } from '../../../redux/features/session/sessionSlice'

const steps = ['Select a name for template', 'Enter details', 'Review Template']
const InductionQuizForm = ({customSection,setCustomSection}) => {
    const {
        session_id,
        quizName,
        sessions
      } = useSelector(state => state.session)
      const [addCustomInputs, setAddCustomInputs] = useState(false)
    //   const [quizName,setQuizName] = useState("")
      const dispatch = useDispatch()
      const backButtonStyle = {
        backgroundColor: '#f63854',
        color: 'white',
        '&:hover': {
          backgroundColor: '#f63854'
        }
      }
      const addCustomSectionHandler = type => {
        if (type === 'section') {
          if (customSection.length > 0) {
            if (customSection.some(obj => obj.title === '')) {
              return
            }
            if (
              customSection[customSection.length - 1].feilds.length === 0 ||
              customSection.some(obj => obj.feilds.some(item => item.name === ''))
            ) {
              return
            }
            setCustomSection([...customSection, { title: '', feilds: [] }])
          }
          setCustomSection([...customSection, { title: '', feilds: [] }])
        }
    
        if (type === 'feild') {
          if (customSection.some(obj => obj.title === '')) {
            return
          }
          if (
            customSection.some(obj => obj.feilds.some(item => item.name === ''))
          ) {
            return
          }
          const idx = customSection?.length - 1
          const updatedItems = customSection.map((item, index) => {
            if (index === idx) {
              return {
                ...item,
                feilds: [...item.feilds, { name: '', type: 'Wrong' }] // Update the title within the 'section' object
              }
            }
            return item
          })
          setCustomSection(updatedItems)
        }
      }
      const addnewFeildHandler = (i, type, idx) => {
        const arr = [...customSection]
        if (type === 'feild') {
          arr[i].feilds.splice(idx + 1, 0, { name: '', type: 'Wrong' })
        }
        // else if (type === "section") {
        //   arr.splice(i + 1 , 0 , { title: "", feilds: [] })
        // }
        setCustomSection(arr)
      }
    
      const removeCustomSectionHandler = (i, type, idx) => {
        const list = [...customSection]
        if (type === 'section') {
          list.splice(i, 1)
        }
        if (type === 'feild') {
          list[i].feilds.splice(idx, 1)
        }
        setCustomSection(list)
      }
    
      const handelCutomSectionChange = (e, i, id, type, idx) => {
        const { name, value } = e.target
        let updatedItems
        if (type === 'title') {
          updatedItems = customSection.map((item, index) => {
            if (index === i) {
              return { ...item, title: value } // Update the title within the 'section' object
            }
            return item
          })
        } else if (type === 'feild') {
          updatedItems = [...customSection]
          if (name === 'name') {
            updatedItems[i].feilds[idx].name = value
          }
          if (name === 'type') {
            updatedItems[i].feilds[idx].type = value
          }
        }
        setCustomSection(updatedItems)
      }
    return (
        <div className={styles.assetForm}>
      <div className={styles.assetInfo}>
        <>
        
          <div className='Checkboxes'>
            <div>
            <TextField
            // error={templateNameError}
            // helperText={templateNameError && 'Incorrect entry.'}
            label='Quiz Name'
            defaultValue={quizName}
            variant='outlined'
            fullWidth
            margin='normal'
            onChange={(e)=>dispatch(setQuizName(e.target.value))}
            // onChange={event => {
            //   dispatch(setTemplateName(event.target.value))
            //   dispatch(setTemplateNameError(false))
            // }}
          />
        </div>
            {customSection?.length > 0 &&
              customSection?.map((input, i) => {
                return (
                  <>
                    {' '}
                    <div key={i} className={styles.controlsSp}>
                      <div className={styles.controlSp}>
                        <TextField
                          label={`Question ${i + 1}`}
                          variant='outlined'
                          fullWidth
                          margin='normal'
                          onChange={e =>
                            handelCutomSectionChange(e, i, input?.id, 'title')
                          }
                          value={input.title}
                        />
                      </div>
                      {/* <div className='flex'> */}
                      {/* <button className={`${styles.controlSpBtn} ${styles.controlSpBtnHov} mr-1`}
                    onClick={() => addnewFeildHandler(i , "section")} type='button'
                    title='Add Section'>
                    <AddSharpIcon />
                  </button> */}
                      <button
                        className={styles.controlSpBtn}
                        onClick={() => removeCustomSectionHandler(i, 'section')}
                        type='button'
                        title='Remove Section'
                      >
                        <CloseSharpIcon />
                      </button>
                      {/* </div> */}
                    </div>
                    <div className='Checkboxes'>
                      {input.feilds.length > 0 &&
                        input.feilds.map((item, idx) => {
                          return (
                            <div key={i} className={styles.controlsSection}>
                              <div className={styles.controlSection}>
                                <input
                                  name='name'
                                  placeholder={`answer${i + 1}`}
                                  onChange={e =>
                                    handelCutomSectionChange(
                                      e,
                                      i,
                                      item?.id,
                                      'feild',
                                      idx
                                    )
                                  }
                                  value={item.name}
                                />
                                <select
                                  name='type'
                                  value={item.type}
                                  onChange={e => {
                                    handelCutomSectionChange(
                                      e,
                                      i,
                                      item?.id,
                                      'feild',
                                      idx
                                    )
                                  }}
                                >
                                  <option value='Wrong'>❌ Wrong</option>
                                  <option value='Right'>☑️ Correct</option>
                                  {/* <option value="Checkbox">☑️  Checkbox</option> */}
                                  {/* <option value="Date">⏰ Date & Time </option> */}
                                </select>
                              </div>
                              <div className='flex'>
                                <button
                                  className={`${styles.controlSectionBtn} ${styles.hovering} mr-1`}
                                  onClick={() =>
                                    addnewFeildHandler(i, 'feild', idx)
                                  }
                                  type='button'
                                  title='Add Field'
                                >
                                  <AddSharpIcon />
                                </button>
                                <button
                                  className={styles.controlSectionBtn}
                                  onClick={() =>
                                    removeCustomSectionHandler(i, 'feild', idx)
                                  }
                                  type='button'
                                  title='Remove Feild'
                                >
                                  <CloseSharpIcon />
                                </button>
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </>
                )
              })}

            {/* addCustomInputHandler */}
            <div className={styles.actionsSection}>
              <button
                disabled={customSection.length === 0}
                onClick={() => addCustomSectionHandler('feild')}
                className='mr-3'
                type='button'
              >
                <AddSharpIcon />
                Add Answer
              </button>
              <button
                onClick={() => addCustomSectionHandler('section')}
                type='button'
              >
                <AddSharpIcon />
                Add Question
              </button>
            </div>
          </div>
        </>
      </div>
    </div>
    );
};

export default InductionQuizForm;