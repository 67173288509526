import React, { useEffect, useMemo, useState } from 'react'
import classes from '../../styles/asset.module.css'
import { DataGrid } from '@mui/x-data-grid';
import Addteams from '../../components/teams/addteams';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from "@mui/material";
import Loader from '../../components/loader/loader';

import profilePicture from '../../assets/img/profile.png';
import PopUpActivateModel from '../../components/teams/popUpModals/popupActivateModal';
import PopUpDeleteModel from '../../components/teams/popUpModals/popUpDeleteModal';

import { toast } from 'react-toastify';
import { Avatar } from '@mui/material';
import {
  closeContractorModalCreate,
  closeContractorModalDelete,
  createContractor, getContractor,
  openContractorModalCreate,
  openContractorModalDelete,
  openContractorModalUpdate,
  deleteContractor,
  setContractor,
  setContractors,
  setContractorSearchTerm,
  reset as resetContractor,
  updateContractor,
} from '../../redux/features/project/projectSclice'
import AddContractor from '../../components/teams/addcontractor';
import PopUpDeleteContractorModel from '../../components/teams/popUpModals/popupDeleteContractorModal';

export default function ContractorList() {
  const dispatch = useDispatch();
  const [data, setData] = useState({ first_name: "", last_name: "", email: "", phone_number: "", company_name: "" })
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(getContractor())
  }, [dispatch])
  const { contractors } = useSelector((state) => state.project)
  useEffect(() => {
    setData({
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_number: data.phone_number,
      company_name: data.company_name,
    })
  }, [])
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  // Getting value from redux
  const {
    contractorModalCreate,
    contractorModalUpdate,
    contractorModalDelete,
    contractorSearchTerm,
    isCreateContractorLoading,
    isUpdateContractorLoading,
    isDeleteContractorLoading,
    isCreateContractorSuccess,
    isUpdateContractorSuccess,
    isDeleteContractorSuccess,
    isCreateContractorError,
    isUpdateContractorError,
    isDeleteContractorError,
  } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(resetContractor());
  }, [
    isCreateContractorSuccess,
    isUpdateContractorSuccess,
    isDeleteContractorSuccess,
    isCreateContractorError,
    isUpdateContractorError,
    isDeleteContractorError,
  ])
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("selected",selectedContractor);
    await dispatch(updateContractor(selectedContractor))
    setData({ first_name: "", last_name: "", email: "", phone_number: "", company_name: "" });
    dispatch(closeContractorModalDelete())
  };
  function stringAvatar(name1, name2, image) {
    if (image && !image.includes('no_profile')) {
      return {
        src: image
      };
    } else {
      //     const initials = name.trim().split(/\s+/)
      //       .map(part => part.charAt(0).toUpperCase())
      //       .join('');
      const initials = name1.charAt(0).toUpperCase() + name2.charAt(0).toUpperCase() || "--"
      return {
        children: initials
      };
    }
  }
  const columns = useMemo(() => [
    {
      field: 'avatar', headerName: 'Picture', width: 150, renderCell: (params) => {
        // console.log("Avtar Param", params.row); // Log the parameters for debugging
        return <Avatar {...stringAvatar(params?.row?.first_name || "", params?.row?.last_name || "", params?.row?.avatar)} title={params?.row?.first_name+ " " + params?.row?.last_name||"contractor"} alt="Avatar" />;
      }
    },
    { field: 'company_name', headerName: 'Company Name', width: 250 },
    { field: 'first_name', headerName: 'First Name', width: 250 },
    { field: 'last_name', headerName: 'Last Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'phone_number', headerName: 'Phone Number', width: 150 },
    { field: 'category', headerName: 'Category', width: 150 },
    // { field: 'position', headerName: 'Position', width: 150 },
    // { field: 'department', headerName: 'Department', width: 150 },
    // { field: 'role', headerName: 'Account Type', width: 200 },
    // {
    //   field: 'is_active',
    //   headerName: 'Active',
    //   width: 200,
    //   renderCell: (params) => (
    //     <span
    //       className={`px-3 py-1 uppercase leading-wide font-bold text-xs rounded-full shadow-sm ${params.value === 'True' ? 'bg-green-100 text-green-800' : ''} ${params.value === 'False' ? 'bg-red-100 text-red-800' : ''}`}
    //     >
    //       {params.value === 'True' ? 'Active' : 'Inactive'}
    //     </span>
    //   ),
    // },

  ])

  //   const rows = teams.filter(team=>team.first_name.toLowerCase().startsWith(teamSearchTerm)).map(item => ({
  //     avatar: item.image,
  //     id: item.id,
  //     email: item.email,
  //     first_name: item.first_name,
  //     last_name: item.last_name,
  //     is_active: item.is_active,
  //     role: item.role,
  //     position: item?.manager?.position?.name,
  //     positionId: item?.manager?.position?.id,
  //     department: item?.department?.name,
  //     departmentId: item?.department?.id,
  //     hourly_rate: item?.manager?.hourly_rate ? item?.manager?.hourly_rate : null
  //   }));
  //todo
  const rows = contractors.filter(selectedContractors => selectedContractors.first_name.toLowerCase().startsWith(contractorSearchTerm))
  // const rows = contractors
  const [showPopupActivate, setShowPopupActivate] = useState(false);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [showPopupEdit, setShowPopupEdit] = useState(false); // Controls modal visibility
  const [selectedContractor, setSelectedContractor] = useState([]); // Holds the data of the selected team

  function displayToast() {
    if (isCreateContractorError) {
      toast.error('Something went wrong with creating the Contractor', {
        toastId: 'contructorCreateError',
      });
    } else if (isUpdateContractorError) {
      toast.error('Something went wrong at updating Contractor', {
        toastId: 'updatingContractorError'
      })
    } else if (isDeleteContractorError) {
      toast.error('Something went wrong at deleting Contractor', {
        toastId: 'deletingContractorError'
      })
    } else if (isCreateContractorSuccess) {
      toast.success('Contractor Created Successfully', {
        toastId: 'createContractorSuccess'
      })
    } else if (isUpdateContractorSuccess) {
      toast.success('Contractor Updated Successfully', {
        toastId: 'updateContractorSuccess'
      })
    } else if (isDeleteContractorSuccess) {
      toast.success('Contractor Deleted Successfully', {
        toastId: 'deleteContractorSuccess'
      })
    }
  }

  const handleCloseEdit = () => {
    setShowPopupEdit(false);
    setSelectedContractor(null);
  };

  const handleCloseDelte = () => {
    setShowPopupDelete(false);
  };
  const handleDeleteContractor = () => {
    dispatch(deleteContractor(selectedContractor?.id));
    setShowPopupDelete(false);
  };


  const isLoading =
    isCreateContractorLoading ||
    isUpdateContractorLoading ||
    isDeleteContractorLoading;

  return isLoading ? (
    <Loader />
  ) : (

    <>
      {displayToast()}
      <div className={classes.assets}>
        {/* adding teams  */}
        <AddContractor />
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            onRowClick={(params) => {
              const selectedRowData = params.row;
              setSelectedContractor(selectedRowData);
              setShowPopupEdit(true); // Open the modal
              dispatch(setContractor(selectedRowData))
            }}
            style={{ background: 'white' }}
          />

        </div>

        <div>
          <PopUpDeleteContractorModel
            showModal={showPopupDelete}
            handleClose={handleCloseDelte}
            data={selectedContractor}
            handleSave={handleDeleteContractor}
          />
        </div>
        <div>
          <Drawer anchor="right" open={showPopupEdit} onClose={handleCloseEdit}>
            {selectedContractor && (
              <>
                <div className="modal-dialog modal-lg" id="modal-dialog">
                  <div className="modal-content" id="modal-content">
                    <div className="modal-body p-0" id="modal-body">
                      <form
                        action="{{action}}"
                        className="h-1000"
                        style={{ width: '600px' }}
                        // method="POST"
                        onSubmit={handleSubmit}
                      >
                        <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header">
                          <div className="col-12 row px-2">

                          </div>
                          <div className="col-lg-12 col-sm-12 row"> </div>

                          <div className="col-lg-12 col-sm-12 row">

                            <div className="col-12 text-center flex justify-content-center">
                              {/* {user?.image ? (
                                          <img
                                            className="prof_img img-circle"
                                            height="100"
                                            alt="profile"
                                            width="100"
                                            src={user?.image}
                                          />
                                        ) : ( */}
                              <img
                                className="prof_img img-circle"
                                height="100"
                                width="100"
                                alt="profile"
                                src={profilePicture}
                              />
                              {/* )} */}
                            </div>
                            {user?.role === "Administrator" ?

                              <button
                                onClick={() => {
                                  setShowPopupDelete(true);
                                  setShowPopupEdit(false);
                                }}
                                className="btn deactivate-btn"
                                data-toggle="modal"
                                data-target="#modal-status"
                                title="Delete"
                                type="button"
                              >
                                Delete
                              </button>
                              : null}
                            <div className="col-12 text-center">
                              <div className="large">
                                <span>
                                  {selectedContractor?.first_name} {selectedContractor?.last_name}
                                </span>
                              </div>
                              <div className="col-12 text-center">
                                <div className="medium-title">
                                  <span>Requester </span>
                                </div>
                              </div>
                            </div>

                            <div className="row justify-content-start mt-2 col-lg-12 col-sm-12 ">
                              <ul
                                className="nav nav-pills justify-content-center"
                                id="custom-tabs-two-tab"
                                role="tablist"
                              >
                                <li className="nav-item">
                                  <a
                                    className="nav-link active"
                                    id="custom-tabs-two-user-tab"
                                    data-toggle="tab"
                                    href="#user-tab"
                                    role="tab"
                                    aria-controls="custom-tabs-two-user"
                                    aria-selected="false"
                                  >
                                    Requester Details
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <>
                          <div
                            className="card-body mx-auto row col-lg-12 pb-0"
                            style={{ paddingBottom: '0rem !important' }}
                          >
                            <div className="col col-lg-12 col-sm-12 mr-2">
                              <div className="row mb-0">
                                <div className=" col-lg-12 col-sm-12">
                                  <div className="row mb-0">
                                    <div className="row mb-0">
                                      <div className=" col-lg-6 col-sm-12">
                                        {' '}
                                        <span className="font-weight-normal">
                                          First Name <span className="font-danger m-0">*</span>
                                        </span>
                                        <input
                                          name="firstname"
                                          type="text"
                                          className="form-control mb-2"
                                          required
                                          placeholder="firstname"
                                          id="username"
                                          onChange={e => setSelectedContractor({ ...selectedContractor, first_name: e.target.value })
                                          }
                                          value={selectedContractor?.first_name}
                                          disabled={user?.role !== 'Administrator'}
                                        />
                                      </div>
                                      <div className=" col-lg-6 col-sm-12">
                                        {' '}
                                        <span className="font-weight-normal">
                                          Last Name <span className="font-danger m-0">*</span>
                                        </span>
                                        <input
                                          name="lastname"
                                          type="text"
                                          className="form-control mb-2"
                                          required
                                          placeholder="last Name"
                                          id="username"
                                          onChange={e => setSelectedContractor({ ...selectedContractor, last_name: e.target.value })
                                          }
                                          value={selectedContractor?.last_name}
                                          disabled={user?.role !== 'Administrator'}
                                        />
                                      </div>
                                      <div className=" col-lg-6 col-sm-12">
                                        {' '}
                                        <span className="font-weight-normal">
                                          Email <span className="font-danger m-0">*</span>
                                        </span>
                                        <input
                                          name="email"
                                          type="email"
                                          className="form-control mb-2"
                                          required
                                          placeholder="Email"
                                          id="username"
                                          onChange={e => setSelectedContractor({ ...selectedContractor, email: e.target.value })}

                                          value={selectedContractor?.email}
                                          disabled={user?.role !== 'Administrator'}
                                        />
                                      </div>
                                      <div className=" col-lg-6 col-sm-12">
                                        {' '}
                                        <span className="font-weight-normal">
                                          Phone Number <span className="font-danger m-0">*</span>
                                        </span>
                                        <input
                                          name="phoneNumber"
                                          type="text"
                                          className="form-control mb-2"
                                          required
                                          placeholder="Phone Number"
                                          id="phoneNumber"
                                          onChange={e => setSelectedContractor({ ...selectedContractor, phone_number: e.target.value })
                                          }
                                          value={selectedContractor?.phone_number}
                                          disabled={user?.role !== 'Administrator'}
                                        />
                                      </div>
                                      <div className=" col-lg-6 col-sm-12">
                                        {' '}
                                        <span className="font-weight-normal">
                                          Company Name <span className="font-danger m-0">*</span>
                                        </span>
                                        <input
                                          name="companyname"
                                          type="text"
                                          className="form-control mb-2"
                                          required
                                          placeholder="Company Name"
                                          id="companyname"
                                          onChange={e => setSelectedContractor({ ...selectedContractor, company_name: e.target.value })
                                          }
                                          value={selectedContractor?.company_name}
                                          disabled={user?.role !== 'Administrator'}
                                        />
                                      </div>
                                      <div className=" col-lg-6 col-sm-12">
                                        {' '}
                                        <span className="font-weight-normal">
                                          Category
                                        </span>
                                        {/* <input
                                          name="companyname"
                                          type="text"
                                          className="form-control mb-2"
                                          required
                                          placeholder="Company Name"
                                          id="companyname"
                                          onChange={e => setSelectedContractor({ ...selectedContractor, category: e.target.value })
                                          }
                                          value={selectedContractor?.category}
                                          disabled={user?.role !== 'Administrator'}
                                        /> */}
                                           <select className="form-control"
                                              name="Category" id="Category"
                                              disabled={user?.role !== 'Administrator'}
                                              onChange={e => setSelectedContractor({ ...selectedContractor, category: e.target.value })}
                                              value={selectedContractor?.category}               >
                                              <option value="" disabled selected>--SELECT--</option>
                                              {[{val:"Internal Requestor" , label:"Internal Requester"}, 
                                                  {val:"Contractor" , label:"Contractor"}].map(item => (
                                                  <option key={item.val} value={item.val}>{item.label}</option>
                                              ))}
                                          </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className=" row  m-0 p-2 page-profile-footer">
                            <button className="btn primary-btn" type="submit" disabled={user?.role !== 'Administrator'}>
                              Save
                            </button>
                          </div>
                        </>
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Drawer>

        </div>
      </div>
    </>
  )
}



