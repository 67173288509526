import React from "react";
import classes from "../../../styles/asset.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faPlus } from "@fortawesome/free-solid-svg-icons";
import { openEptwModalCreate, setEptwSearchTerm } from "../../../redux/features/eptw/eptwSlice";
import { useDispatch } from "react-redux";
export default function AddEptw() {
  const dispatch = useDispatch()
  return (
    <div>
      <div className={classes.assets}>
        <div className={classes.assetsTop}>
          <h4 className={classes.assetsTitle}>Permit Management</h4>
          <div className={classes.assetActions}>
          <input style={{backgroundColor:'white'}}  type="text" placeholder="Search" onChange={(e)=>{dispatch(setEptwSearchTerm(e.target.value.toLowerCase()))}}/>
            <button
            onClick={()=>
              dispatch(openEptwModalCreate())
            }
              type='button'  
              className={classes.assetfaPlus}>
            <span><FontAwesomeIcon icon={faPlus}  /></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
