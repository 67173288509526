import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getTeamByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/team/`);
  return assert(res, res.data, 'Retrieval of team  by its company ID failed', res);
};

const activateTeam = async TeamId => {
  const res = await http.put(`${BASE_URL}/team_activate/${TeamId}/`, { is_active: 'True' });

  return assert(res, res.data, 'Activation of team  failed', res);
};

const deactivateTeam = async TeamId => {
  const res = await http.put(`${BASE_URL}/team_deactivate/${TeamId}/`, { is_active: 'False' });

  return assert(res, res.data, 'Deactivation of team  failed', res);
};

const getTeamById = async TeamId => {
  const res = await http.get(`${BASE_URL}/team/${TeamId}`);

  return assert(res, res.data, 'Retrieval of team  by its ID failed', res);
};

const createTeam = async TeamData => {
  const res = await http.post(`${BASE_URL}/team/new_user/`, TeamData);

  return assert(res, res.data, 'Creation of team  failed', res);
};

const deleteTeam = async TeamId => {
  const res = await http.delete(`${BASE_URL}/team/${TeamId}/`);

  return assert(res, TeamId, 'Deletion of team  failed', res);
};

const updateTeam = async TeamData => {
  const res = await http.put(`${BASE_URL}/team/${TeamData.id}/`, TeamData.dataToSend);

  return assert(res, res.data, 'Update of team  failed', res);
};

// const getPagination = async data => {
//   const res = await http.get(
//     `${BASE_URL}/team/?search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}`,
//   );
//   return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
// };

const TeamService = {
  getTeamByAuthentication,
  getTeamById,
  createTeam,
  deleteTeam,
  updateTeam,
  activateTeam,
  deactivateTeam,
};

export default TeamService;
