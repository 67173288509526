import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import AddSafetyToContractor from './AddSafetyToContractor'
import { useDispatch, useSelector } from 'react-redux'
import { getContractor } from '../../../redux/features/project/projectSclice'
import { DataGrid } from '@mui/x-data-grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import {
  expireVideoConsent,
  extendVideoConsent,
  getVideoConsent,
  reset,
  updateVideoConsentStatus
} from '../../../redux/features/safety/safetySlice'
import BasicCard from '../../../components/materialCard/materialCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import InductionSystemView from '../../../components/IndctionSystemView'

const SafetyManagement = () => {
  const [rows, setRows] = useState([])
  const dispatch = useDispatch()
  const [showPin, setShowPin] = useState(false);
  const {
    video_consent,
    isVideoConsentError,
    isVideoConsentSuccess,
    isUpdateVideoConsentSuccess,
    isUpdateVideoConsentError,
    isExtendSessionSuccess,
    isExpireSessionSuccess
  } = useSelector(state => state.safety)
  const [approve, setApprove] = useState(0)
  const [pending, setPending] = useState(0)
  const [completed, setCompleted] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedInduction, setSelectedInduction] = useState({})
  useEffect(() => {
    dispatch(getVideoConsent())
    dispatch(getContractor())
  }, [])

  useEffect(() => {
    let pendingCount = 0
    let approveCount = 0
    let completedCount = 0

    video_consent.forEach(video => {
      // console.log(video)
      if (video.status === 'Approved') {
        approveCount++
      } else if (video.status === 'Pending'||"Extended"||"Expired") {
        pendingCount++
      } else if (video.status === 'Submitted') {
        completedCount++
      }
    })

    setApprove(approveCount)
    setPending(pendingCount)
    setCompleted(completedCount)
    // const filteredRows = video_consent.filter(
    //   video => video.status === 'Pending's
    // )
    const updatedRows = video_consent.map(item => ({
      id: item.id,
      company: item?.contractor?.company_name || 'X',
      contractor: item?.contractor?.first_name+" "+item?.contractor?.last_name,
      expiry_date: item.expiry_date,
      induction_name: item.video.title,
      project: item?.project?.name,
      filled_by: item.filled_by,
      Disable: [item?.link, item?.pin_code, item?.video_induction?.id],
      status: item?.status,
      Session: item.link,
      induction_link: item?.video?.link
    }))

    setRows(updatedRows)
  }, [video_consent])

  const handleCopyClick = async (params) => {
    await navigator.clipboard.writeText(params);
    setShowPin(true)
  };
  function displayToast() {
    if (isVideoConsentSuccess) {
      toast.success('Video consent created successfully', {
        toastId: 'Video consent created successfully'
      })
    } else if (isVideoConsentError) {
      toast.error('Something went wrong', {
        toastId: 'Something went wrong'
      })
    } else if (isUpdateVideoConsentSuccess) {
      toast.success('Status of video induction session updated successfully', {
        toastId: 'Status of video induction session'
      })
    } else if (isUpdateVideoConsentError) {
      toast.error('Failed to update video induction session', {
        toastId: 'failed to update video induction session'
      })
    }else if(isExtendSessionSuccess){
      toast.success("Video induction session enabled",{
        toastId: 'Session extended'
      })
    }else if (isExpireSessionSuccess){ 
      toast.success("Video induction session disabled",{
        toastId: 'Session expired'
      })
    }
    dispatch(reset())
  }
  const handlePopUp = params => {
    // console.log('activated')
    setSelectedInduction(params)
    setIsOpen(true)
  }
  const columns = useMemo(() => [
    // {
    //   field: 'detailedView',
    //   headerName: '....',
    //   width: 50,
    //   renderCell: params => (
    //     <FontAwesomeIcon
    //       icon={faUpRightFromSquare}
    //       onClick={() => handlePopUp(params.row)}
    //       style={{ cursor: 'pointer' }}
    //     />
    //   )
    // },
    {
      field: 'company',
      headerName: 'Company',
      width: 250
    },
    { field: 'contractor', headerName: 'Contractor', width: 300 },
    { field: 'expiry_date', headerName: 'Expiry date', width: 150 },
    { field: 'induction_name', headerName: 'Induction Name', width: 150 },
    { field: 'project', headerName: 'Project', width: 150 },
    { field: 'filled_by', headerName: 'Filled by', width: 150 },
    {
      field: 'Disable',
      headerName: 'Status',
      width: 150,
      renderCell: params =>
        params.row.status === 'Pending' || params.row.status === 'Extended' ? (
          <Button 
            onClick={e=>dispatch(expireVideoConsent(params.row.id))}
          variant='outlined' color='error'>
            Disable 
          </Button>
        ) : (
          <Button 
            onClick={e=>dispatch(extendVideoConsent(params.row.id))}
          variant='outlined'>Enable</Button>
        )

        /* <Button onClick={e => {
          const linkData = params.row.Disable[0].split('details/')[1].split('/')
          const data = {
            id: linkData[0],
            contractor_id: linkData[1],
            pin_code: params.row.Disable[1],
            video_induction: params.row.Disable[2]
          }
          dispatch(updateVideoConsentStatus(data))

        }} variant="outlined">Complete</Button> */
    },
    {
      field: 'Session',
      headerName: 'Session',
      width: 200,
      renderCell: params => (
        <a href={params.row.Session} target='_blank' rel='noopener noreferrer'>
          <Button variant='outlined' color='primary'>
            SESSION LINK
          </Button>
        </a>
      )
    },
    {
      field: 'induction_link',
      headerName: 'Session View',
      width: 150,
      renderCell: (params) => (
        <a onClick={() => handlePopUp(params.row)} target="_blank" rel="noopener noreferrer">
          <Button variant="outlined" color="error">
            View
          </Button>
        </a>
      ),
    },
    // {
    //   field: 'pin',
    //   headerName: 'PIN',
    //   width: 150,
    //   renderCell: (params) => (

    //     <Button variant="outlined" color="error" onClick={(e) => handleCopyClick(params.row.Disable[1])}>
    //       {!showPin ? '********' : params.row.Disable[1]}
    //     </Button>
    //   ),
    // },
  ]);

  return (
    <div>
      <AddSafetyToContractor />
      {displayToast()}
      <Grid container spacing={3}>
          <Grid container justifyContent='center' spacing={2} mb={4} px={5}>
            <Grid item xs={12} md={6} sm={6}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography color='#f63854' variant='h5' component='div'>
                    Pending Inductions
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography color='#f63854' variant='h4'>
                    {pending}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography color='#f63854' variant='h5' component='div'>
                    Completed Inductions
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography color='#f63854' variant='h4'>
                    {completed}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

      </Grid>
      <BasicCard
        component={
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={4}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 }
                }
              }}
              pageSizeOptions={[5, 10, 20]}
              style={{ background: 'white' }}
            />
          </div>
        }
        m={2}
      />
      <InductionSystemView
        data={selectedInduction}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  )
}

export default SafetyManagement
