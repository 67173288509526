/* eslint-disable react/prop-types */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './style.css'
function PopUpDeleteContractorModel({
    showModal,
    handleClose,
    handleSave,
}) {
    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Contractor</Modal.Title>
                <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={handleClose}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="col">
                    <div className="row">
                        <div className="col-lg-12 col-sm-12 m-0">
                            Are you sure you want to delete this contractor
                            {/* this {data?.first_name}? */}
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="custom-button" onClick={handleClose}>
                    Close
                </Button>
                <Button className="action-button" onClick={handleSave}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default PopUpDeleteContractorModel;
