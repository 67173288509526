import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'
import SafetyManagement from '../SafetyManagement/SafetyManagement'
import InductionManagement from '../InductionManagement/InductionManagement'
const SafetyHomeDashboard = () => {
  const [key, setKey] = useState(
    JSON.parse(localStorage.getItem('safetyTab')) || 'safety-tab'
  )
  const { user, isLoading, isError, isSuccess } = useSelector(
    state => state.user
  )

  useEffect(() => {
    localStorage.setItem('safetyTab', JSON.stringify(key))
  }, [])
  return (
    // <div className=' ml-2'>
      <Tabs
        defaultActiveKey={key}
        id='uncontrolled-tab-example'
        className='nav nav-tabs custom_tabs'
        onSelect={k => setKey(k)}
      >
        <Tab eventKey='safety-tab' title='Safety Induction Management'>
          <SafetyManagement/>
          {/* <TeamsTab/> */}
        </Tab>
        {/* {!isLoading && user?.role!=="Manager"?
        <Tab eventKey='approver-tab' title='Approvers'>
        </Tab>
        :null} */}
        <Tab eventKey='induction-tab' title='Session Management'>
          <InductionManagement />
        </Tab>
      </Tabs>
    // </div>
  )
}

export default SafetyHomeDashboard
