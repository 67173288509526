/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { smartTryCatch } from '../../../utils'
import AnalyticsService from './analyticService'

const initialState = {
  analytics: {},
  // analytics status
  isAnalyticsLoading: false,
  isAnalyticsSuccess: false,
  isAnalyticsError: false,

  // to store the chart image link
  pieChartImg: '',
  progressChartImg: '',
  semiCircleChartImg: '',
  trendTaskChartImg: '',

  // filter data
  filter: null,
  staff: null
}

export const getAnalyticsForDashboard = createAsyncThunk(
  'getAnalyticsForDashboard',
  async (data, thunkAPI) => {
    const analyticsResult = await smartTryCatch(
      AnalyticsService.getAnalyticsForDashboard,
      data,
      thunkAPI
    )
    return analyticsResult
  }
)

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initialState,
  reducers: {
    reset: state => {
      state.analytics = {}
      state.isAnalyticsError = false
      state.isAnalyticsLoading = false
      state.isAnalyticsSuccess = false
      state.pieChartImg = ''
      state.progressChartImg = ''
      state.semiCircleChartImg = ''
      state.trendTaskChartImg = ''
      state.filter = null
      state.staff = null
    },
    setPieChartImage: (state, action) => {
      state.pieChartImg = action.payload
    },
    setProgressChartImage: (state, action) => {
      state.progressChartImg = action.payload
    },
    setSemiCircleChartImage: (state, action) => {
      state.semiCircleChartImg = action.payload
    },
    setTrenTaskChartImage: (state, action) => {
      state.trendTaskChartImg = action.payload
    },
    setDate: (state, action) => {
      state.filter = action.payload
    },
    setSite: (state, action) => {
      state.site = action.payload
    },
    setLocation: (state, action) => {
      state.location = action.payload
    },
    setStaff: (state, action) => {
      state.staff = action.payload
    },
    resetAnalytic: state => {
      state.pieChartImg = ''
      state.progressChartImg = ''
      state.semiCircleChartImg = ''
      state.trendTaskChartImg = ''
    }
  },

  extraReducers: builder => {
    builder
      .addCase(getAnalyticsForDashboard.pending, state => {
        state.isAnalyticsError = false
        state.isAnalyticsLoading = true
        state.isAnalyticsSuccess = false
      })
      .addCase(getAnalyticsForDashboard.fulfilled, (state, action) => {
        state.isAnalyticsError = false
        state.isAnalyticsLoading = false
        state.isAnalyticsSuccess = true
        state.analytics = action.payload
      })
      .addCase(getAnalyticsForDashboard.rejected, state => {
        state.isAnalyticsError = true
        state.isAnalyticsLoading = false
        state.isAnalyticsSuccess = false
      })
  }
})

export const {
  analytics,
  setPieChartImage,
  setProgressChartImage,
  setSemiCircleChartImage,
  setTrendTaskChartImage,
  setDate,
  setStaff,
  resetAnalytic
} = analyticsSlice.actions

export default analyticsSlice.reducer
