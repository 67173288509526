import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { getProjectById, projectDelete, setObjectData, setOpenSafetyPopup } from "../../../redux/features/project/projectSclice";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { IconButton } from "@mui/material";
import Editproject from "../projectDrawer/editproject";
import PopUpDeleteModal from "../../popUpModals/popUpDeleteModal";
import Avatar from '@mui/material/Avatar';

const getStatusColor = (status) => {
  switch (status) {
    case 'In Progress':
      return {
        backgroundColor: '#007bff', // Blue for "In Progress"
        color: '#FFFFFF', // White text
        fontSize: 10
      };
    case 'Completed':
      return {
        backgroundColor: '#28a745', // Green for "Completed"
        color: '#FFFFFF', // White text
      };
    case 'Cancelled':
      return {
        backgroundColor: '#dc3545', // Red for "Cancelled"
        color: '#FFFFFF', // White text
      };
    default:
      return {
        backgroundColor: '#eb618a', // Default color for other statuses
        color: '#FFFFFF', // White text
      };
  }
}

export default function ProjectCard({ formData }) {
  const [projectData, setProjectData] = React.useState(null)
  const [showPopupDelete, setShowPopupDelete] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCloseDelte = () => {
    setShowPopupDelete(false);
  };
  const shorten=(text) =>{
    var ret = text||"";
    if (ret.length > 36) {
        ret = ret.substr(0,36-3) + "...";
    }
    return ret;
}
  const handleViewClick = async () => {
    try {
      await dispatch(getProjectById(formData.id)); // project id is passing
      history.push({
        pathname: "/project-task-table", //Redecting to the component
        state: { formData: formData },
      });
    } catch (error) {
      console.error("Error fetching project data:", error);
    }
  };
  const handleDeleteClick = async () => {
    await dispatch(projectDelete(formData.id));
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const handleEditClick = async () => {
    setOpenEditModal(true);
    setProjectData(formData)
    await dispatch(getProjectById(formData.id))
  };

  function stringAvatar(name1,name2, image) {
    if (image && !image.includes('no_profile')) {
      return {
        src: image
      };
    } else {
  //     const initials = name.trim().split(/\s+/)
  //       .map(part => part.charAt(0).toUpperCase())
  //       .join('');
const initials = name1.charAt(0).toUpperCase()+name2.charAt(0).toUpperCase()||"--"
  return {
        children: initials
      };
    }
  }
  const handelPush = ()=>{
    history.push({
      pathname: "/safetyDashBoard", //Redecting to the component
    });
    dispatch(setOpenSafetyPopup(true))
    dispatch(setObjectData(formData))
  }
  
  return (
    <>
      <Card
        // sx={{
        //   cursor: "pointer",
        //   maxWidth: '25rem',
        //   borderRadius: '12px',
        //   boxShadow: 3,
        //   transition: 'transform 0.3s, box-shadow 0.3s',
        //   '&:hover': {
        //     transform: 'translate(0, -5px) scale(1.02)',
        //     boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)', // Slightly stronger shadow on hover
        //   },
        // }}
        sx={{
          cursor: "pointer",
          maxHeight: "16rem",
          minWidth: '20rem',
          borderRadius: '12px',
          boxShadow: 3,
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            transform: 'translate(0, -5px) scale(1.02)',
            boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <CardContent>
          <div style={{display: "flex" , justifyContent: "space-between" , alignItems: "center"}}>
          <Typography title={formData.name} onClick={handleViewClick} gutterBottom variant="h6" component="div" style={{ fontWeight: 'bold'  }}>
            {(formData.name?.length > 14 ? formData.name?.slice(0, 15)+`...` : formData.name) || 'Fox IT'}
          </Typography>
          <button title="Add Safety Induction" className="btn secondary-btn mb-1" onClick={handelPush} >
           {formData?.induction_session?.name ? 
           (formData?.induction_session?.name?.length > 8 ? formData?.induction_session?.name?.slice(0, 9)+`...` : formData?.induction_session?.name)  : <FontAwesomeIcon icon={faPlus}  />}
          </button>
          </div>
          <div
            onClick={handleViewClick}
            style={{
              fontWeight: 'bolder',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Typography variant="subtitle2" color="textSecondary">
                Location:
              </Typography>
              <Typography variant="body2">
                {/* {formData.status == "TODO" ? 'To do' : formData.status || 'N/A'} */}
                {shorten(formData?.location) || ""}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Description:
              </Typography>
              <Typography variant="body2" >
              {shorten(formData?.description) || ""}
              </Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Created By:
              </Typography>
              {/* <Typography variant="body2"> */}
                {/* {formData.status == "TODO" ? 'To do' : formData.status || 'N/A'} */}
                {/* {formData?.created_by?.first_name || ""} */}
              {/* </Typography> */}
              <div style={{ display: 'flex', gap: '8px' }}>
                {
                  // <Avatar alt={stringAvatar(formData?.created_by?.first_name||"",formData?.created_by?.username) || ""} src={formData?.created_by?.image||"/profile.png"} sx={{ width: 32, height: 32 }} />
                  <Avatar {...stringAvatar(formData?.created_by?.first_name,formData?.created_by?.last_name,formData?.created_by?.image,formData?.created_by?.image) || ""} title={formData?.created_by?.first_name||"created by"} sx={{ width: 32, height: 32 }} />
                }
                </div>
              {/* <div style={{ display: 'flex', gap: '8px' }}>
                {formData.assigned_to.map(assigned => {
                  return <Avatar src="/profile.png" sx={{ width: 32, height: 32 }} />
                })} */}
              {/* <Avatar sx={{ width: 32, height: 32 }} />
              <Avatar sx={{ width: 32, height: 32 }} /> */}
              {/* </div> */}
            </div>
          </div>
        </CardContent>
        <CardActions style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}>
          {/* <Button
            variant="contained"
            sx={{
              fontSize:10,
              fontWeight:'bold',
              width: '100px',
              height: '36px',
              marginRight: '8px',
              backgroundColor: '#eb618a',
              // borderColor: '#F63854',
              ...getStatusColor(formData.status), // Apply the color based on status
              '&.MuiButton-contained:hover': {
                background: getStatusColor(formData.status).backgroundColor, // Use the same color on hover
              },
            }}
          >
            {formData.status=="TODO"?'To do':formData.status}
          </Button>
          <Button
            variant="contained"
            onClick={handleViewClick}
            sx={{
              fontWeight:'bold',
              width: '100px',
              height: '36px',
              backgroundColor: '#F63854',
              color: '#ffffff',
              '&.MuiButton-contained:hover': {
                background: '#F63854',
              },
            }}
          >
            View
          </Button> */}
          <IconButton onClick={(event) => {
            event.stopPropagation()
            setShowPopupDelete(true)
          }}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton onClick={(event) => {
            event.stopPropagation();
            handleEditClick();
          }}>
            <EditIcon />
          </IconButton>
        </CardActions>
      </Card>
      <Editproject
        openModal={openEditModal}
        closeModal={() => setOpenEditModal(false)}
        formData={projectData}
      />
      <PopUpDeleteModal
        showModal={showPopupDelete}
        handleClose={handleCloseDelte}
        handleConfirm={handleDeleteClick}
        modalTitle="Delete Project"
        modalBodyText="Are you sure you want to delete the project? This action cannot be
        undone."
      />
    </>
  );
}
