import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { getContractorSessionDetailsPin, setPinCodeVerified } from '../../redux/features/session/sessionSlice'
import SafetyDashboard from '../../pages/SafetyDashBoard/SafetyDashboard'
import { getCompanyProfile } from '../../redux/features/company/companySlice'

const PinPage = () => {
    const { contractor_session_details_pin, isPinCodeVerified } = useSelector(state => state.session)
    const { company } = useSelector(state => state.company);
    const dispatch = useDispatch()
    const [pin, setPin] = useState('')
    const [hidePin, setHidePin] = useState(false)
    const [showResult, setShowResult] = useState(false)
    const { sessionId, contractorId, id, encoded } = useParams()
    // useEffect(()=>{
    //     dispatch(getCompanyProfile())
    // },[])
    useEffect(() => {
        dispatch(setPinCodeVerified(false))
    })
    const handlePinChange = event => {
        const newPin = event.target.value
        setPin(newPin)
    }
    const Submit = () => {
        const decoded = atob(encoded)
        // console.log('encoded', encoded, 'decoded', decoded, 'pin', pin)
        const data = {
            id: sessionId,
            contractor_id: contractorId,
            pin_code: decoded,
            video_induction: +id
        }
        dispatch(getContractorSessionDetailsPin(data))
        setShowResult(true)
        setHidePin(true)
    }
    useEffect(() => {
    }, [contractor_session_details_pin])

    const backButtonStyle = {
        backgroundColor: '#f63854',
        color: 'white',
        '&:hover': {
            backgroundColor: '#f63854'
        }
    }
    return (
        <div style={{ position: 'relative', height: '100vh' }}>
            {!showResult?
            <img
                src={company?.logo||'/FOX-Logo3.jpeg'}
                alt='Company Logo'
                style={{
                    position: 'absolute',
                    top: '10px',
                    left: '10px',
                    maxWidth: '100px',
                    borderRadius:'20px'
                }}
            />:null}

            {/* Centered content */}
            <Grid
                container
                justifyContent='center'
                alignItems='center'
                style={{ height: '100%' }}
            >
                {!hidePin ? <Grid item>
                    <Typography mb={5} variant='h4' gutterBottom>
                        Enter PIN Code
                    </Typography>
                    <TextField
                        sx={{ mb: 2 }}
                        fullWidth
                        placeholder='**********'
                        id='fullWidth'
                        type='password'
                        value={pin}
                        onChange={handlePinChange}
                    />
                    {pin && (
                        <Button onClick={Submit} fullWidth style={backButtonStyle}>
                            Login
                        </Button>
                    )}
                </Grid> : null}
                {showResult ? <SafetyDashboard img={company?.logo||'/FOX-Logo3.jpeg'} data={contractor_session_details_pin} /> : null}
            </Grid>
        </div>
    )
}

export default PinPage
