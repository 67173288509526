import React from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

// Create a styled button using the styled() function
const CustomButton = styled(Button)(({ theme }) => ({
  background: "#F63854", // Customize the background color
  color: "white",       // Text color
  borderRadius: "8px",  // Border radius
  padding: "10px 20px", // Padding
  fontWeight: "bold",   // Font weight
  fontSize: "16px",     // Font size
  textTransform: "none", // Disable uppercase transformation
  // Add a rule specifically for the contained variant to remove hover effect
  "&.MuiButton-contained:hover": {
    background: "#F63854", // Same as the default background color
  },
}));

export default CustomButton;
