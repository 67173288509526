import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classess from "../../../styles/asset.module.css"

import { useDispatch, useSelector } from 'react-redux';
import { updateStatus } from '../../../redux/features/approveRejectPtw/approveRejectPtwSlice';

function RejectModal({ open, onClose, rowData , setIsError , isError }) {
    const [reason, setReason] = React.useState('')
    const dispatch = useDispatch();
    const {
        title,
    } = useSelector(state => state.eptw)
    const confirmRejection = (e) => {
        if (reason) {
            dispatch(updateStatus({ id: rowData.id, status: "Rejected", comment: reason }))
        }else{
            setIsError(true)
        }
    }
    const updateRejectionReason = (e) => {
        setReason(e.target.value)
        setIsError(false)
    }

    return (
        <Modal show={open} onHide={onClose} animation={true}>
            <Modal.Header>
                <Modal.Title>Reject ePTW</Modal.Title>
                <button
                    className='btn secondary-btn'
                    onClick={onClose} type='button'>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </Modal.Header>
            <Modal.Body>
                
                    <div className="pt-2">
                        <label className="font-weight-normal">Please state your reason to reject.<label className="font-danger m-0">*</label></label>
                        {isError && <p className="font-danger mb-1">Please fill Reason Input</p>}
                        <textarea
                            required
                            name='meter_name'
                            className="form-control mb-4"
                            rows="4" // Adjust the number of rows as needed to control the height
                            placeholder="Rejection reason"
                            value={reason}
                            onChange={updateRejectionReason}
                        />
                    </div>
            </Modal.Body>
            <Modal.Footer>
                {/* <div className={classess.eptwButtons}> */}
                <Button type="submit" variant="primary" size="small" onClick={confirmRejection}>
                    Confirm Rejection
                </Button>
                {/* </div> */}
                {/* <Button variant="primary"
                    onClick={onClose}
                >
                    Close
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
}

export default RejectModal
