import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import InductionSuperVisorTable from '../../components/InductionSuperVisorTable/InductionSuperVisorTable';
import { getCompanyProfile } from '../../redux/features/company/companySlice';
import  Button  from '@mui/material/Button';

const SafetyDashboard = ({data,logo}) => {
    
    const malaysiaTimeZone = 'Asia/Kuala_Lumpur';
    const date = new Date(data.created_at);
    const endDate = new Date(data.expiry_date);
    const backButtonStyle = {
        backgroundColor: '#f63854',
        color: 'white',
        '&:hover': {
            backgroundColor: '#f63854',
        },
    };
    const handlePrint = () => {
        window.print();
      };
    return (
        <div
            style={{
                background: '#F8F8F8',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}
        >

            <Box sx={{
                flexGrow: 1,
                background: '#F8F8F8',
            }}>
                <Card sx={{ borderRadius: '12px', margin: 20 }}>
                    <img
                        src={logo}
                        alt="Company Logo"
                        style={{

                            maxWidth: '100px',
                        }}
                    />
                    <CardContent>

                        <Grid
                            container
                            spacing={8}
                            justifyContent="center" 
                            alignItems="center"    
                            style={{ minHeight: '90vh' }} 
                        >
                            <Grid item xs={6}>
                                <h4>Company Name:{data.company_name}</h4>
                                <h4>Induction ID: {data.id}</h4>
                                {/* <h4>Induction Detail: </h4> */}
                            </Grid>
                            <Grid item xs={6}>
                                <h4>Creation Date: {date.toLocaleString('en-MY', { timeZone: malaysiaTimeZone })}</h4>
                                {/* <h4>Expiry Date: {new Date(data.expiry_date).toLocaleDateString()}</h4> */}
                                <h4>Expiry Date: {endDate.toLocaleString('en-MY', { timeZone: malaysiaTimeZone })}</h4>
                                <Button style={backButtonStyle} onClick={handlePrint}>Print page</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <InductionSuperVisorTable filledBy={data} />
                            </Grid>
                        </Grid>

                    </CardContent>
                </Card>
            </Box>
        </div>
    );
};

export default SafetyDashboard;