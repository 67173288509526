import React from "react";
import loader from "../../assets/img/preloader.gif";
import "./loader.css"

function Loader  ()  {
  return <div className="loader">
    <img src={loader} alt="Loader" />
  </div>;
};

export default Loader;