import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Unstable_Grid2'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Checkbox from '@mui/material/Checkbox'
import FoxLogo from '../../assets/img/FOX-Logo3.png'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getContractorSession, postContractorSession, reset } from '../../redux/features/session/sessionSlice'
import MobileStepper from '@mui/material/MobileStepper'
import Paper from '@mui/material/Paper'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { useTheme } from '@mui/material/styles'
import safetyImf from '../../assets/img/sessionCreatedSuccess.jpg'
import Loader from '../../components/loader/loader'
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import NotFoundPage from '../../components/404/NotFoundPage'
import DangerousIcon from '@mui/icons-material/Dangerous';
const SafetySession = () => {

  const paperStyle = {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '800px',
  };
  const iconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f63854',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    marginBottom: '16px',
  };
  const iconStyle = {
    fontSize: '48px',
    color: 'white',
  };

  const messageStyle = {
    textAlign: 'center',
    marginBottom: '16px',
  };

  const cardStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };
  const [isSubmit, setisSubmit] = useState(false)
  const [checked, setChecked] = useState(false)
  const [failed, setFailed] = useState(false)
  const [quizzes, setQuizzes] = useState([])
  const [activeStep, setActiveStep] = useState(0)
  const [totalCorrect, setTotalCorrect] = useState(0)
  const [answers, setAnswers] = useState([0, 0])
  const [sessionData, setSessionData] = useState({
    name: '',
    id_number: '',
    email: '',
    phone_number: '',
    passport_number: '',
    permit_number: '',
    checkbox: true,
    role: '',
    video_induction_session: 0,
  })
  const { param1, param2 } = useParams()
  const dispatch = useDispatch()
  const history = useHistory();

  const theme = useTheme()
  const maxSteps = quizzes?.length
  const { isContractorSessionPostSuccess, isContractorSessionPostError, isContractorSessionGetPending, contractor_session } = useSelector(
    state => state.session
  )

  useEffect(() => {
    dispatch(getContractorSession({ id: param1, contractor_id: param2 }))
  }, [])
  useEffect(() => {
    const array = []
    contractor_session?.video_induction?.video?.quizes[0]?.questions.map(ques => {
      const quizData = {
        question: ques.question,
        answer1: ques.answer1,
        answer2: ques.answer2,
        answer3: ques.answer3,
        answer4: ques.answer4,
        correct_answer: ques.correct_answer,
        given_answer: null,
        isCorrect: false,
        id: ques.id
      }
      array.push(quizData)
    })
    setQuizzes(array)
    setSessionData({
      ...sessionData,
      video_induction_session: contractor_session?.id
    })
  }, [contractor_session])
  const backButtonStyle = {
    backgroundColor: '#f63854',
    color: 'white',
    '&:hover': {
      backgroundColor: '#f63854'
    }
  }

  const handleNext = e => {
    const value = e.target.value
    if (value === 'Next') {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    } else if (value === 'Submit') {
      // setisSubmit(true)
      handleSubmitForm()
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }
  const handleAnswer = e => {
    const updatedQuizzes = [...quizzes]
    updatedQuizzes[activeStep].given_answer = +e.target.value

    // check the correct answer
    if (updatedQuizzes[activeStep].correct_answer === +e.target.value) {
      updatedQuizzes[activeStep].isCorrect = true
      setTotalCorrect(
        totalCorrect === maxSteps ? totalCorrect : totalCorrect + 1
      )
    } else {
      setTotalCorrect(totalCorrect === 0 ? 0 : totalCorrect - 1)
      updatedQuizzes[activeStep].isCorrect = false
    }
    setQuizzes(updatedQuizzes)
  }

  const validate = (input) => {
    const contractorName = /^[a-zA-Z',@. ]{1,70}$/;
    // const contractorPhonePattern = /\+60\d{2,12}/;
    // const rolepattern = /^[a-zA-Z,\s-]{1,128}$/;
    const permitNumberPattern = /^[a-zA-Z0-9-]{1,128}$/;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;


    if (!contractorName.test(input.name)) {
      toast.error("Invalid Name, name cannot be empty or contain special characters", {
        toastId: "invalid induction contractor name"
      })
      return false
    }
    if (!emailPattern.test(input.email)) {
      toast.error("Invalid email entered, please ensure the email entered is valid", {
        toastId: "invalid email address"
      })
      return false
    }
    // if (!contractorPhonePattern.test(input.phone_number)) {
    //   toast.error("Invalid phone number, A valid phone number starts with +60", {
    //     toastId: "invalid phone number"
    //   })
    //   return false
    // }
    if (!input.id_number && !input.ikad) {
      toast.error("A passport Number or an Ikad number must be submitted", {
        toastId: "invalid passport ikad"
      })
      return false
    }
    // if (!rolepattern.test(input.role)) {
    //   toast.error("Invalid input in role, please ensure the role contains atleast 1 character or number", {
    //     toastId: "invalid session role"
    //   })
    //   return false
    // }
    if (!permitNumberPattern.test(input.permit_number)) {
      toast.error("Invalid permit number, please ensure the permit number contains atleast 1 character or number", {
        toastId: "invalid permit number"
      })
      return false
    }
    return true

  }

  const handleSubmitForm = e => {
    const isValiddForm = validate(sessionData)
    let correctAnswerCount = 0;
    let wrongAnswerCount = 0;
    if (isValiddForm) {
      // }
      // else if(isValiddForm){
      const questionCount = quizzes.length;
      const inductionLink = `/video_induction_session/details/${param1}/${param2}/`
      console.log("quizzes" , quizzes);
      quizzes.map((singleQ) => {
        singleQ.isCorrect === true ? correctAnswerCount += 1 : wrongAnswerCount += 1;
      })
      const result = wrongAnswerCount > 0;
      if (result) {
        setAnswers([correctAnswerCount, wrongAnswerCount])
        setFailed(true)
        // history.push('/induction/Fail',{result,correctAnswerCount,wrongAnswerCount,inductionLink,sessionData})
      }
      else if (!result) {
        sendInducitonToServer()
      }
    }
    else {
      dispatch(reset())
    }
  }

  const sendInducitonToServer = () => {
    dispatch(postContractorSession(sessionData))
    history.push('/induction/success')
  }
  function displayToast() {
    if (isContractorSessionPostSuccess) {
      toast.success('Induction Form successfully submitted', {
        toastId: 'Induction Form',
      });
    } else if (isContractorSessionPostError) {
      toast.error("Induction Form failed to submit", {
        toastId: "Induction Form Failed"
      })
    }
    dispatch(reset())
  }

  const handleButtonClick = () => {
    const encoded = btoa(contractor_session.pin_code)
    history.push(`/view-status/${param1}/${param2}/${contractor_session?.video_induction?.id}/${encoded}`);
  };
  return isContractorSessionGetPending ? (
    <Loader />
  ) : contractor_session?.status === "Pending" || contractor_session?.status === "Extended" ? (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        mt: 6,
        p: 8,
        justifyContent: 'center'
      }}
    >
      {displayToast()}
      <Grid container justifyContent='center' spacing={2}>
        <Grid xs={12}>
          <Card elevation={3} style={{ justifyContent: 'center', paddingBottom: 4, ...cardStyle }}>
            <img
              src={contractor_session?.company_image || FoxLogo}
              style={{ width: '70%', maxWidth: '200px' }}
              alt='company logo'
            />
            <Typography variant='h3'>FOX Safety Induction Session</Typography>
            <Typography sx={{ pb: 2 }} variant='h4'>{contractor_session?.video_induction?.title}</Typography>
            <Button onClick={handleButtonClick} style={backButtonStyle}>Supervisor Login</Button>
          </Card>
        </Grid>
        <Grid xs={12}>
          <Container maxWidth='md'>
            <Card sx={{ mb: 4 }}>
              <CardContent>
                <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                  <iframe
                    title='induction video'
                    width='100%'
                    height='100%'
                    src={contractor_session?.video_induction?.video?.link}
                    frameBorder='0'
                    allowFullScreen
                    style={{ position: 'absolute', top: 0, left: 0 }}
                  />
                </div>
              </CardContent>
            </Card>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    onChange={e =>
                      setSessionData({ ...sessionData, name: e.target.value })
                    }
                    label='Full Name'
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={e =>
                      setSessionData({ ...sessionData, email: e.target.value })
                    }
                    label='Email'
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={e =>
                      setSessionData({
                        ...sessionData,
                        phone_number: e.target.value
                      })
                    }
                    label='Phone Number'
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    onChange={e =>
                      setSessionData({
                        ...sessionData,
                        id_number: e.target.value
                      })
                    }
                    label='Passport / MyKad Number'
                    fullWidth
                    required
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <TextField
                    onChange={e =>
                      setSessionData({ ...sessionData, ikad: e.target.value })
                    }
                    label='MyKad Number'
                    fullWidth
                    required
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <TextField
                    onChange={e =>
                      setSessionData({
                        ...sessionData,
                        permit_number: e.target.value
                      })
                    }
                    label='Permit Number'
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <TextField
                    onChange={e =>
                      setSessionData({ ...sessionData, role: e.target.value })
                    }
                    label='Role'
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Card sx={{ mt: 4 }}>
                {!failed &&
                <CardContent>
                  <Typography variant='h5' component='h2'>
                    Quiz section
                    {/* Add the quiz section here shaheen */}
                  </Typography>
                  {isSubmit ? (
                    <>
                      <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                        <Paper
                          square
                          elevation={0}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 50,
                            pl: 2,
                            bgcolor: 'background.default'
                          }}
                        >
                          <Typography>
                            {activeStep + 1} : {quizzes[activeStep]?.question}
                          </Typography>
                        </Paper>
                        <Box
                          sx={{
                            height: 255,
                            maxWidth: 400,
                            width: '100%',
                            p: 2
                          }}
                        >
                          {
                            <FormControl>
                              <>
                                <RadioGroup
                                  aria-labelledby='demo-radio-buttons-group-label'
                                  defaultValue='female'
                                  name='radio-buttons-group'
                                  onChange={handleAnswer}
                                  value={
                                    quizzes[activeStep]?.given_answer || ''
                                  }
                                >
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label={quizzes[activeStep]?.answer1}
                                  />
                                  <FormControlLabel
                                    value={2}
                                    control={<Radio />}
                                    label={quizzes[activeStep]?.answer2}
                                  />
                                  {quizzes[activeStep]?.answer3 &&
                                    <FormControlLabel
                                      value={3}
                                      control={<Radio />}
                                      label={quizzes[activeStep]?.answer3}
                                    />
                                  }
                                  {quizzes[activeStep]?.answer4 &&
                                    <FormControlLabel
                                      value={4}
                                      control={<Radio />}
                                      label={quizzes[activeStep]?.answer4}
                                    />
                                  }
                                </RadioGroup>
                              </>
                              {/* {quizzes[activeStep]?.given_answer && (
                                <p
                                  style={{
                                    color: quizzes[activeStep]?.isCorrect
                                      ? 'green'
                                      : 'red'
                                  }}
                                >
                                  {quizzes[activeStep]?.isCorrect
                                    ? 'Correct Answer'
                                    : 'Wrong Answer... Try again'}
                                </p>
                              )} */}
                            </FormControl>
                          }
                        </Box>
                        <MobileStepper
                          variant='text'
                          steps={maxSteps}
                          position='static'
                          activeStep={activeStep}
                          nextButton={
                            <Button
                              size='small'
                              onClick={handleNext}
                              value={
                                activeStep === maxSteps - 1 ? 'Submit' : 'Next'
                              }
                            // disabled={!quizzes[activeStep]?.isCorrect}
                            >
                              {activeStep === maxSteps - 1 ? 'Submit' : 'Next'}
                              {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                              ) : (
                                <KeyboardArrowRight />
                              )}
                            </Button>
                          }
                          backButton={
                            <Button
                              size='small'
                              onClick={handleBack}
                              disabled={activeStep === 0}
                            >
                              {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                              ) : (
                                <KeyboardArrowLeft />
                              )}
                              Back
                            </Button>
                          }
                        />
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                      <img
                        height={10}
                        src={safetyImf}
                        alt='session created image'
                        style={{ height: '20em', marginBottom: '10px' }}
                      />
                    </Box>
                  )}
                </CardContent>}
                {
                  !isSubmit && <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
                      label='I hereby declare that I have watched the entire Safety Induction video and accept the terms and conditions of the company.'
                    />
                      <Grid item xs={12}>
                      <Button
                        onClick={()=> setisSubmit(true)}
                        variant='contained'
                        style={!checked ? { color: 'gray' } : backButtonStyle}
                        fullWidth
                        disabled={!checked}
                      >
                        {"Agree"}
                      </Button>
                      </Grid>
                  </Grid>
                }
                {
                  isSubmit && failed && <Grid item xs={12}>
                    <Paper style={paperStyle} elevation={3}>
                      <div style={iconContainerStyle}>
                        <DangerousIcon style={iconStyle} />
                      </div>
                      <Typography variant="h5" style={messageStyle}>
                        Result : Fail
                      </Typography>
                      <Typography variant="h5" style={messageStyle}>
                        Your Score: {((answers[0]) / (answers[1] + answers[0]) * 100)?.toFixed(2)}% {(answers[0] + answers[1])} questions
                      </Typography>
                      <Typography variant="h5" style={messageStyle}>
                        Passing Score: 100% ({(answers[0] + answers[1])} correct answer(s))
                      </Typography>
                      {/* {console.log("all",props.location.state.sessionData)} */}
                      <Button
                        variant="contained"
                        style={backButtonStyle}
                        onClick={() => {
                          quizzes.forEach((quiz) => {
                            quiz.given_answer = null
                          })
                          setActiveStep(0)
                            setFailed(false)
                        }
                        }
                      >
                        Try Again
                      </Button>

                    </Paper>
                    {/* <FormControlLabel
                      control={<Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)} />}
                      label='I hereby declare that I have watched the entire Safety Induction video and accept the terms and conditions of the company.'
                    /> */}
                  </Grid>
                }
                {/* <Grid item xs={12}>
                  <Button
                    onClick={sendInducitonToServer}
                    variant='contained'
                    style={!checked ? { color: 'gray' } : backButtonStyle}
                    fullWidth
                    disabled={!checked}
                  >
                    {"Submit"}
                  </Button>
                </Grid> */}
              </Card>
            </form>
          </Container>
        </Grid>
      </Grid>
    </Box>
  ) : (
    // <p>The form is no longer available</p>
    <NotFoundPage />
  )
}

export default SafetySession
