import { assert, http } from '../../../utils'
import { BASE_URL } from '../../../utils/axios'

const getContractorSessionDetailsPin = async data => {
  const res = await http.post(
    `${BASE_URL}/video_induction_session/supervisor_details/${data.id}/${data.contractor_id}/`,
    {
      pin_code: data.pin_code,
      video_induction: data.video_induction
    }
  )
  return assert(res, res.data, 'Get the Form details failed', res)
}

const getContractorSession = async data => {
  const res = await http.getUrlWithNoAuthHeader(
    `${BASE_URL}/video_induction_session/details/${data.id}/${data.contractor_id}`
  )
  return assert(res, res.data, 'Getting the video indication form failed', res)
}

const postContractorSession = async data => {
  const res = await http.postUrlWithNoAuthHeader(
    `${BASE_URL}/video_induction_session/submit/`,
    data
  )
  return assert(
    res,
    res.data,
    'Post of contractor induction session failed',
    res
  )
}

const getInductionSession = async data => {
  const res = await http.get(`${BASE_URL}/video_induction/`)
  return assert(res, res.data, 'Getting Induction session is failed', res)
}
const getInductionSessionByID = async id => {
  const res = await http.get(`${BASE_URL}/video_induction/${id}/`)
  return assert(res, res.data, 'Getting Induction session is failed', res)
}
const getInductionSessionFillingDataById = async id => {
  const res = await http.get(`${BASE_URL}/video_induction_session/${id}/`)
  return assert(res, res.data, 'Getting Induction session fill details failed', res)
}

const postInductionSession = async data => {
  const res = await http.post(`${BASE_URL}/video_induction/`, data)
  return assert(res, res.data, 'Create Induction session is failed', res)
}

const deleteInductionSession = async id => {
  const res = await http.delete(`${BASE_URL}/video_induction/${id}/`)
  return assert(res, res.data, 'Getting Induction session is failed', res)
}
const updateInductionSession = async data => {
  const res = await http.patch(
    `${BASE_URL}/video_induction/${data.id}/`,
    data.data
  )
  return assert(res, res.data, 'Getting Induction session is failed', res)
}

const sessionService = {
  getContractorSessionDetailsPin,
  getInductionSessionByID,
  getInductionSession,
  postInductionSession,
  deleteInductionSession,
  updateInductionSession,
  getContractorSession,
  postContractorSession,
  getInductionSessionFillingDataById
}

export default sessionService
