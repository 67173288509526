import React, { useEffect, useState } from "react";
import { Drawer, colors } from "@mui/material";
import classes from "../../../styles/asset.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../redux/features/user/userSlice";
import { toast } from 'react-toastify';
import { addProject, 
  getContractor,
   resetProject,
   setActualFinishDate,
    setActualStartDate,
     setAssignTo,
     setContractor,
     setCustomizedFeilds, 
     openProjectModalCreate,
     setDiscription, 
     setFinishDate,  
     setLocation,
    setName, 
    setProjectSearchTerm, 
    setStartDate, 
    setStatus,
    closeProjectModalCreate} from "../../../redux/features/project/projectSclice";

export default function AddProject({
  openModal,
  openModalCreate,
}) {

  const {name,location,status,assigned_to,contractor,description,start_date,
  finish_date,actual_finish_date,actual_start_date,customized_fields,projectModalCreate
  }= useSelector((state) => state.project)

  // const getUserProfileData = async ()=> {
  //   await dispatch(getAllUsers());
  // }
  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getContractor());
  }, []);

  // const getContractorList = async ()=> {
  //   await dispatch(getContractor());
  // }
  // useEffect(() => {
  //   getContractorList()
  // }, []);

  const { allUsers } = useSelector((state) => state.user);
  const {  contractors,projectSearchTerm } = useSelector((state) => state.project);
  const contractorOptions = contractors.map((contractor) => ({
    id: contractor.id,
    label: contractor.first_name+contractor.last_name, 
    
  }));
  const userOptions = allUsers.map((user) => ({
    id: user.id,
    label: `${user.first_name} ${user.last_name}`, 
  }));
  
  const dummyOptions1 = [
    { id: 1, label: "TODO",name:"TO DO" },
    { id: 2, label: "In Progress",name:"In Progress" },
    { id: 3, label: "Completed",name:"Completed" },
    { id: 4, label: "Cancelled",name:"Cancelled" },
    // Add more dummy options as needed
  ];
  const [selectedContractor, setSelectedContractor] = useState('');
  const [selectedAssignedto, setSelectedAssignedto] = useState('');
  const [startDate,setStartDateforLogic]=useState('')
  const [finishDate,finishDateforLogic]=useState('')
  const dispatch = useDispatch();
  // const handleFormSubmit =async(e) => {
  //   e.preventDefault();
  // const formData = new FormData()
   
  // if(name){
  //   formData.append("name",name)
  //  }
  //  if(location){
  //   formData.append("location",location)
  //  }
  //  if(status){
  //   formData.append("status",status)
  //  }
  //  if(assigned_to){
  //   formData.append("assigned_to",selectedAssignedto)
  //  }
  //  if(contractor){
  //   formData.append("contractor",selectedContractor)
  //  }
  //  if(description){
  //   formData.append("description",description)
  //  }
  //  if(start_date){
  //   formData.append("start_date",start_date)
  //  }
  //  if(finish_date){
  //   formData.append("finish_date",finish_date)
  //  }
  //  if(actual_start_date){
  //   formData.append("actual_start_date",actual_start_date)
  //  }
  //  if(actual_finish_date){
  //   formData.append("actual_finish_date",actual_finish_date)
  //  }
  //  if(customized_fields){
  //   formData.append("customized_fields",customized_fields)
  //  }

  //   await dispatch(addProject(formData));; 
   
  //   dispatch(resetProject())
  //   dispatch(closeProjectModalCreate())
  //   // closeModalCreate()
  // };
  //a more efficient method
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    const fields = {
      name,
      location,
      status,
      assigned_to: selectedAssignedto,
      contractor: selectedContractor,
      description,
      start_date,
      finish_date,
      actual_start_date,
      actual_finish_date,
      customized_fields,
    };
  
    Object.entries(fields).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });
  
    await dispatch(addProject(formData));
    dispatch(resetProject());
    dispatch(closeProjectModalCreate());
    // closeModalCreate()
  };
const handleName =(e)=>{
 dispatch(setName(e.target.value))
}
const handleLocation = (e)=>{
  dispatch(setLocation(e.target.value))
}
const handleStatus=(e)=>{
  dispatch(setStatus(e.target.value))
}
const handleDiscription =(e)=>{
  dispatch(setDiscription(e.target.value))
}
const handleStartDate =(e)=>{
  setStartDateforLogic(e.target.value)
  dispatch(setStartDate(e.target.value))
}
const handleFinishDate =(e)=>{
  dispatch(setFinishDate(e.target.value))
}

dispatch(setContractor(selectedContractor))
dispatch(setAssignTo(selectedAssignedto)) 

const handleFinishDateChange = (e) => {
  const start = new Date(startDate).getTime();
  const end = new Date(e.target.value).getTime();
  if (start <= end) {
    handleFinishDate(e)
  }else{
    toast.error("Please select a valid start date and end date.")
  } 
  
};
// console.log(closeModalCreate)
  return (
    <div>
      <div className={classes.assets}>
        <div className={classes.assetsTop}>
          <h4 className={classes.assetsTitle}>Project Management</h4>
          <div className={classes.assetActions}>
            <input style={{backgroundColor:'white'}} type="text" placeholder="Search" onChange={(e)=>{dispatch(setProjectSearchTerm(e.target.value.toLowerCase()))}}/>
            {/* <button type="button" disabled className={classes.assetEllipsis}>
              <FontAwesomeIcon icon={faEllipsis} />
            </button> */}
            <button
            onClick={()=>
              dispatch(openProjectModalCreate())
              // console.log("test")
            }
              type='button'  
              className={classes.assetfaPlus}>
            <span><FontAwesomeIcon icon={faPlus}  /></span>
            </button>
          </div>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={openModalCreate}
        onClose={()=>dispatch(closeProjectModalCreate())}
        PaperProps={{
          style: {
            width: "100%",
            maxWidth: "600px",
            height: "100%",
          },
        }}
      >
        <form
          action="{{action}}"
          className="h-100"
          method="POST"
          onSubmit={handleFormSubmit}
        >
          <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
            <div className="col-12 row px-2">
              <div
                className="my-auto"
                style={{ fontWeight: "bold", padding: "9px" }}
              >
                Add Project
              </div>
            </div>
          </div>

          <div className="col-12 pb-1">
            <div className="card-body row">
              <div className="col-lg-12 col-sm-12">
                <span className="font-weight-normal row col-12">
                  Project:
                  <div className="font-danger">*</div>
                </span>
                <input
                  type="text"
                  name="name"
                  className="form-control mb-2"
                  value={name}
                  onChange={handleName}
                  required
                />
              </div>
              <div className="col-lg-12 col-sm-12">
                <span className="font-weight-normal row col-12">
                  Location:
                  <div className="font-danger">*</div>
                </span>
                <input
                  type="text"
                  name="location"
                  className="form-control mb-2"
                  value={location}
                  onChange={handleLocation}
                />
              </div>
              <div className="col-lg-12 col-sm-12">
                <span className="font-weight-normal row col-12">
                  Description:
                  <div className="font-danger">*</div>
                </span>
                <textarea
                  type="text"
                  name="description"
                  className="form-control mb-2"
                  style={{height:'100px',textAlign:'left'}}
                  value={description}
                  onChange={handleDiscription}
                />
              </div>
              {/* <div className=" col-lg-6 col-md-6 col-sm-12">
                <label className="font-weight-normal">
                  Start Date <label className="font-danger m-0">*</label>
                </label>
                <input
                  type="datetime-local"
                  className="form-control mb-2"
                  name="start_date"
                  id="start_date"
                  placeholder="Start Date"
                  required
                  value={start_date}
                  onChange={handleStartDate}
                />
              </div> */}
              {/* <div className=" col-lg-6 col-md-6 col-sm-12">
                <label className="font-weight-normal">
                  Finish Date <label className="font-danger m-0">*</label>
                </label>
                <input
                  type="datetime-local"
                  className="form-control mb-2"
                  name="finish_date"
                  id="finish_date"
                  placeholder="Start Date"
                  required
                  value={finish_date}
                  onChange={handleFinishDateChange}
                />
              </div> */}
              {/* <div className=" col-lg-6 col-md-6 col-sm-12">
                <label className="font-weight-normal">
                  Actual Start Date{" "}
                  <label className="font-danger m-0">*</label>
                </label>
                <input
                  type="datetime-local"
                  className="form-control mb-2"
                  name="actual_start_date"
                  id="actual_start_date"
                  placeholder="Start Date"
                  required
                  value={actual_start_date}
                  onChange={handleActualStartDate}
                />
              </div> */}
              {/* <div className=" col-lg-6 col-md-6 col-sm-12">
                <label className="font-weight-normal">
                  Actual Finish Date{" "}
                  <label className="font-danger m-0">*</label>
                </label>
                <input
                  type="datetime-local"
                  className="form-control mb-2"
                  name="actual_finish_date"
                  id="actual_finish_date"
                  placeholder="Start Date"
                  required
                  value={actual_finish_date}
                  onChange={handleActualFinishDate}
                />
              </div> */}
              {/* <div className="col-lg-12 col-sm-12"> */}
                {/* <label className="font-weight-normal">
                  Select Status <span className="font-danger">*</span>
                </label> */}
                {/* <div className="col-12 p-0">
                  <select
                    className="form-control select-meter custom-select2 mb-2"
                    name="status"
                    id="status"
                    style={{ width: "100%" }}
                    required
                    value={status}
                    onChange={handleStatus}
                  >
                    <option value="" disabled>
                      --SELECT--
                    </option>
                    {dummyOptions1.map((option) => (
                      <option key={option.id} value={option.label}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div> */}

                {/* <label className="font-weight-normal">
                  Assign To <span className="font-danger">*</span>
                </label> */}
                {/* <div className="col-12 p-0">
                  <select
                    className="form-control select-meter custom-select2 mb-2"
                    name="assignto"
                    id="assignto"
                    style={{ width: "100%" }}
                    required
                    value={selectedAssignedto}
                    onChange={(e) => {setSelectedAssignedto(e.target.value)}}
                  >
                    <option value="" disabled>
                      --SELECT--
                    </option>
                    {userOptions.map((option) => (
                      <option key={`${option.id}-${option.label}`} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* <label className="font-weight-normal">
                  Contractor <span className="font-danger">*</span>
                </label> */}
                {/* <div className="col-12 p-0">
                  <select
                    className="form-control select-meter custom-select2 mb-2"
                    name="constractor"
                    id="constructor"
                    style={{ width: "100%" }}
                    required
                    value={selectedContractor}
                    onChange={(e)=> setSelectedContractor(e.target.value)}
                  >
                    <option value="" disabled>
                      --SELECT--
                    </option>
                    {contractorOptions.map((option) => (
                      <option key={`${option.id}-${option.label}`} value={option.id}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div> */}
                {/* <div className="col-lg-12 col-sm-12">
                  <span className="font-weight-normal row col-12">
                    Customized Feilds
                  </span>
                  <input
                      type="text"
                      name="customized_feilds"
                      className="form-control mb-2"
                      value={JSON.stringify(customized_fields)}
                      onChange={handleCustomizedFeilds}
                      placeholder="Anything"
                    />
                </div> */}
              {/* </div> */}
            </div>
          </div>

          <div className="row  m-0 p-2 page-profile-footer">
            <button
              className="btn button-btn pmd-btn-fab primary-btn"
              type="submit"
            >
              <span className="button__text">Save</span>
            </button>
          </div>
        </form>
      </Drawer>

    </div>
  );
}
