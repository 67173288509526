/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { smartTryCatch } from '../../../utils'
import SafetyService from './safetyService'

const initialeState = {
  safety_video: [],
  video_consent: [],
  single_video: {},
  // status of getting the safety_video
  isSafetyVideoError: false,
  isSafetyVideoLoading: false,
  isSafetyVideoSuccess: false,

  // status of update the safety_video
  isSafetyVideoUpdateError: false,
  isSafetyVideoUpdateLoading: false,
  isSafetyVideoUpdateSuccess: false,

  // status of delete the safety_video
  isSafetyVideoDeleteError: false,
  isSafetyVideoDeleteLoading: false,
  isSafetyVideoDeleteSuccess: false,

  // status of posting the safety_video
  isPostSafetyVideoError: false,
  isPostSafetyVideoLoading: false,
  isPostSafetyVideoSuccess: false,

  // status posting video_consent
  isVideoConsentError: false,
  isVideoConsentLoading: false,
  isVideoConsentSuccess: false,

  // status get video_consent
  isGetVideoConsentError: false,
  isGetVideoConsentLoading: false,
  isGetVideoConsentSuccess: false,

  // status update video_consent
  isUpdateVideoConsentError: false,
  isUpdateVideoConsentLoading: false,
  isUpdateVideoConsentSuccess: false,

  // state for expire induction session
  isExpireSessionLoading: false,
  isExpireSessionError: false,
  isExpireSessionSuccess: false,

  //state for extend induction session
  isExtendSessionLoading: false,
  isExtendSessionError: false,
  isExtendSessionSuccess: false
}

export const expireVideoConsent = createAsyncThunk(
  'expireVideoConsent',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.expireVideoConsent,
      data,
      thunkAPI
    )
    return result
  }
)

export const extendVideoConsent = createAsyncThunk(
  'extendVideoConsent',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.extendVideoConsent,
      data,
      thunkAPI
    )
    return result
  }
)

export const updateVideoConsentStatus = createAsyncThunk(
  'updateVideoConsentStatus',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.updateVideoConsentStatus,
      data,
      thunkAPI
    )
    return result
  }
)
export const getVideoConsent = createAsyncThunk(
  'getVideoConsent',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.getVideoConsent,
      data,
      thunkAPI
    )
    return result
  }
)

export const postVideoConsent = createAsyncThunk(
  'postVideoConsent',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.postVideoConsent,
      data,
      thunkAPI
    )
    return result
  }
)

export const getSafetyVideo = createAsyncThunk(
  'getSafetyVideo',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.getSafetyVideo,
      data,
      thunkAPI
    )
    return result
  }
)

export const getSafetyVideoById = createAsyncThunk(
  'getSafetyVideoById',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.getSafetyVideoById,
      data,
      thunkAPI
    )
    return result
  }
)

export const deleteSafetyVideo = createAsyncThunk(
  'deleteSafetyVideo',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.deleteSafetyVideo,
      data,
      thunkAPI
    )
    return result
  }
)

export const updateSafetyVideo = createAsyncThunk(
  'updateSafetyVideo',
  async (data, thunkAPI) => {
    const result = await smartTryCatch(
      SafetyService.updateSafetyVideo,
      data,
      thunkAPI
    )
    return result
  }
)

export const postSafetyVideo = createAsyncThunk(
  'postSafetyVideo',
  async (data, thunkAPI) => {
    const postResult = await smartTryCatch(
      SafetyService.postSafetyVideo,
      data,
      thunkAPI
    )
    return postResult
  }
)

export const safetySlice = createSlice({
  name: 'safety',
  initialState: initialeState,
  reducers: {
    reset: state => {
      // state.safety_video = []
      // state.single_video = {}
      // status of getting the safety_vide
      state.isSafetyVideoError = false
      state.isSafetyVideoLoading = false
      state.isSafetyVideoSuccess = false

      // status of update the safety_vide
      state.isSafetyVideoUpdateError = false
      state.isSafetyVideoUpdateLoading = false
      state.isSafetyVideoUpdateSuccess = false

      // status of delete the safety_vide
      state.isSafetyVideoDeleteError = false
      state.isSafetyVideoDeleteLoading = false
      state.isSafetyVideoDeleteSuccess = false

      // status of Post the safety_vide
      state.isPostSafetyVideoError = false
      state.isPostSafetyVideoLoading = false
      state.isPostSafetyVideoSuccess = false

      // status posting video_consent
      state.isVideoConsentError = false
      state.isVideoConsentLoading = false
      state.isVideoConsentSuccess = false

      // status get video_consent
      state.isGetVideoConsentError = false
      state.isGetVideoConsentLoading = false
      state.isGetVideoConsentSuccess = false

      // status update video_consent
      state.isUpdateVideoConsentError = false
      state.isUpdateVideoConsentLoading = false
      state.isUpdateVideoConsentSuccess = false

      // state for expire induction session
      state.isExpireSessionLoading = false
      state.isExpireSessionError = false
      state.isExpireSessionSuccess = false

      //state for extend induction session
      state.isExtendSessionLoading = false
      state.isExtendSessionError = false
      state.isExtendSessionSuccess = false
    }
  },
  extraReducers: builder => {
    builder
      .addCase(expireVideoConsent.pending, state => {
        state.isExpireSessionLoading = true
        state.isExpireSessionError = false
        state.isExpireSessionSuccess = false
      })
      .addCase(expireVideoConsent.fulfilled, (state, action) => {
        state.isExpireSessionLoading = false
        state.isExpireSessionError = false
        state.isExpireSessionSuccess = true
        state.video_consent = state.video_consent.map(video =>
          video.id === action.payload.data.id ? action.payload.data : video
        )
      })
      .addCase(expireVideoConsent.rejected, state => {
        state.isExpireSessionLoading = false
        state.isExpireSessionError = true
        state.isExpireSessionSuccess = false
      })
      .addCase(extendVideoConsent.pending, state => {
        state.isExtendSessionLoading = true
        state.isExtendSessionError = false
        state.isExtendSessionSuccess = false
      })
      .addCase(extendVideoConsent.fulfilled, (state, action) => {
        state.isExtendSessionLoading = false
        state.isExtendSessionError = false
        state.isExtendSessionSuccess = true
        state.video_consent = state.video_consent.map(video =>
          video.id === action.payload.data.id ? action.payload.data : video
        )
      })
      .addCase(extendVideoConsent.rejected, state => {
        state.isExtendSessionLoading = false
        state.isExtendSessionError = true
        state.isExtendSessionSuccess = false
      })
      .addCase(updateVideoConsentStatus.pending, (state, action) => {
        state.isUpdateVideoConsentError = false
        state.isUpdateVideoConsentLoading = true
        state.isUpdateVideoConsentSuccess = false
      })
      .addCase(updateVideoConsentStatus.fulfilled, (state, action) => {
        state.isUpdateVideoConsentError = false
        state.isUpdateVideoConsentLoading = false
        state.isUpdateVideoConsentSuccess = true
        state.video_consent = state.video_consent.map(video =>
          video.id === action.payload.id ? action.payload : video
        )
      })
      .addCase(updateVideoConsentStatus.rejected, (state, action) => {
        state.isUpdateVideoConsentError = true
        state.isUpdateVideoConsentLoading = false
        state.isUpdateVideoConsentSuccess = false
      })
      .addCase(getVideoConsent.pending, (state, action) => {
        state.isGetVideoConsentError = false
        state.isGetVideoConsentLoading = true
        state.isGetVideoConsentSuccess = false
      })
      .addCase(getVideoConsent.fulfilled, (state, action) => {
        state.isGetVideoConsentError = false
        state.isGetVideoConsentLoading = false
        state.isGetVideoConsentSuccess = true
        state.video_consent = action.payload
      })
      .addCase(getVideoConsent.rejected, (state, action) => {
        state.isGetVideoConsentError = true
        state.isGetVideoConsentLoading = false
        state.isGetVideoConsentSuccess = false
      })
      .addCase(postVideoConsent.pending, (state, action) => {
        state.isVideoConsentError = false
        state.isVideoConsentLoading = true
        state.isVideoConsentSuccess = false
      })
      .addCase(postVideoConsent.fulfilled, (state, action) => {
        state.isVideoConsentError = false
        state.isVideoConsentLoading = false
        state.isVideoConsentSuccess = true
        state.video_consent = [...state.video_consent, action.payload]
      })
      .addCase(postVideoConsent.rejected, (state, action) => {
        state.isVideoConsentError = true
        state.isVideoConsentLoading = false
        state.isVideoConsentSuccess = false
      })
      .addCase(getSafetyVideo.pending, (state, action) => {
        state.isSafetyVideoError = false
        state.isSafetyVideoLoading = true
        state.isSafetyVideoSuccess = false
        // state.safety_video = action.payLoad
      })
      .addCase(getSafetyVideo.fulfilled, (state, action) => {
        state.isSafetyVideoError = false
        state.isSafetyVideoLoading = false
        state.isSafetyVideoSuccess = true
        state.safety_video = action.payload
      })
      .addCase(getSafetyVideo.rejected, (state, action) => {
        state.isSafetyVideoError = false
        state.isSafetyVideoLoading = false
        state.isSafetyVideoSuccess = true
      })
      .addCase(getSafetyVideoById.pending, (state, action) => {
        state.isSafetyVideoError = false
        state.isSafetyVideoLoading = true
        state.isSafetyVideoSuccess = false
        // state.safety_video = action.payLoad
      })
      .addCase(getSafetyVideoById.fulfilled, (state, action) => {
        state.isSafetyVideoError = false
        state.isSafetyVideoLoading = false
        state.isSafetyVideoSuccess = true
        state.single_video = action.payload
      })
      .addCase(getSafetyVideoById.rejected, (state, action) => {
        state.isSafetyVideoError = false
        state.isSafetyVideoLoading = false
        state.isSafetyVideoSuccess = true
      })
      .addCase(updateSafetyVideo.pending, (state, action) => {
        state.isSafetyVideoUpdateError = false
        state.isSafetyVideoUpdateLoading = true
        state.isSafetyVideoUpdateSuccess = false
      })
      .addCase(updateSafetyVideo.fulfilled, (state, action) => {
        state.isSafetyVideoUpdateError = false
        state.isSafetyVideoUpdateLoading = false
        state.isSafetyVideoUpdateSuccess = true
        state.safety_video = state.safety_video.map(video =>
          video.id === action.payload.id ? action.payload : video
        )
      })
      .addCase(updateSafetyVideo.rejected, (state, action) => {
        state.isSafetyVideoUpdateError = true
        state.isSafetyVideoUpdateLoading = false
        state.isSafetyVideoUpdateSuccess = false
      })
      .addCase(deleteSafetyVideo.pending, (state, action) => {
        state.isSafetyVideoDeleteError = false
        state.isSafetyVideoDeleteLoading = true
        state.isSafetyVideoDeleteSuccess = false
      })
      .addCase(deleteSafetyVideo.fulfilled, (state, action) => {
        state.isSafetyVideoDeleteError = false
        state.isSafetyVideoDeleteLoading = false
        state.isSafetyVideoDeleteSuccess = true
        state.safety_video = state.safety_video.filter(
          video => video.id !== +action.payload
        )
      })
      .addCase(deleteSafetyVideo.rejected, (state, action) => {
        state.isSafetyVideoDeleteError = true
        state.isSafetyVideoDeleteLoading = false
        state.isSafetyVideoDeleteSuccess = false
      })
      .addCase(postSafetyVideo.pending, (state, action) => {
        state.isPostSafetyVideoError = false
        state.isPostSafetyVideoLoading = true
        state.isPostSafetyVideoSuccess = false
      })
      .addCase(postSafetyVideo.fulfilled, (state, action) => {
        state.isPostSafetyVideoError = false
        state.isPostSafetyVideoLoading = false
        state.isPostSafetyVideoSuccess = true
      })
      .addCase(postSafetyVideo.rejected, (state, action) => {
        state.isPostSafetyVideoError = true
        state.isPostSafetyVideoLoading = false
        state.isPostSafetyVideoSuccess = false
      })
  }
})

export const { reset, safety_video } = safetySlice.actions

export default safetySlice.reducer
