import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTeamsByAuthentication, updateTeam } from '../../../redux/features/teams/teamSlice';
import { getmemberPositionsByAuthentication } from '../../../redux/features/memberPosition/memberPositionSlice';
import { getDepartmentsByAuthentication } from '../../../redux/features/department/departmentSlice';


export default function MemberPopUp ({selectedTeam,handleClosePopup}) {
    const dispatch = useDispatch()
    const {team}=useSelector((state)=>state.team)
    const [formData, setformData]= useState(
      {
        id: team.id,
        email: team.email,
        first_name: team.first_name,
        last_name: team.last_name,
        is_active: team.is_active,
        role: team.role,
        position:team?.position,
        positionId : team?.positionId, 
        department:team?.department,
        departmentId : team?.departmentId,
        hourly_rate : team?.hourly_rate,
        phone_number:team?.phone_number,
          }
    );
    const handleSubmit = async (e) => {
      // Prepare the data to be sent to the backend
      e.preventDefault();
      const dataToSend = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        is_active: formData.is_active,
        "member": {
          position: formData.positionId,
          hourly_rate: formData?.hourly_rate
        },
        department: formData.departmentId,
        phone_number:formData.phone_number,
        role:formData.role
      };
      await dispatch(updateTeam({id : formData.id , dataToSend}));
      handleClosePopup()
    };

    // For getting list of managers
    useEffect(() => {
        async function fetchManagerPositions() {
          await dispatch(getmemberPositionsByAuthentication());
        }
        fetchManagerPositions();
      }, [dispatch]);

     //For getting List Departments
     useEffect(() => {
        async function fetchDepartmentPositions() {
          await dispatch(getDepartmentsByAuthentication());
            // Store department positions in state
        }
        fetchDepartmentPositions();
      }, [dispatch]); 
      
      const {members}=useSelector(state => state.memberPosition);
      const {departments} = useSelector(state => state.department);

  return (
    <>
      <div
        className="card-body mx-auto row col-lg-12 pb-0 mb-5"
        style={{ paddingBottom: '0rem !important' }}
      >
        <div className="col col-lg-12 col-sm-12 mr-2">
          <div className="row mb-0">
            <div className=" col-lg-12 col-sm-12">
              <div className="row mb-0">
                <div className="row mb-0">
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      Email <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="username"
                      type="email"
                      className="form-control mb-2"
                      required
                      disabled
                      placeholder="Email"
                      id="username"
                      onChange={e => setformData(prevData => ({ ...prevData, email: e.target.value }))}
                      value={formData?.email}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">
                      Account Type <span className="font-danger m-0">*</span>
                    </span>

                    {/* <select
                      required
                      className="mb-2"
                      name="role"
                      id="role"
                      onChange={e => setformData(prevData => ({ ...prevData, role: e.target.value }))}
                    >
                      <option selected>
                        { formData?.role}
                      </option>
                    </select> */}

                    {selectedTeam.role==='Administrator'?(
                                          <select
                                          disabled
                                            required
                                            className="mb-2"
                                            name="role"
                                            id="role"
                                            defaultValue={selectedTeam?.role}
                                          >
                                            {/* <option value='Administrator' >Administrator</option> */}
                                          </select>
                    ):
                                          <select
                                          required
                                            className="mb-2"
                                            name="role"
                                            id="role"
                                            defaultValue={selectedTeam?.role}
                                            onChange={e => setformData(prevData => ({ ...prevData, role: e.target.value }))}
                                            // value={e.target.value}
                                          >
                                            <option value='Administrator' >Administrator</option>
                                            <option value='Manager'>Manager</option>
                                            <option value='Member'>Member</option>
                                          </select>
                                          }
                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      First Name <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="first_name"
                      type="text"
                      className="form-control mb-2"
                      required
                      placeholder="First Name"
                      id="first_name"
                      onChange={e => setformData(prevData => ({ ...prevData, first_name: e.target.value }))}
                      value={formData?.first_name}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      Last Name <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="last_name"
                      type="text"
                      className="form-control mb-2"
                      required
                      placeholder="Last name"
                      id="last_name"
                      onChange={e => setformData(prevData => ({ ...prevData, last_name: e.target.value }))}
                      value={formData?.last_name}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">
                      Department <span className="font-danger m-0">*</span>
                    </span>

                    <select
                      required
                      className="mb-2"
                      name="department"
                      id="department"
                      onChange={e => setformData(prevData => ({ ...prevData, departmentId : parseInt(e.target.value) }))}
                     
                    >
                      <option value="" disabled selected>
                        --SELECT--
                      </option>
                      {departments?.map(t =>
                        team?.departmentId === t.id ? (
                          <option value={t.id} selected>
                            {t.name}
                          </option>
                        ) : (
                          <option value={t.id}>{t?.name}</option>
                        ),
                      )}
                    </select>

                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    <span className="font-weight-normal">
                      Position <span className="font-danger m-0">*</span>
                    </span>

                    <select
                      required
                      className="mb-2"
                      name="position"
                      id="position"
                      onChange={e => setformData(prevData => ({ ...prevData, positionId: parseInt(e.target.value) }))}
                      
                    >
                      <option value="" disabled selected>
                        --SELECT--
                      </option>
                      {members?.map(t =>
                        team?.positionId===t.id ? (
                          <option value={t.id} selected>
                          {t.name}
                        </option>
                        ):(
                          <option value={t.id}>{t?.name}</option>
                        )
                          
                        ) }
                    
                    </select>

                    <div className="col-lg-12 col-sm-12 m-0">
                      <span className="font-danger" id="role_error" />
                    </div>
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">
                      Phone Number <span className="font-danger m-0">*</span>
                    </span>
                    <input
                      name="phone_number"
                      type="text"
                      className="form-control mb-2"
                      required
                      placeholder="+6016*******"
                      id="phone_number"
                      onChange={e => setformData(prevData => ({ ...prevData, phone_number: e.target.value }))}
                      value={formData?.phone_number}
                    />
                  </div>
                  <div className=" col-lg-6 col-sm-12">
                    {' '}
                    <span className="font-weight-normal">Hourly Rate</span>
                    <input
                      name="hourate"
                      type="number"
                      className="form-control mb-2"
                      required
                      placeholder="$0"
                      id="hourate"
                      onChange={e => setformData(prevData => ({ ...prevData, hourly_rate: e.target.value }))}
                      value={formData.hourly_rate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" row  m-0 p-2 page-profile-footer">
        <button className="btn primary-btn" type='submit' onClick={handleSubmit}>
          Save
        </button>
      </div>
    </>
  );
}
